import { types, getRoot } from 'mobx-state-tree';
import BaseNode from './BaseNode';

// This provides convenience functions to progress nodes in the tree. eg [fooNode].store to get the progressStore
const progressString = 'Progress';

const ProgressNodeModel = types.model({}).views(self => ({
  get contentNodeType() {
    return self.nodeType.replace(progressString, '');
  },
  get lowerContentNodeType() {
    return self.lowerNodeType.replace(progressString, '');
  },
  get store() {
    const rootStore = getRoot(self);
    return rootStore.progressStore;
  },
})).actions(self => ({
  getContent() {
    if (!self.content) self.content = self[`${self.lowerContentNodeType}Id`];

    return self.content;
  },
}));

const ProgressNode = types.compose(ProgressNodeModel, BaseNode);

export default ProgressNode;

