import React, { Fragment, useState, useEffect } from 'react';
import { AppState } from 'react-native';
import UserInactivity from 'react-native-user-inactivity';
import { Images } from '@nextstep/app/Themes';
import BottomSheetModal from '@nextstep/app/modals/BottomSheetModal';

const InactivityWatcher = (props) => {
  const {
    enabled,
    timeInSeconds,
    isVisible,
    children,
    toggleVisible = () => {},
    actionDeny = () => {},
    actionConfirm = () => {},
  } = props;

  const timeInMilliSeconds = timeInSeconds * 1000;

  const [appState, setAppState] = useState(AppState.currentState);
  const [timer, setTimer] = useState(timeInMilliSeconds);

  const messageTitle = 'Do you want to keep working on this skill?';
  const messageContent = null;
  const buttonConfirmText = 'Yes';
  const buttonDenyText = 'No';

  useEffect(() => {
    function handleAppState(status) {
      setAppState(status);
    }
    AppState.addEventListener('change', handleAppState);
    return function cleanup() {
      AppState.removeEventListener('change', handleAppState);
    };
  });

  const hide = () => {
    const timeResetPadding = 0.0001;
    setTimer(timer + timeResetPadding);
    toggleVisible(false);
  };

  return (
    <Fragment>
      <BottomSheetModal
        dismissible
        onDismiss={() => hide()}
        isVisible={isVisible}
        toggleVisible={() => { toggleVisible(); }}
        messageTitle={messageTitle}
        messageContent={messageContent}
        icon={Images.icons.main.warning}
        primaryAction={{
          text: buttonConfirmText,
          action: actionConfirm,
        }}
        secondaryAction={{
          text: buttonDenyText,
          action: actionDeny,
        }}
      />

      {(enabled && appState === 'active')
        ? (
          <UserInactivity
            timeForInactivity={timer}
            onAction={isActive => {
              toggleVisible(!isActive);
            }}
          >
            {children}
          </UserInactivity>
        ) : (
          <Fragment>
            {children}
          </Fragment>
        )}
    </Fragment>
  );
};
export default InactivityWatcher;
