import React from 'react';
import { inject, observer } from 'mobx-react';
import ViewWrapper from '@nextstep/app/components/ViewWrapper';
import { ButtonText } from '@nextstep/app/components/Button';
import withDimensions from '@nextstep/app/lib/withDimensions';
import { Text, View } from 'react-native';
import { H2, H4, Body1, Body2 } from '@nextstep/app/components/Text';
import Util from '@nextstep/app/services/Util';
import BulletPoint from '@nextstep/app/components/BulletPoint';
import Divider from '@nextstep/app/components/Divider';
import { Collapse, CollapseBody, CollapseHeader } from 'accordion-collapse-react-native';
import { Icon } from 'react-native-elements';
import { currentPalette } from '@nextstep/app/Config/AppConfig';

const webResources = [
  {
    title: 'Centers for Disease Control and Prevention',
    url: 'http://www.cdc.gov/',
    list: [
      { title: 'Standard Precautions', url: 'https://www.cdc.gov/infectioncontrol/basics/standard-precautions.html' },
      { title: 'Transmission - based Precautions', url: 'https://www.cdc.gov/infectioncontrol/basics/transmission-based-precautions.html' },
      { title: 'Contact Precautions - Example Sign', url: 'https://www.cdc.gov/infectioncontrol/pdf/contact-precautions-sign-P.pdf' },
      { title: 'Droplet Precautions  Example Sign', url: 'https://www.cdc.gov/infectioncontrol/pdf/droplet-precautions-sign-P.pdf' },
      { title: 'Airborne Precautions - Example Sign', url: 'https://www.cdc.gov/infectioncontrol/pdf/airborne-precautions-sign-P.pdf' },
      { title: 'Video: Demonstration of Donning (Putting On) Personal ProtectiveEquipment (PPE)', url: 'https://www.youtube.com/watch?v=H4jQUBAlBrI&feature=youtu.be' },
      { title: 'Video: Demonstration of Doffing (Taking Off) Personal ProtectiveEquipment (PPE)', url: 'https://www.youtube.com/watch?v=PQxOc13DxvQ' },
      { title: 'Video Demonstration: Disinfect Your Shoes', url: 'https://youtu.be/fLf-kxOBMEA' },
      { title: 'CDC recommendations on PPE Sequence', url: 'https://www.cdc.gov/hai/pdfs/ppe/ppe-sequence.pdf' },
      { title: 'How to Remove Gloves', url: 'https://www.cdc.gov/vhf/ebola/pdf/poster-how-to-remove-gloves.pdf' },
      { title: 'Fight Germs. Wash Your Hands!', url: 'https://www.youtube.com/watch?v=eZw4Ga3jg3E&feature=youtu.be' },
      { title: 'Respiratory Hygiene/Cough Etiquette in Healthcare Settings', url: 'https://www.cdc.gov/flu/professionals/infectioncontrol/resphygiene.htm' },
      { title: 'Do\'s and Don\'ts of Facemask Usage', url: 'https://www.cdc.gov/coronavirus/2019-ncov/downloads/hcp/fs-facemask-dos-donts.pdf' },
      { title: 'Proper Respirator Usage', url: 'https://www.cdc.gov/coronavirus/2019-ncov/downloads/hcp/fs-respirator-on-off.pdf' },
      { title: 'Guidelines for Handling Sharp', url: 'https://www.cdc.gov/sharpssafety/index.html' },
      { title: 'Infection Control Guidance for Healthcare Professionals about Coronavirus(COVID-19)', url: 'https://www.cdc.gov/coronavirus/2019-ncov/hcp/infection-control.html' },
      { title: 'CDC recommendations on the use of PPE when caring for someone withCOVID-19', url: 'https://www.cdc.gov/coronavirus/2019-ncov/downloads/A_FS_HCP_COVID19_PPE.pdf' },
      { title: 'Quarantine and Isolation', url: 'https://www.cdc.gov/quarantine/quarantineisolation.html' },
    ],
  },
  { title: 'Centers for Medicare & Medicaid Services (CMS) - Minimum Data Set (MDS)Manual', url: 'https://www.cms.gov/Medicare/Quality-Initiatives-Patient-Assessment-Instruments/NursingHomeQualityInits/NHQIMDS30' },
  { title: 'Department of State Health Services - Audiovisual Library', url: 'http://www.dshs.state.tx.us/avlib/' },
  { title: 'Environmental Protection Agency (EPA): Registered Disinfectants', url: 'https://www.epa.gov/pesticide-registration/selected-epa-registered-disinfectants' },
  { title: 'Behavioral & Environmental Interventions (HHSC)', url: 'https://hhs.texas.gov/about-hhs/process-improvement/improving-services-texans/behavioral-health-services/substance-use-misuse-prevention' },
  {
    title: 'Employability Checks (HHSC)',
    url: 'https://hhs.texas.gov/doing-business-hhs/licensing-credentialing-regulation/credentialing/nurse-aide-registry',
    list: [
      { title: 'Nurse Aide Registry (NAR)' },
      { title: 'Employee Misconduct Registry (EMR)' },
    ],
  },
  { title: 'Information regarding Culture Change (HHSC)', url: 'https://hhs.texas.gov/doing-business-hhs/provider-portals/long-term-care-providers/nursing-facilities/nf-culture-change' },
  { title: 'Information regarding Infection Control (HHSC)', url: 'https://hhs.texas.gov/doing-business-hhs/provider-portals/long-term-care-providers/nursing-facilities/quality-monitoring-program/evidence-based-best-practices/infection-control' },
  { title: 'Nurse Aide Competency and Evaluation Program (NATCEP) website (HHSC)', url: 'https://hhs.texas.gov/doing-business-hhs/licensing-credentialing-regulation/credentialing/nurse-aide-training-competency-evaluation-program-natcep' },
  { title: 'Nurse Aide Registry (NAR) website (HHSC)', url: 'https://hhs.texas.gov/doing-business-hhs/licensing-credentialing-regulation/credentialing/nurse-aide-registry' },
  { title: 'Maslow\'s Hierarchy of Needs', url: 'https://www.simplypsychology.org/maslow.html' },
  { title: 'My Plate', url: 'https://www.myplate.gov/eat-healthy/what-is-myplate' },
  { title: 'National Council of State Boards of Nursing (NCSBN)', url: 'https://www.ncsbn.org/index.htm' },
  { title: 'Professional Boundaries: Handout', url: 'https://www.ncsbn.org/ProfessionalBoundaries_Complete.pdf' },
  { title: 'NCSBN', url: 'https://www.ncsbn.org/ProfessionalBoundaries_Complete.pdf' },
  { title: 'Professional Boundaries, Leader to Leader (NCSBN)<', url: 'https://www.ncsbn.org/L2L_Spring2010.pdf' },
  { title: 'Social media: Handout (NCSBN)', url: 'https://www.ncsbn.org/NCSBN_SocialMedia.pdf' },
  { title: 'Osha Guidance for Contaminated Laundry', url: 'https://www.osha.gov/SLTC/etools/hospital/laundry/laundry.html' },
  { title: 'Prometric Texas Nurse Aide website', url: 'https://www.prometric.com/nurseaide/tx' },
  { title: 'Texas Department of Public Safety Crime Record Service', url: 'https://www.dps.texas.gov/administration/crime_records/pages/index.htm' },
  { title: 'The Health Insurance Portability and Accountability Act of 1996 (HIPAA)Privacy and Security Rules website', url: 'http://www.hhs.gov/ocr/privacy/' },
];
const glossaryOfTerms = [
  { acronym: 'A.M.', definition: 'Morning' },
  { acronym: 'AD', definition: 'Alzheimer\'s Disease' },
  { acronym: 'ADL', definition: 'Activities of Daily Living' },
  { acronym: 'AIDS', definition: 'Acquired Immune Deficiency Syndrome' },
  { acronym: 'b.i.d.', definition: 'Latin bis in die meaning "twice a day"' },
  { acronym: 'BP', definition: 'Blood Pressure' },
  { acronym: '°C', definition: 'Degrees Celsius' },
  { acronym: 'cc', definition: 'cubic centimeter' },
  { acronym: 'CDC', definition: 'Centers for Disease Control' },
  { acronym: 'CEP', definition: 'Competency Evaluation Program' },
  { acronym: 'CPR', definition: 'cardiopulmonary resuscitation' },
  { acronym: 'CVA', definition: 'cerebrovascular accident - a general term which encompasses such problems as stroke and cerebral hemorrhage.' },
  { acronym: 'EMS', definition: 'Emergency Medical Service' },
  { acronym: 'mmHg', definition: 'millimeters of mercury to measure the partial pressure of a gas (as for measurement of blood pressure)' },
  { acronym: 'HIV', definition: 'human immunodeficiency virus' },
  { acronym: 'I & O', definition: 'Intake and Output' },
  { acronym: 'IV', definition: 'Within or into a vein' },
  { acronym: 'LTC', definition: 'Long Term Care' },
  { acronym: 'ml', definition: 'measures, milliliter' },
  { acronym: 'mm', definition: 'measures, millimeter' },
  { acronym: 'MDS', definition: 'Minimum Data Set' },
  { acronym: 'MRSA', definition: 'Methicillin Resistant Staphylococcus Aureus' },
  { acronym: 'MSDS', definition: 'Material Safety Data Sheets' },
  { acronym: 'NATCEP', definition: 'Nurse Aide Training and Competency Evaluation Program' },
  { acronym: 'NPO', definition: 'Do not take anything by mouth' },
  { acronym: 'OBRA', definition: 'Omnibus Reconciliation Act of 1987' },
  { acronym: '°F', definition: 'Degrees Fahrenheit' },
  { acronym: 'OSHA', definition: 'Occupational Safety And Health Administration' },
  { acronym: 'oz', definition: 'Italian word "onza" meaning ounce or ounces (fluid measure)' },
  { acronym: 'P', definition: 'Pulse' },
  { acronym: 'P.M.', definition: 'Evening' },
  { acronym: 'PPE', definition: 'Personal Protective Equipment worn by health care workers such as gloves, gowns, masks' },
  { acronym: 'R', definition: 'Respiration' },
  { acronym: 'SoB', definition: 'Shortness of Breath' },
  { acronym: 'STATA', definition: 'common medical abbreviation derived from the Latin word "statim" which means immediately. Used to imply "urgent" or "rush"' },
  { acronym: 'T', definition: 'Temperature' },
  { acronym: 'AX', definition: 'Temperature taken at the axilla (under arm area)' },
  { acronym: 'TAC', definition: 'Texas Administrative Code' },
  { acronym: 'TB', definition: 'commonly used for tuberculosis' },
  { acronym: 'TPR', definition: 'Temperature/Pulse/Respiration' },
  { acronym: 'USDA', definition: 'United States Department of Agriculture' },
  { acronym: 'VRE', definition: 'Vancomycin Resistant Enterococcus' },
];

export function TexasResourcesScreen() {
  return (
    <>
      <ViewWrapper
        smSize={12}
        mdSize={6}
        lgSize={6}
        navigationBar
      >
        <View style={{ paddingVertical: 25, paddingHorizontal: 40 }}>
          <H2>Texas Resource Page</H2>

          <Divider gutter={28} />

          <Collapse>

            <CollapseHeader style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
              <H4 textAlign="left">Web Resources</H4>
              <Icon
                name={'keyboard-arrow-down'}
                color={currentPalette.basic.black}
                underlayColor="transparent"
              />
            </CollapseHeader>

            <CollapseBody>
              <View style={{ height: 28 }} />

              {webResources.map((r, index) => (
                <View>
                  <View style={{ flexDirection: 'row', paddingVertical: 6 }}>
                    <Body2 textAlign={'left'}>{`${index + 1}. `}</Body2>
                    <ButtonText onPress={() => (r.url ? Util.openURL(r.url) : {})} style={{ flex: 1, alignItems: 'flex-start' }} textAlign={'left'}>{r.title}</ButtonText>
                  </View>

                  {r.list && r.list.map(item => (
                    <BulletPoint>
                      {item.url
                        ? <ButtonText onPress={() => Util.openURL(item.url)} textAlign={'left'}>{item.title}</ButtonText>
                        : <Body2 textAlign={'left'}>{item.title}</Body2>
                      }
                    </BulletPoint>
                  ))}
                </View>
              ))}
            </CollapseBody>

          </Collapse>

          <Divider gutter={28} />

          <Collapse>

            <CollapseHeader style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
              <H4 textAlign="left">Glossary of Terms</H4>
              <Icon
                name={'keyboard-arrow-down'}
                color={currentPalette.basic.black}
                underlayColor="transparent"
              />
            </CollapseHeader>

            <CollapseBody>
              <View style={{ height: 28 }} />

              {glossaryOfTerms.map(t => (
                <Text style={{ paddingBottom: 8 }}>
                  <Body2 textAlign={'left'}>{`${t.acronym}: `}</Body2>
                  <Body1>{t.definition}</Body1>
                </Text>
              ))}
            </CollapseBody>

          </Collapse>

        </View>
      </ViewWrapper>
    </>
  );
}

export default withDimensions(inject('rootStore')(observer(TexasResourcesScreen)));
