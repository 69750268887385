import SkillsetChildNavigator from './SkillsetChildNavigator';

export class SkillsetNavigator extends SkillsetChildNavigator {
  goToNextSkillset = async () => {
    const { navigation, rootStore: { progressStore, contentStore, sessionStore } } = this.props;
    const { learner } = sessionStore;
    const { skillset, skillsets } = contentStore;

    if (progressStore.metrics?.percentage === 100) {
      navigation.replace('OutcomeScreen');
      return;
    }

    const currentSkillSetIndex = skillsets.indexOf(skillset);
    const nextSkillSet = skillsets[currentSkillSetIndex + 1];

    if (!nextSkillSet || learner.isGuest) navigation.navigate('App', { screen: 'Learn' });

    else {
      await contentStore.setSkillset(nextSkillSet.key);
      await progressStore.setSkillsetProgress(nextSkillSet.id);
      await progressStore.setExpandedSkillsetKey(nextSkillSet.key);
      this.startFirstSkillsetChild();
    }
  }

  startFirstSkillsetChild = async () => {
    const { navigation, rootStore: { progressStore, contentStore } } = this.props;

    const firstSkill = contentStore.skillset.skills[0];

    if (firstSkill) {
      await contentStore.setSkill(firstSkill.key);
      await progressStore.setSkillProgress(firstSkill.id);

      await progressStore.startSkill();

      this.startFirstSkillChild(firstSkill);
    } else navigation.navigate('App', { screen: 'Learn' });
  }

  startFirstSkillChild = async () => {
    const { navigation, rootStore: { contentStore, progressStore } } = this.props;

    const firstStep = contentStore.skill.steps[0];

    if (firstStep) {
      await contentStore.setStep(firstStep.key);
      await progressStore.setStepProgress(firstStep.id);

      this.goToNextSkillChild();
    } else navigation.navigate('App', { screen: 'Learn' });
  }
}

export default SkillsetNavigator;
