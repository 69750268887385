import React from 'react';
import { inject, observer } from 'mobx-react';
import { FullViewSpinner } from '@nextstep/app/components/SharedUI';
import ViewWrapper from '@nextstep/app/components/ViewWrapper';
import withDimensions, { DeviceType } from '@nextstep/app/lib/withDimensions';
import AppMainHeader from '@nextstep/app/components/AppMainHeader';
import MarkdownRender from '@nextstep/app/components/MarkdownRender';
import { View } from 'react-native';

export function CourseDetailsScreen(props) {
  const { rootStore: { contentStore }, navigation, dimensions } = props;
  const { course } = contentStore;

  if (!course) {
    return <FullViewSpinner />;
  }

  return (
    <>
      {dimensions.deviceType !== DeviceType.Desktop && <AppMainHeader navigation={navigation} />}
      <ViewWrapper
        smSize={12}
        mdSize={6}
        lgSize={6}
        navigationBar
      >
        <View style={{ paddingVertical: 25 }}>
          <MarkdownRender data={course.description} />
        </View>
      </ViewWrapper>
    </>
  );
}

export default withDimensions(inject('rootStore')(observer(CourseDetailsScreen)));
