import { types } from 'mobx-state-tree';
import Course from '@nextstep/app/models/Course';
import Skillset from '@nextstep/app/models/Skillset';
import Skill from '@nextstep/app/models/Skill';
import Step from '@nextstep/app/models/Step';
import LabSkill from '@nextstep/app/models/LabSkill';
import AssessmentBlock from '@nextstep/app/models/AssessmentBlock';
import LinearAssessment from '@nextstep/app/models/LinearAssessment';
import LinearAssessmentIteration from '@nextstep/app/models/LinearAssessmentIteration';
import PracticeLab from '@nextstep/app/models/PracticeLab';
import AssignmentQuestion from '@nextstep/app/models/AssignmentQuestion';
import Question from '@nextstep/app/models/Question';
import Questionnaire from '@nextstep/app/models/Questionnaire';
import ContentStoreActions from './ContentStoreActions';

const ContentStore = types
  .model('ContentStore', {
    // References
    skillset: types.safeReference(Skillset),
    skill: types.safeReference(Skill),
    step: types.safeReference(Step),
    practiceLab: types.safeReference(PracticeLab),
    assessmentBlock: types.safeReference(AssessmentBlock),
    linearAssessment: types.safeReference(LinearAssessment),
    linearAssessmentIteration: types.safeReference(LinearAssessmentIteration),
    question: types.safeReference(Question),
    labSkill: types.safeReference(LabSkill), // In-person Tab
    assignmentQuestion: types.safeReference(AssignmentQuestion), // Assignments Tab

    // Attributes
    identifier: types.optional(types.identifier, 'ContentStore'),
    course: types.maybe(Course),
    skillsets: types.maybe(types.array(Skillset)),
    practiceLabs: types.maybe(types.array(PracticeLab)),
    assignmentQuestions: types.maybe(types.array(AssignmentQuestion)),
    questionnaire: types.maybe(Questionnaire),
  })
  .volatile(() => ({ loading: types.optional(types.boolean, false) }))
  .actions(ContentStoreActions);

export default ContentStore;
