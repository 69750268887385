import React from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import { Icon } from 'react-native-elements';
import hexToRgba from 'hex-to-rgba';
import { Palette } from '@nextstep/app/Themes';
import VideoScreenStyles from '@nextstep/app/containers/styles/VideoScreenStyles';
import AdaptiveContainerStyles from '@nextstep/app/containers/styles/AdaptiveContainerStyles';
import withDimensions, { DeviceType } from '@nextstep/app/lib/withDimensions';
import { Body2 } from '@nextstep/app/components/Text';
import DebouncedTouchableOpacity from '@nextstep/app/components/DebouncedTouchableOpacity';
import ResultBanner from './ResultBanner';

const currentPalette = Palette.light;

const DefaultStyle = {
  autoAdvance: {
    position: 'absolute',
    height: '100%',
    backgroundColor: hexToRgba('#fff', 0.4),
  },
};

const BaseFooter = (props) => {
  const {
    answered, color, onPress, buttonText,
    correct, dimensions, fadeBanner,
    timerPercentage, loading,
  } = props;
  const Styles = VideoScreenStyles(dimensions.deviceType);
  const MainStyles = AdaptiveContainerStyles(dimensions.deviceType);

  return (
    <View style={{ flex: dimensions.deviceType !== DeviceType.Mobile ? 1 : null }}>

      {answered && <ResultBanner fadeBanner={fadeBanner} correct={correct} />}

      <View
        style={[
          MainStyles.bottomBar,
          MainStyles.textContainer,
          {
            backgroundColor: color,
            justifyContent: 'flex-end',
          },
        ]}
      >

        <DebouncedTouchableOpacity
          onPress={onPress}
          style={Styles.iconButton}
          disabled={loading}
        >
          <Body2 color={currentPalette.primary.contrastText}>
            {buttonText}
          </Body2>

          <Icon
            name="keyboard-arrow-right"
            color={currentPalette.primary.contrastText}
            underlayColor="transparent"
          />
        </DebouncedTouchableOpacity>
      </View>

      <View style={[DefaultStyle.autoAdvance, { width: `${timerPercentage}%` }]} />

    </View>
  );
};

BaseFooter.propTypes = {
  buttonText: PropTypes.string,
  color: PropTypes.string,
  correct: PropTypes.bool,
  fadeBanner: PropTypes.object,
  onPress: PropTypes.func,
  answered: PropTypes.bool,
  timerPercentage: PropTypes.number,
  loading: PropTypes.bool,
};

BaseFooter.defaultProps = {
  buttonText: 'Continue',
  color: currentPalette.primary.main,
  correct: false,
  fadeBanner: undefined,
  onPress: () => {},
  answered: false,
  timerPercentage: 0,
  loading: false,
};

export default withDimensions(BaseFooter);
