import React from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import { Palette, Typography } from '@nextstep/app/Themes';
import { Icon } from 'react-native-elements';
import { ButtonText } from '@nextstep/app/components/Button';
import Util from '@nextstep/app/services/Util';

const currentPalette = Palette.light;

const ExternalLink = ({ link, text }) => (
  <View style={{ flexDirection: 'row', flexWrap: 'nowrap' }}>
    <Icon
      name={'md-open-outline'}
      type="ionicon"
      color={currentPalette.primary.main}
      size={25}
    />

    <View style={{ alignItems: 'flex-start', flex: 1, alignSelf: 'center', paddingLeft: 8 }}>
      <ButtonText
        onPress={() => Util.openURL(link)}
        textStyle={Typography.body1}
      >
        {text}
      </ButtonText>

    </View>
  </View>
);

ExternalLink.propTypes = {
  link: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default ExternalLink;
