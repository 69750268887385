import React, { Component } from 'react';
import { View } from 'react-native';
import { inject, observer } from 'mobx-react';
import Intercom from 'react-native-intercom';
import { Images } from '@nextstep/app/Themes';
import { trackAnalytics } from '@nextstep/app/services/Analytics';
import MentorHeader from '@nextstep/app/components/MentorHeader';
import { Body2 } from '@nextstep/app/components/Text';
import { ButtonOutline, ButtonFilled } from '@nextstep/app/components/Button';
import { FullViewSpinner } from '@nextstep/app/components/SharedUI';
import Constants from '@nextstep/app/Config/Constants';
import ViewWrapper from '@nextstep/app/components/ViewWrapper';
import { Row, Column } from '@nextstep/app/lib/ResponsiveLayout';
import withDimensions, { getDeviceType, DeviceType } from '@nextstep/app/lib/withDimensions';
import { TextInput } from '@nextstep/app/components/TextInput';
import NavigationService from '@nextstep/app/services/NavigationService';
import AdaptiveContainerStyles from '@nextstep/app/containers/styles/AdaptiveContainerStyles';
import { currentPalette } from '@nextstep/app/Config/AppConfig';
import ExternalLink from '@nextstep/app/components/ExternalLink';
import Config from '@nextstep/app/Config/DebugConfig';

class OKEnrollmentAgreement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      legalName: {
        value: undefined,
        showError: false,
        errorMessage: undefined,
      },
      buttonDisabled: false,
      selectedInput: undefined,
      loading: false,
    };
  }

  componentDidMount() {
    const { rootStore: { sessionStore } } = this.props;
    sessionStore.getEnrollmentAgreements().then();
  }

  openIntercom = async () => {
    const { rootStore: { sessionStore } } = this.props;
    await sessionStore.intercomVerifyIdentity();
    await Intercom.displayMessageComposer();
    trackAnalytics('enrollmentAgreementQuestions', {});
    trackAnalytics('intercomOpened', {});
  }

  async onNextPressed() {
    this.setState({ buttonDisabled: true });

    const { legalName } = this.state;
    const { rootStore: { sessionStore } } = this.props;

    if (this.validateFields()) {
      await sessionStore.acceptEnrollmentAgreement({ signature: legalName.value });
      NavigationService.navigateToLearnerInitialScreen('Learn');
    }

    this.setState({ buttonDisabled: false });
  }

  validateFields() {
    const { legalName } = this.state;
    let validated = true;

    if (!legalName.value) {
      this.setState({
        legalName: {
          value: legalName.value,
          showError: true,
          errorMessage: 'Please enter your full name.',
        },
      });

      validated = false;
    }

    return validated;
  }

  updateStateField = (objectName: string, text: string, valueFieldName: string = 'value') => {
    const { [objectName]: object } = this.state;

    if (object.showError) {
      this.setState({ [objectName]: { showError: false } });
    }
    this.setState({ [objectName]: { [valueFieldName]: text } });
  }

  render() {
    const { rootStore: { sessionStore }, dimensions } = this.props;
    const { legalName } = this.state;
    const { loading, buttonDisabled } = this.state;
    const { selectedInput } = this.state;

    const deviceType = getDeviceType(dimensions);
    const Styles = AdaptiveContainerStyles(deviceType);

    if (loading) return (<FullViewSpinner showBackground />);

    return (
      <ViewWrapper smSize={12} mdSize={6} lgSize={6} topNavBarColor={currentPalette.background.purple}>

        <MentorHeader
          text={'OK Enrollment Agreement'}
          color="orange"
          mentorContainerStyle={{ backgroundColor: currentPalette.background.purple }}
          customMentorImage={Images.mentor.extra.agreement}
          customMentorImageStyle={{ width: 100, height: 198 }}
        />

        <View style={Styles.textContainer}>
          <Body2>
            Enrollment Agreement Required Information for Trainees
          </Body2>

          <View style={{ height: 17 }} />

          <ExternalLink
            link={'https://www.nextstep.com/policy-agreement'}
            text={'View Enrollment Agreement'}
          />

          <View style={{ height: 17 }} />

          <View style={{ height: deviceType === DeviceType.Mobile ? 10 : 20 }} />

          <TextInput
            title={'Sign your Name'}
            data={legalName}
            customAttributes={{
              placeholder: 'Full Legal Name',
              ref: (ref) => { this.legalName = ref; },
              returnKeyType: 'done',
            }}
            valueType={Constants.InputTextTypes.custom}
            onFocus={() => this.setState({ selectedInput: this.legalName })}
            isFocused={selectedInput === this.legalName}
            onChangeText={(text) => this.updateStateField('legalName', text)}
            onEndEditing={() => trackAnalytics('agreementLegalNameInteracted')}
          />
        </View>

        {/* Buttons */}
        <View style={Styles.textContainer}>
          <View style={{ height: deviceType === DeviceType.Mobile ? 16 : 6 }} />

          <Row style={{ justifyContent: 'center', margin: 'auto' }} gutter={15}>
            {deviceType !== DeviceType.Mobile && (
              <Column smSize={12} mdSize={6} lgSize={5} style={{ alignItems: 'center' }}>
                <ButtonOutline
                  width="100%"
                  disabled={loading}
                  onPress={() => this.openIntercom()}
                >
                  Chat with Mentor
                </ButtonOutline>
              </Column>
            )}

            <Column smSize={12} mdSize={6} lgSize={5} style={{ alignItems: 'center' }}>
              <ButtonFilled
                onPress={() => this.onNextPressed()}
                disabled={buttonDisabled}
                width={'100%'}
                onLongPress={() => (Config.staging ? sessionStore.logout() : {})}
              >
                I Agree
              </ButtonFilled>
            </Column>

            {deviceType === DeviceType.Mobile && (
              <Column smSize={12} mdSize={6} lgSize={5} style={{ alignItems: 'center' }}>
                <ButtonOutline
                  width="100%"
                  disabled={loading}
                  onPress={() => this.openIntercom()}
                >
                  Chat with Mentor
                </ButtonOutline>
              </Column>
            )}
          </Row>
        </View>

        <View style={{ height: 26 }} />

      </ViewWrapper>
    );
  }
}

export default withDimensions(inject('rootStore')(observer(OKEnrollmentAgreement)));
