/* eslint-disable react/prop-types */
import React from 'react';
import { Image, ScrollView, TouchableOpacity, View } from 'react-native';
import { Text } from 'react-native-elements';

import { Images } from '@nextstep/app/Themes/index';
import CustomModal from '@nextstep/app/components/CustomModal';
import Util from '@nextstep/app/services/Util';
import { Body1, Body2 } from '@nextstep/app/components/Text';
import ModalStyles from './styles/TermModalStyles';

const TermModal = ({ term, toggleVisible, isVisible }) => {
  if (!term) return null;

  return (
    <CustomModal
      isVisible={isVisible}
      onBackdropPress={() => toggleVisible()}
      onSwipeComplete={() => toggleVisible()}
      animationIn={'zoomIn'}
      animationOut={'zoomOut'}
      propagateSwipe
    >
      <View style={ModalStyles.container}>
        <ScrollView>
          <View style={ModalStyles.header}>

            <View style={{ maxWidth: '90%' }}>
              <Body2 textAlign="left">{Util.toTitleCase(term.title)}</Body2>
              <Body1>{term.pronunciation}</Body1>
            </View>

            <TouchableOpacity onPress={() => toggleVisible()}>
              <Image
                style={{ width: 14, height: 14, margin: 8 }}
                source={Images.icons.exit}
              />
            </TouchableOpacity>
          </View>

          <View style={{ borderBottomColor: 'rgba(45, 40, 54, 0.1)', borderBottomWidth: 1 }} />

          <View style={ModalStyles.body}>
            <Text>
              <Body2 textAlign="left">Definition: </Body2>
              <Body1 textAlign="left">{term.definition}</Body1>
            </Text>
          </View>

        </ScrollView>
      </View>
    </CustomModal>
  );
};

export default TermModal;
