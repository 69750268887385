/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import { Image, TouchableOpacity, View } from 'react-native';
import { Card as RNCard } from 'react-native-elements';
import PropTypes from 'prop-types';
import { Palette } from '@nextstep/app/Themes';
import { Body2 } from './Text';

const currentPalette = Palette.light;

const defaultStyle = {
  card: { flex: 1 },

  cardContainer: {
    flex: 1,
    borderRadius: 10,
    justifyContent: 'center',
  },

  stateContainer: {
    alignItems: 'center',
    paddingVertical: 20,
    paddingHorizontal: 30,
  },

  image: {
    width: 100,
    height: 100,
    resizeMode: 'contain',
  },
};

const StateCard = (props) => {
  const [hover, changeHoverState] = useState(false);

  const stateNameStyle = {
    color: hover ? currentPalette.primary.main : currentPalette.text.main,
    ...props.stateNameStyle,
  };

  return (
    <TouchableOpacity
      onPress={() => props.onPress()}
      disabled={!props.onPress || props.disabled}
      style={defaultStyle.card}
      onMouseOver={() => changeHoverState(true)}
      onMouseOut={() => changeHoverState(false)}
    >
      <RNCard
        containerStyle={[
          defaultStyle.cardContainer,
          hover && { backgroundColor: currentPalette.background.secondary, borderColor: currentPalette.primary.disable },
        ]}
      >
        <View style={defaultStyle.stateContainer}>
          <Image style={defaultStyle.image} source={props.image} />

          <Body2 style={stateNameStyle} textAlign={'center'}>
            {props.stateName}
          </Body2>
        </View>

      </RNCard>
    </TouchableOpacity>
  );
};

StateCard.propTypes = {
  image: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  onPress: PropTypes.func,
  disabled: PropTypes.bool,
  stateName: PropTypes.string,
  stateNameStyle: PropTypes.object,
};

StateCard.defaultProps = {
  image: undefined,
  onPress: undefined,
  disabled: false,
  stateName: undefined,
  stateNameStyle: {},
};

export default StateCard;
