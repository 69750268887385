import { types } from 'mobx-state-tree';
import { AssignmentStatus, AssignmentStatusEnumeration } from '@nextstep/app/Config/Constants';
import ProgressNode from './ProgressNode';
import AssignmentQuestionInteraction from './AssignmentQuestionInteraction';

const AssignmentQuestionProgressModel = types.model('AssignmentQuestionProgress', {
  id: types.identifierNumber,
  assignmentQuestionId: types.number,
  updatedOn: types.string,
  questionInteraction: types.optional(types.array(AssignmentQuestionInteraction), []),
  status: types.enumeration(AssignmentStatusEnumeration),
}).views(self => ({
  get isLocked() {
    return self.status === AssignmentStatus.locked;
  },
  get isUnlocked() {
    return self.status === AssignmentStatus.unlocked;
  },
  get isApproved() {
    return self.status === AssignmentStatus.approved;
  },
  get isWaitingForApproval() {
    return self.status === AssignmentStatus.waitingForApproval;
  },
  get needsResubmission() {
    return self.status === AssignmentStatus.needsResubmission;
  },
}));

const AssignmentQuestionProgress = types.compose(AssignmentQuestionProgressModel, ProgressNode).named('AssignmentQuestionProgress');

export default AssignmentQuestionProgress;
