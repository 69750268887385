import React, { useEffect } from 'react';
import { Image, View, Platform, SafeAreaView } from 'react-native';
import { inject, observer } from 'mobx-react';
import { ApplicationStyles, Images, Palette } from '@nextstep/app/Themes';
import { Row, Column } from '@nextstep/app/lib/ResponsiveLayout';
import InAppNotifications from '@nextstep/app/containers/InAppNotifications';
import Constants from '@nextstep/app/Config/Constants';
import HeaderAction from './HeaderAction';
import DebouncedTouchableOpacity from './DebouncedTouchableOpacity';

const currentPalette = Palette.light;

const Styles = {
  bottomBorder: {
    borderBottomColor: currentPalette.basic.gray,
    borderBottomWidth: 1,
    width: '100%',
  },
  container: {
    justifyContent: 'center',
    flex: 1,
  },

  mainContainer: {
    flexDirection: 'row',
    paddingHorizontal: 12,
  },

  leftContainer: { alignItems: 'flex-start' },

  centerContainer: {
    alignItems: 'center',
    flex: 1,
  },

  rightContainer: { alignItems: 'flex-end' },
};

export default inject('rootStore')(observer(({ rootStore, navigation }) => {
  const { sessionStore } = rootStore;
  const { learner } = sessionStore;
  const avatar = learner?.profileImage ? { uri: learner.profileImage } : Images.icons.main.defaultAvatar;

  const syncInAppNotifications = async () => {
    await sessionStore.syncInAppNotifications();
  };

  useEffect(() => {
    syncInAppNotifications();
  }, []);

  return (
    <Row style={Styles.bottomBorder}>
      <Column style={Styles.shadow}>
        {Platform.OS !== 'web' && <SafeAreaView style={{ backgroundColor: currentPalette.background.white }} />}

        <View style={[Styles.mainContainer, { backgroundColor: currentPalette.background.white }]}>

          <View style={[Styles.container, Styles.leftContainer]}>
            <HeaderAction
              object={{
                icon: 'menu',
                action: () => (navigation.toggleMenu || navigation.toggleDrawer)(),
              }}
              side="LEFT"
            />
          </View>

          <View style={[Styles.centerContainer]}>
            <Image
              style={ApplicationStyles.navbarTitle}
              source={Images.other.nextStepLogo}
            />
          </View>

          <View style={[Styles.container, Styles.rightContainer]}>
            {learner && !learner.isGuest && (
              <DebouncedTouchableOpacity
                onPress={() => (!learner.isGuest ? navigation.navigate('App', { screen: 'SettingsScreen' }) : {})}
                hitSlop={{ top: 10, left: 10, right: 10, bottom: 10 }}
              >
                <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                  <Image
                    source={avatar}
                    style={[ApplicationStyles.icon, { borderRadius: 50, resizeMode: 'cover' }]}
                  />
                </View>
              </DebouncedTouchableOpacity>
            )}
          </View>

        </View>

        <InAppNotifications notifications={sessionStore.inAppNotifications?.filter(notification => notification.style === Constants.InAppReminderTypes.banner)} />

      </Column>
    </Row>
  );
}));
