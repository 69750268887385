import React, { Component } from 'react';
import { URL } from 'react-native-url-polyfill';
import { Linking, AppState, View, Platform } from 'react-native';
import { getStateFromPath } from '@react-navigation/native';
import AsyncStorage from '@react-native-community/async-storage';
import NetInfo from '@react-native-community/netinfo';
import { inject, observer } from 'mobx-react';
import { ErrorScreen } from '@nextstep/app/lib/ErrorUtils';
import RootNavigator from '@nextstep/app/navigation/RootNavigator';
import { PersistenceKeys } from '@nextstep/app/Config/Constants';
import { FullViewSpinner } from '@nextstep/app/components/SharedUI';
import NavigationService from '@nextstep/app/services/NavigationService';
import styles from './styles/RootContainerStyles';

const linking = {
  config: null,
  prefixes: ['https://learn.nextstep.careers', 'https://learn.nextstep.com', 'https://learn-staging.nextstep.careers', 'https://learn-staging.nextstep.com', 'http://localhost:8080', 'nextstep://nextstep', 'nextstep://'],
};

const excludedInitialLinks = [
  /^\/LoadingScreen/,
  /^\/LaunchScreen/,
  /^\/AccessVerificationCodeScreen/,
  /^\/AccessScreen/,
];

class RootContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      initialState: null,
    };
    this.loadPersistedState = this.loadPersistedState.bind(this);
    this.appStateDidChange = this.appStateDidChange.bind(this);
    this.urlOpened = this.urlOpened.bind(this);
  }

  async appStateDidChange(nextAppState) {
    if (nextAppState === 'active') {
      const { rootStore: { sessionStore } } = this.props;
      NetInfo.fetch().then(state => sessionStore.updateInternetStatus(state.isInternetReachable));
    }
  }

  // eslint-disable-next-line class-methods-use-this
  updateInitialRoute(initialUrl) {
    if (!initialUrl) return;

    let url = null;
    try {
      url = new URL(initialUrl);
      // eslint-disable-next-line no-empty
    } catch {}
    if (!url) return;

    const path = url.pathname + url.search;

    const res = !excludedInitialLinks.some((l) => l.test(path)) && getStateFromPath(path);
    if (res) {
      AsyncStorage.setItem(PersistenceKeys.initialRoute, JSON.stringify(res));
    } else {
      AsyncStorage.removeItem(PersistenceKeys.initialRoute);
    }

    AsyncStorage.setItem(PersistenceKeys.utmSource, url.searchParams.get('utm_source') || '');
    AsyncStorage.setItem(PersistenceKeys.utmMedium, url.searchParams.get('utm_medium') || '');
    AsyncStorage.setItem(PersistenceKeys.utmContent, url.searchParams.get('utm_content') || '');
    AsyncStorage.setItem(PersistenceKeys.utmCampaign, url.searchParams.get('utm_campaign') || '');
  }

  async urlOpened({ url }) {
    if (!(await Linking.canOpenURL(url))) return;
    this.updateInitialRoute(url);
  }

  async componentDidMount() {
    const { rootStore: { sessionStore } } = this.props;

    const hasSavedData = await AsyncStorage.getItem(PersistenceKeys.authTokenKey);
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line no-console
    console.tron.log('has Saved Data:', !!hasSavedData);

    this.setState({ loading: !!hasSavedData });
    await this.loadPersistedState();

    this.netInfoUnsubscribe = NetInfo.addEventListener(state => sessionStore.updateInternetStatus(state.isInternetReachable));

    AppState.addEventListener('change', this.appStateDidChange);
    Linking.addEventListener('url', this.urlOpened);

    try {
      const initialUrl = await Linking.getInitialURL();
      if (initialUrl) this.updateInitialRoute(initialUrl);

      if (Platform.OS !== 'web' && initialUrl == null) {
        const state = await this.loadx();
        if (state !== undefined) {
          this.setState({ initialState: state });
        }
      }
    } finally {
      this.setState({ loading: false });
    }
  }

  componentWillUnmount() {
    // If error causes React to unmount the component tree, netInfoUnsubscribe and the AppState event listener
    // can not exist.
    if (this.netInfoUnsubscribe) {
      this.netInfoUnsubscribe();
      AppState.removeEventListener('change', this.appStateDidChange);
    }

    Linking.removeEventListener('url', this.urlOpened);
  }

  loadPersistedState = async () => {
    const { rootStore } = this.props;
    await rootStore.restoreSnapshot();
  };

  storeNavigationState=async (state) => {
    const { rootStore: { sessionStore } } = this.props;

    if (sessionStore.learner) {
      await sessionStore?.syncCurrentLearner({});
    }

    await AsyncStorage.setItem(PersistenceKeys.navigation, JSON.stringify(state));
  };

  loadx = async () => {
    const retrievedState = await AsyncStorage.getItem(PersistenceKeys.navigation);
    return JSON.parse(retrievedState);
  };

  render() {
    const { loading, initialState } = this.state;
    if (loading) return <FullViewSpinner />;
    return (
      <View style={styles.applicationView}>
        <RootNavigator
          linking={linking}
          initialState={initialState}
          onStateChange={this.storeNavigationState}
          onReady={() => {
            NavigationService.setNavigationReady();
          }}
          documentTitle={{ formatter: (options, route) => `NextStep Careers | ${options?.title ?? route?.name}` }}
          ref={navigatorRef => {
            NavigationService.setTopLevelNavigator(navigatorRef);
          }}
        />
        <ErrorScreen />
      </View>
    );
  }
}

export default inject('rootStore')(observer(RootContainer));
