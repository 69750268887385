import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Palette } from '@nextstep/app/Themes';
import { Body2 } from '@nextstep/app/components/Text';

const currentPalette = Palette.light;

const AssessmentBlockHeader = (props) => {
  const { progressStore } = props;
  const { assessmentBlockProgresses } = progressStore.stepProgress;

  const completedAssessmentBlocks = assessmentBlockProgresses?.filter(ab => ab.status === 'completed');
  const completedCount = completedAssessmentBlocks?.length || 0;
  const assessmentBlockProgressesCount = assessmentBlockProgresses?.length || 0;
  const currentSetNumber = completedCount < assessmentBlockProgressesCount ? completedCount + 1 : completedCount;
  const blockText = `Set: ${currentSetNumber} of ${assessmentBlockProgressesCount}`;

  return (
    <Body2 textAlign={'left'} color={currentPalette.primary.contrastText}>
      {blockText}
    </Body2>
  );
};

AssessmentBlockHeader.propTypes = { progressStore: PropTypes.object.isRequired };

export default inject(({ rootStore: { progressStore } }) => ({ progressStore }))(observer(AssessmentBlockHeader));
