import { types } from 'mobx-state-tree';
import QuestionnaireSingleAnswer from '@nextstep/app/models/QuestionnaireSingleAnswer';
import QuestionnaireSelectAnswer from '@nextstep/app/models/QuestionnaireSelectAnswer';

const QuestionnaireAnswers = types.model('QuestionnaireAnswers', {
  questionId: types.identifier,
  answers: types.array(types.union(
    QuestionnaireSingleAnswer,
    QuestionnaireSelectAnswer,
  )),
});

export default QuestionnaireAnswers;
