/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Image, TouchableOpacity, View } from 'react-native';
import { Card as RNCard, Icon } from 'react-native-elements';
import PropTypes from 'prop-types';
import { Images, Palette } from '@nextstep/app/Themes';
import Util from '@nextstep/app/services/Util';
import { ButtonOutline } from '@nextstep/app/components/Button';
import { Body1, Body2 } from './Text';

const currentPalette = Palette.light;

const defaultStyle = {
  card: { flex: 1 },

  cardContainer: {
    flex: 1,
    width: '100%',
    borderRadius: 10,
    margin: 0,
  },

  section: {
    center: { alignItems: 'center' },
    left: { alignItems: 'flex-start' },
  },

  circleContainer: {
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginRight: 22,
    marginTop: 5,
  },

  outerCircle: {
    width: 58,
    height: 58,
    borderRadius: 50,
    alignItems: 'center',
    justifyContent: 'center',
  },

  innerCircle: {
    width: 48,
    height: 48,
    resizeMode: 'cover',
    borderRadius: 50,
  },
};

const IconCard = (props) => {
  const { isStringLiteral, colorWithTint } = Util;

  const titleStyle = {
    color: currentPalette.text.main,
    ...props.titleStyle,
  };

  const subtitleStyle = {
    color: currentPalette.text.disabled,
    ...props.subtitleStyle,
  };

  const descriptionStyle = {
    color: currentPalette.text.main,
    ...props.descriptionStyle,
  };

  return (
    <TouchableOpacity
      onPress={() => props.onPress()}
      disabled={!props.onPress || props.disabled}
      style={defaultStyle.card}
    >
      <RNCard containerStyle={[defaultStyle.cardContainer, { backgroundColor: props.backGroundColor }]}>
        <View style={defaultStyle.section[props.contentAlignment]}>

          <View style={{ flexDirection: 'row', width: '100%' }}>
            {props.showIcon && (
              <View style={defaultStyle.circleContainer}>
                <View
                  style={[defaultStyle.outerCircle, props.showIconBorder && {
                    backgroundColor:
                      colorWithTint(props.disabled ? props.disabledColor : props.enableColor, 0.6),
                  }]}
                >
                  <Image
                    style={[defaultStyle.innerCircle, { backgroundColor: props.disabled ? props.disabledColor : props.enableColor }]}
                    source={props.disabled ? props.disabledIcon : props.enableIcon}
                  />
                </View>
              </View>
            )}

            <View style={{ flex: 1 }}>
              {props.title && isStringLiteral(props.title) ? (
                <Body2 style={titleStyle} textAlign={props.contentAlignment}>
                  {props.title}
                </Body2>
              ) : props.title}

              <View style={{ height: 2 }} />

              {props.subtitle && isStringLiteral(props.subtitle) ? (
                <Body1 numberOfLines={props.subtitleLines} style={subtitleStyle} textAlign={props.contentAlignment}>
                  {props.subtitle}
                </Body1>
              ) : props.subtitle}

              <View style={{ height: 2 }} />

              {props.description && isStringLiteral(props.description) ? (
                <Body1 numberOfLines={props.descriptionLines} style={descriptionStyle} textAlign={props.contentAlignment}>
                  {props.description}
                </Body1>
              ) : props.description}

              {props.onAction && (
                <View style={{ paddingTop: 20 }}>
                  <ButtonOutline
                    onPress={props.onAction}
                    width={'100%'}
                  >
                    {props.onActionTitle}
                  </ButtonOutline>
                </View>
              )}
            </View>

            {props.onPress && (
              <View style={{ justifyContent: 'center' }}>
                <Icon
                  name="keyboard-arrow-right"
                  color={props.disabled ? currentPalette.text.disabled : currentPalette.text.main}
                  underlayColor="transparent"
                  style={{ paddingRight: 8, paddingLeft: 8 }}
                />
              </View>
            )}
          </View>
        </View>
      </RNCard>
    </TouchableOpacity>
  );
};

IconCard.propTypes = {
  onPress: PropTypes.func,
  onAction: PropTypes.func,
  onActionTitle: PropTypes.string,
  disabled: PropTypes.bool,
  showIcon: PropTypes.bool,
  contentAlignment: PropTypes.oneOf(['left', 'center']),
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  titleStyle: PropTypes.object,
  subtitle: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  subtitleStyle: PropTypes.object,
  subtitleLines: PropTypes.number,
  description: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  descriptionStyle: PropTypes.object,
  descriptionLines: PropTypes.number,
  disabledIcon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  disabledColor: PropTypes.string,
  enableIcon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  enableColor: PropTypes.string,
  backGroundColor: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  showIconBorder: PropTypes.bool,
};

IconCard.defaultProps = {
  onPress: undefined,
  onAction: undefined,
  onActionTitle: undefined,
  disabled: false,
  showIcon: true,
  contentAlignment: 'left',
  title: undefined,
  titleStyle: {},
  subtitle: undefined,
  subtitleStyle: {},
  subtitleLines: undefined,
  description: undefined,
  descriptionStyle: {},
  descriptionLines: undefined,
  disabledIcon: Images.icons.lockedStep,
  disabledColor: currentPalette.basic.skeletonPlaceholder,
  enableIcon: Images.icons.lockedStep,
  enableColor: currentPalette.basic.orangeish,
  backGroundColor: currentPalette.background.white,
  showIconBorder: true,
};

export default IconCard;
