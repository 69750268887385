import { Colors } from '@nextstep/app/Themes';
import { ResponsiveStyleSheet } from '@nextstep/app/lib/ResponsiveLayout';
import { DeviceType } from '@nextstep/app/lib/withDimensions';

export default ResponsiveStyleSheet.create({
  section: {
    backgroundColor: Colors.white,
    paddingHorizontal: 30,

    [DeviceType.Mobile]: { paddingHorizontal: 10 },
  },
  summary: {
    marginTop: 33,
    marginBottom: 24,

    [DeviceType.Mobile]: {
      fontSize: 18,
      lineHeight: 27,
      marginTop: 16,
    },
  },
});
