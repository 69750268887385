import React from 'react';
import ConfirmationScreen from '@nextstep/app/containers/ConfirmationScreen';
import NavigationService from '@nextstep/app/services/NavigationService';

const JobPlacementConfirmationScreen = ({ navigation }) => (
  <ConfirmationScreen
    body={'Thank you for completing your job placement preference. This will help match you with a job in the future!'}
    buttonText={'Continue Learning!'}
    onNextPress={navigation
      ? () => navigation.replace('OutcomeScreen')
      : () => NavigationService.navigateToLearnerInitialScreen('OutcomeScreen')
    }
  />
);

export default JobPlacementConfirmationScreen;
