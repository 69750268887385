/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { Image, View } from 'react-native';
import hexToRgba from 'hex-to-rgba';
import { Palette } from '@nextstep/app/Themes';
import { Body1 } from './Text';

const currentPalette = Palette.light;

const DefaultStyles = {
  icon: {
    width: 40,
    height: 40,
    resizeMode: 'cover',
    borderRadius: 50,
    marginRight: 10,
  },
};

const Banner = (props) => (
  <View
    style={{
      borderRadius: 8,
      backgroundColor: hexToRgba(props.color, 0.2),
      padding: 15,
    }}
  >
    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
      {props.icon && (
        <Image
          style={DefaultStyles.icon}
          source={props.icon}
        />
      )}
      <Body1 color={props.textColor} textAlign={props.textAlign} style={{ flex: 1 }}>
        {props.text}
      </Body1>
    </View>
  </View>
);

Banner.propTypes = {
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  text: PropTypes.string,
  textColor: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  textAlign: PropTypes.string,
};

Banner.defaultProps = {
  color: currentPalette.primary.main,
  icon: undefined,
  text: '',
  textColor: currentPalette.text.main,
  textAlign: 'left',
};

export default Banner;
