import { types } from 'mobx-state-tree';
import { ContentNodeTypes } from '@nextstep/app/Config/Constants';
import ContentNode from './ContentNode';

const AssignmentQuestionModel = types.model('AssignmentQuestion', {
  id: types.identifierNumber,
  title: types.string,
  content: types.string,
  readingMaterial: types.string,
  skillsetKey: types.maybe(types.string), // Deprecated Mar 31 2022
});

const AssignmentQuestion = types.compose(AssignmentQuestionModel, ContentNode)
  .named(ContentNodeTypes.assignmentQuestion);

export default AssignmentQuestion;
