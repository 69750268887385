/* eslint-disable react/prop-types */
import React from 'react';
import { Image, TouchableOpacity, View } from 'react-native';
import { ApplicationStyles, Images, Palette } from '@nextstep/app/Themes/index';
import CustomModal from '@nextstep/app/components/CustomModal';
import { Body1, H3 } from '@nextstep/app/components/Text';
import { ButtonFilled } from '@nextstep/app/components/Button';
import Styles from './styles/BasicCustomModalStyles';

const currentPalette = Palette.light;

const BasicCustomModal = ({
  toggleVisible, isVisible,
  icon, messageTitle,
  messageContent, buttonText,
  toggleAction,
  color = currentPalette.background.secondary,
}) => (
  <CustomModal
    isVisible={isVisible}
    onBackdropPress={() => toggleVisible(false)}
    onSwipeComplete={() => toggleVisible(false)}
  >
    <View style={ApplicationStyles.modalStyles.container}>

      <View style={{ height: 20 }} />

      <View style={Styles.header}>

        <TouchableOpacity onPress={() => toggleVisible(false)}>

          <Image
            style={Styles.headerIcon}
            source={Images.icons.exit}
          />

        </TouchableOpacity>

      </View>

      <View style={Styles.section}>

        <View style={{ height: 20 }} />

        <Image
          style={Styles.modalIcon}
          source={icon}
        />

        <View style={{ height: 40 }} />

        <H3>
          {messageTitle}
        </H3>

        <View style={{ height: 8 }} />

        <Body1>
          {messageContent}
        </Body1>

        <View style={{ height: 16 }} />

      </View>

      <View style={{ height: 20 }} />

      <View style={[Styles.bottomButton, { backgroundColor: color }]}>

        <ButtonFilled
          onPress={() => {
            toggleVisible(false);
            toggleAction();
          }}
          width={228}
        >
          {buttonText}
        </ButtonFilled>

      </View>

    </View>
  </CustomModal>
);
export default BasicCustomModal;
