/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Icon } from 'react-native-elements';
import Tooltip from 'react-native-walkthrough-tooltip';
import PropTypes from 'prop-types';
import { Palette } from '@nextstep/app/Themes';
import withDimensions, { DeviceType } from '@nextstep/app/lib/withDimensions';
import AdaptiveContainerStyles from '@nextstep/app/containers/styles/AdaptiveContainerStyles';
import DebouncedTouchableOpacity from './DebouncedTouchableOpacity';

const currentPalette = Palette.light;

const InformationToolTip = (props) => (
  <Tooltip
    isVisible={props.show}
    content={props.children}
    placement="bottom"
    onClose={() => props.action(false)}
    disableShadow
    contentStyle={[
      AdaptiveContainerStyles().textContainer,
      {
        backgroundColor: currentPalette.basic.skeletonPlaceholder,
        paddingVertical: 30,
      },
      props.dimensions.deviceType !== DeviceType.Mobile && { maxWidth: '50vw' },
    ]}
  >
    <DebouncedTouchableOpacity onPress={() => props.action(true)}>
      <Icon
        name="help-outline"
        color={props.color}
        size={props.size}
      />
    </DebouncedTouchableOpacity>
  </Tooltip>
);

InformationToolTip.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
};

InformationToolTip.defaultProps = {
  color: currentPalette.primary.main,
  size: 20,
};

export default withDimensions(InformationToolTip);
