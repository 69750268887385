import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { View } from 'react-native';
import { Icon } from 'react-native-elements';
import withDimensions, { DeviceType } from '@nextstep/app/lib/withDimensions';
import { Images, Palette } from '@nextstep/app/Themes';
import AppMainHeader from '@nextstep/app/components/AppMainHeader';
import ViewWrapper from '@nextstep/app/components/ViewWrapper';
import { Body1, H3, H4 } from '@nextstep/app/components/Text';
import Divider from '@nextstep/app/components/Divider';
import { TextInput } from '@nextstep/app/components/TextInput';
import Constants from '@nextstep/app/Config/Constants';
import { ButtonFilled } from '@nextstep/app/components/Button';
import Banner from '@nextstep/app/components/Banner';
import AssignmentQuestionResponse from '@nextstep/app/components/AssignmentQuestionResponse';
import AdaptiveContainerStyles from '@nextstep/app/containers/styles/AdaptiveContainerStyles';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

const currentPalette = Palette.light;

class AssignmentQuestionScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questionAnswer: {
        value: undefined,
        showError: false,
        errorMessage: undefined,
      },
      sendingMessage: false,
    };
  }

  async componentDidMount() {
    const { rootStore: { progressStore } } = this.props;

    await progressStore.loadAssignmentQuestionResponses();
  }

  updateStateField = (objectName: string, text: string, valueFieldName: string = 'value') => {
    const { [objectName]: object } = this.state;

    if (object.showError) {
      this.setState({ [objectName]: { showError: false } });
    }
    this.setState({ [objectName]: { [valueFieldName]: text } });
  }

  getStatusConfig = (questionProgress) => {
    let icon;
    let text;
    let color;

    if (questionProgress.isUnlocked) {
      color = currentPalette.brand.magenta;
      icon = Images.assignmentQuestion.unlocked;
      text = 'Submit your answer to receive credit';
    }
    if (questionProgress.isWaitingForApproval) {
      color = currentPalette.brand.orange;
      icon = Images.assignmentQuestion.inProgress;
      text = 'Your response is in review';
    }
    if (questionProgress.needsResubmission) {
      color = currentPalette.brand.pink;
      icon = Images.assignmentQuestion.failed;
      text = 'You’ll need to resubmit in order to receive credit';
    }
    if (questionProgress.isApproved) {
      color = currentPalette.brand.green;
      icon = Images.assignmentQuestion.completed;
      text = 'Great job, you’re all set!';
    }
    if (questionProgress.isLocked) {
      color = currentPalette.basic.skeletonPlaceholder;
      icon = Images.assignmentQuestion.locked;
      text = 'Make progress in the course to unlock this question';
    }
    return { color, icon, text };
  }

  sendAnswer = async (answer) => {
    this.setState({ sendingMessage: true });
    const { rootStore: { progressStore } } = this.props;

    await progressStore.sendAssignmentQuestionResponse(answer);
    await progressStore.loadAssignmentQuestionResponses();

    this.updateStateField('questionAnswer', '');
    this.setState({ sendingMessage: false });
  }


  render() {
    const { rootStore: { contentStore, progressStore }, dimensions, navigation } = this.props;
    const { questionAnswer, sendingMessage } = this.state;

    const { assignmentQuestion } = contentStore;
    const { assignmentQuestionProgress } = progressStore;
    const questionStatusConfig = this.getStatusConfig(assignmentQuestionProgress);

    const GlobalStyles = AdaptiveContainerStyles();

    return (
      <>
        {dimensions.deviceType !== DeviceType.Desktop && <AppMainHeader navigation={navigation} />}
        <ViewWrapper smSize={12} mdSize={11} lgSize={11} navigationBar>
          <KeyboardAwareScrollView>
            <View style={[GlobalStyles.textContainer, { paddingVertical: 20 }]}>

              <Banner
                color={questionStatusConfig.color}
                icon={questionStatusConfig.icon}
                text={questionStatusConfig.text}
              />

              <View style={{ height: 22 }} />

              <Body1 textAlign="left" color={currentPalette.text.gray}>
                Question
              </Body1>
              <H3 textAlign="left">
                {assignmentQuestion.title}
              </H3>

              <View style={{ height: 12 }} />

              <Body1 textAlign="left">
                {assignmentQuestion.content}
              </Body1>

              {assignmentQuestion.readingMaterial && (
                <>
                  <Divider gutter={24} />
                  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <Icon
                      name={'md-book'}
                      type="ionicon"
                      color={currentPalette.primary.main}
                      style={{ paddingRight: 20 }}
                    />
                    <Body1 textAlign={'left'} style={{ flex: 1 }}>
                      {assignmentQuestion.readingMaterial}
                    </Body1>
                  </View>
                </>
              )}

              <Divider gutter={24} />

              <H3 textAlign="left"> Answers </H3>

              {assignmentQuestionProgress.questionInteraction.map((item, index) => (
                <AssignmentQuestionResponse key={index} {...item} />
              ))}

              <View style={{ height: 12 }} />

              {!assignmentQuestionProgress?.isApproved && (
                <>
                  <H4 textAlign="left"> Add an Answer </H4>

                  <TextInput
                    data={questionAnswer}
                    customAttributes={{
                      placeholder: '',
                      ref: (ref) => {
                        this.questionAnswer = ref;
                      },
                      multiline: true,
                    }}
                    valueType={Constants.InputTextTypes.custom}
                    onChangeText={(text) => this.updateStateField('questionAnswer', text)}
                    editable={!sendingMessage}
                  />

                  <ButtonFilled
                    onPress={() => this.sendAnswer(questionAnswer.value)}
                    width={'100%'}
                    disabled={sendingMessage}
                  >
                    Submit
                  </ButtonFilled>
                </>
              )}

            </View>
          </KeyboardAwareScrollView>
        </ViewWrapper>
      </>
    );
  }
}


export default withDimensions(inject('rootStore')(observer(AssignmentQuestionScreen)));
