import React, { Component } from 'react';
import { View, Dimensions } from 'react-native';
import { inject, observer } from 'mobx-react';
import VideoPlayer from 'react-native-af-video-player';
import PropTypes from 'prop-types';
import StyledHTMLView from '@nextstep/app/components/StyledHTMLView';
import Util from '@nextstep/app/services/Util';
import { SectionTypeTitles, StepLearningObjectTypes } from '@nextstep/app/Config/Constants';
import { Palette } from '@nextstep/app/Themes';
import withDimensions from '@nextstep/app/lib/withDimensions';
import AdaptiveContainerStyles from '@nextstep/app/containers/styles/AdaptiveContainerStyles';
import MaterialModal from './MaterialModal';
import TermModal from './TermModal';

const currentPalette = Palette.light;

class StudyMaterialsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      size: Dimensions.get('window'),
      isVisibleTerm: false,
      currentTerm: null,
    };
    this.renderSuccessCriteria = this.renderSuccessCriteria.bind(this);
    this.renderDemoVideos = this.renderDemoVideos.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }

  componentDidMount() {
    Dimensions.addEventListener('change', () => {
      this.setState({ size: Dimensions.get('window') });
    });
  }

  componentWillUnmount() {
    Dimensions.removeEventListener('change');
  }

  toggleTermModal(state) {
    this.setState({ isVisibleTerm: state });
  }

  onTermPress = (term) => {
    if (!term) return;

    this.setState({ currentTerm: term });
    this.toggleTermModal(true);
  };

  renderSuccessCriteria() {
    const { step, dimensions } = this.props;

    return (
      <StyledHTMLView
        overrideHTMLStyles={{ a: { fontWeight: 'bold' } }}
        value={`<div>${step.successCriteria.content}</div>`}
        onTermPress={this.onTermPress}
        style={AdaptiveContainerStyles(dimensions.deviceType).textContainer}
      />
    );
  }

  renderDemoVideos() {
    const { step } = this.props;

    if (!step.demoVideos) return null;
    return step.demoVideos.map((video, index) => (
      <View key={index}>

        {video.url && (
          <View style={{ paddingBottom: 24 }}>
            <VideoPlayer
              url={video.url}
              ignoreSilentSwitch="ignore"
              inlineOnly
              logo="https://s3.amazonaws.com/nextstep.ui/course/images/home-active.png"
            />
          </View>
        )}

      </View>
    ));
  }

  hideModal() {
    const { rootStore: { progressStore } } = this.props;
    const { setStudyMaterialsModalVisibility } = progressStore;
    setStudyMaterialsModalVisibility(false);
  }

  getMaterial = () => {
    const { rootStore: { progressStore }, step, colorType } = this.props;
    const { stepProgress } = progressStore;

    if (!stepProgress || !stepProgress.childOrder) return [];

    return stepProgress.childOrder.map((learningObjectType) => {
      switch (learningObjectType) {
        case StepLearningObjectTypes.successCriteria:
          // Do not show the link if there isn't a success criteira
          if (!step.successCriteria) {
            return null;
          }

          return {
            title: SectionTypeTitles.successCriteria,
            src: Util.getColorResource(colorType).successCriteria,
            show: !!step.successCriteria,
          };
        case StepLearningObjectTypes.demoVideos:
          // Do not show the link if there isn't a demo video
          if (!step.demoVideos || step.demoVideos.length === 0) {
            return null;
          }

          return {
            title: step.demoVideos?.length > 1 ? SectionTypeTitles.demoVideos : SectionTypeTitles.demoVideo,
            src: Util.getColorResource(colorType).learningContent,
            show: !!step.demoVideos,
          };
        case StepLearningObjectTypes.assessmentBlocks:
          // Do not show the link if there isn't an assessment block
          if (!step.assessmentBlock) {
            return null;
          }

          return {
            title: 'Exercise',
            src: Util.getColorResource(colorType).exercise,
            show: step.assessmentBlock,
          };
        default:
          throw new Error(`learning object ${learningObjectType} does not exist`);
      }
    }).filter(a => a); // Remove any empty objects
  }

  render() {
    const { rootStore: { progressStore } } = this.props;
    const { skill, colorTheme, isFocused } = this.props;
    const { size, currentTerm, isVisibleTerm } = this.state;
    const { isStudyMaterialsModalVisible } = progressStore;

    return (
      <>
        <TermModal
          term={currentTerm}
          isVisible={isVisibleTerm && isFocused}
          toggleVisible={() => { this.toggleTermModal(false); }}
        />

        <MaterialModal
          materials={this.getMaterial()}
          isVisible={isStudyMaterialsModalVisible && isFocused}
          toggleVisible={() => this.hideModal()}
          colorTheme={colorTheme}
          renderSuccessCriteria={this.renderSuccessCriteria}
          renderDemoVideos={this.renderDemoVideos}
          skill={skill}
          size={size}
        />
      </>
    );
  }
}

StudyMaterialsModal.propTypes = {
  skill: PropTypes.object,
  step: PropTypes.object,
  colorTheme: PropTypes.string,
  colorType: PropTypes.string,
  isFocused: PropTypes.bool,
};

StudyMaterialsModal.defaultProps = {
  skill: {},
  step: {},
  colorTheme: currentPalette.primary.main,
  colorType: 'main',
  isFocused: true,
};

export default withDimensions(inject('rootStore')(observer(StudyMaterialsModal)));
