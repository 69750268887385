import React, { Component } from 'react';
import { ScrollView, View } from 'react-native';
import { inject, observer } from 'mobx-react';
import Util from '@nextstep/app/services/Util';
import { ExceptionEmitter } from '@nextstep/app/lib/ErrorUtils';
import { Body1, H3 } from '@nextstep/app/components/Text';
import { ButtonFilled } from '@nextstep/app/components/Button';
import Constants from '@nextstep/app/Config/Constants';
import { TextInput } from '@nextstep/app/components/TextInput';
import withDimensions from '@nextstep/app/lib/withDimensions';
import ViewWrapper from '@nextstep/app/components/ViewWrapper';
import { Column, Row } from '@nextstep/app/lib/ResponsiveLayout';
import NavigationService from '@nextstep/app/services/NavigationService';
import AdaptiveContainerStyles from '@nextstep/app/containers/styles/AdaptiveContainerStyles';

export class AccessScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneNumber: {
        value: undefined,
        showError: false,
        errorMessage: undefined,
      },
      loading: false,
    };
    this.checkPhoneNumber = this.checkPhoneNumber.bind(this);
  }

  async onNextPressed() {
    this.setState({ loading: true });
    const { phoneNumber } = this.state;

    if (phoneNumber.value && Util.isValidPhoneNumber(phoneNumber.value)) {
      await this.checkPhoneNumber().catch(ExceptionEmitter);
    } else {
      this.setState({
        phoneNumber: {
          ...phoneNumber,
          showError: true,
          errorMessage: 'Phone number must be 10 digits.',
        },
      });
    }

    this.setState({ loading: false });
  }

  async checkPhoneNumber() {
    const { rootStore } = this.props;
    const { sessionStore } = rootStore;
    const { phoneNumber } = this.state;

    const phone = phoneNumber.value.match(/\d+/g).join([]);

    const response = await sessionStore.checkPhoneNumber({ phone });

    if (response && response.errors) {
      this.setState({
        phoneNumber: {
          ...phoneNumber,
          showError: true,
          errorMessage: 'This number is not recognized in our system, Please proceed to sign up.',
        },
      });
    } else {
      this.navigateToCodeVerificationScreen();
    }
  }

  navigateToCodeVerificationScreen = () => {
    const { navigation } = this.props;
    const { phoneNumber } = this.state;
    navigation.replace('AccessVerificationCodeScreen', { phoneNumber: phoneNumber.value });
  };

  onPhoneNumberChange = (text) => {
    const { phoneNumber } = this.state;

    if (phoneNumber.showError) { this.setState({ phoneNumber: { showError: false } }); }
    this.setState({ phoneNumber: { value: Util.formatUsNumber(text) } });
  }

  render() {
    const { dimensions } = this.props;
    const { phoneNumber, loading } = this.state;

    const Styles = AdaptiveContainerStyles(dimensions.deviceType);

    return (
      <ViewWrapper smSize={12} mdSize={5} lgSize={4} navigationBar backOverride={() => NavigationService.reset('LaunchScreen')}>
        <ScrollView keyboardShouldPersistTaps={'always'}>
          <Row style={[[Styles.textContainer, Styles.sectionContainer], { justifyContent: 'center' }]}>
            <Column smSize={11} mdSize={12} lgSize={12}>

              <View style={{ height: 32 }} />

              <H3>
                Sign In to NextStep
              </H3>

              <View style={{ height: 25 }} />

              <Body1>
                Enter the phone number you signed up with. We’ll
                text you a verification code to sign into your account.
              </Body1>

              <View style={{ height: 21 }} />

              <TextInput
                title={'Phone Number'}
                data={phoneNumber}
                customAttributes={{
                  placeholder: 'Mobile Phone Number',
                  ref: (ref) => {
                    this.phoneNumber = ref;
                  },
                  autoFocus: true,
                }}
                valueType={Constants.InputTextTypes.phoneNumber}
                onClear={() => {
                  this.onPhoneNumberChange('');
                  this.phoneNumber.focus();
                }}
                isFocused
                onChangeText={(text) => this.onPhoneNumberChange(text)}
                onSubmitEditing={() => this.onNextPressed()}
              />

              <View style={{ height: 43 }} />

              <ButtonFilled
                onPress={() => this.onNextPressed()}
                disabled={phoneNumber.value === '' || loading}
                width={'100%'}
              >
                Next
              </ButtonFilled>

              <View style={{ height: 34 }} />
            </Column>
          </Row>
        </ScrollView>
      </ViewWrapper>
    );
  }
}


// Connect everything
export default withDimensions(inject('rootStore')(observer(AccessScreen)));
