import apisauce from 'apisauce';
import Config from 'react-native-config';
import qs from 'qs';

const create = () => {
  const headers = { 'content-type': 'application/x-www-form-urlencoded' };
  const api = apisauce.create({
    baseURL: 'https://nextstep.api-us1.com/admin',
    headers,
  });

  const requestBody = {
    api_key: `${Config.ACTIVECAMPAIGN_TOKEN}`,
    api_output: 'json',
  };

  const buildQuery = (method, url, params = {}) => api[method](url, params, { headers });
  const addMarketingTag = body => buildQuery('post', 'api.php?api_action=contact_tag_add', qs.stringify({ ...requestBody, ...body }));

  return { addMarketingTag };
};

export default { create };
