import { Platform } from 'react-native';
import Segment from '@segment/analytics-react-native';
import Config from 'react-native-config';
import DebugConfig from '@nextstep/app/Config/DebugConfig';

const SegmentAvailable = Boolean(Config.SEGMENT_WRITE_KEY);
const RollbarEnv = Config.ROLLBAR_ENVIRONMENT || '';
let analyticsEnabled = SegmentAvailable;

if (SegmentAvailable) {
  Segment.setup(Config.SEGMENT_WRITE_KEY, {
    recordPageViews: false, // for web
    recordScreenViews: false, // for mobile
    trackAppLifecycleEvents: false,
    trackAttributionData: true,
    android: {
      flushInterval: 60,
      collectDeviceId: true,
    },
    ios: {
      trackAdvertising: true,
      trackDeepLinks: true,
    },
  })
    // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line no-console
    .catch(err => console.error('Something went wrong', err));
}

function isExcludedUser(user) {
  if (!user) return false;
  if (user.email) {
    const parts = user.email.split('@');

    // Ignore bad email address
    if (parts.length !== 2) return false;

    const domain = parts[1].toLowerCase();
    if (domain === 'nextstep.careers' || domain === 'nextstep.com' || domain === 'edify.cr') {
      return true;
    }
  }
  // Exclude admins
  return user.isSuperUser;
}

// This function identifies a user and manages tracking on/off based on exclusion criteria
// Segment Docs: We recommend calling identify a single time when the user’s account is first created,
// and only identifying again later when their traits are changed.
// FIXME: ANALYTICS REFACTOR => This should be split into Identify and Update calls when other platforms are added
export function analyticsIdentifyUser(user) {
  if (!SegmentAvailable || !user) return;

  if (Platform.OS === 'web' && DebugConfig.production) {
    (async () => {
      const { identifyGuest, identifyLearner } = await import('@nextstep/app/Config/LogRocketConfig');
      if (user.isGuest) {
        identifyGuest(user);
      } else {
        identifyLearner(user);
      }
    })();
  }

  const properties = {
    city: user.homeCity,
    currentCourseId: (user.courseProgress || {}).currentCourseId,
    createdAt: user.createdAt,
    email: user.email,
    guestConvertedAt: user.guestConvertedAt,
    isAdmin: user.isSuperUser,
    isGuest: user.isGuest,
    phone: user.phone,
    profileImage: user.profileImage,
    registeredAt: user.registeredAt,
    state: user.homeState,
    tosAcceptedAt: user.tosAcceptedAt,
    tosSkillsetKey: user.tosSkillsetKey,
    userId: user.externalId,
    userType: 'app-learner',
    zipCode: user.homeZipCode,
  };

  // Disable analytics for the excluded users
  if (isExcludedUser(user)) {
    analyticsEnabled = false;
    Segment.track('excludedFromMetrics', properties);
    return;
  }

  // Re-enable analytics if it were somehow disabled
  if (!analyticsEnabled) {
    analyticsEnabled = true;
    Segment.track('addingExcludedToMetrics', properties);
  }

  Segment.identify(user.externalId, properties);
}

export function analyticsReset() {
  Segment.reset();
}

export function analyticsAlias(aliasId) {
  if (!aliasId) return;
  Segment.alias(aliasId);
}

export function trackAnalytics(type, properties, intercom = false) {
  if (!analyticsEnabled) return;
  Segment.track(type, properties, { integrations: { Intercom: intercom } });
  // On web, send events to FB pixel, Google Ads, Snapchat
  if (Platform.OS === 'web' && RollbarEnv.toLowerCase() === 'production') {
    // Send all events to FB and GA
    if (window.fbq) window.fbq('trackCustom', type, properties);
    if (window.gtag) window.gtag('event', type, { event_category: 'All', event_label: 'event', send_to: 'UA-138864403-3' });

    // Registered event
    if (type.toLowerCase() === 'registered') {
      if (window.gtag) window.gtag('event', 'conversion', { send_to: 'AW-740230907/XJciCNqp5_EBEPuN_OAC' });
      if (window.snaptr) window.snaptr('track', 'SIGN_UP');
      if (window.rdt) window.rdt('track', 'SignUp');

    // Enrolled event
    } else if (type.toLowerCase() === 'enrolled') {
      if (window.gtag) window.gtag('event', 'conversion', { send_to: 'AW-740230907/JauRCMOh1vEBEPuN_OAC' });
      if (window.snaptr) window.snaptr('track', 'SUBSCRIBE');
      if (window.rdt) window.rdt('track', 'Purchase');
    }
  }
}

export function analyticsScreenView(screen, properties, intercom = false) {
  if (!analyticsEnabled) return;
  if (!screen) return;
  const logMessage = `Analytics: ${screen}`;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line no-console
  console.tron.log(logMessage); // ---- Reactotron
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line no-console
  console.log(logMessage); // --------- Web
  Segment.screen(screen, properties, { integrations: { Intercom: intercom } });
}
