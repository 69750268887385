import React from 'react';
import { Platform, View, TouchableOpacity } from 'react-native';
import { Icon } from 'react-native-elements';
import MarkdownRender from '@nextstep/app/components/MarkdownRender';
import withDimensions, { DeviceType } from '@nextstep/app/lib/withDimensions';
import { currentPalette } from '@nextstep/app/Config/AppConfig';
import Constants from '@nextstep/app/Config/Constants';
import { Typography } from '@nextstep/app/Themes';
import NavigationService from '@nextstep/app/services/NavigationService';
import AdaptiveContainerStyles from '@nextstep/app/containers/styles/AdaptiveContainerStyles';
import { Body1 } from './Text';

const GlobalStyles = AdaptiveContainerStyles();

const DefaultStyles = {
  mainContainer: {
    paddingRight: 10,
    flexDirection: 'row',
  },

  icon: {
    height: 35,
    width: 35,
    resizeMode: 'cover',
    alignSelf: Platform.OS === 'web' ? 'center' : undefined,
  },

  body: {
    alignSelf: 'flex-start',
    color: currentPalette.text.main,
    fontFamily: Typography.body1.fontFamily,
  },
};

const getPriorityBackgroundColor = (priority) => {
  switch (priority) {
    case Constants.Priority.low:
      return currentPalette.background.primary;
    case Constants.Priority.medium:
      return currentPalette.background.primary;
    case Constants.Priority.high:
      return currentPalette.background.primary;
    default:
      return currentPalette.background.primary;
  }
};


const InAppNotification = ({ notification, children, style, dimensions, onDismissed, contentStore }) => (
  <View
    style={[
      DefaultStyles.mainContainer,
      { backgroundColor: getPriorityBackgroundColor(notification.priority) },
      style,
    ]}
  >

    {/* Custom notifications */}
    {notification.questionnaireId
              && (
              <View style={{ flex: 1 }}>
                <TouchableOpacity
                  style={[GlobalStyles.textContainer]}
                  onPress={async () => {
                    await contentStore.loadQuestionnaire(notification.questionnaireId);
                    NavigationService.navigate('LearnerTaskQuestionnaire');
                  }}
                >
                  <Body1 style={DefaultStyles.body}>{notification.content}</Body1>
                </TouchableOpacity>
              </View>
              )
            }

    {/* Markdown notifications */}
    {!notification.questionnaireId
              && (
              <View style={{ flex: 1 }}>
                <MarkdownRender data={notification.content} />
              </View>
              )
            }

    {children}

    {notification.dismissible && onDismissed && (
      <View style={dimensions.deviceType !== DeviceType.Mobile ? { alignSelf: 'center' } : { paddingTop: 10 }}>
        <Icon name={'close'} onPress={onDismissed} />
      </View>
    )}

  </View>
);

export default withDimensions(InAppNotification);
