import React from 'react';
import { Animated, Image, Platform, StatusBar, Text, View } from 'react-native';
import { Icon } from 'react-native-elements';
import { inject, observer } from 'mobx-react';
import { Images, Palette } from '@nextstep/app/Themes';
import StudyMaterialsModal from '@nextstep/app/modals/StudyMaterialsModal';
import AssessmentBlockHeader from '@nextstep/app/components/learnTab/AssessmentBlockHeader';
import FirstTimeStepCompleteModal from '@nextstep/app/modals/FirstTimeStepCompleteModal';
import { ABScreenStatuses, ContentNodeTypes, ProgressNodeTypes, QuestionTypes } from '@nextstep/app/Config/Constants';
import AssessmentBlockQuestion from '@nextstep/app/components/learnTab/AssessmentBlockQuestion';
import ResultBanner from '@nextstep/app/components/ResultsBanner';
import Utils from '@nextstep/app/services/Util';
import { Body1, Body2, H3 } from '@nextstep/app/components/Text';
import InactivityWatcher from '@nextstep/app/components/InactivityWatcher';
import ScreenTimer from '@nextstep/app/components/ScreenTimer';
import { ButtonText } from '@nextstep/app/components/Button';
import Config from '@nextstep/app/Config/DebugConfig';
import withDimensions, { DeviceType } from '@nextstep/app/lib/withDimensions';
import { FullViewSpinner } from '@nextstep/app/components/SharedUI';
import ViewWrapper from '@nextstep/app/components/ViewWrapper';
import DebouncedTouchableOpacity from '@nextstep/app/components/DebouncedTouchableOpacity';
import FillInBlankAnswer from '@nextstep/app/components/learnTab/FillInBlankAnswer';
import AdaptiveContainerStyles from '@nextstep/app/containers/styles/AdaptiveContainerStyles';
import VideoScreenStyles from '@nextstep/app/containers/styles/VideoScreenStyles';
import screenStyles from '@nextstep/app/containers/styles/AssessmentBlockScreenStyles';
import NavigationService from '@nextstep/app/services/NavigationService';
import BottomSheetModal from '@nextstep/app/modals/BottomSheetModal';
import NetInfo from '@react-native-community/netinfo';
import SkillChildNavigator from './SkillChildNavigator';

const currentPalette = Palette.light;

class AssessmentBlocksScreen extends SkillChildNavigator {
  constructor(props) {
    super(props);
    this.scrollView = React.createRef();
    this.state = {
      chosenAnswers: [],
      skipReload: false,
      status: ABScreenStatuses.answering,
      isAnsweredCorrectly: null,
      fadeBanner: new Animated.Value(100),
      isVisibleFirstTimeStepCompleteModal: false,
      isVisibleInactivityMessage: false,
      isLoading: true,
    };
  }

  async componentDidMount() {
    StatusBar.setBarStyle('dark-content', true);

    const { rootStore: { progressStore, contentStore }, route } = this.props;

    this.setState({ skipReload: false });

    this.restoreUIState();

    contentStore.clearCurrentExcept([
      ContentNodeTypes.course, ContentNodeTypes.skillset,
      ContentNodeTypes.skill, ContentNodeTypes.step]);
    progressStore.clearCurrentExcept([
      ProgressNodeTypes.courseProgress, ProgressNodeTypes.skillsetProgress,
      ProgressNodeTypes.skillProgress, ProgressNodeTypes.stepProgress]);

    const skillsetKey = route.params?.skillsetKey;
    const skillKey = route.params?.skillKey;
    const stepKey = route.params?.stepKey;

    await contentStore.setSkillset(skillsetKey);
    await contentStore.setSkill(skillKey);
    await contentStore.setStep(stepKey);
    await progressStore.setSkillsetProgress(contentStore.skillset.id);
    await progressStore.setSkillProgress(contentStore.skill.id);
    await progressStore.setStepProgress(contentStore.step.id);

    if (progressStore.stepProgress.isUnlocked) {
      await progressStore.startStep();
    }

    await this.setCurrentAssessmentBlock();
    await this.setCurrentQuestion();

    const stepProgress = progressStore.step;

    if (stepProgress && stepProgress.isCompleted) {
      this.navigateBack();
    }

    this.setState({ isLoading: false });
  }

  restoreUIState() {
    const { route } = this.props;
    const uiState = route.params?.uiState;
    if (!uiState) return;
    this.setState((state) => Object.assign(state, uiState));

    if (uiState.status === ABScreenStatuses.checksAndXs) {
      this.animateBanner();
    }
  }

  saveUIState(state) {
    const { navigation, route } = this.props;
    const uiState = route.params?.uiState ?? {};
    navigation.setParams({ uiState: Object.assign(uiState, state) });
  }

  async setCurrentAssessmentBlock() {
    const { rootStore: { progressStore, contentStore } } = this.props;

    const { skipReload } = this.state;

    const assessmentBlockProgress = progressStore.setAssessmentBlockProgress();
    if (!assessmentBlockProgress) return;

    await progressStore.startAssessmentBlock();
    await contentStore.setAssessmentBlock(progressStore.assessmentBlockProgress.assessmentBlockId, skipReload);
    await progressStore.mapAssessmentBlockQuestions();
  }

  async setCurrentQuestion() {
    const { rootStore: { progressStore, contentStore } } = this.props;

    if (!progressStore.assessmentBlockProgress || !progressStore.assessmentBlockProgress.loaded) {
      await this.setCurrentAssessmentBlock();
    }

    const questionProgress = await progressStore.setQuestionProgress();

    if (!questionProgress) return;
    await contentStore.setQuestion(questionProgress.id);
  }

  animateBanner() {
    const { fadeBanner } = this.state;
    Animated.spring(
      fadeBanner,
      {
        toValue: 0,
        velocity: 3,
        tension: 2,
        friction: 8,
        useNativeDriver: true,
      },
    )
      .start();
  }

  resetAnswer = () => {
    const { status } = this.state;
    if (status !== ABScreenStatuses.checksAndXs) {
      this.setState({
        chosenAnswers: [],
        fadeBanner: new Animated.Value(100),
      });
    }
  };

  appendAnswer = (chosenAnswer) => {
    const { chosenAnswers, status } = this.state;
    if (!chosenAnswers.includes(chosenAnswer)) {
      if (status !== ABScreenStatuses.checksAndXs) {
        this.setState({ chosenAnswers: [...chosenAnswers, chosenAnswer] });
      }
    }
  };

  chooseAnswer = (chosenAnswer) => {
    const { status } = this.state;
    if (status !== ABScreenStatuses.checksAndXs) {
      this.setState({ chosenAnswers: [chosenAnswer] });
    }
  };

  hasOrderedCorrectAnswers = () => {
    const { rootStore: { contentStore } } = this.props;
    const { question } = contentStore;
    const questionType = question.type.toLowerCase();
    return questionType === QuestionTypes.fillInTheBlanks || questionType === QuestionTypes.orderedSelect;
  };

  hasUnorderedCorrectAnswers = () => {
    const { rootStore: { contentStore } } = this.props;
    const { question } = contentStore;
    const questionType = question.type.toLowerCase();
    return questionType === QuestionTypes.unorderedSelect;
  };

  async nextUIState(privateAnswered) {
    const { rootStore: { progressStore } } = this.props;
    const { status } = this.state;
    const { areChosenAnswersCorrect } = progressStore;
    const { chosenAnswers } = this.state;
    const isAnsweredCorrectly = areChosenAnswersCorrect(chosenAnswers);

    if (status === ABScreenStatuses.answering) {
      this.showChecksAndXs(privateAnswered);
    } else if (status === ABScreenStatuses.checksAndXs && !isAnsweredCorrectly) {
      this.showCorrectAnswers();
    } else if (status === ABScreenStatuses.checksAndXs && isAnsweredCorrectly || status === ABScreenStatuses.showingCorrectAnswers) {
      this.setState({ status: ABScreenStatuses.loading });
      await this.goToNextLearningObject();
    }

    this.setState({ skipReload: true });
  }

  goToNextLearningObject = async () => {
    const { rootStore: { progressStore } } = this.props;
    const { answerQuestion, stepProgress, assessmentBlockProgress, skillProgress } = progressStore;
    const { chosenAnswers } = this.state;

    await answerQuestion(chosenAnswers);
    this.saveUIState({ chosenAnswers });

    let tempStatus = ABScreenStatuses.answering;
    let stateUpdate = {};
    // choose path based on what is complete
    if (skillProgress.isCompleted) {
      tempStatus = ABScreenStatuses.skillCelebration;
      await this.goToNextSkillChild();
    } else if (stepProgress.isCompleted) {
      await this.goToNextSkillChild();
    } else if (assessmentBlockProgress.isCompleted) {
      await this.setCurrentAssessmentBlock();
      await this.setCurrentQuestion();
    } else { // inside of assessment blocks
      await this.setCurrentQuestion();
    }

    stateUpdate = {
      ...stateUpdate,
      chosenAnswers: [],
      privateAnswers: undefined,
      status: tempStatus,
    };
    this.setState(stateUpdate);
  }

  showCorrectAnswers = () => {
    this.saveUIState({ status: ABScreenStatuses.showingCorrectAnswers });
    this.setState({ status: ABScreenStatuses.showingCorrectAnswers });
  }

  showChecksAndXs = (privateAnswered) => {
    const { rootStore: { progressStore } } = this.props;
    const { areChosenAnswersCorrect } = progressStore;
    const { chosenAnswers } = this.state;
    const isAnsweredCorrectly = privateAnswered || areChosenAnswersCorrect(chosenAnswers);

    this.saveUIState({ status: ABScreenStatuses.checksAndXs, isAnsweredCorrectly });
    this.setState({ status: ABScreenStatuses.checksAndXs, isAnsweredCorrectly });
    this.animateBanner();
  }

  getButtonText = () => {
    const { status, isAnsweredCorrectly, isVisibleQuickTip } = this.state;

    if (isVisibleQuickTip) return 'Continue';

    switch (status) {
      case ABScreenStatuses.checksAndXs:
        return isAnsweredCorrectly ? 'Continue' : 'See Answers';
      case ABScreenStatuses.answering:
        return 'Submit';
      default:
        return 'Continue';
    }
  };

  updateScreenTime = (increment) => {
    const { rootStore: { progressStore } } = this.props;
    const { assessmentBlockProgress } = progressStore;
    if (!assessmentBlockProgress) return;
    assessmentBlockProgress.incrementDuration(increment);
  }

  toggleInactivityMessage = (state) => {
    this.setState({ isVisibleInactivityMessage: state });
  };

  renderQuestion() {
    const { rootStore: { contentStore } } = this.props;
    const { question, skillset } = contentStore;
    const questionType = question.type.toLowerCase();

    if (questionType === QuestionTypes.fillInTheBlanks) {
      return (
        <Text>
          {(question.questionParts || []).map((block, index) => (
            <FillInBlankAnswer skillset={skillset} index={index} />
          ))}
        </Text>
      );
    }
    return (
      <Body2 textAlign="left">
        {question.question.replace(/<b>|<\/b>/g, '')
          .replace(' Select ALL correct answers. Some choices are not used.', '')
          .replace(' Some choices are not used.', '')
        }
      </Body2>
    );
  }

  renderAnswerChoices = (shuffledAnswerChoices) => {
    const { rootStore: { contentStore } } = this.props;
    const { skillset } = contentStore;

    const hasOrderedCorrectAnswers = this.hasOrderedCorrectAnswers();

    let correctAnswers;
    if (hasOrderedCorrectAnswers) {
      correctAnswers = shuffledAnswerChoices.filter(answerChoice => answerChoice.order > 0);
    } else {
      correctAnswers = shuffledAnswerChoices.filter(answerChoice => answerChoice.score > 0);
    }

    if (hasOrderedCorrectAnswers) correctAnswers = correctAnswers.sort((a, b) => a.order - b.order);

    const itemBullet = item => (hasOrderedCorrectAnswers ? `${item.order}.` : '\u25CF');

    return correctAnswers.map((item, index) => (
      <View key={index} style={{ flex: 1, flexDirection: 'row' }}>
        <Body2 textAlign={'left'} color={skillset.colorTheme}>
          {`${itemBullet(item)}\t`}
        </Body2>
        <Body1 textAlign={'left'} style={{ flex: 1 }}>
          {`${Utils.upper(item.answerChoice)}`}
        </Body1>
      </View>
    ));
  };

  renderCorrectAnswers = () => {
    const { rootStore: { contentStore } } = this.props;
    const { question } = contentStore;
    const questionDisplay = this.renderQuestion(question);
    const answerDisplay = this.renderAnswerChoices(question.getShuffledAnswerChoices());
    return (
      <View>
        <View style={{ height: 21 }} />
        {questionDisplay}
        <View style={{ height: 18 }} />
        {answerDisplay}
        <View style={{ height: 21 }} />
      </View>
    );
  };

  renderAssessmentBlock = () => {
    const { chosenAnswers, status } = this.state;

    return (
      <AssessmentBlockQuestion
        chosenAnswers={chosenAnswers}
        showCheckOrX={status === ABScreenStatuses.checksAndXs}
        resetAnswer={this.resetAnswer}
        chooseAnswer={this.chooseAnswer}
        appendAnswer={this.appendAnswer}
        hasUnorderedCorrectAnswers={this.hasUnorderedCorrectAnswers}
        hasOrderedCorrectAnswers={this.hasOrderedCorrectAnswers}
        setPrivateAnswer={this.setPrivateAnswer}
        loading={status === ABScreenStatuses.loading}
      />
    );
  }

  privateSolver = () => {
    if (!Config.answerSolver) return;
    const { privateAnswers } = this.state;
    this.setState({ chosenAnswers: privateAnswers });
    this.nextUIState(true);
  }

  setPrivateAnswer = (answers) => {
    if (!Config.answerSolver) return;
    this.setState({ privateAnswers: answers });
  }

  renderBottomBar() {
    const { rootStore: { progressStore, contentStore }, dimensions } = this.props;
    const { isAnsweredCorrectly, fadeBanner, chosenAnswers, status } = this.state;
    const { setStudyMaterialsModalVisibility } = progressStore;
    const { skillset: { colorTheme, colorTheme20, colorType } } = contentStore;
    const MainStyles = AdaptiveContainerStyles(dimensions.deviceType);

    const enabled = !!chosenAnswers.length;

    return (
      <View style={{ flex: dimensions.deviceType !== DeviceType.Mobile ? 1 : null }}>

        {status === ABScreenStatuses.checksAndXs && (
          <ResultBanner
            backgroundColor={colorTheme20}
            isAnsweredCorrectly={isAnsweredCorrectly}
            fadeBanner={fadeBanner}
            colorType={colorType}
          />
        )}

        <View style={[MainStyles.bottomBar, MainStyles.textContainer, Platform.OS === 'web' && { backgroundColor: colorTheme }]}>

          <DebouncedTouchableOpacity
            onPress={() => {
              setStudyMaterialsModalVisibility(true);
              this.setState({ isVisibleQuickTip: false });
            }}
            style={screenStyles.iconButton}
          >
            <Image
              style={screenStyles.bookIcon}
              source={Images.icons.bookIcon}
            />

            <View style={{ paddingLeft: 8 }} />

            <Body2 color={currentPalette.primary.contrastText}>
              Review
            </Body2>

          </DebouncedTouchableOpacity>

          <View style={{ flex: 1 }} />

          {enabled && status !== ABScreenStatuses.loading && (
            <DebouncedTouchableOpacity
              onPress={() => this.nextUIState()}
              style={screenStyles.iconButton}
            >
              <Body2 color={currentPalette.primary.contrastText}>
                {this.getButtonText()}
              </Body2>

              <Icon
                name="keyboard-arrow-right"
                color={currentPalette.primary.contrastText}
                underlayColor="transparent"
              />
            </DebouncedTouchableOpacity>
          )}

          {Config.answerSolver && !enabled && (
            <ButtonText
              color={currentPalette.basic.white}
              onPress={this.privateSolver}
            >
              SOLVE
            </ButtonText>
          )}

        </View>

      </View>
    );
  }

  renderContent() {
    const { dimensions } = this.props;
    const { status } = this.state;
    const Styles = AdaptiveContainerStyles(dimensions.deviceType);

    let body;

    switch (status) {
      case ABScreenStatuses.showingCorrectAnswers:
        body = this.renderCorrectAnswers();
        break;
      default:
        body = this.renderAssessmentBlock();
        break;
    }

    return (
      <View style={Styles.textContainer}>
        {body}
      </View>
    );
  }

  reviewText = () => (
    <>
      <Body2 color={currentPalette.basic.white} textAlign={'left'}>
        Please review the answer below.
      </Body2>
      <View style={{ height: 16 }} />
      <Body1 color={currentPalette.basic.white} textAlign={'left'}>
        Tip: Pay close attention to expressions like
        “ALL” or “NOT” in the sentence after the question.
      </Body1>
    </>
  );

  renderHeader() {
    const { rootStore: { contentStore }, dimensions } = this.props;
    const { status } = this.state;
    const { skillset } = contentStore;
    const Styles = VideoScreenStyles(dimensions.deviceType);
    const MainStyles = AdaptiveContainerStyles(dimensions.deviceType);
    const showingAnswers = status === ABScreenStatuses.showingCorrectAnswers;

    return (
      <View style={[MainStyles.textContainer, Styles.skillSetInfoContainer, { backgroundColor: skillset.colorTheme }]}>

        {showingAnswers
          ? this.reviewText()
          : (
            <H3 textAlign={'left'} color={currentPalette.primary.contrastText}>
              {skillset.title}
            </H3>
          )
        }

        {!showingAnswers && <AssessmentBlockHeader />}

      </View>
    );
  }

  render() {
    const { rootStore: { contentStore, sessionStore, progressStore }, navigation } = this.props;
    const { isVisibleInactivityMessage, isVisibleFirstTimeStepCompleteModal } = this.state;
    const { learner } = sessionStore;
    const { config } = learner;
    const { skill, step, skillset } = contentStore;

    if (!skillset) return <FullViewSpinner />;

    const { colorType, colorTheme, colorTheme5 } = skillset;

    // Stop timer on inactivity modal
    if (isVisibleInactivityMessage) {
      progressStore.setActiveTab('');
    } else {
      progressStore.setActiveTab('Learn');
    }

    return (
      <ViewWrapper
        smSize={12}
        mdSize={6}
        lgSize={6}
        navigationBar
        bottomBarColor={colorTheme}
        header={this.renderHeader()}
        footer={this.renderBottomBar()}
        scrollToTop
        backOverride={() => NavigationService.navigate('App', { screen: 'Learn' })}
      >

        <BottomSheetModal
          isVisible={!sessionStore.hasInternet}
          dismissable={false}
          icon={Images.icons.noWifi}
          messageTitle={'No internet Connection!'}
          messageContent={'Please wait a couple of seconds and try again'}
          primaryAction={{
            text: 'Retry',
            action: () => NetInfo.fetch().then(state => sessionStore.updateInternetStatus(state.isInternetReachable)),
          }}
        />

        {/* TODO: check if it is deprecated? */}
        {isVisibleFirstTimeStepCompleteModal && (
          <FirstTimeStepCompleteModal
            colorType={colorType}
            colorTheme={colorTheme}
            backgroundColor={colorTheme5}
            step={step}
            isVisible={isVisibleFirstTimeStepCompleteModal}
            toggleVisible={() => {
              this.setState({ isVisibleFirstTimeStepCompleteModal: false });
              this.navigateBack();
            }}
          />
        )}

        <StudyMaterialsModal
          skill={skill}
          step={step}
          colorTheme={skillset.colorTheme}
          colorType={skillset.colorType}
        />

        <InactivityWatcher
          enabled={config.idlePopupEnabled}
          timeInSeconds={config.idleTimerSeconds}
          isVisible={isVisibleInactivityMessage}
          toggleVisible={(active) => { this.toggleInactivityMessage(active); }}
          actionDeny={() => { navigation.navigate('App', { screen: 'Learn' }); }}
        >

          { this.renderContent() }

        </InactivityWatcher>

        <ScreenTimer onUpdateTime={this.updateScreenTime} />

      </ViewWrapper>
    );
  }
}

export default withDimensions(inject('rootStore')(observer(AssessmentBlocksScreen)));
