import { types } from 'mobx-state-tree';

// Select and select with Other answer
const QuestionnaireSelectAnswer = types.model('QuestionnaireSelectAnswer', {
  id: types.identifier,
  answerChoice: types.maybe(types.array(types.string)),
  other: types.maybe(types.string),
});

export default QuestionnaireSelectAnswer;
