const colors = {
  background: '#1F0808',
  clear: 'rgba(0,0,0,0)',
  transparent: 'rgba(0,0,0,0)',
  snow: 'white',
  fire: '#e73536',
  border: '#483F53',
  text: '#E0D7E5',
  text_gray: '#47444f',
  text_light_gray: '#a39fa0',
  black: '#000000',
  white: '#FFFFFF',
  deep_purple: '#2c2339',
  locked: '#e2e2e2',
  bright_orange: '#F96700',
  orangeish: '#ff8d3b',
  light_peach: '#fbdbc3',
  very_light_peach: '#f8f3e8',
  dark: '#2b233a',
  dusk: '#615575',
  pale_purple: '#b6b2bc',
  BG: '#faf8f4',
  green: '#398557',
  lipstick: '#d8152d',
  oldPink: '#e74f85',
  oldOrange: '#f0a104',
  oldGreen: '#8fa112',
  oldBlue: '#657fdf',
  oldMagenta: '#843fa9',
  mainPink: '#D65B85',
  mainOrange: '#D28300',
  mainGreen: '#8D9933',
  mainBlue: '#6981D8',
  mainMagenta: '#7B46A3',
  secondaryPink: '#E990B0',
  secondaryOrange: '#EFA104',
  secondaryGreen: '#B6BE68',
  secondaryBlue: '#94A7F0',
  secondaryMagenta: '#9B6FBD',
  beige_two: '#faf0e7',
  app_grey_grey_2: '#dedadc',
  brand_modal_black: '#29292E',
  lockedGray: '#716A7C',
  skeleton_placeholder: '#DEDADC',
  shadow: '#dedadc',
  shadow_two: '#faf0e7',
  placeholder: '#dedadc',
  brandRed: '#BF223C',
};

export default colors;
