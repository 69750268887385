import { StyleSheet } from 'react-native';
import { Colors } from '@nextstep/app/Themes';

export default StyleSheet.create({
  logo: {
    width: 200,
    height: 100,
    resizeMode: 'contain',
  },

  slide: { flex: 1 },

  imageContainer: { marginBottom: 21 },

  image: {
    aspectRatio: 1.5,
    resizeMode: 'contain',
  },

  textContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 30,
  },

  sliderDot: {
    backgroundColor: Colors.light_peach,
    width: 6,
    height: 6,
    borderRadius: 4,
    marginLeft: 8,
    marginTop: 3,
    marginBottom: 0,
  },

  sliderDotActive: {
    backgroundColor: Colors.bright_orange,
    width: 8,
    height: 8,
  },
});
