import { StyleSheet, TouchableOpacity, View, Platform } from 'react-native';
import React from 'react';
import { Body2 } from '@nextstep/app/components/Text';

const styles = StyleSheet.create({
  sidebarItem: {
    width: '100%',
    paddingHorizontal: 15,
    paddingVertical: 12,
    flexDirection: 'row',
    alignItems: 'center',
  },

  sidebarIcon: { marginRight: 16 },

  sidebarText: {
    textAlign: 'left',
    overflow: 'hidden',
  },
});

export default function SlideItem({ backgroundColor, textColor, onPress, icon, label }) {
  // Prevent text from wrapping on web while resizing window
  if (Platform.OS === 'web') {
    styles.sidebarText = {
      ...styles.sidebarText,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    };
  }
  return (
    <TouchableOpacity
      style={[
        styles.sidebarItem,
        backgroundColor && { backgroundColor },
      ]}
      onPress={onPress}
    >
      { icon && (
        <View style={styles.sidebarIcon}>
          { icon }
        </View>
      )}
      <Body2 style={[styles.sidebarText, textColor && { color: textColor }]}>{label}</Body2>
    </TouchableOpacity>
  );
}
