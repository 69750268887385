import React from 'react';
import { Linking, Platform, Image, View } from 'react-native';
import startCase from 'lodash/startCase';
import camelCase from 'lodash/camelCase';
import find from 'lodash/find';
import { AsYouType } from 'libphonenumber-js';
import { mergeDeepLeft } from 'ramda';
import { applySnapshot } from 'mobx-state-tree';
import Color from 'color';
import { sanitizeStateName, getStateNameByStateCode } from 'us-state-codes';
import { Colors, Images } from '@nextstep/app/Themes';
import states from '@nextstep/tests/fixtures/states.json';
import Constants, { StatusCodes, RegionTimeZones, Severity } from '@nextstep/app/Config/Constants';
import { Caption1 } from '@nextstep/app/components/Text';
import PreclinicalRequirementsScreenStyles from '@nextstep/app/containers/styles/PreclinicalRequirementsScreenStyles';


/** Explanation
 * Normally, if you applySnapshot on a parent node to update the state of the parent, any information in a child node that isn't in the snapshot will be lost.
 * This function merges vales already in the store with those in a snapshot, allowing you to safely change a parent node.
 */
const applyMergedSnapshot = (node, snapshot) => {
  const mergedSnapshot = mergeDeepLeft(snapshot, node);
  applySnapshot(node, mergedSnapshot);
};

const timeInHms = (timeInSeconds) => {
  const h = Math.floor(timeInSeconds / 3600);
  const m = Math.floor((timeInSeconds / 60) - (h * 60));
  const s = (timeInSeconds - (h * 3600) - (m * 60));

  return { h, m, s };
};

const padStart = (number) => `${number}`.padStart(2, '0');

const timeFormatted = (timeInSeconds, seconds = true, format = 'text') => {
  const hmsTime = timeInHms(timeInSeconds);
  if (format === 'text') {
    let formatted = '';
    if (hmsTime.h > 0) formatted += `${hmsTime.h}h `;
    if (hmsTime.m > 0) formatted += `${padStart(hmsTime.m)}m `;
    if (hmsTime.s > 0 && seconds) formatted += `${padStart(hmsTime.s)}s`;

    return formatted.trim();
  }

  if (format === 'textLong') {
    let formatted = '';
    if (hmsTime.h > 0) formatted += `${hmsTime.h} hours `;
    if (hmsTime.m > 0) formatted += `${padStart(hmsTime.m)} minutes `;
    if (hmsTime.s > 0 && seconds) formatted += `${padStart(hmsTime.s)} seconds`;

    return formatted.trim();
  }

  if (format === 'timer') {
    if (hmsTime.h > 0) {
      if (seconds) {
        return `${hmsTime.h}:${padStart(hmsTime.m)}:${padStart(hmsTime.s)}m`;
      }
      return `${hmsTime.h}:${padStart(hmsTime.m)}m`;
    }

    if (seconds) {
      return `${padStart(hmsTime.m)}:${padStart(hmsTime.s)}m`;
    }

    return `${padStart(hmsTime.m)}m`;
  }

  return timeInSeconds;
};

const getDayName = (number) => {
  if (number === 0) return 'sun';
  if (number === 1) return 'mon';
  if (number === 2) return 'tue';
  if (number === 3) return 'wed';
  if (number === 4) return 'thu';
  if (number === 5) return 'fri';
  if (number === 6) return 'sat';
  return '';
};

const shuffle = (array) => {
  const a = [...array];
  for (let i = a.length - 1; i > 0; i -= 1) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
};

const upper = word => word.replace(/^\w/, c => c.toUpperCase());

const toTitleCase = (string) => string.toLocaleLowerCase().split(' ').map(word => upper(word)).join(' ');

const isValidPhoneNumber = (phoneNumber) => {
  const re = /^(\()?\d{3}(\))?(-|\s)?\d{3}(-|\s)\d{4}$/;
  return re.test(phoneNumber);
};

const isValidEmailAddress = (email) => {
  const re = /^[^\s@]+@([^\s@.]*[^.]\.)+[^\s@.]+$/;
  return re.test(email);
};

const isValidUsaState = (stateString) => (Boolean(sanitizeStateName(stateString) || getStateNameByStateCode(stateString)));

const isValidZipcode = (zipCode) => {
  const re = /^\d{5}$/;
  return re.test(zipCode);
};

const formatUsNumber = (text) => {
  if (text.length > 5) {
    return new AsYouType('US').input(text);
  }

  return text;
};

const safeToString = (data) => {
  if (typeof data === 'object') {
    data = JSON.stringify(data);
  }
  return data ?? '';
};

const updateNamingConvention = (response) => {
  const updated = { ...response };
  if (response.introProgress) {
    updated.introVideoProgress = response.introProgress;
    delete updated.introProgress;
  }
  if (response.instructionalVideo) {
    updated.introVideo = response.instructionalVideo;
    delete updated.instructionalVideo;
  }
  return updated;
};

const getStepsData = (skillProgress) => {
  if (!skillProgress) return { totalSteps: 1, stepsCompleted: 0 };

  let totalSteps = 0;
  let stepsCompleted = 0;
  if (skillProgress.introVideoProgress) {
    if (skillProgress.introVideoProgress.isCompleted) stepsCompleted++;
    totalSteps++;
  }
  if (skillProgress.linearAssessmentProgress) {
    if (skillProgress.linearAssessmentProgress.isCompleted) stepsCompleted++;
    totalSteps++;
  }
  if (skillProgress.stepProgresses) {
    skillProgress.stepProgresses.forEach(step => step.isCompleted && stepsCompleted++);
    totalSteps += skillProgress.stepProgresses.length;
  }
  return { totalSteps, stepsCompleted };
};

const getStateFromAbbreviation = (abbreviation) => states[abbreviation];

const getColorResource = (colorType) => Images.icons[colorType];

const pascalize = (str) => startCase(camelCase(str)).replace(/ /g, '');

const pascalToCamel = word => word.replace(/^\w/, c => c.toLowerCase());

const shouldReject = (response) => {
  if (!response || response.status === StatusCodes.conflict) return true;
  // unprocessableEntity we continue execution and try to resolve
  if (response.status === StatusCodes.unprocessableEntity || response.ok) return false;
  // !response.ok
  return true;
};

const openURL = async (url:string, target:string = '_blank') => {
  if (Platform.OS === 'web') window.open(url, target);
  else {
    await Linking.canOpenURL(url)
      .then(await Linking.openURL(url))
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line no-console
      .catch(err => console.error('Something went wrong', err));
  }
};

const isStringLiteral = (element) => typeof element === 'string';

const colorWithTint = (tintColor, weight, dark = false) => Color(dark ? Colors.dark : Colors.white)
  .mix(Color(tintColor), weight)
  .string();

const getTimeZoneForRegion = (region) => {
  const defaultTimeZone = find(RegionTimeZones, reg => reg.default);
  const timeZone = find(RegionTimeZones, reg => reg.regions.includes(region)) || defaultTimeZone;

  return timeZone.timeZone;
};

const removeUSCountryCode = (number: string) => number?.replace(/^\+?[1]?/, '');

const isAppBlockedWithReason = (sessionStore, severity = [Severity.low, Severity.high]) => {
  const { learner } = sessionStore;
  const { config: { appBlockEnabled }, blocked, blockReason, blockSeverity } = learner;

  if (!learner || !learner.config || learner.isGuest) return false;

  const blockEnabledReasons = Object.values(Constants.BlockReason);

  if (appBlockEnabled && blocked && blockEnabledReasons.includes(blockReason)) {
    return severity.includes(blockSeverity);
  }

  return false;
};

const preclinicalRequirementsAssets = (item, mobile) => {
  let icon;
  let badge;

  switch (item.status) {
    case 'Approved':
      icon = (
        <Image
          style={mobile ? PreclinicalRequirementsScreenStyles.iconMobile : PreclinicalRequirementsScreenStyles.icon}
          source={Images.icons.checkMark}
        />
      );
      badge = (
        <View style={PreclinicalRequirementsScreenStyles.completeBadge}>
          <Caption1 color={'#963300'}>Complete</Caption1>
        </View>
      );
      break;
    case 'In Progress':
      icon = (
        <Image
          style={mobile ? PreclinicalRequirementsScreenStyles.iconMobile : PreclinicalRequirementsScreenStyles.icon}
          source={Images.icons.inProgress}
        />
      );
      badge = '';
      break;
    case 'Normal':
      icon = (
        <Image
          style={mobile ? PreclinicalRequirementsScreenStyles.iconMobile : PreclinicalRequirementsScreenStyles.icon}
          source={Images.icons.uploadCloud}
        />
      );
      badge = (
        <View style={PreclinicalRequirementsScreenStyles.uploadBadge}>
          <Caption1 color={'#FFFFFF'}>Upload</Caption1>
        </View>
      );
      break;
    default:
      break;
  }

  return { icon, badge };
};

export default {
  applyMergedSnapshot,
  colorWithTint,
  formatUsNumber,
  getColorResource,
  getDayName,
  getStateFromAbbreviation,
  getStepsData,
  isAppBlockedWithReason,
  isStringLiteral,
  isValidUsaState,
  isValidEmailAddress,
  isValidPhoneNumber,
  isValidZipcode,
  openURL,
  pascalize,
  pascalToCamel,
  removeUSCountryCode,
  safeToString,
  shouldReject,
  shuffle,
  timeFormatted,
  toTitleCase,
  updateNamingConvention,
  upper,
  getTimeZoneForRegion,
  preclinicalRequirementsAssets,
};
