import { ResponsiveStyleSheet } from '@nextstep/app/lib/ResponsiveLayout';
import { DeviceType } from '@nextstep/app/lib/withDimensions';

export default ResponsiveStyleSheet.create({
  icon: {
    height: 96,

    [DeviceType.Mobile]: { marginBottom: 20 },
  },

  circle: {
    position: 'absolute',
    left: 8,
    right: 8,
    top: 8,
    bottom: 8,
    width: 88,
    height: 88,
  },

  circleText: {
    position: 'absolute',
    top: 104,
    minWidth: 156,
  },

  completeIcon: {
    width: 30,
    height: 30,
    resizeMode: 'contain',
    position: 'absolute',
    right: 5,
    top: 5,
  },

  img: {
    height: '100%',
    width: '100%',
    resizeMode: 'cover',
    borderRadius: 50,
  },
});
