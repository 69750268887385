import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { analyticsScreenView } from '@nextstep/app/services/Analytics';
import withDimensions, { DeviceType } from '@nextstep/app/lib/withDimensions';
import AppMainHeader from '@nextstep/app/components/AppMainHeader';
import ViewWrapper from '@nextstep/app/components/ViewWrapper';
import MarkdownRender from '@nextstep/app/components/MarkdownRender';
import { FullViewSpinner } from '@nextstep/app/components/SharedUI';
import NavigationService from '@nextstep/app/services/NavigationService';

class PracticeLabAssetScreen extends Component {
  async componentDidMount() {
    analyticsScreenView('PracticeLabActivityDataViewed');
  }

  render() {
    const { rootStore: { contentStore }, dimensions, navigation } = this.props;
    const { labSkill } = contentStore;

    if (!labSkill) return <FullViewSpinner />;

    return (
      <>
        {dimensions.deviceType !== DeviceType.Desktop && <AppMainHeader navigation={navigation} />}
        <ViewWrapper
          smSize={12}
          mdSize={7}
          lgSize={6}
          navigationBar
          scrollToTop
          backOverride={() => NavigationService.navigate('App', { screen: 'In-Person' })}
        >
          <MarkdownRender data={labSkill.asset} />
        </ViewWrapper>
      </>
    );
  }
}

export default withDimensions(inject('rootStore')(observer(PracticeLabAssetScreen)));
