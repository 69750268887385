import React from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import { Palette } from '@nextstep/app/Themes';
import VideoScreenStyles from '@nextstep/app/containers/styles/VideoScreenStyles';
import AdaptiveContainerStyles from '@nextstep/app/containers/styles/AdaptiveContainerStyles';
import withDimensions from '@nextstep/app/lib/withDimensions';
import { Body1, H3 } from '@nextstep/app/components/Text';

const currentPalette = Palette.light;

const QuestionHeader = ({
  colorTheme,
  currentQuestionIndex,
  dimensions,
  title,
  totalQuestionsCount,
}) => {
  const Styles = VideoScreenStyles(dimensions.deviceType);
  const MainStyles = AdaptiveContainerStyles(dimensions.deviceType);

  return (
    <View style={[MainStyles.textContainer, Styles.skillSetInfoContainer, {
      backgroundColor: colorTheme,
      paddingVertical: 20,
    }]}
    >

      <H3 textAlign={'left'} color={currentPalette.primary.contrastText}>
        { title }
      </H3>

      <Body1 textAlign={'left'} color={currentPalette.primary.contrastText}>
        { `Question ${currentQuestionIndex + 1} of ${totalQuestionsCount}` }
      </Body1>

    </View>
  );
};

QuestionHeader.propTypes = {
  colorTheme: PropTypes.string.isRequired,
  currentQuestionIndex: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  totalQuestionsCount: PropTypes.number.isRequired,
};

export default withDimensions(QuestionHeader);
