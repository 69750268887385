import React, { Component } from 'react';
import { AndroidBackHandler } from 'react-navigation-backhandler';
import { Platform, ScrollView, StatusBar, View } from 'react-native';
import { wrapScrollViewConfigured } from 'react-native-scroll-into-view';
import { inject, observer } from 'mobx-react';
import Skillset from '@nextstep/app/components/Skillset';
import { ApplicationStyles, Images, Palette } from '@nextstep/app/Themes';
import { FullViewSpinner } from '@nextstep/app/components/SharedUI';
import StudyMaterialsModal from '@nextstep/app/modals/StudyMaterialsModal';
import { ContainerErrorBoundary } from '@nextstep/app/lib/ErrorUtils';
import { analyticsScreenView } from '@nextstep/app/services/Analytics';
import { afterInteractions } from '@nextstep/app/lib/afterInteractions';
import ScreenTimer from '@nextstep/app/components/ScreenTimer';
import withDimensions, { DeviceType } from '@nextstep/app/lib/withDimensions';
import { Row, Column } from '@nextstep/app/lib/ResponsiveLayout';
import SkillsetHeader from '@nextstep/app/components/SkillsetHeader';
import { Caption1 } from '@nextstep/app/components/Text';
import AppMainHeader from '@nextstep/app/components/AppMainHeader';
import DebouncedTouchableOpacity from '@nextstep/app/components/DebouncedTouchableOpacity';
import NavigationService from '@nextstep/app/services/NavigationService';
import Util from '@nextstep/app/services/Util';
import { ContentNodeTypes, ProgressNodeTypes } from '@nextstep/app/Config/Constants';
import BottomSheetModal from '@nextstep/app/modals/BottomSheetModal';

import NetInfo from '@react-native-community/netinfo';
import Styles from './styles/LearnScreenStyles';
import AppBlockedScreen from './AppBlockedScreen';

const EMAIL = 'support@nextstep.com';

const currentPalette = Palette.light;

const WrappedScrollView = wrapScrollViewConfigured({ onUpdate: false })(ScrollView);

export class LearnScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFocused: true,
      loading: true,
      isGuest: false,
    };

    this.navigateToStep = this.navigateToStep.bind(this);
    this.onBackButtonPressAndroid = this.onBackButtonPressAndroid.bind(this);
  }

  onBackButtonPressAndroid() {
    const { isGuest } = this.state;

    if (!isGuest) NavigationService.navigate('App', { screen: 'Home' });
    return true;
  }

  // eslint-disable-next-line react/no-deprecated,camelcase
  async UNSAFE_componentWillMount() {
    const { route } = this.props;
    if (!route.params?.predefinedSkillset) await this.setLastUncompletedSkillset();
  }

  async componentDidMount() {
    const { rootStore, navigation } = this.props;
    const { sessionStore, contentStore, progressStore } = rootStore;
    const { learner } = sessionStore;

    analyticsScreenView('learnTabViewed');

    await afterInteractions();

    contentStore.clearCurrentExcept([ContentNodeTypes.skillset, ContentNodeTypes.course]);
    progressStore.clearCurrentExcept([ProgressNodeTypes.skillsetProgress, ProgressNodeTypes.courseProgress]);

    await sessionStore.syncCurrentLearner({});

    StatusBar.setBarStyle('dark-content', true);
    if (Platform.OS === 'android') StatusBar.setBackgroundColor(currentPalette.background.white, true);

    this.focusListener = navigation.addListener('focus', () => {
      this.setState({ isFocused: true });
    });
    this.blurListener = navigation.addListener('blur', () => {
      this.setState({ isFocused: false });
    });

    this.setState({
      loading: false,
      isFocused: navigation.isFocused(),
      isGuest: learner.isGuest,
    });
  }

  componentWillUnmount() {
    if (!this.focusListener && !this.blurListener) return;
    this.focusListener();
    this.blurListener();
  }

  async setLastUncompletedSkillset() {
    const { rootStore } = this.props;
    const { contentStore, progressStore } = rootStore;

    if (contentStore?.skillsets?.length) {
      const { lastUncompletedSkillset } = progressStore;
      const skillset = contentStore.getSkillset(lastUncompletedSkillset?.skillsetId);
      await progressStore.setExpandedSkillsetKey(skillset.key);
    }
  }

  async navigateToStep(step, skill) {
    const { navigation, rootStore } = this.props;
    const { contentStore, progressStore } = rootStore;

    // todo: put these in componentDidMount on skill
    await contentStore.setSkill(skill.key);
    await progressStore.setSkillProgress(skill.id);

    if (progressStore.skillProgress.isUnlocked) {
      await progressStore.startSkill();
    }

    await contentStore.setStep(step.key);
    await progressStore.setStepProgress(step.id);

    const { stepProgress, setStudyMaterialsModalVisibility } = progressStore;

    if (stepProgress.isCompleted) {
      setStudyMaterialsModalVisibility(true);
    } else {
      const learningObject = progressStore.getNextStepChild();
      navigation.navigate(learningObject, {
        stepKey: step.key,
        skillKey: skill.key,
        skillsetKey: contentStore.skillset.key,
      });
    }
  }

  updateScreenTime = (decrement) => {
    const { rootStore } = this.props;
    const { progressStore, contentStore } = rootStore;
    const { skillsetProgress, activeTab, expandedSkillsetKey } = progressStore;
    const { skillset } = contentStore;

    if (!skillset || !skillsetProgress || skillsetProgress.isCompleted) return;
    const validSkillset = skillset.id === skillsetProgress.skillsetId;
    const isLearnTab = activeTab === 'Learn';
    const validExpandedSkillsetKey = expandedSkillsetKey !== '';
    const hasTimeConstraint = skillset.requiredMinimumTime > 0;

    if (isLearnTab && validExpandedSkillsetKey && validSkillset && hasTimeConstraint) {
      progressStore.reduceRemainingRequiredTime(decrement);
    }
  }

  updateRemainingRequiredTime = () => {
    const { rootStore } = this.props;
    const { progressStore, contentStore } = rootStore;
    const { skillsetProgress, activeTab, expandedSkillsetKey } = progressStore;
    const { skillset } = contentStore;

    if (!skillset || !skillsetProgress) return;
    const validSkillset = skillset.id === skillsetProgress.skillsetId;
    const isLearnTab = activeTab === 'Learn';
    const validExpandedSkillsetKey = expandedSkillsetKey !== '';
    const hasTimeConstraint = skillset.requiredMinimumTime > 0;

    if (isLearnTab && validExpandedSkillsetKey && validSkillset) {
      progressStore.logTimer(hasTimeConstraint);
    }
  }

  openSkillset = (skillset) => {
    const { rootStore } = this.props;
    const { progressStore } = rootStore;
    const { expandedSkillsetKey } = progressStore;
    if (expandedSkillsetKey === skillset.key) return;

    progressStore.setExpandedSkillsetKey(skillset.key);
  }

  findPracticeLab = (skillset) => {
    const { rootStore } = this.props;
    return rootStore.contentStore.practiceLabs?.find(lab => lab.requiredSkillsetKey === skillset?.key);
  }

  skillsetsSection = () => {
    const { rootStore, dimensions, navigation } = this.props;
    const { contentStore, progressStore } = rootStore;

    return (
      <WrappedScrollView bounces={false} style={{ width: '100%' }}>
        {contentStore.skillsets?.map((skillset, index) => {
          const skillsetProgress = progressStore.skillsetProgresses.find(ss => ss.skillsetId === skillset.id);
          const locked = skillsetProgress?.isLocked;
          const practiceLab = this.findPracticeLab(skillset);

          if (dimensions.deviceType === DeviceType.Mobile) {
            return (
              <Skillset
                key={index}
                index={index}
                practiceLab={practiceLab}
                skillset={skillset}
                scrollToView
                collapsible
                goToStep={this.navigateToStep}
                navigation={navigation}
              />
            );
          }

          return (
            <DebouncedTouchableOpacity
              key={index}
              onPress={() => this.openSkillset(skillset)}
            >
              <SkillsetHeader
                skillset={skillset}
                locked={locked}
                practiceLab={practiceLab}
                currentSkillsetKey={progressStore.expandedSkillsetKey}
                skillsetProgress={skillsetProgress}
                compact
              />
            </DebouncedTouchableOpacity>
          );
        })}
      </WrappedScrollView>
    );
  };

  expandedSkillsetSection = () => {
    const { navigation, rootStore } = this.props;
    const { contentStore, progressStore } = rootStore;
    const { expandedSkillsetKey } = progressStore;
    const { isFocused } = this.state;

    const skillset = contentStore.skillsets?.find(s => s.key === expandedSkillsetKey);
    const practiceLab = this.findPracticeLab(skillset);

    return (
      <View style={{ height: '100%' }}>
        <ScrollView
          style={{ height: '100%' }}
          contentContainerStyle={{ flexGrow: 1 }}
          scrollEventThrottle={400}
          bounces={false}
        >
          <View style={{ height: 30 }} />
          <Row style={{ flex: 1 }}>
            <Column mdSize={11} lgSize={11} smSize={11} style={{ height: '100%' }}>
              {skillset && (
              <View style={Styles.skillsetCard}>
                <Skillset
                  skillset={skillset}
                  practiceLab={practiceLab}
                  navigation={navigation}
                  goToStep={this.navigateToStep}
                  isLearnScreenFocused={isFocused}
                />
              </View>
              )}
            </Column>
          </Row>
          <View style={{ height: 30 }} />
          <View style={Styles.footer}>
            <Caption1 style={Styles.footerText} textAlign="right">
              {`© ${new Date().getFullYear()} NextStep Interactive Inc. All Rights Reserved`}
            </Caption1>
          </View>
        </ScrollView>
      </View>
    );
  }

  render() {
    const { rootStore: { snapshotUpdating } } = this.props;
    const { rootStore: { contentStore, progressStore, sessionStore }, navigation, dimensions } = this.props;
    const { loading, isFocused } = this.state;
    const { skillset, skill, step } = contentStore;
    const { learner, hasInternet } = sessionStore;
    const { allGuestSkillsetsCompleted, metrics, skillsetProgresses } = progressStore;

    if (loading || !metrics || !skillset || !skillsetProgresses) return <FullViewSpinner showBackground />;
    if (isFocused) progressStore.setActiveTab('Learn');
    if (Util.isAppBlockedWithReason(sessionStore)) AppBlockedScreen(learner);

    const isRequiredGuestSignupModalVisible = allGuestSkillsetsCompleted && sessionStore.learner && sessionStore.learner.isGuest;

    return (
      <ContainerErrorBoundary navigation={navigation}>
        <AndroidBackHandler onBackPress={this.onBackButtonPressAndroid}>
          { dimensions.deviceType !== DeviceType.Desktop && <AppMainHeader navigation={navigation} /> }

          <View
            style={[
              ApplicationStyles.screenContainer(),
              Styles.container,
              Platform.OS !== 'web' && { flex: 1 },
            ]}
          >

            <BottomSheetModal
              isVisible={!hasInternet}
              dismissable={false}
              icon={Images.icons.noWifi}
              messageTitle={'No internet Connection!'}
              messageContent={'Please wait a couple of seconds and try again'}
              primaryAction={{
                text: 'Retry',
                action: () => NetInfo.fetch().then(state => sessionStore.updateInternetStatus(state.isInternetReachable)),
              }}
            />

            <BottomSheetModal
              isVisible={isRequiredGuestSignupModalVisible && isFocused}
              dismissable={false}
              icon={Images.icons.main.trophy}
              messageTitle={'You completed a skillset! '}
              messageContent={`Interested in enrolling? Contact us at ${EMAIL} to find our employer partners in your area`}
              primaryAction={{ text: 'Contact Us', action: () => Util.openURL(`mailto:${EMAIL}`, '_self') }}
              secondaryAction={{ text: 'Sign Out', action: () => sessionStore.logout() }}
            />

            <StudyMaterialsModal
              skill={skill}
              step={step}
              colorTheme={skillset.colorTheme}
              colorType={skillset.colorType}
              isFocused={isFocused}
            />

            {snapshotUpdating && (navigation.navigate('App', { screen: 'Home' }))}

            <Row style={{ width: '100%', height: '100%' }}>

              <Column smSize={12} mdSize={5} lgSize={3} style={{ height: '100%' }}>
                {this.skillsetsSection()}
              </Column>

              <Column mdSize={7} lgSize={9} style={{ height: '100%' }}>

                {this.expandedSkillsetSection()}

              </Column>

            </Row>

            <ScreenTimer
              increment={1}
              onUpdateTime={this.updateScreenTime}
              onMinutePassed={this.updateRemainingRequiredTime}
            />

          </View>
        </AndroidBackHandler>
      </ContainerErrorBoundary>
    );
  }
}

export default withDimensions(inject('rootStore')(observer(LearnScreen)));
