import NavigationService from '@nextstep/app/services/NavigationService';
import { BlockReason } from '@nextstep/app/Config/Constants';

const ENROLLMENT_AGREEMENTS = { OK: 'OKEnrollmentAgreement' };

export default (learner) => {
  if (!learner) return null;

  let enrollmentAgreement;

  switch (learner.blockReason) {
    case BlockReason.disabled:
      NavigationService.reset('DisabledAccountScreen');
      break;
    case BlockReason.paymentFailed: // ................................ NOTE: Deprecated
      NavigationService.reset('NoPaymentAccountScreen');
      break;
    case BlockReason.ineligible: // ................................... NOTE: Deprecated
      NavigationService.reset('IneligibleAccountScreen');
      break;
    case BlockReason.disenrolled:
      NavigationService.reset('DisEnrolledAccountScreen');
      break;
    case BlockReason.reagreement:
      enrollmentAgreement = ENROLLMENT_AGREEMENTS[learner.legacyState];

      if (enrollmentAgreement) {
        NavigationService.reset(enrollmentAgreement);
      } else {
        NavigationService.reset('EnrollmentAgreement');
      }

      break;
    case BlockReason.okAgreement:
      NavigationService.reset('OKEnrollmentAgreement');
      break;
    case BlockReason.covidCardMissing: // ............................. NOTE: Deprecated
      NavigationService.reset('CovidProof');
      break;
    case BlockReason.upskillEULA:
      NavigationService.reset('UpskillEnrollmentAgreement');
      break;
    default:
      return null;
  }

  return null;
};
