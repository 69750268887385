import React from 'react';
import {
  NavigationHelpersContext,
  useNavigationBuilder,
  createNavigatorFactory,
} from '@react-navigation/native';
import SlideView from './SlideView';
import SlideRouter from './SlideRouter';

function TabNavigator({
  initialRouteName,
  children,
  screenOptions,
  slideContentOptions,
  slideContent,
  header,
  style,
}) {
  const props = useNavigationBuilder(SlideRouter, {
    children,
    screenOptions,
    initialRouteName,
  });

  const { navigation } = props;
  return (
    <NavigationHelpersContext.Provider value={navigation}>
      <SlideView
        {...props}
        header={header}
        style={style}
        contentOptions={slideContentOptions}
        contentComponent={slideContent}
      />
    </NavigationHelpersContext.Provider>
  );
}

const createSlideNavigator = createNavigatorFactory(TabNavigator);
export default createSlideNavigator;
