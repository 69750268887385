export const Status = {
  completed: 'completed',
  inProgress: 'in_progress',
  failed: 'failed',
  locked: 'locked',
  unlocked: 'unlocked',
  ok: 'OK',
  skippable: 'skippable',
  required: 'required',
};

export const AssignmentStatus = {
  locked: 'locked',
  unlocked: 'unlocked',
  approved: 'approved',
  waitingForApproval: 'waiting_for_approval',
  needsResubmission: 'needs_resubmission',
};

export const AssignmentStatusEnumeration = [
  AssignmentStatus.locked,
  AssignmentStatus.unlocked,
  AssignmentStatus.approved,
  AssignmentStatus.waitingForApproval,
  AssignmentStatus.needsResubmission,
];

export const StatusEnumeration = [
  Status.locked,
  Status.unlocked,
  Status.inProgress,
  Status.completed,
  Status.failed,
];

export const OutcomeTypes = {
  finalExamTimeOut: 'final_exam_time_out',
  finalExamFinished: 'final_exam_finished',
  knowledgeCheckFailed: 'knowledge_check_failed',
  skillCelebration: 'celebration_skill',
  skillsetCelebration: 'celebration_skillset',
  courseCelebration: 'celebration_course',
  fastLearner: 'fast_learner',
  empty: 'empty',
};

export const ABScreenStatuses = {
  checksAndXs: 'checks_and_xs',
  showingCorrectAnswers: 'showing_correct_answers',
  answering: 'answering',
  skillCelebration: 'celebration_skill',
  loading: 'loading',
};

export const QuestionTypes = {
  multipleChoice: 'multiple_choice',
  imageSelect: 'image_select',
  fillInTheBlanks: 'fill_in_the_blanks',
  unorderedSelect: 'unordered_select',
  orderedSelect: 'ordered_select',
};

export const QuestionnaireQuestionTypes = {
  select: 'select',
  boolean: 'boolean',
  text: 'text',
};

export const SectionTypeTitles = {
  demoVideo: 'Demo Video',
  demoVideos: 'Demo Videos',
  successCriteria: 'Success Criteria',
};

export const StepLearningObjectTypes = {
  assessmentBlocks: 'assessment_blocks',
  successCriteria: 'success_criteria',
  demoVideos: 'demo_videos',
};

export const LabSkillTypes = {
  discussion: 'discussion',
  activity: 'activity',
  video: 'video',
};

export const ContentNodeTypes = {
  course: 'Course',
  skillset: 'Skillset',
  skill: 'Skill',
  step: 'Step',
  linearAssessment: 'LinearAssessment',
  linearAssessmentIteration: 'LinearAssessmentIteration',
  assessmentBlock: 'AssessmentBlock',
  question: 'Question',
  introVideo: 'IntroVideo',
  demoVideo: 'DemoVideo',
  successCriteria: 'SuccessCriteria',
  practiceLab: 'PracticeLab',
  labSkill: 'LabSkill',
  assignmentQuestion: 'AssignmentQuestion',
};

export const ProgressNodeTypes = {
  courseProgress: 'CourseProgress',
  skillsetProgress: 'SkillsetProgress',
  skillProgress: 'SkillProgress',
  stepProgress: 'StepProgress',
  linearAssessmentProgress: 'LinearAssessmentProgress',
  linearAssessmentIterationProgress: 'LinearAssessmentIterationProgress',
  assessmentBlockProgress: 'AssessmentBlockProgress',
  questionProgress: 'QuestionProgress',
  introVideoProgress: 'IntroVideoProgress',
  demoVideoProgress: 'DemoVideoProgress',
  successCriteriaProgress: 'SuccessCriteriaProgress',
  practiceLabProgress: 'PracticeLabProgress',
  labSkillProgress: 'LabSkillProgress',
  assignmentQuestionProgress: 'AssignmentQuestionProgress',
};

export const SkillSectionTypes = {
  introVideo: ContentNodeTypes.introVideo,
  step: ContentNodeTypes.step,
  linearAssessment: ContentNodeTypes.linearAssessment,
};

export const StatusCodes = {
  none: 0,
  unauthorized: 401,
  conflict: 409,
  preconditionFailed: 412,
  unprocessableEntity: 422,
};

export const ErrorCodes = {
  fatalError: 'FATAL_ERROR',
  applicationError: 'APPLICATION_ERROR',
  clientError: 'CLIENT_ERROR', // Any non-specific 400 series error.
  serverError: 'SERVER_ERROR', // Any 500 series error.
  timeoutError: 'TIMEOUT_ERROR', // Server didn't respond in time.
  connectionError: 'CONNECTION_ERROR', // Server not available, bad dns.
  networkError: 'NETWORK_ERROR', // Network not available.
  cancelError: 'CANCEL_ERROR', // Request has been cancelled.
  versionError: 'VERSION_ERROR', // App version incompatible with server version; 412 status.
  conflictError: 'CONFLICT', // Data conflict (http 409)
  FatalErrors: [
    'InternalError',
    'RangeError',
    'ReferenceError',
    'SyntaxError',
    'TypeError',
    'URIError',
  ],
};

export const ErrorActions = {
  retry: 'retry',
  restart: 'restart',
  updateApp: 'updateApp',
  conflict: 'conflict',
};

export const Events = {
  showErrorEvent: 'showError',
  navigateEvent: 'navigateEvent',
};

export const SignUpStatus = { verified: 'verified' };

export const PersistenceKeys = {
  app: 'APP_PERSISTENCE_KEY',
  release_version: 'RELEASE_VERSION',
  navigation: 'NAV_STATE_KEY',
  authTokenKey: 'AUTH_TOKEN_KEY',
  refreshTokenKey: 'REFRESH_TOKEN_KEY',
  initialRoute: 'INITIAL_ROUTE',
  utmSource: 'UTM_SOURCE',
  utmMedium: 'UTM_MEDIUM',
  utmContent: 'UTM_CONTENT',
  utmCampaign: 'UTM_CAMPAIGN',
};

export const Formats = { date: 'YYYY-MM-DDTHH:mm:ss[Z]Z' };

export const InputTextTypes = {
  fullName: 'fullName',
  firstName: 'firstName',
  middleName: 'middleName',
  lastName: 'lastName',
  emailAddress: 'emailAddress',
  zipCode: 'zipCode',
  phoneNumber: 'phoneNumber',
  password: 'password',
  verificationCode: 'verificationCode',
  custom: 'custom',
  addressCity: 'addressCity',
  addressState: 'addressState',
  streetAddressLine1: 'streetAddressLine1',
  streetAddressLine2: 'streetAddressLine2',
};

export const PaymentTrackTypes = {
  scholarship: 'scholarship',
  tuition: 'tuition',
  deferred: 'deferred',
  notSelected: 'not_selected',
  noFee: 'no_fee',
  tuitionEmployerPaid: 'tuition_employer_paid',
};

export const PaymentTrackState = {
  required: 'required',
  notRequired: 'not_required',
  deferrable: 'deferrable',
};

export const BlockReason = {
  disabled: 'disabled',
  disenrolled: 'disenrolled',
  ineligible: 'ineligible',
  paymentFailed: 'payment_failed',
  reagreement: 'agreement_consent_required',
  covidCardMissing: 'covid_card_missing',
  upskillEULA: 'upskill_eula_agreement_required',
};

export const WidthThemes = {
  wide: 'wide',
  narrow: 'narrow',
};

export const MinSkillHeaderHeight = 125;

export const TuitionDefaults = {
  payment_frequency: 'bi-weekly',
  registration_fee: 120,
  installments: 5,
  cost: 995,
};

export const RegionTimeZones = [
  {
    timeZone: 'America/Denver',
    regions: ['CO'],
    default: true,
  },
  {
    timeZone: 'America/Los_Angeles',
    regions: ['WA', 'OR', 'CA'],
  },
];

export const InAppReminderTypes = {
  banner: 'BANNER',
  inline: 'INLINE',
};

export const Priority = {
  low: 'LOW',
  medium: 'MEDIUM',
  high: 'HIGH',
};

export const Severity = {
  low: 'LOW',
  high: 'HIGH',
};

export default {
  AssignmentStatus,
  AssignmentStatusEnumeration,
  BlockReason,
  ErrorCodes,
  Formats,
  InAppReminderTypes,
  InputTextTypes,
  LabSkillTypes,
  LearningObjectTypes: ContentNodeTypes,
  MinSkillHeaderHeight,
  OutcomeTypes,
  PaymentTrackState,
  PaymentTrackTypes,
  PersistenceKeys,
  Priority,
  QuestionTypes,
  QuestionnaireQuestionTypes,
  RegionTimeZones,
  Severity,
  SignUpStatus,
  Status,
  StatusEnumeration,
  StepLearningObjectTypes,
  TuitionDefaults,
  WidthThemes,
};
