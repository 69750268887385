export class BranchEvent {
  // eslint-disable-next-line class-methods-use-this
  logEvent() {}
}

export default {
  BranchEvent,
  logout: () => {},
  setIdentity: () => {},
};
