/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Image, Platform, View } from 'react-native';
import { Card, Icon } from 'react-native-elements';
import PropTypes from 'prop-types';
import { Images, Typography } from '@nextstep/app/Themes';
import Styles from '@nextstep/app/containers/styles/SettingScreenStyles';
import moment from 'moment';
import { inject, observer } from 'mobx-react';
import { currentPalette } from '@nextstep/app/Config/AppConfig';
import { ButtonText } from './Button';
import { Body1 } from './Text';

class EnrollmentAgreementHistoryCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = { emailedAgreement: false };
  }

  emailEnrollmentAgreement = async () => {
    const { rootStore: { sessionStore }, id } = this.props;

    this.setState({ emailedAgreement: true });
    await sessionStore.emailEnrollmentAgreement(id);
    setTimeout(() => this.setState({ emailedAgreement: false }), 10000);
  }

  render() {
    const { startOn, completionDeadlineOn, requestText, requestSentText } = this.props;
    const { emailedAgreement } = this.state;

    return (
      <>
        <Card containerStyle={[Styles.cardContainerStyle, Platform.OS === 'web' && { borderWidth: 2 }]}>
          <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
            <Image
              style={[
                Styles.smallIcon,
                Platform.OS === 'web' && { filter: 'none' },
              ]}
              source={Images.icons.learn}
            />

            <View style={{ width: 8 }} />

            <View style={{ alignItems: 'flex-start' }}>
              <Body1>
                Enrollment Agreement Start
              </Body1>

              <Body1 color={currentPalette.text.gray}>
                {`${moment(startOn, 'YYYY-MM-DD').format('l')}`}
              </Body1>
            </View>
          </View>

          <View style={{ height: 17 }} />

          <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
            <Icon
              name={'md-school'}
              type="ionicon"
              color={currentPalette.primary.main}
              size={25}
            />

            <View style={{ width: 10 }} />

            <View style={{ alignItems: 'flex-start' }}>
              <Body1>
                Enrollment Agreement End Date
              </Body1>

              <Body1 color={currentPalette.text.gray}>
                {`${moment(completionDeadlineOn, 'YYYY-MM-DD').format('l')}`}
              </Body1>
            </View>
          </View>

          <View style={{ height: 17 }} />

          <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
            {emailedAgreement ? (
              <>
                <Icon
                  name={'md-checkmark-circle'}
                  type="ionicon"
                  color={currentPalette.basic.green}
                  size={25}
                />

                <View style={{ width: 10 }} />

                <Body1
                  textAlign="left"
                  color={currentPalette.basic.green}
                  style={{ flex: 1 }}
                >
                  {requestSentText}
                </Body1>
              </>
            ) : (
              <>
                <Icon
                  name={'md-document'}
                  type="ionicon"
                  color={currentPalette.primary.main}
                  size={25}
                  style={{ paddingLeft: 5 }}
                />

                <View style={{ width: 10 }} />

                <ButtonText
                  id={'learner_agreement'}
                  textStyle={{ ...Typography.body1, textAlign: 'left' }}
                  onPress={async () => this.emailEnrollmentAgreement()}
                >
                  {requestText}
                </ButtonText>
              </>
            )}

          </View>
        </Card>

        <View style={{ height: 15 }} />
      </>
    );
  }
}

EnrollmentAgreementHistoryCard.propTypes = {
  id: PropTypes.number.isRequired,
  startOn: PropTypes.string,
  completionDeadlineOn: PropTypes.string,
  requestText: PropTypes.string,
  requestSentText: PropTypes.string,
};

EnrollmentAgreementHistoryCard.defaultProps = {
  startOn: undefined,
  completionDeadlineOn: undefined,
  requestText: 'Request Signed Enrollment Agreement',
  requestSentText: 'We are sending your enrollment agreement to the email provided.',
};



export default inject('rootStore')(observer(EnrollmentAgreementHistoryCard));
