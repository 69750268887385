import React from 'react';
import { Platform, View } from 'react-native';
import LottieView from 'lottie-react-native';
import { Palette } from '@nextstep/app/Themes';
import Trophy from '@nextstep/assets/animations/trophy.json';
import withDimensions from '@nextstep/app/lib/withDimensions';
import { Column, Row } from '@nextstep/app/lib/ResponsiveLayout';
import Confetti from '@nextstep/assets/animations/confetti-celebration.json';
import NavigationService from '@nextstep/app/services/NavigationService';
import { ButtonFilled } from '@nextstep/app/components/Button';
import { Body1, H3, Title } from '@nextstep/app/components/Text';

const currentPalette = Palette.light;

const CourseCompletedCelebration = () => {
  const [confetti, setConfetti] = React.useState(null);
  React.useEffect(() => {
    import('@nextstep/assets/animations/confetti-celebration.json').then(({ default: c }) => {
      setConfetti(c);
    });
  }, []);

  if (!confetti) return null;

  return (
    <View style={[{ justifyContent: 'center' }, Platform.OS !== 'web' && { height: '100%' }]}>

      <View style={{ height: 100 }} />

      <Row>
        <Column smSize={10} mdSize={10} lgSize={6}>

          <LottieView
            source={Confetti}
            style={{ alignSelf: 'center', position: 'absolute', width: '100%' }}
            autoPlay
          />

          <LottieView
            source={Trophy}
            style={{ height: 217, alignSelf: 'center' }}
            autoPlay
            loop={false}
          />

          <View style={{ height: 20 }} />

          <Title color={currentPalette.primary.main}>
            CONGRATS ON COMPLETING
          </Title>

          <H3 color={currentPalette.primary.main}>
            the In-App Course!
          </H3>

          <View style={{ height: 20 }} />

          <Body1>
            Thank you for your hard work, perseverance, and belief in yourself.
          </Body1>

        </Column>
      </Row>

      <View style={{ height: 40 }} />

      <Row>
        <Column smSize={12} style={{ alignItems: 'center' }}>
          <ButtonFilled onPress={() => NavigationService.navigate('App', { screen: 'Home' })}>
            Home
          </ButtonFilled>
        </Column>
      </Row>

      <View style={{ height: 36 }} />

    </View>
  );
};

export default withDimensions(CourseCompletedCelebration);
