import { types } from 'mobx-state-tree';
import WithStatus from './WithStatus';
import WithDuration from './WithDuration';
import ProgressNode from './ProgressNode';
import WithLeafNodeActions from './WithLeafNodeActions';
import LinearAssessmentIterationProgress from './LinearAssessmentIterationProgress';
import LinearAssessment from './LinearAssessment';

const LinearAssessmentProgressModel = types.model({
  id: types.identifierNumber,
  linearAssessmentId: types.maybe(types.number),
  linearAssessmentIterationProgress: types.maybe(LinearAssessmentIterationProgress),
  courseKey: types.maybe(types.string),
  parentId: types.maybe(types.number),
  parentType: types.maybe(types.string), // use constants
  content: types.maybe(types.safeReference(LinearAssessment)),
  correctQuestionCount: types.maybe(types.number),
}).actions(self => ({
  setCurrent: () => {
    self.store.linearAssessmentProgress = self;
    return self;
  },
}));

const LinearAssessmentProgress = types.compose(LinearAssessmentProgressModel, ProgressNode, WithStatus, WithDuration, WithLeafNodeActions).named('LinearAssessmentProgress');

export default LinearAssessmentProgress;
