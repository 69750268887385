import { types } from 'mobx-state-tree';
import { ContentNodeTypes, LabSkillTypes } from '@nextstep/app/Config/Constants';

const LabSkillModel = types.model('LabSkill', {
  id: types.identifierNumber,
  key: types.string,
  title: types.string,
  description: types.maybe(types.string),
  skillType: types.maybe(types.union(
    types.literal(LabSkillTypes.activity),
    types.literal(LabSkillTypes.discussion),
    types.literal(LabSkillTypes.video),
  )),
  asset: types.maybe(types.string),
});

const LabSkill = types.compose(LabSkillModel).named(ContentNodeTypes.labSkill);

export default LabSkill;
