import { types } from 'mobx-state-tree';
import { Status } from '@nextstep/app/Config/Constants';
import SkillProgress from './SkillProgress';
import WithStatus from './WithStatus';
import ProgressNode from './ProgressNode';
import LinearAssessmentProgress from './LinearAssessmentProgress';
import Skillset from './Skillset';

const SkillsetProgressModel = types.model('SkillsetProgress', {
  courseKey: types.maybe(types.string),
  courseProgressId: types.maybe(types.number),
  fastLearnerMessage: false,
  guestAccessible: types.maybe(types.boolean),
  id: types.optional(types.identifierNumber, 0),
  remainingRequiredTime: 0,
  skillProgresses: types.maybe(types.array(SkillProgress)),
  linearAssessmentProgress: types.maybeNull(LinearAssessmentProgress),
  skillsetId: types.number,
  tosPromptStatus: types.maybe(types.string),
  paymentTrackPromptStatus: types.maybe(types.string),
  content: types.maybe(types.safeReference(Skillset)),
}).views(self => ({
  get skillsCompletedCount() {
    if (!self.skillProgresses) return 0;
    return self.skillProgresses.filter(sp => sp.isCompleted).length;
  },
  get isGuestAccessible() {
    return self.guestAccessible;
  },
  get areAllChildrenCompleted() {
    if (!self.skillProgresses.every(skill => skill.isCompleted)) {
      return false;
    }

    if (self.linearAssessmentProgress && !self.linearAssessmentProgress.isCompleted) {
      return false;
    }

    return true;
  },
  get hasProgress() {
    return Boolean([Status.inProgress, Status.completed].includes(self.status));
  },
}));

const SkillsetProgress = types.compose(SkillsetProgressModel, ProgressNode, WithStatus).named('SkillsetProgress');

export default SkillsetProgress;
