import axios from 'axios';
import apisauce from 'apisauce';
import Config from 'react-native-config';
import { Platform } from 'react-native';
import jwtDecode from 'jwt-decode';
import DeviceInfo from 'react-native-device-info';
import axiosRetry from 'axios-retry';
import { StatusCodes } from '@nextstep/app/Config/Constants';

const isWeb = Platform.OS === 'web';

const create = (baseURL = Config.API_URL) => {
  // For web, DeviceInfo will come from stand in /web/react-native-device-info.js
  const releaseVersion = DeviceInfo.getVersion();
  const headers = {
    'Cache-Control': 'no-cache',
    Accept: 'application/json',
    'Content-Type': 'application/json',
    APP_RELEASE_VERSION: releaseVersion,
    API_VERSION_DATE: Config.API_VERSION_DATE,
    OPERATING_SYSTEM: isWeb ? navigator.platform : Platform.OS,
    OS_VERSION: isWeb ? navigator.appVersion : Platform.Version,
  };

  if (isWeb) headers.NS_WEB = true;

  const RETRY_WAIT_MS = 2000;
  const MAX_RETRIES = 2;
  const REQUEST_TIMEOUT_MS = 15000;

  const axiosInstance = axios.create({ baseURL });
  axiosRetry(axiosInstance, { retries: MAX_RETRIES, retryDelay: retryCount => retryCount * RETRY_WAIT_MS });

  const api = apisauce.create({
    axiosInstance,
    headers,
    timeout: REQUEST_TIMEOUT_MS,
  });

  api.addAsyncRequestTransform(async (request) => {
    if (!global.API_TOKEN) {
      global.API_TOKEN = await global.rootStore.sessionStore.getAuthToken();
    }

    if (global.API_TOKEN && request.url !== '/mobile/refresh') {
      let shouldRefresh;
      try {
        const { exp } = jwtDecode(global.API_TOKEN);
        shouldRefresh = Date.now() >= exp * 1000;
      } catch (e) {
        shouldRefresh = true;
      }

      if (!global.REFRESH_TOKEN) {
        global.REFRESH_TOKEN = await global.rootStore.sessionStore.getRefreshToken();
      }

      let promise;
      if (global.REFRESH_TOKEN && shouldRefresh) {
        promise = global.rootStore.sessionStore.refreshTokenFunc(global.REFRESH_TOKEN);
      } else {
        promise = Promise.resolve({ accessToken: global.API_TOKEN });
      }

      return promise.then(({ accessToken }) => {
        global.API_TOKEN = accessToken.replace(/Bearer /gi, '');
        request.headers.authorization = `Bearer ${accessToken}`;
      });
    }

    return Promise.resolve();
  });

  // eslint-disable-next-line consistent-return
  api.addAsyncResponseTransform(async (response) => {
    if (response.status === StatusCodes.unauthorized && response.config.url !== '/mobile/login') {
      global.rootStore.sessionStore.logout();
    } else if (response.status === StatusCodes.conflict) {
      // If we get a data conflict we need to reject, so it
      //   shows the learner the sync screen.
      return Promise.reject(response);
    }
  });

  const buildQuery = (method, url, params = {}) => api[method](url, params, { headers });

  // Auth Endpoints
  const checkEmail = body => buildQuery('get', '/mobile/email_exists', body);
  const checkPhoneNumber = body => buildQuery('post', '/mobile/initiate', body);
  const logEvent = body => buildQuery('post', '/api/events', body);
  const createGuest = (body) => buildQuery('post', 'mobile/guests', body);
  const login = body => buildQuery('post', '/mobile/login', body);
  const refreshToken = token => buildQuery('post', '/mobile/refresh', { token });
  const signUp = body => buildQuery('post', '/mobile/signup', body);
  const createCheckoutSession = (webSuccessUrl, webCancelUrl) => buildQuery('post', 'api/payments', { webSuccessUrl, webCancelUrl });
  const createCardCaptureSession = (webSuccessUrl, webCancelUrl) => buildQuery('post', 'api/payments/capture', { webSuccessUrl, webCancelUrl });
  const createRegistrationFeeSession = (webSuccessUrl, webCancelUrl) => buildQuery('post', 'api/payments/registration_fee', { webSuccessUrl, webCancelUrl });
  const guestSignUp = body => buildQuery('post', '/mobile/guests/signup', body);

  // Learner Endpoints
  const getUser = (learnerId) => buildQuery('get', `/api/learners/${learnerId}`);
  const reregister = (learnerId) => buildQuery('post', `/api/learners/${learnerId}/reregister`);
  const updateUser = (learnerId, body) => buildQuery('put', `/api/learners/${learnerId}`, body);
  const updateStudyPlan = (learnerId, body) => buildQuery('post', `/api/learners/${learnerId}/study_plan`, body);
  const skipPaymentTrack = (learnerId, body) => buildQuery('post', `/api/learners/${learnerId}/skip_payment_track`, body);
  const emailEnrollmentAgreement = (learnerId, agreementId) => buildQuery('post', `/api/learners/${learnerId}/agreements/${agreementId}/email`);
  const getInAppNotifications = (learnerId) => buildQuery('get', `/api/learners/${learnerId}/notifications`);
  const inAppNotificationDismiss = (learnerId, id) => buildQuery('put', `/api/learners/${learnerId}/notifications/${id}/dismiss`);
  const inAppNotificationDisplayed = (learnerId, id) => buildQuery('put', `/api/learners/${learnerId}/notifications/${id}/displayed`);
  const inAppNotificationInteracted = (learnerId, id) => buildQuery('put', `/api/learners/${learnerId}/notifications/${id}/interacted`);
  const getEnrollmentAgreements = (learnerId) => buildQuery('get', `/api/learners/${learnerId}/agreements`);
  const acceptEnrollmentAgreement = (learnerId, body) => buildQuery('post', `/api/learners/${learnerId}/agreements`, body);
  const acceptUpskillAgreement = (learnerId) => buildQuery('post', `/api/learners/${learnerId}/accept_eula`);
  const getQuestionnaire = (learnerId, questionnaireId) => buildQuery('get', `/api/learners/${learnerId}/questionnaires/${questionnaireId}`);
  const sendQuestionnaireResponse = (learnerId, questionnaireId, body) => buildQuery('post', `api/learners/${learnerId}/questionnaires/${questionnaireId}/questionnaire_responses`, body);

  // Content Endpoints
  const getApprovedStates = () => buildQuery('get', 'api/regions');
  const getAssessmentBlock = ({ courseKey, assessmentBlockKey }) => buildQuery('get', `/content/courses/${courseKey}/assessment_blocks/${assessmentBlockKey}`);

  const getCourse = ({ key }) => buildQuery('get', `content/courses/${key}`);
  const getCourseSkillsets = ({ courseKey }) => buildQuery('get', `/content/courses/${courseKey}/skillsets`);
  const getCoursePracticeLabs = ({ courseKey }) => buildQuery('get', `/content/courses/${courseKey}/practice_labs`);
  const getCoursePracticeLabDetails = ({ courseKey, practiceLabKey }) => buildQuery('get', `/content/courses/${courseKey}/practice_labs/${practiceLabKey}`);

  const getSkillset = ({ courseKey, skillsetKey }) => buildQuery('get', `/content/courses/${courseKey}/skillsets/${skillsetKey}`);
  const getSkillsetLinearAssessments = ({ skillsetKey }) => buildQuery('get', `/content/skillsets/${skillsetKey}/linearAssessments`);
  const getSkillsetLinearAssessment = ({ skillsetKey, linearAssessmentKey }) => buildQuery('get', `/content/skillsets/${skillsetKey}/linearAssessments/${linearAssessmentKey}`);
  const createSkillsetLinearAssessmentIteration = ({ skilletKey, linearAssessmentKey }) => buildQuery('post', `/content/skillsets/${skilletKey}/linearAssessments/${linearAssessmentKey}/iteration`);
  const getSkillsetLinearAssessmentIteration = ({ courseKey, linearAssessmentKey, linearAssessmentIterationId }) => buildQuery('get', `/content/courses/${courseKey}/linearAssessments/${linearAssessmentKey}/iteration/${linearAssessmentIterationId}`);

  const getSkill = ({ courseKey, skillKey }) => buildQuery('get', `/content/courses/${courseKey}/skills/${skillKey}`);
  const getSkillLinearAssessments = ({ skillKey }) => buildQuery('get', `/content/skills/${skillKey}/linearAssessments`);
  const getSkillLinearAssessment = ({ skillKey, linearAssessmentKey }) => buildQuery('get', `/content/skills/${skillKey}/linearAssessments/${linearAssessmentKey}`);
  const getSkillLinearAssessmentIteration = ({ courseKey, linearAssessmentKey, linearAssessmentIterationId }) => buildQuery('get', `/content/courses/${courseKey}/linearAssessments/${linearAssessmentKey}/iteration/${linearAssessmentIterationId}`);
  const createSkillLinearAssessmentIteration = ({ skilletKey, linearAssessmentKey }) => buildQuery('post', `/content/skillsets/${skilletKey}/linearAssessments/${linearAssessmentKey}/iteration`);

  const getStep = ({ courseKey, stepKey }) => buildQuery('get', `/content/courses/${courseKey}/steps/${stepKey}`);

  const getAssignmentQuestions = ({ courseKey }) => buildQuery('get', `/content/courses/${courseKey}/assignment_questions`);

  // Progress Endpoints
  const createCourseProgress = body => buildQuery('post', '/progress/courses', body);
  const generates3url = body => buildQuery('get', `/api/uploads/s3url?key=${body.key}`);
  const getAssessmentBlockProgress = (progressId) => buildQuery('get', `/progress/assessment_blocks/${progressId}`);
  const getLearnerCourseMetrics = (progressId) => buildQuery('get', `/progress/courses/${progressId}/metrics`);
  const getCourseSkillsetProgresses = (progressId) => buildQuery('get', `/progress/courses/${progressId}/skillsets`);
  const getCoursePracticeLabProgresses = (progressId) => buildQuery('get', `/progress/courses/${progressId}/practice_labs`);
  const getCourseProgresses = () => buildQuery('get', '/progress/courses');
  const getIntroVideoProgress = id => buildQuery('get', `/progress/intros/${id}`);
  const getSkillProgress = (progressId) => buildQuery('get', `/progress/skills/${progressId}`);
  const getSkillsetProgress = (progressId) => buildQuery('get', `/progress/skillsets/${progressId}`);
  const getStepProgress = (progressId) => buildQuery('get', `/progress/steps/${progressId}`);
  const anchorSkillset = (skillsetId) => buildQuery('post', '/progress/skillsets/lock', { skillsetId });
  const getCourseSkills = ({ courseId, status }) => buildQuery('get', `/progress/courses/${courseId}/skills/content`, { status });
  const getDemoVideoProgress = (id) => buildQuery('put', `/progress/demo_videos/${id}`);
  const getSuccessCriteriaProgress = (id) => buildQuery('get', `/progress/success_criteria/${id}`);

  const getLinearAssessmentIteration = ({ iterationId, courseKey, linearAssessmentKey }) => buildQuery('get', `/content/courses/${courseKey}/linear_assessments/${linearAssessmentKey}/iterations/${iterationId}`);
  const startLinearAssessment = (id) => buildQuery('put', `/progress/linear_assessments/${id}/start`);
  const completeLinearAssessment = (id, duration, correctQuestionCount) => buildQuery('put', `/progress/linear_assessments/${id}/complete`, { duration, correctQuestionCount });
  const failLinearAssessment = (id, duration, correctQuestionCount) => buildQuery('put', `/progress/linear_assessments/${id}/fail`, { duration, correctQuestionCount });

  const sendQuestionResponse = ({ questionId, isAnsweredCorrectly, assessmentIterationProgressId }) => buildQuery('post', `/progress/linear_assessment_iteration_progresses/${assessmentIterationProgressId}/question_responses`, { question_id: questionId, status: isAnsweredCorrectly ? 'correct' : 'incorrect' });

  const startAssessmentBlock = (id) => buildQuery('put', `/progress/assessment_blocks/${id}/start`);
  const completeAssessmentBlock = (id, duration) => buildQuery('put', `/progress/assessment_blocks/${id}/complete`, { duration });
  const failAssessmentBlock = (id, duration) => buildQuery('put', `/progress/assessment_blocks/${id}/fail`, { duration });
  const startDemoVideo = (id) => buildQuery('put', `/progress/demo_videos/${id}/start`);
  const completeDemoVideo = (id, duration) => buildQuery('put', `/progress/demo_videos/${id}/complete`, { duration });
  const startIntroVideo = (id) => buildQuery('put', `/progress/intros/${id}/start`);
  const completeIntroVideo = (id, duration) => buildQuery('put', `/progress/intros/${id}/complete`, { duration });
  const startSkill = (id) => buildQuery('put', `/progress/skills/${id}/start`);
  const startStep = (id) => buildQuery('put', `/progress/steps/${id}/start`);
  const startSuccessCriteria = (id) => buildQuery('put', `/progress/success_criteria/${id}/start`);
  const completeSuccessCriteria = (id, duration) => buildQuery('put', `/progress/success_criteria/${id}/complete`, { duration });

  const logTimer = ({ skillsetProgressId, requiredTimeSpent }) => buildQuery('patch', `/progress/skillsets/${skillsetProgressId}/required_time_spent`, { required_time_spent: requiredTimeSpent });

  const getAssignmentQuestionProgresses = () => buildQuery('get', '/progress/assignment_questions/');
  const getAssignmentQuestionResponses = ({ id }) => buildQuery('get', `/progress/assignment_questions/${id}/assignment_question_responses`);
  const sendAssignmentQuestionResponse = ({ id, body }) => buildQuery('post', `/progress/assignment_questions/${id}/assignment_question_responses`, body);

  return {
    acceptEnrollmentAgreement,
    acceptUpskillAgreement,
    checkEmail,
    checkPhoneNumber,
    completeAssessmentBlock,
    completeDemoVideo,
    completeIntroVideo,
    completeLinearAssessment,
    completeSuccessCriteria,
    createCardCaptureSession,
    createCheckoutSession,
    createCourseProgress,
    createGuest,
    createRegistrationFeeSession,
    createSkillLinearAssessmentIteration,
    createSkillsetLinearAssessmentIteration,
    emailEnrollmentAgreement,
    failAssessmentBlock,
    failLinearAssessment,
    generates3url,
    getApprovedStates,
    getAssessmentBlock,
    getAssessmentBlockProgress,
    getAssignmentQuestionProgresses,
    getAssignmentQuestionResponses,
    getAssignmentQuestions,
    getCourse,
    getCoursePracticeLabDetails,
    getCoursePracticeLabProgresses,
    getCoursePracticeLabs,
    getCourseProgresses,
    getCourseSkills,
    getCourseSkillsetProgresses,
    getCourseSkillsets,
    getDemoVideoProgress,
    getEnrollmentAgreements,
    getInAppNotifications,
    getIntroVideoProgress,
    getLearnerCourseMetrics,
    getLinearAssessmentIteration,
    getQuestionnaire,
    getSkill,
    getSkillLinearAssessment,
    getSkillLinearAssessmentIteration,
    getSkillLinearAssessments,
    getSkillProgress,
    getSkillset,
    getSkillsetLinearAssessment,
    getSkillsetLinearAssessmentIteration,
    getSkillsetLinearAssessments,
    getSkillsetProgress,
    getStep,
    getStepProgress,
    getSuccessCriteriaProgress,
    getUser,
    guestSignUp,
    inAppNotificationDismiss,
    inAppNotificationDisplayed,
    inAppNotificationInteracted,
    anchorSkillset,
    logEvent,
    logTimer,
    login,
    refreshToken,
    reregister,
    sendAssignmentQuestionResponse,
    sendQuestionResponse,
    sendQuestionnaireResponse,
    signUp,
    skipPaymentTrack,
    startAssessmentBlock,
    startDemoVideo,
    startIntroVideo,
    startLinearAssessment,
    startSkill,
    startStep,
    startSuccessCriteria,
    updateStudyPlan,
    updateUser,
  };
};

export default { create };
