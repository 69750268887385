import React, { useState, useEffect } from 'react';
import { View, Platform } from 'react-native';
import { Card, Icon } from 'react-native-elements';
import { inject, observer } from 'mobx-react';
import { Palette, Typography, Colors } from '@nextstep/app/Themes';
import { analyticsScreenView } from '@nextstep/app/services/Analytics';
import { Body1, H3 } from '@nextstep/app/components/Text';
import { ButtonFilled, ButtonText } from '@nextstep/app/components/Button';
import { FullViewSpinner } from '@nextstep/app/components/SharedUI';
import ViewWrapper from '@nextstep/app/components/ViewWrapper';
import { Row, Column } from '@nextstep/app/lib/ResponsiveLayout';
import withDimensions, { getDeviceType, DeviceType } from '@nextstep/app/lib/withDimensions';
import Divider from '@nextstep/app/components/Divider';
import Util from '@nextstep/app/services/Util';
import NavigationService from '@nextstep/app/services/NavigationService';
import AdaptiveContainerStyles from '@nextstep/app/containers/styles/AdaptiveContainerStyles';
import Config from '@nextstep/app/Config/DebugConfig';


const currentPalette = Palette.light;

const DefaultStyles = {
  cardContainerStyle: {
    display: 'flex',
    borderRadius: 8,
    borderColor: 'rgba(43, 35, 58, 0.05)',
    backgroundColor: '#FBFBFB',
    margin: 0,
  },

  headerContainer: {
    height: DeviceType.Mobile ? 216 : 270,
    alignItems: 'center',
    paddingHorizontal: DeviceType.Mobile ? 16 : 0,
    marginBottom: 40,
    backgroundColor: currentPalette.background.purple,
  },

  headerRow: {
    position: 'absolute',
    flexDirection: 'row',
    paddingTop: 90,
  },

  textContainer: {
    color: 'white',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flex: 1,
  },

  textStyle: { color: Colors.very_light_peach },

};

const EnrollmentAgreement = (props) => {
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [loading] = useState(false);
  const { rootStore: { sessionStore }, dimensions } = props;
  const { learner } = sessionStore;
  const deviceType = getDeviceType(dimensions);
  const Styles = AdaptiveContainerStyles(deviceType);

  if (loading) return (<FullViewSpinner showBackground />);

  useEffect(() => {
    analyticsScreenView('learnerTrackViewed');
    sessionStore.getEnrollmentAgreements().then();
  }, []);

  const onNextPressed = async () => {
    setButtonDisabled(true);

    await sessionStore.acceptUpskillAgreement();
    NavigationService.navigateToLearnerInitialScreen('Learn');

    setButtonDisabled(false);
  };

  return (
    <ViewWrapper smSize={12} mdSize={9} lgSize={6} topNavBarColor={currentPalette.background.purple}>

      <View style={DefaultStyles.headerContainer}>
        <View style={DefaultStyles.headerRow}>
          <View style={DefaultStyles.textContainer}>
            <H3 style={DefaultStyles.textStyle}>
              NextStep EULA & Course Policies
            </H3>
          </View>
        </View>
      </View>


      {/* Enrollment Start/End Date */}
      <View style={Styles.textContainer}>

        <Card containerStyle={DefaultStyles.cardContainerStyle}>
          <View style={{ alignItems: 'flex-start' }}>
            <Body1>Review and agree to the terms and conditions as outlined in the:</Body1>
          </View>
          <View style={{ height: 10 }} />
          <View style={{ flexDirection: 'row', flexWrap: 'nowrap' }}>
            <Icon
              name={'md-open-outline'}
              type="ionicon"
              color={currentPalette.primary.main}
              size={25}
            />

            <View style={{ width: 8 }} />

            <View style={{ alignItems: 'flex-start' }}>
              <ButtonText
                onPress={() => (learner.config ? Util.openURL(learner.config.eulaUrl) : {})}
                textStyle={Typography.body1}
                textAlign={'left'}
              >
                End User License Agreement
              </ButtonText>
            </View>
          </View>

          <View style={{ height: 17 }} />

          <View style={{ flexDirection: 'row', flexWrap: 'nowrap' }}>
            <Icon
              name={'md-open-outline'}
              type="ionicon"
              color={currentPalette.primary.main}
              size={25}
            />

            <View style={{ width: 8 }} />

            <View style={{ alignItems: 'flex-start' }}>
              <ButtonText
                onPress={() => (learner.config ? Util.openURL(learner.config.coursePolicyUrl) : {})}
                textStyle={Typography.body1}
                textAlign={'left'}
              >
                Course Policies
              </ButtonText>
            </View>
          </View>
        </Card>
      </View>

      <View style={{ height: 17 }} />

      {Platform.OS === 'web' ? <Divider /> : <View style={{ height: 20 }} />}
      <View style={{ height: 20 }} />

      <Row>
        <Column smSize={6} mdSize={5} lgSize={6} style={{ alignItems: 'center' }}>
          <ButtonFilled
            disabled={buttonDisabled}
            onPress={onNextPressed}
            onLongPress={() => (Config.staging ? sessionStore.logout() : {})}
          >
            I Agree
          </ButtonFilled>
        </Column>
      </Row>

      <View style={{ height: 26 }} />

    </ViewWrapper>
  );
};

export default withDimensions(inject('rootStore')(observer(EnrollmentAgreement)));
