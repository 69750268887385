import { Animated, Image, View } from 'react-native';
import React from 'react';
import Styles from '@nextstep/app/containers/styles/AssessmentBlockScreenStyles';
import { Images, Palette } from '@nextstep/app/Themes';
import { Body1 } from './Text';

const currentPalette = Palette.light;

const ResultBanner = ({ fadeBanner, isAnsweredCorrectly, colorType, backgroundColor }) => {
  const bannerIcon = (isAnsweredCorrectly)
    ? Images.icons[colorType || 'green']?.checkMark
    : Images.icons[colorType || 'red']?.xMark;

  return (
    <Animated.View
      style={[
        Styles.resultBanner,
        {
          padding: 0,
          backgroundColor: currentPalette.background.white,
          transform: [{ translateY: fadeBanner }],
        },
      ]}
    >
      <View style={[Styles.resultBanner, { flex: 1, backgroundColor }]}>
        <Image
          style={Styles.bannerIcon}
          source={bannerIcon}
        />

        <View style={{ width: 20 }} />

        <Body1>
          {isAnsweredCorrectly ? 'That’s correct!' : 'Oops! That’s incorrect'}
        </Body1>
      </View>

    </Animated.View>
  );
};

export default ResultBanner;
