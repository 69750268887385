import rollbar from '@nextstep/app/Config/RollbarConfig';

const noop = () => {};
const useConsole = __DEV__ && typeof console === 'object';

const Logger = {

  log: (() => {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line no-console
    const fn = (useConsole) ? console.log : noop;
    return Function.prototype.bind.apply(fn, console);
  })(),

  error: (() => {
    if (useConsole) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line no-console
      return Function.prototype.bind.apply(console.error, console);
    }
    return (error, errorInfo) => {
      rollbar.error(error, errorInfo);
    };
  })(),

  loadTronLog: () => {
    if (!useConsole || !console?.tron?.socket) return false;
    let consoleTronEnabled = false;
    const { tron, log } = console;
    tron.socket.onmessage = () => {
      if (tron.isReady && !consoleTronEnabled) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-console
        console.log = (...args) => {
          tron.log(args);
          return log.apply(console, args);
        };
        consoleTronEnabled = true;
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-console
        console.log('Reactotron log output enabled');
      }
    };
    return consoleTronEnabled;
  },
};

export default Logger;
