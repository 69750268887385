import { StyleSheet } from 'react-native';
import { Palette } from '@nextstep/app/Themes';

const currentPalette = Palette.light;

export default StyleSheet.create({
  tableRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10,
    borderTopWidth: 1,
    borderTopColor: '#DEDADC',
    paddingVertical: 10,
  },
  icon: {
    margin: 2,
    height: 25,
    width: 25,
    resizeMode: 'contain',
    marginRight: 15,
  },
  iconMobile: {
    margin: 2,
    height: 45,
    width: 45,
    resizeMode: 'contain',
    marginRight: 15,
  },
  completeBadge: {
    width: '100%',
    paddingVertical: 8,
    paddingHorizontal: 15,
    borderRadius: 50,
    backgroundColor: currentPalette.background.primary,
  },
  uploadBadge: {
    width: '100%',
    paddingVertical: 8,
    paddingHorizontal: 15,
    borderRadius: 50,
    backgroundColor: currentPalette.primary.main,
    cursor: 'pointer',
  },
  description: {
    textAlign: 'left',
    marginBottom: 10,
  },
});
