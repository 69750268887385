/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { TouchableOpacity, View } from 'react-native';
import { Palette } from '@nextstep/app/Themes';
import Styles from '@nextstep/app/Themes/ApplicationStyles';
import { ButtonSelect } from './Button';

const currentPalette = Palette.light;

const DefaultStyle = (props) => {
  if (!props.value) return ({ ...Styles.newInput });
  return ({ ...Styles.newInput, borderColor: currentPalette.primary.main });
};

export const InputSelect = (props) => {
  const textStyle = {
    color: !props.editable ? currentPalette.text.disabled : props.textColor,
    textAlign: 'left',
    ...props.textStyle,
  };

  return (
    <TouchableOpacity
      onPress={() => props.onValueChange(!props.value)}
      style={{ display: 'flex' }}
    >
      <View style={props.showBorder ? DefaultStyle(props) : { paddingLeft: 4 }}>
        <ButtonSelect
          value={props.value}
          onValueChange={(state) => props.onValueChange(state)}
          trueColor={props.trueColor}
          falseColor={props.falseColor}
          textColor={textStyle.color}
          textStyle={textStyle}
        >
          {props.children}
        </ButtonSelect>

      </View>

      <View style={{ height: 16 }} />

    </TouchableOpacity>
  );
};

InputSelect.propTypes = {
  editable: PropTypes.bool,
  falseColor: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  onValueChange: PropTypes.func,
  textColor: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  textStyle: PropTypes.object,
  trueColor: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  value: PropTypes.bool.isRequired,
  showBorder: PropTypes.bool,
};

InputSelect.defaultProps = {
  editable: true,
  falseColor: undefined,
  onValueChange: () => {},
  textColor: currentPalette.text.main,
  textStyle: {},
  trueColor: undefined,
  showBorder: false,
};

export default InputSelect;
