import { StyleSheet } from 'react-native';
import Palette from '@nextstep/app/Themes/Palette';

const currentPalette = Palette.light;

export default StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100%',
    backgroundColor: currentPalette.background.main,
    paddingHorizontal: 16,
  },
  logo: { width: 200 },
});
