import { types } from 'mobx-state-tree';
import { find, prop, propEq, sortBy } from 'ramda';
import Util from '@nextstep/app/services/Util';
import { ContentNodeTypes, QuestionTypes } from '@nextstep/app/Config/Constants';
import AnswerChoice from './AnswerChoice';
import QuestionPart from './QuestionPart';
import ContentNode from './ContentNode';

const QuestionModel = types.model('Question', {
  id: types.identifierNumber,
  type: types.maybe(types.string),
  question: types.maybeNull(types.string),
  questionParts: types.maybeNull(types.array(QuestionPart)),
  answerChoices: types.array(AnswerChoice),
  shuffledAnswerChoices: types.maybe(types.array(types.safeReference(AnswerChoice))),
}).actions(self => ({
  shuffleAnswerChoices() {
    const shuffled = Util.shuffle(self.answerChoices);
    self.shuffledAnswerChoices = shuffled.map(answerChoice => answerChoice.id);
  },
  getShuffledAnswerChoices() {
    if (!self.shuffledAnswerChoices) self.shuffleAnswerChoices();

    return self.shuffledAnswerChoices;
  },
  checkAnswers(answers) {
    const question = self;
    const questionType = question.type.toLowerCase();

    if (questionType === QuestionTypes.fillInTheBlanks || questionType === QuestionTypes.orderedSelect) {
      let correct = true;

      const requiredAnswers = question.answerChoices
        .filter(sa => sa.order !== -1 || sa.score > 0)
        .sort((a, b) => {
          if (a.order < b.order) { return -1; }
          if (a.order > b.order) { return 1; }
          return 0;
        });

      requiredAnswers.forEach((a, i) => {
        if (!answers[i] || answers[i].id !== a.id) correct = false;
      });

      return correct && requiredAnswers.length === answers.length;
    }

    if (questionType === QuestionTypes.unorderedSelect) {
      if (find(propEq('score', 0))(answers)) return false;
      if ((sortBy(prop('score'), question.answerChoices.filter(a => a.score))).length !== answers.length) {
        return false;
      }
    }
    return (answers[0] || {}).score === 1;
  },
}));

const Question = types.compose(QuestionModel, ContentNode)
  .named(ContentNodeTypes.question);

export default Question;
