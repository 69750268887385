/* eslint-disable max-classes-per-file,class-methods-use-this */
import Rollbar from 'rollbar';

export class Configuration {
  constructor(accessToken, options) {
    Object.assign(this, {
      accessToken,
      ...options,
      captureUncaught: true,
      captureUnhandledRejections: true,
    });
  }
}

export class Client extends Rollbar {
  setPerson(id) {
    this.configure({
      payload: {
        person: { id },
      },
    });
  }

  setTransform(transform) {
    this.configure({
      transform,
    });
  }

  clearPerson() {
    this.configure({
      payload: {
        person: undefined,
      },
    });
  }
}


