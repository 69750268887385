import { Colors } from '@nextstep/app/Themes';
import { ResponsiveStyleSheet } from '@nextstep/app/lib/ResponsiveLayout';

export default ResponsiveStyleSheet.create({
  subSection: { paddingHorizontal: 30 },

  skillSetInfoContainer: { paddingVertical: 30 },

  bookIcon: {
    width: 24,
    height: 24,
    resizeMode: 'contain',
    tintColor: Colors.white,
  },

  iconButton: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },

  continueButtonDisable: { display: null },
});
