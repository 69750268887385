import apisauce from 'apisauce';
import Config from 'react-native-config';

const create = () => {
  const headers = {
    'Cache-Control': 'no-cache',
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  const api = apisauce.create({
    baseURL: 'https://api.intercom.io',
    headers,
  });

  api.addRequestTransform((request) => {
    request.headers.Authorization = `Bearer ${Config.INTERCOM_ACCESS_TOKEN}`;
  });

  const buildQuery = (method, url, params = {}) => api[method](url, params, { headers });
  const postMessage = body => buildQuery('post', 'messages', body);
  const postNote = body => buildQuery('post', 'notes', body);
  const fetchConversations = externalId => buildQuery('get', `conversations?order=desc&sort=created_at&type=user&user_id=${externalId}`);
  const replyToConversation = (conversationId, body) => buildQuery('post', `conversations/${conversationId}/reply`, body);

  return {
    postMessage,
    postNote,
    fetchConversations,
    replyToConversation,
  };
};

export default { create };
