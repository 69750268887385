/* eslint-disable react/prop-types,react/destructuring-assignment */
import React from 'react';
import { ScrollView, View } from 'react-native';
import { Collapse, CollapseHeader, CollapseBody } from 'accordion-collapse-react-native';
import { ScrollIntoView } from 'react-native-scroll-into-view';
import { inject, observer } from 'mobx-react';
import { Images } from '@nextstep/app/Themes';
import { ComponentErrorBoundary } from '@nextstep/app/lib/ErrorUtils';
import BottomSheetModal from '@nextstep/app/modals/BottomSheetModal';
import Constants from '@nextstep/app/Config/Constants';
import withDimensions, { DeviceType } from '@nextstep/app/lib/withDimensions';
import SkillsetChildNavigator from '@nextstep/app/containers/skill/SkillsetChildNavigator';
import LearnerTaskCard from '@nextstep/app/components/LearnerTaskCard';
import NavigationService from '@nextstep/app/services/NavigationService';
import Intercom from 'react-native-intercom';
import SkillsetHeader from './SkillsetHeader';
import SkillsetChild from './SkillsetChild';
import { H3 } from './Text';
import SkillsetStyles from './styles/SkillsetStyles';
import TimeLeftCard from './TimeLeftCard';

class Skillset extends SkillsetChildNavigator {
  constructor(props) {
    super(props);
    this.state = { skillHeaderHeight: null };
    this.handleHeaderLayout = this.handleHeaderLayout.bind(this);
  }

  handleHeaderLayout(event) {
    const { height } = event.nativeEvent.layout;
    const { skillHeaderHeight } = this.state;
    if (!skillHeaderHeight || height < skillHeaderHeight) {
      this.setState({ skillHeaderHeight: height });
    }
  }

  openIntercom = async () => {
    const { rootStore: { sessionStore } } = this.props;
    sessionStore.intercomVerifyIdentity();
    await Intercom.displayMessageComposer();
  }

  learnerQuestionnaire = (hasInlineQuestionnaire) => (
    <>
      <LearnerTaskCard
        icon={Images.icons.stethoscope}
        title={'Job Preference'}
        description={hasInlineQuestionnaire.content}
        onPress={async () => {
          const { contentStore } = this.props;
          await contentStore.loadQuestionnaire(hasInlineQuestionnaire.questionnaireId);
          NavigationService.navigate('LearnerTaskQuestionnaire');
        }}
      />
      <View style={{ height: 26 }} />
    </>
  )

  render() {
    const { skillHeaderHeight } = this.state;
    const {
      skillset, index, progressStore, practiceLab,
      scrollIntoView, collapsible, scrollable,
      goToStep, dimensions, isLearnScreenFocused,
      sessionStore, contentStore,
    } = this.props;
    const { expandedSkillsetKey } = progressStore;
    const Styles = SkillsetStyles(dimensions.deviceType);
    const computeScrollPadding = () => (skillHeaderHeight || Constants.MinSkillHeaderHeight) * index;
    const collapsed = !collapsible || (expandedSkillsetKey === skillset.key);

    const skillsetProgress = progressStore.skillsetProgresses.find(ss => ss.skillsetId === skillset.id);
    const { isLocked: locked, paymentTrackPromptStatus } = skillsetProgress;

    const paymentTrack = {
      isRequired: paymentTrackPromptStatus === Constants.PaymentTrackState.required,
      isDeferrable: paymentTrackPromptStatus === Constants.PaymentTrackState.deferrable,
    };

    const isPaymentTrackModalVisible = isLearnScreenFocused && skillsetProgress.hasProgress
      && (paymentTrack.isRequired || paymentTrack.isDeferrable);

    const hasInlineQuestionnaire = sessionStore.inAppNotifications && sessionStore.inAppNotifications
      .find(notification => notification.skillsetIndex === contentStore.skillsets.indexOf(skillset)
        && notification.style === Constants.InAppReminderTypes.inline);

    return (
      <ScrollIntoView
        enabled={collapsed && scrollIntoView}
        immediate
        animated={false}
        align={'top'}
        computeScrollY={computeScrollPadding}
        style={[scrollable && { flex: 1 }]}
      >
        <Collapse
          isExpanded={collapsed}
          disabled={locked || !collapsible}
          onToggle={() => (expandedSkillsetKey === skillset.key
            ? progressStore.resetExpandedSkillSetKey()
            : progressStore.setExpandedSkillsetKey(skillset.key)
          )}
          style={[scrollable && { flex: 1 }]}
        >
          <CollapseHeader>
            <SkillsetHeader
              skillset={skillset}
              onLayout={e => this.handleHeaderLayout(e)}
              locked={locked}
              practiceLab={practiceLab}
              currentSkillsetId={progressStore.skillsetProgress?.skillsetId}
              skillsetProgress={skillsetProgress}
              compact={dimensions.deviceType === DeviceType.Mobile}
            />
          </CollapseHeader>

          <CollapseBody style={[scrollable && { flex: 1 }]}>
            <ComponentErrorBoundary errorMessage="Skillset could not be loaded, please try again">
              <ScrollView style={[Styles.section, scrollable && { flex: 1 }]}>

                {/* TODO: Remove the paymentTrack.isDeferrable from BE first */}
                <BottomSheetModal
                  toggleVisible={() => paymentTrack.isDeferrable}
                  isVisible={isPaymentTrackModalVisible}
                  icon={Images.icons.main.warning}
                  messageTitle={'Payment Track Missing!'}
                  messageContent={'Please Contact your mentor for further instructions.'}
                  primaryAction={{ text: 'Chat with a Mentor', action: () => this.openIntercom() }}
                  secondaryAction={{ text: 'Sign Out', action: () => sessionStore.logout() }}
                  dismissible={paymentTrack.isDeferrable}
                />

                <H3 textAlign={'left'} style={Styles.summary}>
                  {skillset.summary}
                </H3>

                {skillsetProgress.hasProgress && (
                  <TimeLeftCard skillsetProgress={skillsetProgress} />
                )}

                <View style={{ flexDirection: 'column', flex: 1 }}>
                  {practiceLab && (
                    <SkillsetChild
                      skillset={skillset}
                      skillsetProgress={{
                        ...skillsetProgress,
                        skillProgresses: [{
                          ...skillsetProgress.linearAssessmentProgress,
                          skillId: 0,
                        }],
                      }}
                      practiceLab={practiceLab}
                      skillsetChild={({
                        id: 0,
                        title: 'Practice Lab',
                        summary: `<b>You’re making great progress. Once you finish this skill set, you’ll be eligible for Practice Lab ${practiceLab.title}!</b> <p>Learn more about practice lab requirements in the In-Person section of the app.</p>`,
                        icon: 'https://nextstep-course-images.s3.us-east-2.amazonaws.com/lctw/lab_beaker.png',
                      })}
                      goToStep={goToStep}
                    />
                  )}

                  {skillset?.skills?.map((skill, i) => (
                    <SkillsetChild
                      key={i}
                      skillset={skillset}
                      skillsetProgress={skillsetProgress}
                      skillsetChild={skill}
                      goToStep={goToStep}
                    />
                  ))}

                  {(skillset && skillset.linearAssessment && !skillset.linearAssessment.isFinalExam) && (
                    <SkillsetChild
                      skillset={skillset}
                      skillsetProgress={{
                        ...skillsetProgress,
                        skillProgresses: [{
                          ...skillsetProgress.linearAssessmentProgress,
                          skillId: 0,
                        }],
                      }}
                      skillsetChild={({
                        id: 0,
                        title: `${skillset.title} Knowledge Check`,
                        summary: `<p>Score an ${skillset?.linearAssessment?.requiredGrade || 80}% or higher on the knowledge check to move to the next skill set. You’ve got this!</p>`,
                        icon: 'https://nextstep-course-images.s3.us-east-2.amazonaws.com/lctw/knowledge_check.png',
                        linearAssessment: skillset.linearAssessment,
                      })}
                      goToStep={goToStep}
                    />
                  )}

                  {hasInlineQuestionnaire && this.learnerQuestionnaire(hasInlineQuestionnaire)}

                </View>
              </ScrollView>

            </ComponentErrorBoundary>

          </CollapseBody>

        </Collapse>
      </ScrollIntoView>
    );
  }
}

export default withDimensions(inject(({ rootStore: { contentStore, progressStore, sessionStore } }) => ({ contentStore, progressStore, sessionStore }))(observer(Skillset)));
