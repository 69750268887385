import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  header: {
    flexDirection: 'row',
    paddingRight: 20,
    justifyContent: 'flex-end',
  },

  headerIcon: {
    width: 14,
    height: 14,
  },

  section: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 16,
  },

  bottomButton: {
    alignItems: 'center',
    paddingVertical: 24,
  },

  modalIcon: {
    width: 78,
    height: 78,
    resizeMode: 'contain',
  },
});
