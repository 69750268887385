import { Animated, Image, View } from 'react-native';
import React from 'react';
import Styles from '@nextstep/app/containers/styles/AssessmentBlockScreenStyles';
import { Images } from '@nextstep/app/Themes';
import { Body1 } from '@nextstep/app/components/Text';

const ResultBanner = ({ backgroundColor, correct, fadeBanner }) => {
  const bannerIcon = correct ? Images.icons.green.checkMark : Images.icons.wrongMark;
  const bgColor = correct ? '#CFE4D7' : '#FCD3DA';

  return (
    <Animated.View
      style={[
        Styles.resultBanner,
        {
          paddingVertical: 0,
          backgroundColor: bgColor,
          transform: [{ translateY: fadeBanner }],
        },
      ]}
    >
      <View style={[Styles.resultBanner, { flex: 1, backgroundColor }]}>
        <Image
          style={Styles.bannerIcon}
          source={bannerIcon}
        />

        <View style={{ width: 20 }} />

        <Body1>
          {correct ? 'That’s correct!' : 'That’s incorrect!'}
        </Body1>
      </View>

    </Animated.View>
  );
};

export default ResultBanner;
