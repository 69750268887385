/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Icon } from 'react-native-elements';
import PropTypes from 'prop-types';
import { Collapse, CollapseBody, CollapseHeader } from 'accordion-collapse-react-native';
import { Palette } from '@nextstep/app/Themes';
import AdaptiveContainerStyles from '@nextstep/app/containers/styles/AdaptiveContainerStyles';
import { Subtitle2 } from './Text';

const currentPalette = Palette.light;

const InPersonCollapse = ({ id, children, onToggle, expandedCardId, title }) => {
  const GlobalStyles = AdaptiveContainerStyles();

  const isExpanded = expandedCardId.find(currId => currId === id);

  return (
    <Collapse
      isExpanded={isExpanded}
      onToggle={() => onToggle(id)}
      style={GlobalStyles.card}
    >
      <CollapseHeader style={[GlobalStyles.textContainer, { paddingVertical: 29, flexDirection: 'row', justifyContent: 'space-between' }]}>

        <Subtitle2 textAlign={'left'}>{title}</Subtitle2>

        <Icon
          name={isExpanded && 'keyboard-arrow-up' || 'keyboard-arrow-down'}
          color={currentPalette.basic.black}
          underlayColor="transparent"
        />

      </CollapseHeader>

      <CollapseBody style={[GlobalStyles.textContainer, { paddingBottom: 29 }]}>
        {children}
      </CollapseBody>
    </Collapse>
  );
};

InPersonCollapse.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  onToggle: PropTypes.func.isRequired,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
};

export default InPersonCollapse;
