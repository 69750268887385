import React, { Component } from 'react';
import { Platform, ScrollView, Text, View } from 'react-native';
import { inject, observer } from 'mobx-react';
import withDimensions from '@nextstep/app/lib/withDimensions';
import { Body1, Body2, Caption1, Subtitle2 } from '@nextstep/app/components/Text';
import NavigationService from '@nextstep/app/services/NavigationService';
import ViewWrapper from '@nextstep/app/components/ViewWrapper';
import { Column, Row } from '@nextstep/app/lib/ResponsiveLayout';
import StateCard from '@nextstep/app/components/StateCard';
import Util from '@nextstep/app/services/Util';
import { Images } from '@nextstep/app/Themes';
import { FullViewSpinner } from '@nextstep/app/components/SharedUI';
import MentorHeader from '@nextstep/app/components/MentorHeader';
import { currentPalette } from '@nextstep/app/Config/AppConfig';

const EMAIL = 'support@nextstep.com';

export class StateSelectionScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      approvedStates: [],
      loading: true,
      loadingMessage: false,
    };
  }

  componentDidMount() {
    const { rootStore: { contentStore } } = this.props;

    contentStore.getApprovedStates().then(states => {
      this.setState({ approvedStates: states });
      this.setState({ loading: false });
    });
  }

  getStatesNameFromAbbreviation = () => {
    const { approvedStates } = this.state;
    return approvedStates.map(stateObject => Util.getStateFromAbbreviation(stateObject.abbreviation));
  }

  approvedStatesText = (statesNames) => {
    switch (statesNames.length) {
      case 0:
        return '';
      case 1:
        return `${statesNames[0]}`;
      case 2:
        return `${statesNames[0]} and ${statesNames[1]}`;
      default:
        return `${statesNames[0]}, ${this.approvedStatesText(statesNames.slice(1))}`;
    }
  };

  login = async (state) => {
    this.setState({ loading: true, loadingMessage: true });

    const { rootStore: { sessionStore } } = this.props;
    const body = { legacyState: state.abbreviation, regionId: state.id };

    await sessionStore.loginAsGuest(body);
    await sessionStore.assignCourse();

    await NavigationService.reset('App');

    this.setState({ loading: false });
  }

  render() {
    const { approvedStates, loading, loadingMessage } = this.state;
    const approvedStatesNames = this.getStatesNameFromAbbreviation();

    if (loading) {
      return (
        <FullViewSpinner
          message={loadingMessage ? 'We’re getting everything set up for you. Just one moment...' : undefined}
        />
      );
    }

    return (
      <ViewWrapper
        smSize={12}
        mdSize={7}
        lgSize={6}
        navigationBar
      >
        <ScrollView>

          <MentorHeader
            text={'Preview the NextStep Course!'}
            color="orange"
            mentorContainerStyle={{ backgroundColor: currentPalette.background.purple }}
            customMentorImage={Images.mentor.extra.welcome}
          />

          <Row>

            <Column smSize={10} mdSize={10} lgSize={10} style={{ justifyContent: 'center' }}>

              <Body1>
                Curious about the course? Select the state you plan to work in and try a skill or two.
                {'\n\n'}
                Contact us at
                <Text
                  style={{ color: currentPalette.primary.main }}
                  onPress={() => Util.openURL(
                    `mailto:${EMAIL}`,
                    '_self',
                  )}
                >
                  {` ${EMAIL} `}
                </Text>
                to find your local employer or training partner, and begin your journey to become a CNA.
                {'\n\n'}
                <Body2>REMINDER: </Body2>
                Any course progress you make during this trial period will not count towards your program completion.
              </Body1>

              <View style={{ height: 40 }} />

              <Subtitle2>
                Choose the State You Plan to Work in.
              </Subtitle2>

              <View style={{ height: 20 }} />

              {approvedStates.length === 0 && <FullViewSpinner />}

              <Row style={{ justifyContent: 'center', margin: 'auto' }}>
                {approvedStates.map((state, index) => (
                  <Column
                    key={index}
                    smSize={12}
                    mdSize={6}
                    lgSize={5}
                    style={{ justifyContent: 'center', alignItems: Platform.OS === 'web' ? 'space-around' : 'stretch' }}
                  >
                    <StateCard
                      onPress={() => this.login(state)}
                      image={Images.states[state.abbreviation]}
                      stateName={Util.getStateFromAbbreviation(state.abbreviation)}
                    />
                  </Column>
                ))}
              </Row>

              <View style={{ height: 34 }} />

              <Caption1>
                NextStep’s program is currently approved in
                {` ${this.approvedStatesText(approvedStatesNames)}. `}
                We’ll be launching programs in more states soon!
              </Caption1>

              <View style={{ height: 34 }} />

            </Column>
          </Row>
        </ScrollView>
      </ViewWrapper>
    );
  }
}

export default withDimensions(inject('rootStore')(observer(StateSelectionScreen)));
