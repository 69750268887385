/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { inject, observer } from 'mobx-react';
import { afterInteractions } from '@nextstep/app/lib/afterInteractions';
import withDimensions from '@nextstep/app/lib/withDimensions';
import ViewWrapper from '@nextstep/app/components/ViewWrapper';
import BaseHeader from '@nextstep/app/components/baseStructure/BaseHeader';
import BaseFooter from '@nextstep/app/components/baseStructure/BaseFooter';
import FailedQuiz from '@nextstep/app/components/skillSetOutcomes/FailedQuiz';
import Constants from '@nextstep/app/Config/Constants';
import FinalExamComplete from '@nextstep/app/components/skillSetOutcomes/FinalExamFinished';
import FinalExamTimeOut from '@nextstep/app/components/skillSetOutcomes/FinalExamTimeOut';
import SkillCelebration from '@nextstep/app/components/skillSetOutcomes/SkillCelebration';
import SkillsetCelebration from '@nextstep/app/components/skillSetOutcomes/SkillsetCelebration';
import NavigationService from '@nextstep/app/services/NavigationService';
import ProblemMessage from '@nextstep/app/components/skillSetOutcomes/Problem';
import CourseCompletedCelebration from '@nextstep/app/components/skillSetOutcomes/CourseCompletedCelebration';
import { FullViewSpinner } from '@nextstep/app/components/SharedUI';
import BottomSheetModal from '@nextstep/app/modals/BottomSheetModal';
import { Images } from '@nextstep/app/Themes';
import FastLearnerMessage from '@nextstep/app/components/skillSetOutcomes/FastLearnerMessage';
import SkillsetNavigator from './SkillsetNavigator';

class outcomeScreen extends SkillsetNavigator {
  constructor(props) {
    super(props);

    this.state = {
      header: {
        show: false,
        title: '',
        subtitle: '',
      },
      footer: {
        show: false,
        buttonText: '',
        onPress: () => {},
      },
      body: undefined,
      timerPercentage: 0,
      timerSeconds: 5,
      loading: false,
    };
  }

  async componentDidMount() {
    const {
      empty, finalExamTimeOut, skillCelebration, skillsetCelebration,
      courseCelebration, fastLearner,
    } = Constants.OutcomeTypes;
    const { rootStore: { progressStore, sessionStore, contentStore }, route } = this.props;

    await afterInteractions();

    if (route.params?.status === finalExamTimeOut) { // ------------------------------ Timed out exam, finished exam
      this.setScreenData(route.params.status);
    } else if (route.params?.status) { // -------------------------------------------- Failed linear assessment, finished exam
      this.setScreenData(route.params.status);
    } else if (progressStore.metrics.percentage === 100) { // ------------------------ End of course
      this.setScreenData(courseCelebration);
    } else if ( // ------------------------------------------------------------------- Skillset complete
      progressStore.skillsetProgress.isCompleted || progressStore.skillsetProgress.areAllChildrenCompleted) {
      if (progressStore.skillsetProgress.remainingRequiredTime > 0) { // ------------- Skillset complete - CA Time Constraint
        this.setScreenData(fastLearner);
        this.startTimer(10);
      } else if (sessionStore.inAppNotifications?. // -------------------------------- Skillset complete - In-Line Notification
        find(notification => notification.skillsetIndex === contentStore.skillsets.indexOf(contentStore.skillset))) {
        this.goToLearnTab();
      } else { // -------------------------------------------------------------------- Skillset complete - regular path
        this.setScreenData(skillsetCelebration);
        if (!progressStore.isAssignmentQuestionModalVisible) this.startTimer();
      }
    } else if (progressStore.skillProgress?.isCompleted) { // ------------------------ Skill Complete
      this.setScreenData(skillCelebration);
      this.startTimer();
    } else { // ---------------------------------------------------------------------- Unknown / Error
      this.setScreenData(empty);
      this.startTimer();
    }
  }

  closeFirstForumChatModal = () => {
    const { rootStore } = this.props;
    const { progressStore } = rootStore;
    progressStore.setAssignmentQuestionUnlockedModalVisibility(false);
    this.startTimer();
  }

  goToAssignmentQuestions = () => {
    NavigationService.navigate('App', { screen: 'Discussion Board' });
  }

  setHeader = (title, subtitle, show = true) => {
    this.setState({ header: { title, subtitle, show } });
  }

  setFooter = (buttonText, onPress, show = true) => {
    this.setState({ footer: { buttonText, onPress, show } });
  }

  setBody = (body) => {
    this.setState({ body });
  }

  startTimer = (seconds) => {
    if (seconds) this.setState({ timerSeconds: seconds });

    const { timerSeconds } = this.state;

    this.timerInterval = setInterval(() => {
      if (this.state.timerPercentage < 100) {
        this.setState((prevState) => ({ timerPercentage: prevState.timerPercentage + (100 / (timerSeconds * 100)) }));
      } else {
        this.state.footer.onPress();
      }
    }, 10);
  }

  getFailedQuizSubtitle = () => {
    const { rootStore: { contentStore } } = this.props;
    const { linearAssessmentIteration } = contentStore;
    const amountOfQuestions = linearAssessmentIteration?.questions?.length || 0;

    return `Question ${amountOfQuestions} of ${amountOfQuestions}`;
  }

  getCelebrationSubtitle = () => {
    const { rootStore: { contentStore, progressStore } } = this.props;
    const { skillset } = contentStore;
    const { skillsetProgress } = progressStore;

    const amountOfSkills = skillset.skillCount || 0;
    const amountOfCompletedSkills = skillsetProgress.skillsCompletedCount || 0;

    return `${amountOfCompletedSkills} of ${amountOfSkills} Skills earned`;
  }

  goToLinearAssessment = () => {
    const { navigation, rootStore: { contentStore } } = this.props;

    const skillsetKey = contentStore.skillset.key;

    navigation.replace('LinearAssessment', { skillsetKey });
  }

  goToLearnTab = () => {
    clearInterval(this.timerInterval);
    NavigationService.navigate('App', { screen: 'Learn' });
  }

  transitionMiddleware(func) {
    this.setState({ loading: true });
    clearInterval(this.timerInterval);
    func();
  }

  setScreenData = (status) => {
    const {
      finalExamTimeOut, finalExamFinished, knowledgeCheckFailed,
      skillCelebration, skillsetCelebration, courseCelebration,
      fastLearner,
    } = Constants.OutcomeTypes;
    const { rootStore: { contentStore } } = this.props;
    const { skillset, skill, linearAssessmentIteration } = contentStore;

    switch (status) {
      case finalExamTimeOut:
        this.setHeader(skillset.title, `${linearAssessmentIteration.questions.length} questions`);
        this.setFooter('Complete Exam', () => this.transitionMiddleware(() => {
          this.setState({ timerPercentage: 0, loading: false });
          this.setScreenData(finalExamFinished);
        }));
        this.setBody(<FinalExamTimeOut color={skillset.colorTheme} />);
        break;

      case finalExamFinished:
        this.setHeader(skillset.title, `${linearAssessmentIteration.questions.length} questions`);
        this.setFooter('Home', () => {
          clearInterval(this.timerInterval);
          NavigationService.navigate('App', { screen: 'Home' });
        });
        this.setBody(<FinalExamComplete color={skillset.colorTheme} />);
        break;

      case knowledgeCheckFailed:
        this.setHeader(`${skillset.title} Knowledge Check`, this.getFailedQuizSubtitle());
        this.setFooter('Try Again', () => this.transitionMiddleware(this.goToLinearAssessment));
        this.setBody(<FailedQuiz color={skillset.colorTheme} colorType={skillset.colorType} />);
        break;

      case skillCelebration:
        this.setHeader(skillset.title, this.getCelebrationSubtitle());
        this.setFooter('Start Next Skill', () => this.transitionMiddleware(this.goToNextSkill));
        this.setBody(<SkillCelebration color={skillset.colorTheme} icon={skill?.icon} skillName={skill?.title} />);
        break;

      case fastLearner:
        this.setFooter('Review Material', () => this.goToLearnTab());
        this.setBody(<FastLearnerMessage colorType={skillset.colorType} />);
        break;

      case skillsetCelebration:
        this.setHeader(skillset.title, this.getCelebrationSubtitle());
        this.setFooter('Start Next Skill Set', () => this.transitionMiddleware(this.goToNextSkillset));
        this.setBody(<SkillsetCelebration color={skillset.colorTheme} index={skillset.id} skillsetName={skillset.title} icon={skillset.icon} />);
        break;

      case courseCelebration:
        this.setBody(<CourseCompletedCelebration />);
        break;

      default:
        this.setHeader('', '', true);
        this.setFooter('Go Back', () => {
          clearInterval(this.timerInterval);
          NavigationService.navigate('App', { screen: 'Learn' });
        });
        this.setBody(<ProblemMessage color={skillset.colorTheme} colorType={skillset.colorType} />);
        break;
    }
  }

  componentWillUnmount() {
    clearInterval(this.timerInterval);
  }

  render() {
    const { rootStore: { contentStore, progressStore } } = this.props;
    const {
      header: { title, subtitle, show: headerShow },
      footer: { buttonText, onPress, show: footerShow },
      body, timerPercentage, loading,
    } = this.state;

    const { skillset } = contentStore;
    const { isAssignmentQuestionModalVisible } = progressStore;

    if (!body || !skillset) return <FullViewSpinner color={skillset?.colorTheme} />;

    return (
      <ViewWrapper
        smSize={12}
        mdSize={6}
        lgSize={5}
        topNavBarColor={headerShow ? skillset.colorTheme : undefined}
        bottomBarColor={footerShow ? skillset.colorTheme : undefined}
        header={headerShow && <BaseHeader title={title} subtitle={subtitle} color={skillset.colorTheme} />}
        footer={footerShow && (
          <BaseFooter
            buttonText={buttonText}
            onPress={onPress}
            color={skillset.colorTheme}
            timerPercentage={timerPercentage}
            loading={loading}
          />
        )}
      >
        {body}
        <BottomSheetModal
          isVisible={isAssignmentQuestionModalVisible}
          dismissible
          icon={Images.icons.main.forumIcon}
          messageTitle={'You have a new discussion board question!'}
          messageContent={'Questions from our nurse instructors give you the opportunity to think more deeply about the information you\'ve been learning in app — and to see what other learners are saying about their new skills!'}
          toggleVisible={this.closeFirstForumChatModal}
          primaryAction={{
            text: 'Go to Discussion Board',
            action: () => this.transitionMiddleware(this.goToAssignmentQuestions),
          }}
        />
      </ViewWrapper>
    );
  }
}

export default withDimensions(inject('rootStore')(observer(outcomeScreen)));

