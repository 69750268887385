import React, { Component } from 'react';
import { SafeAreaView, ScrollView, StatusBar, View, Platform } from 'react-native';
import PropTypes from 'prop-types';
import { AndroidBackHandler } from 'react-navigation-backhandler';
import { Column, Row } from '@nextstep/app/lib/ResponsiveLayout';
import withDimensions, { DeviceType } from '@nextstep/app/lib/withDimensions';
import { ApplicationStyles, Palette } from '@nextstep/app/Themes';
import AdaptiveContainerStyles from '@nextstep/app/containers/styles/AdaptiveContainerStyles';
import NavigationService from '@nextstep/app/services/NavigationService';
import AuthHeader from './AuthHeader';

const currentPalette = Palette.light;

export class ViewWrapper extends Component {
  constructor(props) {
    super(props);
    this.scrollView = React.createRef();
  }

  render() {
    const {
      dimensions, children,
      smSize, mdSize, lgSize, bottomBarColor,
      navigationColor, header, footer,
      topNavBarColor, backOverride,
      hideBorder, scrollToTop, backgroundColor, fillHeight,
    } = this.props;

    let { navigationBar } = this.props;

    const Styles = AdaptiveContainerStyles(dimensions.deviceType);

    if (typeof navigationBar === 'boolean' && navigationBar) { // make it compatible with the existing code
      navigationBar = {
        left: {
          icon: 'arrow-back',
          text: 'Back',
          action: () => (backOverride ? backOverride() : NavigationService.back()),
        },
      };
    }

    const isWeb = Platform.OS === 'web';
    const isMobile = dimensions.deviceType === DeviceType.Mobile;

    const mobile = (
      <>
        <AndroidBackHandler onBackPress={() => {}} />
        <SafeAreaView style={[ApplicationStyles.safeAreaTop, { backgroundColor: topNavBarColor }]} />
        <StatusBar barStyle="dark-content" animated backgroundColor={topNavBarColor} />
        <SafeAreaView style={[ApplicationStyles.safeArea, { backgroundColor: bottomBarColor }]}>
          {navigationBar && (
            <Row style={{ paddingVertical: 16, backgroundColor: topNavBarColor }}>
              <AuthHeader
                left={navigationBar.left}
                right={navigationBar.right}
                color={navigationColor}
              />
            </Row>
          )}
          <Row style={{ flex: 1 }}>
            <Column smSize={smSize} mdSize={mdSize} lgSize={lgSize} style={{ height: '100%' }}>
              {header}
              <ScrollView
                ref={(ref) => { this.viewWrapperScrollView = ref; }}
                style={[Styles.scrollView, { backgroundColor }]}
                contentContainerStyle={{ flexGrow: 1, justifyContent: 'flex-start' }}
                scrollEventThrottle={400}
                bounces={false}
                keyboardShouldPersistTaps="always"
                onContentSizeChange={() => scrollToTop && this.viewWrapperScrollView.scrollTo({ x: 0, y: 0 })}
              >
                {Boolean(header) && <View style={{ height: 16 }} />}
                {children}
                {Boolean(footer) && <View style={{ height: 16 }} />}
              </ScrollView>
              {footer}
            </Column>
          </Row>
        </SafeAreaView>
      </>
    );

    const fullWeb = (
      <Column smSize={smSize} mdSize={mdSize} lgSize={lgSize}>
        <View style={{ height: 24 }} />

        {navigationBar && (
          <>
            <Row style={{ backgroundColor: navigationColor }}>
              <AuthHeader
                left={navigationBar.left}
                right={navigationBar.right}
                color={navigationColor}
              />
            </Row>
            <View style={{ height: 24 }} />
          </>
        )}
        <View style={[Styles.card, hideBorder && { shadowColor: currentPalette.basic.transparent }, fillHeight && { flex: 1 }]}>
          {header}
          {children}
          {footer}
        </View>

        <View style={{ height: 24 }} />
      </Column>
    );

    const mobileWeb = (
      <Column smSize={smSize} mdSize={mdSize} lgSize={lgSize} style={{ maxHeight: '100vh', minHeight: '100vh' }}>
        {navigationBar && (
          <Row style={{ backgroundColor: navigationColor, paddingVertical: 24 }}>
            <AuthHeader
              left={navigationBar.left}
              right={navigationBar.right}
              color={navigationColor}
            />
          </Row>
        )}
        {header}
        <ScrollView
          ref={(ref) => { this.webScrollView = ref; }}
          contentContainerStyle={fillHeight && { flex: 1 }}
          style={Styles.mobileWebContainer}
        >
          {children}
        </ScrollView>
        {footer}
      </Column>
    );

    const web = (
      <ScrollView
        style={[ApplicationStyles.screenContainer(), { backgroundColor }]}
        contentContainerStyle={{ flexGrow: 1, justifyContent: 'flex-start' }}
        bounces={false}
      >
        <Row style={fillHeight && { flex: 1 }}>
          { (isMobile && isWeb) ? mobileWeb : fullWeb }
        </Row>
      </ScrollView>
    );

    return isWeb ? web : mobile;
  }
}

ViewWrapper.propTypes = {
  navigationBar: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      left: PropTypes.shape({
        icon: PropTypes.string,
        text: PropTypes.string,
        action: PropTypes.func,
      }),
      right: PropTypes.shape({
        icon: PropTypes.string,
        text: PropTypes.string,
        action: PropTypes.func,
      }),
      color: PropTypes.string,
    }),
  ]),
  navigationColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  smSize: PropTypes.number.isRequired,
  mdSize: PropTypes.number.isRequired,
  lgSize: PropTypes.number.isRequired,
  topNavBarColor: PropTypes.string,
  bottomBarColor: PropTypes.string,
  hideBorder: PropTypes.bool,
  scrollToTop: PropTypes.bool,
  backOverride: PropTypes.func,
  fillHeight: PropTypes.bool,
};

ViewWrapper.defaultProps = {
  navigationBar: false,
  navigationColor: 'transparent',
  backgroundColor: Platform.OS === 'web' ? currentPalette.background.main : currentPalette.background.white,
  topNavBarColor: currentPalette.background.white,
  bottomBarColor: currentPalette.background.white,
  hideBorder: false,
  scrollToTop: false,
  backOverride: undefined,
  fillHeight: false,
};

export default withDimensions(ViewWrapper);

