import React from 'react';
import { inject, observer } from 'mobx-react';
import { ActivityIndicator, View } from 'react-native';
import withDimensions from '@nextstep/app/lib/withDimensions';
import ViewWrapper from '@nextstep/app/components/ViewWrapper';
import AdaptiveContainerStyles from '@nextstep/app/containers/styles/AdaptiveContainerStyles';
import { ButtonFilled } from '@nextstep/app/components/Button';
import Constants from '@nextstep/app/Config/Constants';
import { BooleanQuestion, SelectQuestion, TextQuestion } from '@nextstep/app/containers/Questionaire';
import { currentPalette } from '@nextstep/app/Config/AppConfig';
import BaseHeader from '@nextstep/app/components/baseStructure/BaseHeader';
import Divider from '@nextstep/app/components/Divider';
import { Caption2 } from '@nextstep/app/components/Text';
import NavigationService from '@nextstep/app/services/NavigationService';
import Banner from '@nextstep/app/components/Banner';
import SkillChildNavigator from './SkillChildNavigator';

class LearnerTaskQuestionnaire extends SkillChildNavigator {
  constructor(props) {
    super(props);
    this.state = {
      validatingQuestionnaire: false,
      errors: undefined,
    };
  }

  onNextPress = async () => {
    this.setState({ validatingQuestionnaire: true });
    const { rootStore: { sessionStore }, navigation } = this.props;

    const { id, answers } = sessionStore.questionnaireAnswers;
    const response = await sessionStore.validateQuestionnaireAnswers(id, { payload: { answers } });

    if (response?.status === 201) {
      await sessionStore.syncInAppNotifications();
      navigation.replace('JobPlacementConfirmationScreen');
    }

    if (response?.data?.errors) {
      this.setState({ errors: response.data.errors });
    }

    this.setState({ validatingQuestionnaire: false });
  };

  generateQuestion = (question, error) => {
    const { select, boolean, text } = Constants.QuestionnaireQuestionTypes;

    switch (question.type) {
      case select:
        return <SelectQuestion question={question} showError={error.show} errorMessage={error.message} />;
      case boolean:
        return <BooleanQuestion question={question} showError={error.show} errorMessage={error.message} />;
      case text:
        return <TextQuestion question={question} showError={error.show} errorMessage={error.message} />;
      default:
        return <></>;
    }
  };

  hasError = (question) => {
    const { errors } = this.state;
    let show = false;
    let message;

    if (errors) {
      const errorMessage = errors[`${question.id}`];
      show = !!errorMessage;
      message = errorMessage;
    }

    return { show, message };
  }

  render() {
    const { rootStore: { contentStore }, dimensions } = this.props;
    const { validatingQuestionnaire, errors } = this.state;

    const { questionnaire } = contentStore;
    if (!questionnaire) {
      NavigationService.navigate('Learn');
      return <></>;
    }

    const MainStyles = AdaptiveContainerStyles(dimensions.deviceType);

    return (
      <ViewWrapper
        smSize={12}
        mdSize={6}
        lgSize={6}
        header={(
          <BaseHeader
            title={questionnaire.title}
            color={currentPalette.basic.white}
            titleColor={currentPalette.text.main}
            titleAlign={'center'}
          />
        )}
        navigationBar
      >

        <Divider style={{ marginBottom: 30 }} />

        <View style={[MainStyles.textContainer]}>

          <Caption2 textAlign="left" color={currentPalette.text.error}>
            * Required questions
          </Caption2>

          <View style={{ height: 22 }} />

          {questionnaire.questions.map(question => (
            <View key={question.id} style={{ paddingBottom: 15 }}>
              {this.generateQuestion(question, this.hasError(question))}
            </View>
          ))}

        </View>

        {errors && (
          <View style={[MainStyles.textContainerPadding]}>
            <Banner
              text={'You must Complete all the required fields above.'}
              color={currentPalette.text.error}
              textColor={currentPalette.text.error}
              textAlign="center"
            />
          </View>
        )}

        <View style={{ height: 15 }} />

        <View style={[MainStyles.actionsContainer]}>
          <ButtonFilled
            onPress={() => this.onNextPress()}
            disabled={validatingQuestionnaire}
          >
            {validatingQuestionnaire
              ? <ActivityIndicator style={{ height: 53 }} size="small" color={currentPalette.primary.main} />
              : questionnaire.submissionText
            }
          </ButtonFilled>
        </View>

        <View style={{ height: 32 }} />

      </ViewWrapper>
    );
  }
}

export default withDimensions(inject('rootStore')(observer(LearnerTaskQuestionnaire)));
