/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { View } from 'react-native';
import PropTypes from 'prop-types';
import { Icon } from 'react-native-elements';
import { Body1 } from '@nextstep/app/components/Text';
import Util from '@nextstep/app/services/Util';
import { currentPalette } from '@nextstep/app/Config/AppConfig';

const IconTimer = (props) => (
  <View style={{ flexDirection: props.compact ? 'row' : 'column', alignItems: 'center', alignContent: 'center' }}>
    <Icon
      name={props.iconName}
      size={props.compact ? props.iconSize : (props.iconSize * 2)}
      color={props.color}
    />
    <View style={{ paddingLeft: 5 }}>
      <Body1 textAlign={'left'} color={props.color}>
        {Util.timeFormatted(props.time)}
      </Body1>
    </View>
  </View>
);


IconTimer.propTypes = {
  iconSize: PropTypes.number,
  iconName: PropTypes.string,
  time: PropTypes.number,
  color: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  compact: PropTypes.bool,
};

IconTimer.defaultProps = {
  iconSize: 18,
  iconName: 'timer',
  time: 0,
  color: currentPalette.text.gray,
  compact: true,
};

export default IconTimer;
