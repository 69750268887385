/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Image, View } from 'react-native';
import PropTypes from 'prop-types';
import { Colors, Images } from '@nextstep/app/Themes';
import withDimensions, { DeviceType } from '@nextstep/app/lib/withDimensions';
import { H3 } from './Text';

const defaultStyle = {
  mentorRow: {
    position: 'absolute',
    flexDirection: 'row',
    paddingTop: DeviceType.Mobile ? 35 : 90,
    maxWidth: 360,
  },

  mentorContainer: {
    height: DeviceType.Mobile ? 216 : 270,
    alignItems: 'center',
    paddingHorizontal: DeviceType.Mobile ? 16 : 0,
    marginBottom: 40,
  },

  mentorIcon: {
    width: 110,
    height: 198,
  },

  textContainer: {
    justifyContent: 'center',
    alignItems: 'flex-start',
    bottom: 14,
    flex: 1,
    marginLeft: 40,
  },
};

const colors = {
  main: Colors.bright_orange,
  pink: Colors.mainPink,
  orange: Colors.mainOrange,
  green: Colors.mainGreen,
  blue: Colors.mainBlue,
  magenta: Colors.mainMagenta,
};

const MentorHeader = (props) => {
  const textStyle = {
    color: Colors.very_light_peach,
    ...props.textStyle,
  };

  const mentorIconStyle = {
    ...defaultStyle.mentorIcon,
    ...props.customMentorImageStyle,
  };

  const mentorContainerStyle = {
    ...defaultStyle.mentorContainer,
    backgroundColor: colors[props.color],
    ...props.mentorContainerStyle,
  };

  return (
    <View style={mentorContainerStyle}>
      <View style={defaultStyle.mentorRow}>

        <Image
          style={mentorIconStyle}
          source={props.customMentorImage || Images.mentor[props.color][props.mentorStyle]}
        />

        <View style={defaultStyle.textContainer}>
          <H3 style={textStyle}>
            {props.text}
          </H3>
        </View>

      </View>
    </View>
  );
};
// mentorIconStyle
MentorHeader.propTypes = {
  text: PropTypes.string,
  color: PropTypes.oneOf(['main', 'pink', 'orange', 'green', 'blue', 'magenta']),
  mentorStyle: PropTypes.oneOf(['board', 'pencil', 'oops', 'cell', 'computer']),
  customMentorImage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  customMentorImageStyle: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
  }),
  mentorContainerStyle: PropTypes.shape({ height: PropTypes.number }),
  // textStyle: PropTypes.object, // ---- Hidden property
};

MentorHeader.defaultProps = {
  text: 'Placeholder',
  color: 'main',
  mentorStyle: 'board',
  customMentorImage: undefined,
  customMentorImageStyle: {},
  mentorContainerStyle: {},
  // textStyle: {}, // ---- Hidden property
};

export default withDimensions(MentorHeader);
