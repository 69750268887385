import { types } from 'mobx-state-tree';

const UserConfig = types.model('UserConfig', {
  agreementUrl: types.maybe(types.string),
  appBlockEnabled: types.maybe(types.boolean),
  cardCaptureForScholarshipEnabled: types.optional(types.boolean, true),
  coursePolicyUrl: types.maybeNull(types.string),
  createAccountEnabled: types.optional(types.boolean, true),
  discussionBoardsEnabled: types.optional(types.boolean, true),
  inPersonEnabled: types.optional(types.boolean, true),
  eulaUrl: types.maybe(types.string),
  fastForwardEnabled: types.maybe(types.boolean),
  hourlyPay: types.maybe(types.number),
  idlePopupEnabled: types.maybe(types.boolean),
  idleTimerSeconds: types.maybe(types.number),
  intercomEnabled: types.optional(types.boolean, true),
  paymentTrackSelectionDeferrable: types.optional(types.boolean, false),
  paymentTracks: types.maybe(types.array(types.string)),
  registrationFee: types.maybe(types.number),
  registrationFeeEnabled: types.maybe(types.boolean),
  scholarshipRequiresJobCommitment: types.maybe(types.boolean),
  state: types.maybe(types.string),
  tuitionCost: types.maybe(types.integer),
  tuitionNumberOfInstallments: types.maybe(types.integer),
  tuitionPaymentFrequency: types.maybe(types.string),
  videoAutoPlay: true,
  videoUploadEnabled: types.maybe(types.boolean),
  weeksToCompleteCourse: types.optional(types.maybe(types.integer), 10),
});

export default UserConfig;
