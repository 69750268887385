import { types } from 'mobx-state-tree';
import { ContentNodeTypes } from '@nextstep/app/Config/Constants';
import ContentNode from './ContentNode';

const SuccessCriteriaModel = types.model({
  id: types.identifierNumber,
  key: types.string,
  content: types.string,
});

const SuccessCriteria = types.compose(SuccessCriteriaModel, ContentNode)
  .named(ContentNodeTypes.successCriteria);

export default SuccessCriteria;
