/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Image, ScrollView, View } from 'react-native';
import { ApplicationStyles, Images } from '@nextstep/app/Themes/index';
import CustomModal from '@nextstep/app/components/CustomModal';
import DebouncedTouchableOpacity from '@nextstep/app/components/DebouncedTouchableOpacity';
import { Body1, Subtitle2 } from '@nextstep/app/components/Text';
import Divider from '@nextstep/app/components/Divider';
import { SectionTypeTitles } from '@nextstep/app/Config/Constants';
import Styles from './styles/StudyMaterialsModalStyles';

const DefaultStyle = {
  modalContainerStyle: [
    Styles.container,
    ApplicationStyles.modalStyles.container,
  ],
};

const MaterialModal = ({
  materials, renderSuccessCriteria, renderDemoVideos,
  toggleVisible, isVisible, colorTheme, skill, size,
}) => {
  let section;
  const [sectionType, setSectionType] = useState(null);

  if (sectionType === SectionTypeTitles.successCriteria) section = renderSuccessCriteria();
  if (sectionType === SectionTypeTitles.demoVideos || sectionType === SectionTypeTitles.demoVideo) section = renderDemoVideos();

  return (
    <CustomModal
      isVisible={isVisible}
      onBackdropPress={() => {
        setSectionType(null);
        toggleVisible();
      }}
      onSwipeComplete={() => {
        setSectionType(null);
        toggleVisible();
      }}
      animationIn={'zoomIn'}
      animationOut={'zoomOut'}
      propagateSwipe
    >
      <View style={DefaultStyle.modalContainerStyle}>

        <View style={{ height: 20 }} />

        <View style={Styles.title}>

          {section && (
            <DebouncedTouchableOpacity
              style={{ marginRight: 8 }}
              onPress={() => setSectionType(null)}
            >
              <Image
                style={[Styles.icon, { tintColor: colorTheme }]}
                source={Images.icons.back}
              />
            </DebouncedTouchableOpacity>
          )}

          <Subtitle2 style={{ flex: 1 }} textAlign={'left'}>
            {skill.title}
          </Subtitle2>

          <DebouncedTouchableOpacity
            style={{ marginLeft: 8 }}
            onPress={() => {
              setSectionType(null);
              toggleVisible();
            }}
          >
            <Image
              style={Styles.icon}
              source={Images.icons.exit}
            />
          </DebouncedTouchableOpacity>

        </View>

        <View style={{ height: 20 }} />

        <Divider />

        {/* Body */}
        <ScrollView style={{ maxHeight: size.height / 1.5 }}>

          <View style={{ height: 10 }} />

          {!section
            ? materials.filter(m => m.show)
              .map((d, i) => (
                <DebouncedTouchableOpacity
                  key={i}
                  style={Styles.body}
                  onPress={() => setSectionType(d.title)}
                >
                  <Body1>
                    {d.title}
                  </Body1>

                  <Image
                    style={Styles.imgIcon}
                    source={d.src}
                  />

                </DebouncedTouchableOpacity>
              ))
            : section
          }

          <View style={{ height: 20 }} />

        </ScrollView>
      </View>
    </CustomModal>
  );
};

export default MaterialModal;
