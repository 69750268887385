/* eslint-disable react/prop-types,react/destructuring-assignment */
import React, { Fragment } from 'react';
import { View, Image } from 'react-native';
import { inject, observer } from 'mobx-react';
import { Icon as IconRNE } from 'react-native-elements';
import { Images, Palette } from '@nextstep/app/Themes';
import Util from '@nextstep/app/services/Util';
import withDimensions from '@nextstep/app/lib/withDimensions';
import { ContentNodeTypes } from '@nextstep/app/Config/Constants';
import NavigationService from '@nextstep/app/services/NavigationService';
import SkillsetChildStyles from './styles/SkillsetChildStyles';
import { Body1, Caption1, Subtitle2 } from './Text';
import { ButtonFilled } from './Button';
import StyledHTMLView from './StyledHTMLView';
import DebouncedTouchableOpacity from './DebouncedTouchableOpacity';
import Divider from './Divider';
import SkillsetChildIcon from './SkillsetChildIcon';

const currentPalette = Palette.light;

const SkillsetChild = ({
  progressStore,
  skillsetChild,
  skillset,
  skillsetProgress,
  goToStep,
  practiceLab,
  dimensions,
}) => {
  let progressValue = 0;
  let completed = false;

  // knowledge checks are when linear assessments are the child of a skillset
  // when that happens, we render a 'skill' (as skillset child) using the knowledge check's status
  /// and we render a 'step' (as skill child) using the same progress.
  const isKnowledgeCheck = skillsetChild.nodeType !== ContentNodeTypes.skill;

  const Styles = SkillsetChildStyles(dimensions.deviceType);
  const skillsetChildProgress = (isKnowledgeCheck)
    ? skillsetProgress.linearAssessmentProgress
    : skillsetProgress.skillProgresses?.find(s => s.skillId === skillsetChild.id);

  const locked = (skillsetChildProgress?.isLocked ?? true);
  const stepsData = Util.getStepsData(skillsetChildProgress);

  if (skillsetChildProgress) {
    completed = skillsetChildProgress.isCompleted;
    progressValue = completed ? 1 : (stepsData.stepsCompleted / stepsData.totalSteps);
  }

  const { colorTheme, colorType, colorTheme20 } = skillset;

  const getSkillChildIcon = (skillChildProgress) => {
    const iconType = Images.icons[colorType];
    if (!iconType) return Images.icons.lockedStep;

    if (!skillChildProgress) return Images.icons.lockedStep;
    if (skillChildProgress.isLocked) return Images.icons.lockedStep;
    if (skillChildProgress.isUnlocked) return iconType.stepUnlocked;
    if (skillChildProgress.isInProgress || skillChildProgress.isFailed) return iconType.step2;
    if (skillChildProgress.isCompleted) return iconType.checkMark;

    return Images.icons.lockedStep;
  };


  const renderStepRow = ({
    isCompleted,
    isLocked,
    showChevron,
    stepImage,
    stepText,
  }) => (
    <View style={Styles.stepRowContainer}>
      <View style={{ flexDirection: 'row', flex: 1 }}>
        {stepImage}

        <View style={{ flex: 1 }}>
          <Body1 color={currentPalette.text.main} textAlign={'left'}>{stepText}</Body1>

          {/* TODO: check if amount of time is present */}
          {/* {skillset && (
            <>
              <IconTimer time={260} color={currentPalette.text.gray} />
            </>
          )} */}
        </View>

      </View>

      <View style={{ flexDirection: 'row' }}>
        {isCompleted && (
          <View style={[Styles.badge, { backgroundColor: colorTheme20 }]}>
            <Caption1 color={Util.colorWithTint(colorTheme, 0.4, true)}>
              Complete
            </Caption1>
          </View>
        )}

        {showChevron && (
          <IconRNE
            style={{ marginRight: 15, marginLeft: 8 }}
            name="keyboard-arrow-right"
            color={isLocked ? currentPalette.text.disabled : currentPalette.text.main}
          />
        )}

        {!showChevron && (<View style={{ width: 32 }} />)}
      </View>
    </View>
  );

  const renderStep = (step, index) => {
    const stepProgress = skillsetChildProgress?.stepProgresses?.find(item => item.stepId === step.id);
    const isLocked = (!stepProgress || stepProgress.isLocked);
    const isCompleted = (stepProgress && stepProgress.isCompleted) || false;
    const stepImage = (
      <Image
        style={[Styles.stepBodyImage, { backgroundColor: colorTheme20 }]}
        source={getSkillChildIcon(stepProgress)}
      />
    );

    const stepText = step.title || `Step ${index + 1}`;

    return (
      <DebouncedTouchableOpacity
        style={Styles.stepBody}
        disabled={isLocked}
        onPress={() => goToStep(step, skillsetChild)}
        key={index}
      >
        { renderStepRow({ isLocked, isCompleted, stepImage, stepText, showChevron: true }) }
      </DebouncedTouchableOpacity>
    );
  };

  const renderSkillChild = ({
    skillChild,
    skillChildProgress,
  }) => {
    const isCompleted = (skillChildProgress && skillChildProgress.isCompleted) || false;
    const disabled = !skillChildProgress || skillChildProgress.isLocked
      || (skillChild.nodeType === ContentNodeTypes.linearAssessment && isCompleted);
    const onPress = () => NavigationService.navigateToNode(skillChild);

    const stepImage = (
      <Image
        style={[Styles.stepBodyImage, { backgroundColor: colorTheme20 }]}
        source={getSkillChildIcon(skillChildProgress)}
      />
    );

    const getStepText = () => {
      if (skillChild.nodeType === ContentNodeTypes.linearAssessment) {
        const { questionCount } = skillChild;
        if (isKnowledgeCheck || skillChild.isFinalExam) return `${questionCount} Questions`;
        return 'Skill Quiz';
      }

      return skillsetChild?.introVideo?.title || 'Introduction';
    };

    const stepText = getStepText();

    return (
      <DebouncedTouchableOpacity
        style={Styles.stepBody}
        disabled={disabled}
        onPress={() => onPress(skillsetChild)}
      >
        { renderStepRow({ isLocked: disabled, isCompleted, stepImage, stepText, showChevron: !(isCompleted && disabled) }) }
      </DebouncedTouchableOpacity>
    );
  };

  const skillChildren = [
    skillsetChild.introVideo,
    skillsetChild.steps,
    skillsetChild.linearAssessment,
  ].filter(Boolean);

  const SkillChildren = skillChildren.map((skillChild) => {
    if (skillChild?.map) return skillChild.map((step, i) => renderStep(step, i));

    let skillChildProgress = skillsetChildProgress && skillsetChildProgress[skillChild.lowerProgressNodeType];

    // For linear assessments on a skillset, we use both the skill and step iconography
    if (isKnowledgeCheck) {
      skillChildProgress = skillsetChildProgress;
    }

    return renderSkillChild({
      skillChild,
      skillChildProgress,
    });
  }).flat();

  const navigateToInPersonScreen = () => {
    progressStore.loadCoursePracticeLabProgresses();
    NavigationService.navigate('App', { screen: 'In-Person' });
  };

  return (
    <View style={[Styles.container, practiceLab && { backgroundColor: Util.colorWithTint(colorTheme, 0.1) }]}>
      <View style={Styles.header}>
        <SkillsetChildIcon
          skillsetChild={skillsetChild}
          colorTheme={colorTheme}
          colorType={colorType}
          progressValue={progressValue}
          disabled={practiceLab ? false : locked}
          completed={completed}
          dimensions={dimensions}
        />
        <View style={Styles.headerText}>
          <Subtitle2 textAlign="left" style={Styles.headerTextTitle}>
            {skillsetChild.title}
          </Subtitle2>

          <StyledHTMLView
            overrideHTMLStyles={{ p: { textAlign: 'center' } }}
            value={`${skillsetChild.summary || ''}`}
          />

          { practiceLab && (
          <View style={{ paddingTop: 20 }}>
            <ButtonFilled width="25%" style={{ minWidth: 200, paddingHorizontal: 5 }} onPress={navigateToInPersonScreen}>
              In-Person
              {'  \u003E'}
            </ButtonFilled>
          </View>
          )}
        </View>
      </View>

      <Divider />

      <View style={Styles.stepsBody}>
        {SkillChildren.map((skillChild, i) => (
          <Fragment key={i}>
            {skillChild}
            { i < SkillChildren.length - 1 && <Divider /> }
          </Fragment>
        ))}
      </View>
    </View>
  );
};

export default withDimensions(inject(({ rootStore: { progressStore } }) => ({ progressStore }))(observer(SkillsetChild)));
