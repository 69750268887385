import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Text, View, Button } from 'react-native';
import Logger from './Logger';

class ComponentErrorBoundary extends Component {
    static defaultProps = {
      errorButtonAction: null,
      errorButtonTitle: null,
    }

    static propTypes = {
      children: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array,
      ]).isRequired,
      errorMessage: PropTypes.string.isRequired,
      errorButtonAction: PropTypes.func,
      errorButtonTitle: PropTypes.string,
    };

    static getDerivedStateFromError(error) {
      return { hasError: error };
    }

    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }

    componentDidCatch(error, errorInfo) {
      Logger.error(error, errorInfo);
    }

    render() {
      const { hasError } = this.state;
      const { children, errorMessage, errorButtonAction, errorButtonTitle } = this.props;
      if (hasError) {
        return (
          <View>
            <Text>{ errorMessage }</Text>
            {(errorButtonTitle && errorButtonAction
            && <Button title={errorButtonTitle} onPress={() => errorButtonAction()} />
              )}
          </View>
        );
      }
      return children;
    }
}
export default ComponentErrorBoundary;
