import { View } from 'react-native';
import React from 'react';
import { Icon } from 'react-native-elements';
import PropTypes from 'prop-types';
import { Palette } from '@nextstep/app/Themes';
import withDimensions from '@nextstep/app/lib/withDimensions';
import { Body1 } from './Text';
import DebouncedTouchableOpacity from './DebouncedTouchableOpacity';

const currentPalette = Palette.light;

const headerAction = ({ object, side }) => (
  <DebouncedTouchableOpacity
    onPress={() => ((object && object.action) ? object.action() : {})}
    hitSlop={{ top: 10, left: 10, right: 10, bottom: 10 }}
  >
    <View style={{ flexDirection: 'row' }}>

      {side === 'LEFT' && object?.icon && (
        <>
          <Icon
            name={object.icon}
            activeOpacity={0}
            color={currentPalette.primary.main}
            underlayColor="transparent"
          />

          <View style={{ width: 10 }} />
        </>
      )}

      <Body1 style={{ color: currentPalette.primary.main, display: 'flex', justifyContent: 'center' }}>
        {object?.text}
      </Body1>

      {side === 'RIGHT' && object?.icon && (
        <>
          <View style={{ width: 10 }} />

          <Icon
            name={object.icon}
            activeOpacity={0}
            color={currentPalette.primary.main}
            underlayColor="transparent"
          />
        </>
      )}

    </View>
  </DebouncedTouchableOpacity>
);

headerAction.propTypes = {
  object: PropTypes.shape({
    icon: PropTypes.string,
    text: PropTypes.string,
    action: PropTypes.func,
  }).isRequired,
  side: PropTypes.oneOf(['LEFT', 'RIGHT']),
};

headerAction.defaultProps = { side: 'LEFT' };

export default withDimensions(headerAction);
