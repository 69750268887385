// Simple React Native specific changes

import Palette from '@nextstep/app/Themes/Palette';

export default {
  // font scaling override - RN default is on
  allowTextFontScaling: true,
};

export const currentPalette = Palette.light;
