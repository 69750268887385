/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { Platform, Text, View } from 'react-native';
import LottieView from 'lottie-react-native';
import { Column, Row } from '@nextstep/app/lib/ResponsiveLayout';
import Palette from '@nextstep/app/Themes/Palette';
import withDimensions from '@nextstep/app/lib/withDimensions';
import Amy from '@nextstep/assets/animations/amy.json';
import Iris from '@nextstep/assets/animations/iris.json';
import Julie from '@nextstep/assets/animations/julie.json';
import { Subtitle1, Title } from '@nextstep/app/components/Text';

const currentPalette = Palette.light;

const animations = [Amy, Iris, Julie];

const FinalExamTimeOut = (props) => (
  <View style={[{ justifyContent: 'center' }, Platform.OS !== 'web' && { height: '100%' }]}>

    {Platform.OS === 'web' && <View style={{ height: 36 }} />}

    <Row>
      <Column smSize={10}>

        <LottieView
          source={animations[Math.floor((Math.random() * animations.length))]}
          style={{ height: 217, alignSelf: 'center' }}
          autoPlay
          loop={false}
        />

        <View style={{ height: 40 }} />

        <Title color={props.color}>
          OOPS! YOU RAN OUT OF TIME
        </Title>

        <View style={{ height: 24 }} />

        <Text style={{ alignSelf: 'center' }}>
          <Subtitle1 color={props.color}>
            {"You'll be graded on the exam questions that you've answered"}
          </Subtitle1>

          <Subtitle1 color={props.color}>
            {"and we'll be in touch soon about your exam results."}
          </Subtitle1>
        </Text>

      </Column>
    </Row>

    {Platform.OS === 'web' && <View style={{ height: 36 }} />}

  </View>
);


FinalExamTimeOut.propTypes = { color: PropTypes.string };

FinalExamTimeOut.defaultProps = { color: currentPalette.primary.main };

export default withDimensions(FinalExamTimeOut);
