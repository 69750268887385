/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { View } from 'react-native';
import PropTypes from 'prop-types';
import { Palette } from '@nextstep/app/Themes';

const currentPalette = Palette.light;

const defaultStyle = {
  borderBottomWidth: 2,
  width: '100%',
};

const Divider = (props) => {
  const dividerStyle = {
    ...defaultStyle,
    marginVertical: props.gutter,
    borderBottomColor: props.color,
    ...props.style,
  };

  return (
    <View style={dividerStyle} />
  );
};

Divider.propTypes = {
  style: PropTypes.object,
  gutter: PropTypes.number,
  color: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

Divider.defaultProps = {
  style: {},
  gutter: 0,
  color: currentPalette.basic.border,
};

export default Divider;
