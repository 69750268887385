import { types } from 'mobx-state-tree';
import { ContentNodeTypes } from '@nextstep/app/Config/Constants';
import ContentNode from './ContentNode';
import LabSkill from './LabSkill';

const PracticeLabModel = types.model('PracticeLab', {
  id: types.identifierNumber,
  key: types.string,
  title: types.string,
  requiredSkillsetKey: types.maybe(types.string),
  shortDescription: types.maybe(types.string),
  description: types.maybe(types.string),
  image: types.maybe(types.string),
  labSkills: types.maybe(types.array(LabSkill)),
}).views(self => ({
  get loaded() {
    return !!self.key;
  },
  get detailsLoaded() {
    return !!self.labSkills;
  },

}));

const PracticeLab = types.compose(PracticeLabModel, ContentNode)
  .named(ContentNodeTypes.practiceLab);

export default PracticeLab;
