import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Dimensions, ScrollView, Text,
  View, Image, ActivityIndicator,
} from 'react-native';
import HTMLView from 'react-native-htmlview';
import { isNil } from 'ramda';
import WebView from 'react-native-webview';
import { Colors } from '@nextstep/app/Themes';
import { Body1, Body2, H1, H2, H3, H4 } from './Text';

const windowDimensions = Dimensions.get('window');

export default class StyledHTMLView extends Component {
// eslint-disable-next-line consistent-return
  renderNode = (node, index, siblings, parent, defaultRenderer) => {
    const { overrideHTMLStyles, onTermPress, onSkillPress } = this.props;
    if (node.name === 'iframe') {
      const a = node.attribs;
      return (
        <WebView
          useWebKit
          key={index}
          source={{ uri: a.src }}
          javaScriptEnabled
          domStorageEnabled
          startInLoadingState
          renderLoading={() => <ActivityIndicator size="large" color={Colors.bright_orange} />}
          style={{ width: windowDimensions.width, height: a.height ? Number(a.height) : 300 }}
        />
      );
    }
    if (node.name === 'div') {
      return (
        <View key={index}>
          {defaultRenderer(node.children, parent)}
        </View>
      );
    }
    if (node.name === 'b') {
      return (
        <Body2 textAlign="left" key={index} style={overrideHTMLStyles.b}>
          {defaultRenderer(node.children, parent)}
        </Body2>
      );
    }
    if (node.name === 'p') {
      return (
        <Body1 key={index} style={overrideHTMLStyles.p}>
          {defaultRenderer(node.children, parent)}
        </Body1>
      );
    }
    if (node.name === 'h1') {
      return (
        <H1 key={index} style={overrideHTMLStyles.h1}>
          {defaultRenderer(node.children, parent)}
        </H1>
      );
    }
    if (node.name === 'h2') {
      return (
        <H2 key={index} style={overrideHTMLStyles.h2}>
          {defaultRenderer(node.children, parent)}
        </H2>
      );
    }
    if (node.name === 'h3') {
      return (
        <H3 key={index} style={overrideHTMLStyles.h3}>
          {defaultRenderer(node.children, parent)}
        </H3>
      );
    }
    if (node.name === 'h4') {
      return (
        <H4 key={index} style={overrideHTMLStyles.h4}>
          {defaultRenderer(node.children, parent)}
        </H4>
      );
    }
    if (node.name === 'img') {
      const a = node.attribs;
      return (
        <Image key={index} source={{ uri: a.src }} style={overrideHTMLStyles.image} />
      );
    }
    if (node.name === 'ul') {
      return (
        <View key={index} style={[overrideHTMLStyles.ul, { marginLeft: 16 }]}>
          {defaultRenderer(node.children, node)}
        </View>
      );
    }
    if (node.name === 'ol') {
      return (
        <View key={index} style={overrideHTMLStyles.ol}>
          {defaultRenderer(node.children, node)}
        </View>
      );
    }
    if (node.name === 'li') {
      return (
        <View style={{ flexDirection: 'row' }}>
          <Body2>
            {'\u2022'}
          </Body2>
          <Body1 style={{ marginLeft: 16 }} textAlign={'left'}>
            {defaultRenderer(node.children, node)}
          </Body1>
        </View>
      );
    }
    if (node.name === 'span') {
      const a = node.attribs;
      if ('data-blank' in a) {
        return (
          <Text key={index} style={overrideHTMLStyles.blank}>
            {defaultRenderer(node.children, parent)}
          </Text>
        );
      }
    }
    if (node.name === 'a') {
      const { definition, pronunciation, skillid } = node.attribs || {};
      if (!isNil(definition) && onTermPress) {
        const title = (node.children || []).reduce((prev, ch) => prev + (ch.type === 'text' ? ch.data : ''), '');
        const term = { definition, pronunciation, title };
        return (
          <Text key={index} onPress={() => onTermPress(term)}>
            {defaultRenderer(node.children, node)}
          </Text>
        );
      }

      if (!isNil(skillid) && onSkillPress) {
        return (
          <Text key={index} onPress={() => onSkillPress(skillid)}>
            {defaultRenderer(node.children, node)}
          </Text>
        );
      }
    }
  };

  render() {
    const { value, style, overrideHTMLStyles } = this.props;
    return (
      <ScrollView>
        <HTMLView
          value={value}
          style={style}
          renderNode={this.renderNode}
          stylesheet={overrideHTMLStyles}
        />
      </ScrollView>
    );
  }
}


StyledHTMLView.propTypes = {
  style: PropTypes.object,
  overrideHTMLStyles: PropTypes.object,
  value: PropTypes.string.isRequired,
  onTermPress: PropTypes.func,
  onSkillPress: PropTypes.func,
};

StyledHTMLView.defaultProps = {
  style: {},
  overrideHTMLStyles: {},
  onTermPress: null,
  onSkillPress: null,
};
