/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { Image, Platform, View } from 'react-native';
import hexToRgba from 'hex-to-rgba';
import { inject, observer } from 'mobx-react';
import { Column, Row } from '@nextstep/app/lib/ResponsiveLayout';
import Images from '@nextstep/app/Themes/Images';
import withDimensions from '@nextstep/app/lib/withDimensions';
import Util from '@nextstep/app/services/Util';
import { Body1, Body2, Subtitle2 } from '@nextstep/app/components/Text';

const DefaultStyles = {
  mySkillBadgeBackground: {
    width: 93,
    height: 93,
    borderRadius: 100,
    alignItems: 'center',
    justifyContent: 'center',
  },

  mySkillBadge: {
    width: 80,
    height: 80,
    resizeMode: 'cover',
    borderRadius: 100,
    overflow: 'hidden',
    alignItems: 'center',
  },
};

const FastLearnerMessage = (props) => {
  const { rootStore: { progressStore, contentStore } } = props;
  const { skillsetProgress } = progressStore;
  const { skillset } = contentStore;
  const { remainingRequiredTime } = skillsetProgress;

  return (
    <View style={[{ justifyContent: 'center' }, Platform.OS !== 'web' && { height: '100%' }]}>

      {Platform.OS === 'web' && <View style={{ height: 36 }} />}

      <Row>
        <Column smSize={10}>

          <View style={{ alignItems: 'center' }}>
            <View style={[DefaultStyles.mySkillBadgeBackground, { backgroundColor: hexToRgba(skillset.colorTheme, 0.4) }]}>
              <Image
                style={DefaultStyles.mySkillBadge}
                source={Images.icons[props.colorType].fastLearner}
              />
            </View>
          </View>

          <View style={{ height: 20 }} />

          <Subtitle2>
            Wow! You’re a fast learner!
          </Subtitle2>

          <View style={{ height: 21 }} />

          <Body1>
            It looks like you are finished with this module and have some time left:
          </Body1>

          <View style={{ height: 21 }} />

          <Body2>
            {Util.timeFormatted(remainingRequiredTime, true, 'timer')}
          </Body2>

          <View style={{ height: 21 }} />

          <Body1>
            Let’s take some time to review
          </Body1>

        </Column>
      </Row>

      {Platform.OS === 'web' && <View style={{ height: 36 }} />}

    </View>
  );
};


FastLearnerMessage.propTypes = { colorType: PropTypes.string };

FastLearnerMessage.defaultProps = { colorType: 'main' };

export default withDimensions(inject('rootStore')(observer(FastLearnerMessage)));
