import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Image, View } from 'react-native';
import { FullViewSpinner } from '@nextstep/app/components/SharedUI';
import withDimensions, { DeviceType } from '@nextstep/app/lib/withDimensions';
import { Column, Row } from '@nextstep/app/lib/ResponsiveLayout';
import { Images, Palette } from '@nextstep/app/Themes';
import AppMainHeader from '@nextstep/app/components/AppMainHeader';
import IconCard from '@nextstep/app/components/IconCard';
import ViewWrapper from '@nextstep/app/components/ViewWrapper';
import { Body1, Body2, H3 } from '@nextstep/app/components/Text';
import NavigationService from '@nextstep/app/services/NavigationService';
import AdaptiveContainerStyles from '@nextstep/app/containers/styles/AdaptiveContainerStyles';

const currentPalette = Palette.light;

class AssignmentsScreen extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true };
  }

  async componentDidMount() {
    const { navigation, rootStore: { contentStore, progressStore } } = this.props;
    await contentStore.loadAssignmentQuestions();
    await progressStore.loadAssignmentQuestionProgresses();

    this.setState({ loading: false });

    this.focusListener = navigation.addListener('focus', () => {
      progressStore.loadAssignmentQuestionProgresses();
    });
  }

  componentWillUnmount() {
    if (!this.focusListener) return;
    this.focusListener();
  }

  goToQuestion = async (question) => {
    const { rootStore: { contentStore, progressStore } } = this.props;
    await contentStore.setAssignmentQuestion(question.id);
    await progressStore.setAssignmentQuestionProgress(question.id);
    NavigationService.navigate('Question');
  }

  getIcon = (questionProgress) => {
    if (questionProgress.isApproved) return Images.assignmentQuestion.completed;
    if (questionProgress.isWaitingForApproval) return Images.assignmentQuestion.inProgress;
    if (questionProgress.needsResubmission) return Images.assignmentQuestion.failed;
    return Images.assignmentQuestion.unlocked;
  }

  getDescription = (questionProgress) => {
    if (questionProgress.isUnlocked) return 'Answer the corresponding question.';
    if (questionProgress.isApproved) return 'Great job, you’re all set!';
    if (questionProgress.isWaitingForApproval) return 'Your response is in review.';
    if (questionProgress.needsResubmission) return 'You’ll need to resubmit in order to receive credit.';
    return 'Make progress in the course to unlock this question.';
  }


  render() {
    const { dimensions, navigation, rootStore: { contentStore, progressStore } } = this.props;
    const { loading } = this.state;

    if (loading) return <FullViewSpinner showBackground />;

    const { assignmentQuestions } = contentStore;
    const { assignmentQuestionProgresses } = progressStore;

    const GlobalStyles = AdaptiveContainerStyles();

    return (
      <>
        {dimensions.deviceType !== DeviceType.Desktop && <AppMainHeader navigation={navigation} />}
        <ViewWrapper
          smSize={12}
          mdSize={12}
          lgSize={12}
          hideBorder
          backgroundColor={currentPalette.background.main}
          bottomBarColor={currentPalette.background.main}
        >
          <View
            style={[
              dimensions.deviceType === DeviceType.Mobile && { paddingTop: 20 },
              { backgroundColor: currentPalette.background.main },
            ]}
          >
            <Row>
              <Column smSize={11} mdSize={11} lgSize={11}>
                <View style={[GlobalStyles.card, { padding: 32 }]}>
                  <View style={{ flexDirection: 'row', width: '100%', alignItems: 'center' }}>
                    <Image
                      style={{ width: 60, height: 60, marginRight: 10 }}
                      source={Images.icons.main.forumIcon}
                    />
                    <H3 textAlign={'left'}>
                      Discussion Board
                    </H3>
                  </View>

                  <View style={{ height: 30 }} />

                  <Body1 textAlign={'left'}>
                    In the Discussion Board section, you’ll receive questions from expert NextStep
                    nursing instructors that will guide you to think more deeply about the skills
                    you’ve covered in the app.
                    {'\n\n'}
                    As you progress through the skill sets, you’ll unlock the Discussion Board
                    questions. The NextStep nursing instructors will review your responses for credit.
                    {' '}
                    <Body2>
                      You must complete all of the Discussion Board questions at least two days
                      before your scheduled practice labs.
                    </Body2>
                  </Body1>

                  <View style={{ height: 30 }} />
                  <Row gutter={27} style={{ justifyContent: 'flex-start' }}>
                    {assignmentQuestions.map((question, index) => {
                      const questionProgress = assignmentQuestionProgresses.find(aqp => aqp.assignmentQuestionId === question.id);
                      return (
                        <Column key={index} smSize={12} mdSize={6} lgSize={4}>
                          <IconCard
                            enableIcon={questionProgress ? this.getIcon(questionProgress) : undefined}
                            enableColor={currentPalette.background.main}
                            disabledIcon={Images.assignmentQuestion.locked}
                            disabledColor={currentPalette.background.main}
                            disabled={questionProgress ? questionProgress?.isLocked : true}
                            title={question.title}
                            description={questionProgress ? this.getDescription(questionProgress) : undefined}
                            onPress={() => this.goToQuestion(question)}
                          />
                        </Column>
                      );
                    })}
                  </Row>
                </View>
              </Column>
            </Row>
          </View>
        </ViewWrapper>
      </>
    );
  }
}


export default withDimensions(inject('rootStore')(observer(AssignmentsScreen)));
