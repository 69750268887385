import React from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';

export default class LottieView extends React.Component {
  async componentDidMount() {
    const {
      options,
      eventListeners,
      autoplay,
      source,
      loop,
    } = this.props;

    this.options = {
      container: this.el,
      renderer: 'svg',
      loop: loop !== false,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
      autoplay,
      animationData: source,
    };

    this.options = {
      ...this.options, ...options,
    };

    const { default: lottie } = await import('lottie-web');
    this.anim = lottie.loadAnimation(this.options);
    this.registerEvents(eventListeners);
  }


  componentDidUpdate() {
    const { segments, isStopped } = this.props;

    if (isStopped) {
      this.stop();
    } else if (segments) {
      this.playSegments();
    } else {
      this.play();
    }

    this.pause();
    this.setSpeed();
    this.setDirection();
  }

  componentWillUnmount() {
    const { eventListeners } = this.props;
    this.deRegisterEvents(eventListeners);
    this.destroy();
    this.options.animationData = null;
    this.anim = null;
  }

  setSpeed() {
    const { speed } = this.props;
    this.anim.setSpeed(speed);
  }

  setDirection() {
    const { direction } = this.props;
    this.anim.setDirection(direction);
  }

  play() {
    this.anim.play();
  }

  playSegments() {
    const { segments } = this.props;
    this.anim.playSegments(segments);
  }

  stop() {
    this.anim.stop();
  }

  pause() {
    const { isPaused } = this.props;
    if (isPaused && !this.anim.isPaused) {
      this.anim.pause();
    } else if (!isPaused && this.anim.isPaused) {
      this.anim.pause();
    }
  }

  destroy() {
    this.anim.destroy();
  }

  registerEvents(eventListeners) {
    eventListeners.forEach((eventListener) => {
      this.anim.addEventListener(eventListener.eventName, eventListener.callback);
    });
  }

  deRegisterEvents(eventListeners) {
    eventListeners.forEach((eventListener) => {
      this.anim.removeEventListener(eventListener.eventName, eventListener.callback);
    });
  }

  handleClickToPause = () => {
    if (this.anim.isPaused) {
      this.anim.play();
    } else {
      this.anim.pause();
    }
  }

  render() {
    const {
      width,
      height,
      ariaRole,
      ariaLabel,
      isClickToPauseDisabled,
      title,
    } = this.props;

    const getSize = (initial) => {
      let size;

      if (typeof initial === 'number') {
        size = `${initial}px`;
      } else {
        size = initial || '100%';
      }

      return size;
    };

    const { style } = this.props;
    const lottieStyles = {
      width: getSize(width),
      height: getSize(height),
      overflow: 'hidden',
      margin: '0 auto',
      outline: 'none',
      ...style,
    };

    const onClickHandler = isClickToPauseDisabled ? () => null : this.handleClickToPause;

    return (
      <View
        ref={(c) => {
          this.el = c;
        }}
        style={lottieStyles}
        onClick={onClickHandler}
        title={title}
        role={ariaRole}
        aria-label={ariaLabel}
        tabIndex="0"
      />
    );
  }
}

LottieView.propTypes = {
  eventListeners: PropTypes.arrayOf(PropTypes.object),
  options: PropTypes.object,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isStopped: PropTypes.bool,
  isPaused: PropTypes.bool,
  speed: PropTypes.number,
  segments: PropTypes.arrayOf(PropTypes.number),
  direction: PropTypes.number,
  ariaRole: PropTypes.string,
  ariaLabel: PropTypes.string,
  isClickToPauseDisabled: PropTypes.bool,
  title: PropTypes.string,
  source: PropTypes.object.isRequired,
  autoplay: PropTypes.bool,
  loop: PropTypes.bool,
};

LottieView.defaultProps = {
  eventListeners: [],
  isStopped: false,
  isPaused: false,
  speed: 1,
  direction: 1,
  segments: [],
  ariaRole: 'button',
  ariaLabel: 'animation',
  isClickToPauseDisabled: false,
  title: '',
  autoplay: true,
  loop: true,
  height: 200,
  width: 200,
  options: {},
};
