import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import { inject, observer } from 'mobx-react';
import LoadingScreen from '@nextstep/app/containers/LoadingScreen';
import LaunchScreen from '@nextstep/app/containers/LaunchScreen';
import AccessScreen from '@nextstep/app/containers/appAccess/AccessScreen';
import StateSelectionScreen from '@nextstep/app/containers/appAccess/StateSelectionScreen';
import AccessVerificationCodeScreen from '@nextstep/app/containers/appAccess/AccessVerificationCodeScreen';
import IntroVideoScreen from '@nextstep/app/containers/skill/IntroVideoScreen';
import SuccessCriteriaScreen from '@nextstep/app/containers/skill/SuccessCriteriaScreen';
import DemoVideoScreen from '@nextstep/app/containers/skill/DemoVideosScreen';
import DisabledAccountScreen from '@nextstep/app/containers/userRestriction/DisableAccountScreen';
import DisEnrolledAccountScreen from '@nextstep/app/containers/userRestriction/DisEnrolledAccountScreen';
import IneligibleAccountScreen from '@nextstep/app/containers/userRestriction/IneligibleAccountScreen';
import NoPaymentAccountScreen from '@nextstep/app/containers/userRestriction/NoPaymentAccountScreen';
import MarkdownPlayground from '@nextstep/app/components/MarkdownPlayground';
import AssessmentBlocksScreen from '@nextstep/app/containers/skill/AssessmentBlocksScreen';
import LinearAssessmentScreen from '@nextstep/app/containers/skill/LinearAssessmentScreen';
import LearnerTaskQuestionnaire from '@nextstep/app/containers/skill/LearnerTaskQuestionnaire';
import CourseDetailsScreen from '@nextstep/app/containers/CourseDetailsScreen';
import DetailedCourseOutlineScreen from '@nextstep/app/containers/DetailedCourseOutlineScreen';
import TexasResourcesScreen from '@nextstep/app/containers/TexasResourcesScreen';
import SettingsScreen from '@nextstep/app/containers/SettingsScreen';
import InPersonScreen from '@nextstep/app/containers/InPersonScreen';
import PracticeLabAssetScreen from '@nextstep/app/containers/PracticeLabAssetScreen';
import PracticeLabDetailsScreen from '@nextstep/app/containers/PracticeLabDetailsScreen';
import OutcomeScreen from '@nextstep/app/containers/skill/OutcomeScreen';
import EnrollmentAgreement from '@nextstep/app/containers/userRestriction/EnrollmentAgreement';
import OKEnrollmentAgreement from '@nextstep/app/containers/userRestriction/OKEnrollmentAgreement';
import UpskillEnrollmentAgreement from '@nextstep/app/containers/userRestriction/UpskillEnrollmentAgreement';
import JobPlacementConfirmationScreen from '@nextstep/app/containers/JobPlacementConfirmationScreen';
import withDimensions, { DeviceType } from '@nextstep/app/lib/withDimensions';
import NavigationService from '@nextstep/app/services/NavigationService';
import { analyticsScreenView } from '@nextstep/app/services/Analytics';
import CovidProof from '@nextstep/app/containers/userRestriction/CovidProof';
import VaccineBenefits from '@nextstep/app/containers/userRestriction/VaccineBenefits';
import CovidProofUploadComplete from '@nextstep/app/containers/userRestriction/CovidProofUploadComplete';
import { SlideNavigator, DrawerNavigator } from './MainNavigator';

const Stack = createStackNavigator();

const AuthNavigation = ({ rootStore, dimensions }) => {
  const { learner } = rootStore.sessionStore || {};
  let Nav;
  if (dimensions.deviceType === DeviceType.Desktop) {
    Nav = SlideNavigator;
  } else {
    Nav = DrawerNavigator;
  }

  const path = NavigationService.getActivePath();
  let currentAppScreen;
  if (path[path.length - 2] === 'App') {
    currentAppScreen = path[path.length - 1];
  }

  return (
    <Stack.Navigator
      initialRouteName="LoadingScreen"
      screenOptions={{ headerShown: false, gestureEnabled: false, animationEnabled: false }}
    >
      <Stack.Screen
        name="LoadingScreen"
        component={LoadingScreen}
      />
      <Stack.Screen
        name="EnrollmentAgreement"
        component={EnrollmentAgreement}
        listeners={{ focus: () => analyticsScreenView('EnrollmentAgreementViewed') }}
      />
      <Stack.Screen
        name="UpskillEnrollmentAgreement"
        component={UpskillEnrollmentAgreement}
        listeners={{ focus: () => analyticsScreenView('UpskillEnrollmentAgreementViewed') }}
      />
      <Stack.Screen
        name="OKEnrollmentAgreement"
        component={OKEnrollmentAgreement}
        listeners={{ focus: () => analyticsScreenView('OKEnrollmentAgreementViewed') }}
      />
      <Stack.Screen
        name="MarkdownPlayground"
        component={MarkdownPlayground}
      />
      <Stack.Screen
        name="StateSelectionScreen"
        component={StateSelectionScreen}
        listeners={{ focus: () => analyticsScreenView('StateSelectionViewed') }}
      />
      <Stack.Screen
        name="LaunchScreen"
        component={LaunchScreen}
        listeners={{ focus: () => analyticsScreenView('landingScreenViewed') }}
      />
      <Stack.Screen
        name="AccessVerificationCodeScreen"
        component={AccessVerificationCodeScreen}
        listeners={{ focus: () => analyticsScreenView('verificationCodeViewed') }}
      />
      <Stack.Screen
        name="AccessScreen"
        component={AccessScreen}
        listeners={{ focus: () => analyticsScreenView('accessScreenViewed') }}
      />
      <Stack.Screen
        name="DisabledAccountScreen"
        component={DisabledAccountScreen}
        listeners={{ focus: () => analyticsScreenView('DisabledAccountScreen') }}
      />
      <Stack.Screen
        name="DisEnrolledAccountScreen"
        component={DisEnrolledAccountScreen}
        listeners={{ focus: () => analyticsScreenView('DisEnrolledAccountScreen') }}
      />
      <Stack.Screen
        name="IneligibleAccountScreen"
        component={IneligibleAccountScreen}
        listeners={{ focus: () => analyticsScreenView('IneligibleAccountScreen') }}
      />
      <Stack.Screen
        name="NoPaymentAccountScreen"
        component={NoPaymentAccountScreen}
        listeners={{ focus: () => analyticsScreenView('NoPaymentAccountScreen') }}
      />
      <Stack.Screen
        name="CovidProof"
        component={CovidProof}
        listeners={{ focus: () => analyticsScreenView('CovidProofScreen') }}
      />
      <Stack.Screen
        name="VaccineBenefits"
        component={VaccineBenefits}
        listeners={{ focus: () => analyticsScreenView('VaccineBenefitsScreen') }}
      />
      <Stack.Screen
        name="CovidProofUploadComplete"
        component={CovidProofUploadComplete}
        listeners={{ focus: () => analyticsScreenView('CovidProofUploadCompleteScreen') }}
      />

      { learner && (
        <>
          <Stack.Screen
            name="SettingsScreen"
            component={SettingsScreen}
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-console
            listeners={{ transitionEnd: () => console.tron.log('SettingsScreen') }}
          />
          <Stack.Screen
            name="InPersonScreen"
            component={InPersonScreen}
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-console
            listeners={{ transitionEnd: () => console.tron.log('InPersonScreen') }}
          />
          <Stack.Screen
            name="PracticeLabDetailsScreen"
            component={PracticeLabDetailsScreen}
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-console
            listeners={{ transitionEnd: () => console.tron.log('PracticeLabDetailsScreen') }}
          />
          <Stack.Screen
            name="CourseDetails"
            component={CourseDetailsScreen}
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-console
            listeners={{ transitionEnd: () => console.tron.log('CourseDetails') }}
          />
          <Stack.Screen
            name="DetailedCourseOutlineScreen"
            component={DetailedCourseOutlineScreen}
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-console
            listeners={{ transitionEnd: () => console.tron.log('DetailedCourseOutlineScreen') }}
          />
          <Stack.Screen
            name="TexasResourcesScreen"
            component={TexasResourcesScreen}
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-console
            listeners={{ transitionEnd: () => console.tron.log('TexasResourcesScreen') }}
          />
          <Stack.Screen
            name="IntroVideo"
            component={IntroVideoScreen}
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-console
            listeners={{ transitionEnd: () => console.tron.log('IntroVideo') }}
          />
          <Stack.Screen
            name="SuccessCriteria"
            component={SuccessCriteriaScreen}
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-console
            listeners={{ transitionEnd: () => console.tron.log('SuccessCriteria') }}
          />
          <Stack.Screen
            name="DemoVideos"
            component={DemoVideoScreen}
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-console
            listeners={{ transitionEnd: () => console.tron.log('DemoVideos') }}
          />
          <Stack.Screen
            name="LinearAssessment"
            component={LinearAssessmentScreen}
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-console
            listeners={{ transitionEnd: () => console.tron.log('LinearAssessment') }}
          />
          <Stack.Screen
            name="AssessmentBlocks"
            component={AssessmentBlocksScreen}
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-console
            listeners={{ transitionEnd: () => console.tron.log('AssessmentBlocks') }}
          />
          <Stack.Screen
            name="LearnerTaskQuestionnaire"
            component={LearnerTaskQuestionnaire}
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-console
            listeners={{ transitionEnd: () => console.tron.log('LearnerTaskQuestionnaire') }}
          />
          <Stack.Screen
            name="PracticeLabAssetScreen"
            component={PracticeLabAssetScreen}
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-console
            listeners={{ transitionEnd: () => console.tron.log('PracticeLabAssetScreen') }}
          />
          <Stack.Screen
            name="OutcomeScreen"
            component={OutcomeScreen}
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-console
            listeners={{ transitionEnd: () => console.tron.log('OutcomeScreen') }}
          />
          <Stack.Screen
            name="JobPlacementConfirmationScreen"
            component={JobPlacementConfirmationScreen}
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-console
            listeners={{ transitionEnd: () => console.tron.log('JobPlacementConfirmationScreen') }}
          />
          <Stack.Screen
            name="App"
            // TODO: Fix this the next time the file is edited.
            // eslint-disable-next-line no-console
            listeners={{ transitionEnd: () => console.tron.log('App') }}
          >
            {(props) => (
              <Nav initialRoute={currentAppScreen} {...props} />
            )}
          </Stack.Screen>
        </>
      )}
    </Stack.Navigator>
  );
};


export default withDimensions(inject('rootStore')(observer(AuthNavigation)));
