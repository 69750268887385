import React, { Component } from 'react';
import { View, Linking, Platform, Keyboard } from 'react-native';
import RNRestart from 'react-native-restart';
import { ErrorCodes, Events, ErrorActions } from '@nextstep/app/Config/Constants';
import { Body1, Subtitle2 } from '@nextstep/app/components/Text';
import { ButtonFilled } from '@nextstep/app/components/Button';
import NavigationService from '@nextstep/app/services/NavigationService';
import MentorHeader from '@nextstep/app/components/MentorHeader';
import ViewWrapper from '@nextstep/app/components/ViewWrapper';
import AdaptiveContainerStyles from '@nextstep/app/containers/styles/AdaptiveContainerStyles';
import { FullViewSpinner } from '@nextstep/app/components/SharedUI';
import Divider from '@nextstep/app/components/Divider';
import { Palette } from '@nextstep/app/Themes';
import { Column, Row } from '@nextstep/app/lib/ResponsiveLayout';
import Styles from './styles/ErrorScreenStyles';

const currentPalette = Palette.light;

class ErrorScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      subtitle: '',
      btnTitle: '',
      visible: props.visible || false,
      action: '',
      headerText: '',
      loading: false,
    };
    this.navigateEvent = Events.navigateEvent;
    this.showErrorEvent = Events.showErrorEvent;
    this.show = this.show.bind(this);
    this.hide = this.hide.bind(this);
    this.onPress = this.onPress.bind(this);
  }

  static restart() {
    RNRestart.Restart();
  }

  retry() {
    global.emitter.emit(this.navigateEvent, { reset: true });
    this.hide();
  }

  reset() {
    this.setState({ loading: true });
    global.rootStore.sessionStore.resolveConflict().then(() => {
      this.hide();
      NavigationService.reset('Home');
    });
  }

  hide() {
    this.setState({ visible: false, loading: false });
  }

  static updateApp() {
    const LINK_IOS_STORE = 'itms-apps://itunes.apple.com/us/app/apple-store/id1462188060?mt=8';
    const LINK_ANDROID_STORE = 'market://details?id=com.nextstep.careers.app';

    const url = Platform.OS === 'ios' ? LINK_IOS_STORE : LINK_ANDROID_STORE;
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line no-console
    Linking.canOpenURL(url).then(supported => supported && Linking.openURL(url), (err) => console.error(err));
  }

  componentDidMount() {
    global.emitter.on(this.showErrorEvent, this.show);
  }

  componentWillUnmount() {
    global.emitter.off(this.showErrorEvent);
  }

  // TODO: This is getting complex quickly. We should refactor this to something more
  //   sustainable. Maybe raising/catching specific exceptions?
  show(opts) {
    const { errorCode, action, message } = opts;

    let title = (typeof message === 'string') ? message : 'Something Went Wrong.';
    let subtitle = 'We are working on getting this fixed as soon as we can.';
    let btnTitle = '';
    let headerText = 'Oops! \n An error occurred.';

    switch (action) {
      case ErrorActions.restart:
        btnTitle = 'Restart App';
        break;
      case ErrorActions.retry:
        btnTitle = 'Retry';
        break;
      case ErrorActions.updateApp:
        btnTitle = Platform.OS === 'ios' ? 'Go to App Store' : 'Go to Play Store';
        headerText = 'Oops! We need you to \n update your app.';
        break;
      case ErrorActions.conflict:
        btnTitle = 'Sync Progress';
        headerText = 'Pardon the interruption.';
        break;
      default:
    }

    switch (errorCode) {
      case ErrorCodes.networkError:
      case ErrorCodes.timeoutError:
      case ErrorCodes.connectionError:
        title = 'Network error. We were unable to load your content.';
        subtitle = 'Please check your internet connection and try again.';
        break;
      // Server 500 error
      case ErrorCodes.serverError:
        subtitle = `${subtitle}  You may be able to try again.`;
        break;
      case ErrorCodes.fatalError:
        subtitle = `${subtitle}  Please restart the app.`;
        break;
      case ErrorCodes.versionError:
        title = 'You are currently using an older version of the app';
        subtitle = 'Please update to the latest version of the NextStep app to continue learning!';
        break;
      case ErrorCodes.conflictError:
        // Navigate to the home screen
        NavigationService.navigate('App', { screen: 'Home' });
        title = 'We need to sync your progress';
        subtitle = 'It looks like your device is out of sync. Let\'s make sure your progress is synchronized.';
        break;
      default:
    }

    this.setState({
      visible: true,
      action,
      title,
      subtitle,
      btnTitle,
      headerText,
    });
  }

  onPress() {
    const { action } = this.state;
    switch (action) {
      case ErrorActions.restart:
        ErrorScreen.restart();
        break;
      case ErrorActions.retry:
        this.retry();
        break;
      case ErrorActions.conflict:
        this.reset();
        break;
      case ErrorActions.updateApp:
        ErrorScreen.updateApp();
        this.retry();
        break;
      default:
        throw new Error(`Action ${action} not available`);
    }
  }

  render() {
    const { title, subtitle, btnTitle, visible, headerText, loading } = this.state;

    Keyboard.dismiss();

    if (!visible) {
      return null;
    }

    const GlobalStyles = AdaptiveContainerStyles();

    return (
      <View style={{ position: 'absolute', width: '100%', height: '100%' }}>
        <ViewWrapper smSize={12} mdSize={11} lgSize={6} topNavBarColor={currentPalette.primary.main}>
          <MentorHeader
            text={headerText}
            mentorStyle={'oops'}
          />

          {Platform.OS !== 'web' && <View style={{ flex: 1 }} />}

          <View style={[Styles.section, GlobalStyles.textContainer]}>
            <Row>
              <Column
                smSize={12}
                mdSize={7}
                lgSize={6}
                style={Platform.OS === 'web' && { paddingVertical: '5%' }}
              >

                <Subtitle2>
                  {title}
                </Subtitle2>

                <View style={{ height: 25 }} />

                <Body1>
                  {subtitle}
                </Body1>

                <View style={{ height: 25 }} />

              </Column>
            </Row>
          </View>

          {Platform.OS === 'web' ? <Divider /> : <View style={{ flex: 1 }} />}

          <Row style={[Styles.section, GlobalStyles.textContainer, Styles.actionButtons]}>
            <Column smSize={10} mdSize={7} lgSize={7} style={{ alignItems: 'center' }}>

              {Platform.OS === 'web' && <View style={{ height: 36 }} />}

              { loading && <FullViewSpinner /> }
              {!loading && (
                <ButtonFilled
                  width="100%"
                  onPress={() => this.onPress()}
                >
                  {btnTitle}
                </ButtonFilled>
              )}

              {Platform.OS === 'web' && <View style={{ height: 36 }} />}

            </Column>
          </Row>

        </ViewWrapper>
      </View>
    );
  }
}

export default ErrorScreen;
