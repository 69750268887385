import React from 'react';
import { Text, Linking } from 'react-native';
import { Images, Palette } from '@nextstep/app/Themes';
import { Body1 } from '@nextstep/app/components/Text';
import { ButtonText } from '@nextstep/app/components/Button';

const currentPalette = Palette.light;

const clinicalPrerequisites = (state) => {
  if (state === 'OR') {
    return ({
      header: {
        title: 'Pre-Clinical Requirements',
        body: (
          <Body1 textAlign={'left'}>
            These requirements must be submitted and approved by an RN Instructor before you begin practice labs and your CNA job. First, gather any records that show you&apos;ve already completed one or more of the requirements. Then upload your required documents by following the link below:
          </Body1>
        ),
      },
      body: [
        {
          title: 'Background Check',
          enableIcon: Images.inPersonTab.backgroundCheck,
          description: 'All learners are required to complete a background check in order to proceed in the NextStep Nursing Assistant program.',
        },
        {
          title: 'Basic Life Support for Health Care Providers',
          enableIcon: Images.inPersonTab.bls,
          description: 'Nursing Assistants are required to take a specific type of CPR class - basic life support (BLS) for health care providers. This class must be provided by an American Heart Association Training Center.',
          // description: <Text onPress={() => {}}><Body1 textAlign={'left'} color={currentPalette.primary.main}>Schedule CPR Certification CLass</Body1></Text>,
        },
        {
          title: 'Two-Step TB Skin Test',
          description: 'This test requires four appointments and should be completed one to four weeks after enrollment.',
          enableIcon: Images.inPersonTab.skin,
        },
        {
          title: 'Flu Vaccine (Influenza)',
          enableIcon: Images.inPersonTab.needle,
          description: 'Influenza vaccines, also known as flu shots, are vaccines that protect against infection by influenza viruses.',
        },
        {
          title: 'COVID Vaccine',
          enableIcon: Images.inPersonTab.needle,
          description: 'COVID vaccinations are required. You must be fully vaccinated with both doses (or 1 if applicable) a minimum of 2 weeks prior to attending clinical.',
        },
        {
          title: 'Hepatitis B Vaccine',
          enableIcon: Images.inPersonTab.needle,
          description: 'This vaccine prevents hepatitis B. The first dose is recommended within 24 hours of birth with either two or three more doses given after that.',
        },
        {
          title: 'MMR Vaccine',
          enableIcon: Images.inPersonTab.needle,
          description: 'This vaccine protects against measles, mumps and rubella.',
        },
        {
          title: 'Varicella (Chickenpox)',
          enableIcon: Images.inPersonTab.needle,
          description: 'Also known as chickenpox vaccine, this vaccine protects against the chickenpox virus.',
        },
        {
          title: 'Tdap (Tetanus, Diphtheria, Pertussis)',
          enableIcon: Images.inPersonTab.needle,
          description: 'This vaccine protects against tetanus, diphtheria and pertussis.',
        },
        {
          title: 'ID',
          enableIcon: Images.inPersonTab.id,
          description: 'A government-issued ID is required to verify your identity and is necessary for you to take your state certification exam. A drivers license, state-issued ID card or passport are some examples.',
        },
        {
          title: 'Patient Directed Care',
          enableIcon: Images.inPersonTab.id,
          description: (
            <>
              <Text><Body1 textAlign={'left'}>All Oregon learners are expected to complete the patient directed care module as part of their training.</Body1></Text>
              <Text><ButtonText textAlign={'left'} color={currentPalette.primary.main} onPress={() => { Linking.openURL('http://orculturechange.org/resources/online-training-program/'); }}>Access Module Here</ButtonText></Text>
            </>
          ),
        },
      ],
    });
  }

  if (state === 'WA') {
    return ({
      header: {
        title: 'Clinical Prerequisites',
        body: (
          <Body1 textAlign={'left'}>
            These requirements must be submitted and approved by an RN Instructor before you begin practice labs and your CNA job. First, gather any records that show you&apos;ve already completed one or more of the requirements. Then upload your required documents by following the link below:
          </Body1>
        ),
      },
      body: [
        {
          title: 'Background Check',
          enableIcon: Images.inPersonTab.backgroundCheck,
          description: 'All learners are required to complete a background check in order to proceed in the NextStep Nursing Assistant program.',
        },
        {
          title: 'Basic Life Support for Health Care Providers',
          enableIcon: Images.inPersonTab.bls,
          description: 'Nursing Assistants are required to take a specific type of CPR class - basic life support (BLS) for health care providers. This class must be provided by an American Heart Association Training Center.',
          // description: <Text onPress={() => {}}><Body1 textAlign={'left'} color={currentPalette.primary.main}>Schedule CPR Certification CLass</Body1></Text>,
        },
        {
          title: 'Two-Step TB Skin Test',
          description: 'This test requires four appointments and should be completed one to four weeks after enrollment.',
          enableIcon: Images.inPersonTab.skin,
        },
        {
          title: 'Flu Vaccine (Influenza)',
          enableIcon: Images.inPersonTab.needle,
          description: 'Influenza vaccines, also known as flu shots or flu jabs, are vaccines that protect against infection by influenza viruses.',
        },
        {
          title: 'COVID Vaccine',
          enableIcon: Images.inPersonTab.needle,
          description: 'COVID vaccinations are required. You must be fully vaccinated with both doses (or 1 if applicable) a minimum of 2 weeks prior to attending clinical.',
        },
        {
          title: 'Hepatitis B Vaccine',
          enableIcon: Images.inPersonTab.needle,
          description: 'This vaccine prevents hepatitis B. The first dose is recommended within 24 hours of birth with either two or three more doses given after that.',
        },
        {
          title: 'MMR Vaccine',
          enableIcon: Images.inPersonTab.needle,
          description: 'This vaccine protects against measles, mumps and rubella.',
        },
        {
          title: 'Varicella (Chickenpox)',
          enableIcon: Images.inPersonTab.needle,
          description: 'Also known as chickenpox vaccine, this vaccine protects against the chickenpox virus.',
        },
        {
          title: 'Tdap (Tetanus, Diphtheria, Pertussis)',
          enableIcon: Images.inPersonTab.needle,
          description: 'This vaccine protects against tetanus, diphtheria and pertussis.',
        },
        {
          title: 'Physical Exam',
          enableIcon: Images.inPersonTab.bls,
          description: 'A physical exam checks to make sure you can physically perform all the job duties of a CNA.',
        },
        {
          title: 'ID',
          enableIcon: Images.inPersonTab.id,
          description: 'A government-issued ID is required to verify your identity and is necessary for you to take your state certification exam. A drivers license, state-issued ID card or passport are some examples.',
        },
      ],
    });
  }

  // CO Default
  return ({
    header: {
      title: 'Clinical Prerequisites',
      body: (
        <Body1 textAlign={'left'}>
          These requirements must be submitted and approved by an RN Instructor before you begin practice labs and your CNA job. First, gather any records that show you&apos;ve already completed one or more of the requirements. Then upload your required documents by following the link below:
        </Body1>
      ),
    },
    body: [
      {
        title: 'Background Check',
        enableIcon: Images.inPersonTab.backgroundCheck,
        description: 'All learners are required to complete a background check in order to proceed in the NextStep Nursing Assistant program.',
      },
      {
        title: 'Basic Life Support for Health Care Providers',
        enableIcon: Images.inPersonTab.bls,
        description: 'Nursing Assistants are required to take a specific type of CPR class - basic life support (BLS) for health care providers. This class must be provided by an American Heart Association Training Center.',
        // description: <Text onPress={() => {}}><Body1 textAlign={'left'} color={currentPalette.primary.main}>Schedule CPR Certification CLass</Body1></Text>,
      },
      {
        title: 'Two-Step TB Skin Test',
        description: 'This test requires four appointments and should be completed one to four weeks after enrollment.',
        enableIcon: Images.inPersonTab.skin,
      },
      {
        title: 'Flu Vaccine (Influenza)',
        enableIcon: Images.inPersonTab.needle,
        description: 'Influenza vaccines, also known as flu shots or flu jabs, are vaccines that protect against infection by influenza viruses.',
      },
      {
        title: 'COVID Vaccine',
        enableIcon: Images.inPersonTab.needle,
        description: 'COVID vaccinations are required. You must be fully vaccinated with both doses (or 1 if applicable) a minimum of 2 weeks prior to attending clinical.',
      },
      {
        title: 'Hepatitis B Vaccine',
        enableIcon: Images.inPersonTab.needle,
        description: 'This vaccine prevents hepatitis B. The first dose is recommended within 24 hours of birth with either two or three more doses given after that.',
      },
      {
        title: 'MMR Vaccine',
        enableIcon: Images.inPersonTab.needle,
        description: 'This vaccine protects against measles, mumps and rubella.',
      },
      {
        title: 'Varicella (Chickenpox)',
        enableIcon: Images.inPersonTab.needle,
        description: 'Also known as chickenpox vaccine, this vaccine protects against the chickenpox virus.',
      },
      {
        title: 'Tdap (Tetanus, Diphtheria, Pertussis)',
        enableIcon: Images.inPersonTab.needle,
        description: 'This vaccine protects against tetanus, diphtheria and pertussis.',
      },
      {
        title: 'ID',
        enableIcon: Images.inPersonTab.id,
        description: 'A government-issued ID is required to verify your identity and is necessary for you to take your state certification exam. A drivers license, state-issued ID card or passport are some examples.',
      },
    ],
  });
};

const practiceLabData = (state) => {
  if (state === 'OR') {
    return ({
      header: {
        title: 'In-Person Practice Labs',
        body: (
          <Body1 textAlign={'left'}>
            It’s time to put your new CNA skills into action! Practice labs are your chance to practice what you learned in the app with the support of a instructor and your fellow learners.
            {'\n\n'}
            Preparation is key to practice lab success! Make sure you’ve finished all required skills in the app prior to your scheduled lab date and have reviewed the skills you’ll be practicing in lab. Click a card below to start reviewing!
          </Body1>
        ),
      },
      links: [
        {
          iconName: 'description',
          title: 'Practice Lab Code of Conduct',
          url: 'https://s3.amazonaws.com/nextstep.careers/Practice+Lab+%26Clinical_CodeOfConduct.pdf',
        },
      ],
      body: [
        {
          key: '1-mahoney',
          shortDescription: 'Basic Care skill set required to attend',
        },
        {
          key: '2-seacole',
          shortDescription: 'Personal Care skill set required to attend',
        },
        {
          key: '3-taylor',
          shortDescription: 'Special Procedures skill set required to attend',
        },
        {
          key: '4-staupers',
          shortDescription: 'All skill sets completed required to attend',
        },
      ],
    });
  }

  if (state === 'WA') {
    return ({
      header: {
        title: 'In-Person Practice Labs',
        body: (
          <Body1 textAlign={'left'}>
            It’s time to put your new CNA skills into action! Practice labs are your chance to practice what you learned in the app with the support of a nurse instructor and your fellow learners.
            {'\n\n'}
            Preparation is key to practice lab success! Make sure you’ve finished all required skills in the app prior to your scheduled lab date and have reviewed the skills you’ll be practicing in lab. Click a card below to start reviewing!
          </Body1>
        ),
      },
      links: [
        {
          iconName: 'description',
          title: 'Practice Lab Code of Conduct',
          url: 'https://s3.amazonaws.com/nextstep.careers/Practice+Lab+%26Clinical_CodeOfConduct.pdf',
        },
      ],
      body: [
        {
          key: '1-mahoney',
          shortDescription: 'Reposition and Move skill set required to attend',
        },
        {
          key: '2-seacole',
          shortDescription: 'Basic Care skill set required to attend',
        },
        {
          key: '3-taylor',
          shortDescription: 'Personal Care skill set required to attend',
        },
        {
          key: '4-staupers',
          shortDescription: 'Restorative Care skill set required to attend',
        },
        {
          key: '5-barton',
          shortDescription: 'Special Procedures skill set required to attend',
        },
        {
          key: '6-nightingale',
          shortDescription: 'All skill sets completed',
        },
      ],
    });
  }

  // CO Default
  return ({
    header: {
      title: 'In-Person Practice Labs',
      body: (
        <Body1 textAlign={'left'}>
          It’s time to put your new CNA skills into action! Practice labs are your chance to practice
          what you learned in the app with the support of a nurse instructor and your fellow
          learners.
          {'\n\n'}
          Preparation is key to practice lab success! Make sure you’ve finished all required skills
          in the app prior to your scheduled lab date and have reviewed the skills you’ll be practicing
          in lab. Click a card below to start reviewing!
        </Body1>
      ),
    },
    links: [
      {
        iconName: 'description',
        title: 'Practice Lab Code of Conduct',
        url: 'https://s3.amazonaws.com/nextstep.careers/Practice+Lab+%26Clinical_CodeOfConduct.pdf',
      },
    ],
    body: [
      {
        key: '1-mahoney',
        shortDescription: 'Reposition and Move skill set required to attend',
      },
      {
        key: '2-seacole',
        shortDescription: 'Basic Care skill set required to attend',
      },
      {
        key: '3-taylor',
        shortDescription: 'Personal Care skill set required to attend',
      },
      {
        key: '4-staupers',
        shortDescription: 'Restorative Care skill set required to attend',
      },
      {
        key: '5-barton',
        shortDescription: 'Special Procedures skill set required to attend',
      },
      {
        key: '6-nightingale',
        shortDescription: 'All skill sets completed',
      },
    ],
  });
};

const clinicalData = (state) => {
  if (state === 'OR') {
    return ({
      header: {
        title: 'Clinical Rotation',
        body: (
          <Body1 textAlign={'left'}>
            Once you pass all practice labs and a nurse approves your clinical prerequisites, you’ll be cleared for your clinical rotation. Plan to attend clinicals within two week of passing your last practice lab.
          </Body1>
        ),
      },
      links: [
        {
          iconName: 'description',
          title: 'Code of Conduct',
          url: 'https://s3.amazonaws.com/nextstep.careers/Practice+Lab+%26Clinical_CodeOfConduct.pdf',
        },
      ],
      body: [],
    });
  }

  if (state === 'WA') {
    return ({
      header: {
        title: 'Clinical Rotation',
        body: (
          <Body1 textAlign={'left'}>
            Once you pass all practice labs and a nurse approves your clinical prerequisites , you’ll be cleared for your clinical rotation. Plan to attend clinicals within one week of passing your last practice lab.
          </Body1>
        ),
      },
      links: [
        {
          iconName: 'description',
          title: 'Code of Conduct',
          url: 'https://s3.amazonaws.com/nextstep.careers/Practice+Lab+%26Clinical_CodeOfConduct.pdf',
        },
      ],
      body: [],
    });
  }

  // CO Default
  return ({
    header: {
      title: 'Clinical Rotation',
      body: (
        <Body1 textAlign={'left'}>
          Once you pass all practice labs and a nurse instructor approves your clinical prerequisites, you’ll be cleared for your clinical rotation. Plan to attend clinicals within one week of passing your last practice lab.
        </Body1>
      ),
    },
    links: [
      {
        iconName: 'description',
        title: 'Code of Conduct',
        url: 'https://s3.amazonaws.com/nextstep.careers/Practice+Lab+%26Clinical_CodeOfConduct.pdf',
      },
    ],
    body: [],
  });
};

export default {
  clinicalData,
  clinicalPrerequisites,
  practiceLabData,
};
