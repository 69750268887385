import { types } from 'mobx-state-tree';
import ProgressNode from './ProgressNode';
import Question from './Question';

const QuestionProgressModel = types.model({
  id: types.identifierNumber,
  answeredCorrectly: types.maybe(types.boolean),
  content: types.maybe(types.safeReference(Question)),
}).views(self => ({
  get isAnswered() {
    return (self.answeredCorrectly === true || self.answeredCorrectly === false);
  },
})).actions(self => ({
  setAnswer(isCorrect) {
    self.answeredCorrectly = !!isCorrect;
  },
  resetAnswer() {
    self.answeredCorrectly = undefined;
  },
}));

const QuestionProgress = types.compose(QuestionProgressModel, ProgressNode).named('QuestionProgress');

export default QuestionProgress;
