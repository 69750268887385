import { applySnapshot, flow, getParent, getRoot, getType, types } from 'mobx-state-tree';
import Util from '@nextstep/app/services/Util';
import { ContentNodeTypes } from '@nextstep/app/Config/Constants';
import Question from './Question';
import ContentNode from './ContentNode';

// LinearAssessments hold the metadata for linear linearAssessments - linearAssessment iterations hold the questions for each attempt at passing the assessment.
// When a learner passes an linearAssessment iteration (passing grade is defined in the linearAssessment), they complete the linearAssessment and that iteration.
const LinearAssessmentIterationModel = types.model('LinearAssessmentIteration', {
  id: types.identifierNumber,
  questions: types.maybe(types.array(Question)),
}).actions(self => ({
  setCurrent: flow(function* () {
    // the below is really annoying - depth two is b/c there is an array of iterations, but we'd rather not have iterations have to know about that. That could be done with getParentOfType(node, LinearAssessment), but that introduces circular dependencies :(
    const linearAssessment = getParent(self);
    const grandParent = getParent(linearAssessment);
    const grandParentType = getType(grandParent).name;
    const grandParentTypeLower = Util.pascalToCamel(grandParentType);

    // like above, this can be done more cleanly as `getParentOfType(self, ContentStore);`, but circular dependencies
    const rootStore = getRoot(self);
    const { contentStore } = rootStore;

    if (grandParent !== contentStore[grandParentTypeLower] || linearAssessment !== contentStore.linearAssessment) return;

    if (!self.loaded) {
      yield self.load();
    }
    contentStore.setCurrent({ nodeData: self });
  }),

  load: flow(function* () {
    const linearAssessment = getParent(self);
    const grandParent = getParent(linearAssessment);
    const grandParentType = getType(grandParent).name;

    const rootStore = getRoot(self);
    const { contentStore } = rootStore;

    const linearAssessmentIterationContent = yield contentStore.getContent({
      type: getType(self).name,
      apiParams: {
        [`${grandParentType}Key`]: grandParent.key,
        linearAssessmentKey: linearAssessment.key,
        linearAssessmentIterationKey: self.key,
      },
      apiFuncName: `get${grandParentType}LinearAssessmentIteration`,
    });
    applySnapshot(self, Util.updateNamingConvention(linearAssessmentIterationContent));
  }),

  isPassedIteration: ({ correctQuestionCount }) => {
    const { requiredGrade } = self.parent;

    return ((correctQuestionCount / self.questions?.length) >= (requiredGrade / 100));
  },
})).views(self => ({
  get loaded() {
    return Boolean(self.questions);
  },
}));

const LinearAssessmentIteration = types.compose(LinearAssessmentIterationModel, ContentNode)
  .named(ContentNodeTypes.linearAssessmentIteration);

export default LinearAssessmentIteration;
