import React from 'react';
import { Image, View, StyleSheet } from 'react-native';
import PropTypes from 'prop-types';
import { Colors, Images } from '@nextstep/app/Themes/index';
import Styles from '@nextstep/app/components/styles/RenderImageAnswerChoiceStyles';
import DebouncedTouchableOpacity from '@nextstep/app/components/DebouncedTouchableOpacity';
import { Body1 } from '@nextstep/app/components/Text';

const RenderImageAnswerChoice = ({ answerChoice, chooseAnswer, checked, index, showCheckOrX, skillset }) => (
  <DebouncedTouchableOpacity
    onPress={() => chooseAnswer(answerChoice)}
    style={{
      borderColor: skillset.colorTheme40,
      backgroundColor: checked ? skillset.colorTheme : Colors.transparent,
    }}
  >
    <Image
      source={{ uri: answerChoice.image || 'https://via.placeholder.com/150' }}
      style={[{
        width: '100%',
        resizeMode: 'cover',
        height: 90,
      }]}
    />

    <View
      style={{
        ...StyleSheet.flatten(Styles.imageWrap),
        borderTopRightRadius: 8,
        backgroundColor: checked && showCheckOrX ? skillset.colorTheme : Colors.transparent,
      }}
    >
      {checked && showCheckOrX && answerChoice.score === 1 && (
        <Image
          style={Styles.checked}
          source={Images.icons.checked}
        />
      )}

      {checked && showCheckOrX && answerChoice.score !== 1 && (
        <Image
          style={Styles.xMark}
          source={Images.icons.xMark}
        />
      )}

    </View>

    <View style={Styles.optionBody}>

      <Body1 color={checked ? Colors.white : '#ea4894'}>
        {`${(index + 10).toString(36)}.`.toUpperCase()}
      </Body1>

      <View style={Styles.optionBodyAnswer}>
        <Body1 color={checked ? Colors.white : Colors.dark}>
          {answerChoice.answerChoice}
        </Body1>
      </View>

    </View>
  </DebouncedTouchableOpacity>
);

RenderImageAnswerChoice.propTypes = {
  answerChoice: PropTypes.object.isRequired,
  chooseAnswer: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  showCheckOrX: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
};

export default RenderImageAnswerChoice;
