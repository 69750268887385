/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { Image, Text, View } from 'react-native';
import { Images, Palette } from '@nextstep/app/Themes';
import VideoScreenStyles from '@nextstep/app/containers/styles/VideoScreenStyles';
import AdaptiveContainerStyles from '@nextstep/app/containers/styles/AdaptiveContainerStyles';
import withDimensions from '@nextstep/app/lib/withDimensions';
import { Body1, H3 } from '@nextstep/app/components/Text';

const currentPalette = Palette.light;

const DefaultStyle = {
  icon: {
    width: 24,
    height: 24,
    overflow: 'hidden',
    resizeMode: 'contain',
  },
};

const BaseHeader = (props) => {
  const Styles = VideoScreenStyles(props.dimensions.deviceType);
  const MainStyles = AdaptiveContainerStyles(props.dimensions.deviceType);

  return (
    <View style={[MainStyles.textContainer, Styles.skillSetInfoContainer, {
      backgroundColor: props.color,
      paddingVertical: 20,
    }]}
    >

      <H3 textAlign={props.titleAlign} color={props.titleColor}>
        {props.title}
      </H3>

      <Text style={{ color: currentPalette.primary.contrastText }}>
        <Body1 textAlign={'left'} color={currentPalette.primary.contrastText}>
          {props.subtitle}
        </Body1>
        {props.enablesPracticeLab && (
          <>
            {'  \u25CF '}
            <Image
              source={Images.icons.beakerActive}
              style={DefaultStyle.icon}
            />
          </>
        )}
      </Text>

    </View>
  );
};

BaseHeader.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string,
  titleColor: PropTypes.string,
  titleAlign: PropTypes.string,
  subtitle: PropTypes.string,
  enablesPracticeLab: PropTypes.bool,
};

BaseHeader.defaultProps = {
  color: currentPalette.primary.main,
  title: '',
  titleColor: currentPalette.primary.contrastText,
  titleAlign: 'left',
  subtitle: '',
  enablesPracticeLab: false,
};

export default withDimensions(BaseHeader);
