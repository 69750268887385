import { types } from 'mobx-state-tree';
import WithStatus from './WithStatus';
import WithDuration from './WithDuration';
import ProgressNode from './ProgressNode';
import WithLeafNodeActions from './WithLeafNodeActions';
import DemoVideo from './DemoVideo';

const DemoVideoProgressModel = types.model({
  id: types.identifierNumber,
  demoVideoId: types.number,
  learnerId: types.number,
  courseKey: types.string,
  stepProgressId: types.number,
  content: types.maybe(types.safeReference(DemoVideo)),
});

const DemoVideoProgress = types.compose(DemoVideoProgressModel, ProgressNode, WithStatus, WithDuration, WithLeafNodeActions).named('DemoVideoProgress');

export default DemoVideoProgress;
