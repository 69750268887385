/* eslint-disable jsx-a11y/accessible-emoji */
/* eslint-disable class-methods-use-this,no-underscore-dangle */
import React, { Component } from 'react';
import { Platform, SafeAreaView, ScrollView, StatusBar, View } from 'react-native';
import { inject, observer } from 'mobx-react';
import Intercom from 'react-native-intercom';
import { FullViewSpinner } from '@nextstep/app/components/SharedUI';
import { ApplicationStyles } from '@nextstep/app/Themes';
import { ContainerErrorBoundary } from '@nextstep/app/lib/ErrorUtils';
import { analyticsScreenView } from '@nextstep/app/services/Analytics';
import { afterInteractions } from '@nextstep/app/lib/afterInteractions';
import { Caption1 } from '@nextstep/app/components/Text';
import { Row, Column } from '@nextstep/app/lib/ResponsiveLayout';
import withDimensions, { DeviceType } from '@nextstep/app/lib/withDimensions';
import AppMainHeader from '@nextstep/app/components/AppMainHeader';
import { ContentNodeTypes, ProgressNodeTypes } from '@nextstep/app/Config/Constants';
import HomeScreenStyles from './styles/HomeScreenStyles';
import CourseTimeline from './homeSections/CourseTimeline';
import CourseProgress from './homeSections/CourseProgress';
import CourseEnrollment from './homeSections/CourseEnrollment';
import CourseSkillBadges from './homeSections/CourseSkillBadges';

class HomeScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isFocused: true,
    };
  }

  async componentDidMount() {
    const { rootStore: { progressStore, contentStore }, navigation } = this.props;

    await afterInteractions();

    contentStore.clearCurrentExcept([ContentNodeTypes.course]);
    progressStore.clearCurrentExcept([ProgressNodeTypes.courseProgress]);

    analyticsScreenView('homeTabViewed');
    StatusBar.setBarStyle('dark-content', true);
    if (Intercom.webIntegration) Intercom.webIntegration(); // Intercom Web Integration - if it is available

    if (progressStore.courseProgress) await progressStore.loadCompletedSkills();

    navigation.addListener('didFocus', () => {
      this.setState({ isFocused: true });
    });
    navigation.addListener('didBlur', () => {
      this.setState({ isFocused: false });
    });

    this.setState({ loading: false, isFocused: navigation.isFocused() });
  }

  render() {
    const { loading, isFocused } = this.state;
    const { rootStore, dimensions, navigation } = this.props;
    const { progressStore, sessionStore } = rootStore;
    const { learner } = sessionStore;
    const Styles = HomeScreenStyles(dimensions.deviceType);

    if (loading || rootStore.snapshotUpdating) return <FullViewSpinner showBackground />;
    if (isFocused) progressStore.setActiveTab('Home');
    if (!progressStore.metrics) return null;

    return (
      <ContainerErrorBoundary navigation={navigation}>
        <SafeAreaView style={ApplicationStyles.safeAreaTop} />
        <StatusBar barStyle="dark-content" animated />
        { dimensions.deviceType !== DeviceType.Desktop && <AppMainHeader navigation={navigation} /> }

        <ScrollView
          style={[
            Styles.container,
            ApplicationStyles.screenContainer({ bottomBarHeight: dimensions.deviceType === DeviceType.Mobile ? 48 : 0 }),
          ]}
        >

          <View style={{ height: dimensions.deviceType === DeviceType.Mobile ? 10 : 30 }} />

          {learner && (
            <Row gutter={dimensions.deviceType === DeviceType.Mobile ? 10 : 32}>
              <Column smSize={12} mdSize={learner.graduationDeadlineEnabled ? 4 : 6}>
                <CourseEnrollment />
              </Column>
              <Column smSize={12} mdSize={learner.graduationDeadlineEnabled ? 4 : 6}>
                <CourseProgress />
              </Column>
              {learner.graduationDeadlineEnabled
                && (
                <Column smSize={12} mdSize={4}>
                  <CourseTimeline />
                </Column>
                )
              }
            </Row>
          )}

          <View style={{ height: dimensions.deviceType === DeviceType.Mobile ? 10 : 30 }} />

          <View style={{ flex: 1 }}>
            <CourseSkillBadges />
          </View>

          <View style={{ height: dimensions.deviceType === DeviceType.Mobile ? 40 : 0 }} />

          {Platform.OS === 'web' && (
            <View style={Styles.footer}>
              <Caption1 style={Styles.footerText} textAlign="right">
                {`© ${new Date().getFullYear()} NextStep Interactive Inc. All Rights Reserved`}
              </Caption1>
            </View>
          )}

        </ScrollView>
      </ContainerErrorBoundary>
    );
  }
}


export default withDimensions(inject('rootStore')(observer(HomeScreen)));
