const ProgressStoreViews = self => ({
  get enrolled() {
    return Boolean(self.courseProgress);
  },

  get currentCourseId() {
    return (self.courseProgress || {}).courseId;
  },

  get currentCourseKey() {
    return (self.courseProgress || {}).courseKey;
  },

  get currentSkillsetId() {
    return self.skillsetProgress.skillsetId;
  },

  get currentSkillId() {
    return self.skillProgress.skillId;
  },

  get currentStepId() {
    return self.stepProgress.stepId;
  },

  get lastUncompletedSkillset() {
    return self.skillsetProgresses.find(ss => ss.isInProgress || ss.isUnlocked);
  },

  get allGuestSkillsetsCompleted() {
    if (
      !self.skillsetProgresses
      || !self.skillsetProgresses.length
      || !self.skillsetProgresses[0].isGuestAccessible
    ) return false;
    for (let i = 0; i < self.skillsetProgresses.length; i++) {
      const ssp = self.skillsetProgresses[i];
      // skillset is guest accessible and not complete
      if (ssp.isGuestAccessible && !ssp.isCompleted) return false;
      // all guest accessible skillsets completed.
      if (!ssp.isGuestAccessible) return true;
    }
    return false;
  },

  get firstTimeCompletedStep() {
    const [firstSkillset] = self.skillsetProgresses;
    if (firstSkillset.isCompleted) return false;
    const [firstSkill] = firstSkillset?.skillProgresses;
    if (firstSkill.isCompleted) return false;
    const completedSteps = firstSkill?.stepProgresses?.filter(s => s.isCompleted);
    return completedSteps && (completedSteps.length === 1);
  },
});

export default ProgressStoreViews;
