import React, { useState } from 'react';
import { View, StyleSheet, Platform } from 'react-native';
import PropTypes from 'prop-types';
import { Colors } from '@nextstep/app/Themes/index';
import { QuestionTypes } from '@nextstep/app/Config/Constants';
import Config from '@nextstep/app/Config/DebugConfig';
import { Body1, Body2 } from '@nextstep/app/components/Text';
import Styles from '@nextstep/app/components/styles/RenderAnswerChoiceStyles';
import DebouncedTouchableOpacity from '@nextstep/app/components/DebouncedTouchableOpacity';
import InformationButton from '@nextstep/app/components/InformationButton';
import { currentPalette } from '@nextstep/app/Config/AppConfig';
import OrdinalBadge from './OrdinalBadge';
import AnswerStatusIcon from './AnswerStatusIcon';

const defaultStyles = {
  rightSideButtonContainer: {
    position: 'absolute',
    right: 0,
    marginRight: 12,
  },
};

const AnswerChoice = ({
  answerChoice, showingResult, index, order, questionType,
  selectAnswer, selected, themeColor, lightThemeColor,
  tooltip, disabled,
}) => {
  const [showTooltip, setTooltipVisibility] = useState(false);
  const isCorrectOrderedAnswerChoice = answerChoice.order > 0;
  const isCorrectAnswer = answerChoice.score === 1 || answerChoice.order === order + 1;
  let borderColor;

  if (showingResult && selected) {
    borderColor = (isCorrectAnswer) ? 'green' : 'red';
  } else { borderColor = lightThemeColor; }

  const OrdinalBadgePlaceholder = () => <View style={{ width: 30, height: 30, marginLeft: 5 }} />;

  return (
    <DebouncedTouchableOpacity
      onPress={() => selectAnswer()}
      disabled={disabled && !selected}
      style={{
        ...StyleSheet.flatten(Styles.container),
        borderColor: disabled ? currentPalette.text.placeHolder : borderColor,
        backgroundColor: selected ? lightThemeColor : Colors.transparent,
      }}
    >
      <View style={[StyleSheet.flatten(Styles.optionBody)]}>

        <Body2 style={{ color: disabled && !selected ? currentPalette.text.placeHolder : themeColor }}>
          {`${(index + 10).toString(36)}.`.toUpperCase()}
        </Body2>

        <View style={[Styles.optionBodyAnswer, tooltip && { paddingRight: 26 }]}>

          <Body1 style={{ color: Colors.dark, textAlign: 'left' }}>
            {answerChoice.answerChoice}
          </Body1>

        </View>

        {/* Ordered select OR fill in the blanks */}
        { ([QuestionTypes.fillInTheBlanks, QuestionTypes.orderedSelect].includes(questionType)) && (
          <View style={{ flexDirection: 'row' }}>

            { selected && !showingResult && <OrdinalBadge textColor={themeColor} borderColor={borderColor} order={order + 1} />}

            { selected && showingResult && !isCorrectAnswer && <OrdinalBadge textColor={'red'} borderColor={borderColor} order={order + 1} />}

            {/* This keeps the red column aligned */}
            { (selected && showingResult && !isCorrectAnswer) && !isCorrectOrderedAnswerChoice && <OrdinalBadgePlaceholder />}

            { showingResult && isCorrectOrderedAnswerChoice && <OrdinalBadge textColor={'green'} borderColor={borderColor} order={answerChoice.order} />}

          </View>
        ) }

        {/* Multiple choice */}
        { showingResult && (questionType === QuestionTypes.multipleChoice) && (
          <View style={{ flexDirection: 'row' }}>

            {selected && !isCorrectAnswer && <AnswerStatusIcon correct={false} />}

            {isCorrectAnswer && <AnswerStatusIcon correct /> }

          </View>
        ) }

        {/* Unordered select */}
        { showingResult && (questionType === QuestionTypes.unorderedSelect) && (
          <View style={{ flexDirection: 'row' }}>

            {selected && !isCorrectAnswer && <AnswerStatusIcon correct={false} />}

            {(!selected && !Config.answerSolver) && isCorrectAnswer && <AnswerStatusIcon correct={false} />}

            {isCorrectAnswer && <AnswerStatusIcon correct /> }

          </View>
        ) }

        {tooltip && (
          <View style={defaultStyles.rightSideButtonContainer}>
            <InformationButton
              show={showTooltip}
              action={(state) => setTooltipVisibility(state)}
              size={25}
            >
              <Body1 textAlign="left" style={Platform.OS === 'web' ? { paddingBottom: 20 } : {}}>
                {tooltip}
              </Body1>
            </InformationButton>
          </View>
        )}

      </View>
    </DebouncedTouchableOpacity>
  );
};

AnswerChoice.propTypes = {
  answerChoice: PropTypes.object.isRequired,
  showingResult: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  order: PropTypes.number,
  questionType: PropTypes.string,
  selectAnswer: PropTypes.func,
  selected: PropTypes.bool.isRequired,
  tooltip: PropTypes.string,
  disabled: PropTypes.bool,
};

AnswerChoice.defaultProps = {
  order: null,
  questionType: 'multiple_choice',
  tooltip: undefined,
  selectAnswer: () => {},
  disabled: false,
};

export default AnswerChoice;
