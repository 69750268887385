/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Image, View } from 'react-native';
import PropTypes from 'prop-types';
import { Body1 } from '@nextstep/app/components/Text';
import { currentPalette } from '@nextstep/app/Config/AppConfig';
import { Images } from '@nextstep/app/Themes';

const IconPracticeLab = (props) => (
  <View style={{ flexDirection: 'row', alignItems: 'center', alignContent: 'center' }}>
    <Image
      source={Images.icons.beakerActive}
      style={{
        width: props.iconSize,
        height: props.iconSize,
        overflow: 'hidden',
        resizeMode: 'contain',
      }}
    />
    <View style={{ paddingLeft: 5 }}>
      <Body1 textAlign={'left'} color={props.color}>
        Practice Lab
      </Body1>
    </View>
  </View>
);

IconPracticeLab.propTypes = {
  iconSize: PropTypes.number,
  color: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

IconPracticeLab.defaultProps = {
  iconSize: 16,
  color: currentPalette.primary.contrastText,
};

export default IconPracticeLab;
