const typography = {
  h1: {
    fontFamily: 'Poppins-Regular',
    fontSize: 48,
    lineHeight: 72,
  },
  h2: {
    fontFamily: 'Poppins-Bold',
    fontSize: 32,
    lineHeight: 56,
  },
  h3: {
    fontFamily: 'Poppins-Bold',
    fontSize: 24,
    lineHeight: 40,
  },
  h4: {
    fontFamily: 'Poppins-Regular',
    fontSize: 24,
    lineHeight: 40,
  },
  title: {
    fontFamily: 'Poppins-Bold',
    fontSize: 16,
    lineHeight: 24,
    letterSpacing: 1,
  },
  subtitle1: {
    fontFamily: 'Poppins-Regular',
    fontSize: 20,
    lineHeight: 28,
  },
  subtitle2: {
    fontFamily: 'Poppins-Bold',
    fontSize: 20,
    lineHeight: 28,
  },
  body1: {
    fontFamily: 'Poppins-Regular',
    fontSize: 16,
    lineHeight: 24,
  },
  body2: {
    fontFamily: 'Poppins-Bold',
    fontSize: 16,
    lineHeight: 24,
  },
  button: {
    fontFamily: 'Poppins-Bold',
    fontSize: 16,
    // Text appears cropped when lineHeight = fontSize
    // lineHeight: 16,
  },
  caption1: {
    fontFamily: 'Poppins-Regular',
    fontSize: 12,
    lineHeight: 16,
  },
  caption2: {
    fontFamily: 'Poppins-Bold',
    fontSize: 12,
    lineHeight: 16,
  },
  formLabel: {
    fontFamily: 'Poppins-Bold',
    fontSize: 14,
    lineHeight: 24,
  },
};

export default typography;
