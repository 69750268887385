import { StyleSheet } from 'react-native';
import Palette from '@nextstep/app/Themes/Palette';
import { Colors } from '@nextstep/app/Themes';

const currentPalette = Palette.light;

export default StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: currentPalette.background.main,
  },

  skillsetCard: {
    borderRadius: 8,
    shadowColor: 'rgb(43, 35, 58)',
    shadowOpacity: 0.12,
    shadowRadius: 2,
    shadowOffset: {
      height: 1,
      width: 0.5,
    },
    flex: 1,
    overflow: 'hidden',
    backgroundColor: currentPalette.background.white,
  },

  footer: {
    marginBottom: 15,
    marginRight: 35,
  },

  footerText: { color: Colors.lockedGray },
});
