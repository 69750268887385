import { applySnapshot, flow, getEnv, getParent, getSnapshot, getType } from 'mobx-state-tree';
import { mergeDeepLeft } from 'ramda';
import { ExceptionEmitter } from '@nextstep/app/lib/ErrorUtils';
import Course from '@nextstep/app/models/Course';
import Util from '@nextstep/app/services/Util';
import { ContentNodeTypes } from '@nextstep/app/Config/Constants';
import Config from 'react-native-config';

const ContentStoreActions = self => ({
  async load(key) {
    const loadCourse = self.loadCourse(key);
    const loadSkillsets = self.loadSkillsets(key);
    const loadPracticeLabs = self.loadPracticeLabs(key);

    await Promise.all([
      loadCourse, loadSkillsets, loadPracticeLabs,
    ]);
  },

  deleteSnapshot: () => {
    applySnapshot(self, {});
  },

  loadCourse: flow(function* (key) {
    self.course = yield self.getContent({
      type: ContentNodeTypes.course,
      apiParams: { key },
    });
  }),

  setSkillset: flow(function* (key) {
    const skillset = self.skillsets.find(s => s.key === key);
    if (!skillset) return false;

    if (!skillset.loaded) {
      const skillsetContent = yield self.getContent({
        type: getType(skillset).name,
        apiParams: { skillsetKey: key },
      })
        .catch(ExceptionEmitter);

      applySnapshot(skillset, Util.updateNamingConvention(skillsetContent));
    }
    self.skillset = skillset;
    return skillset;
  }),

  setSkill: flow(function* (key) {
    if (!self.skillset) return;

    const { skills } = self.skillset;
    const skill = skills.find(s => s.key === key);
    if (!skill) return;

    if (!skill.loaded) {
      const skillContent = yield self.getContent({ type: getType(skill).name, apiParams: { skillKey: key } })
        .catch(ExceptionEmitter);
      applySnapshot(skill, Util.updateNamingConvention(skillContent));
    }
    self.skill = skill;
  }),

  setStep: flow(function* (key) {
    if (!self.skill) return;
    const { steps } = self.skill;
    const step = steps.find(s => s.key === key);
    if (!step) return;

    if (!step.loaded) {
      const stepContent = yield self.getContent({ type: getType(step).name, apiParams: { stepKey: key } })
        .catch(ExceptionEmitter);
      applySnapshot(step, stepContent);
    }
    self.step = step;
  }),

  setAssessmentBlock: flow(function* (id, skipReload) {
    if (!self.step) return;
    const { assessmentBlocks } = self.step;
    const assessmentBlock = assessmentBlocks.find(s => s.id === id);
    if (!assessmentBlock.loaded || !skipReload) {
      const assessmentBlockContent = yield self.getContent({ type: getType(assessmentBlock).name, apiParams: { assessmentBlockKey: assessmentBlock.key } });
      applySnapshot(assessmentBlock, assessmentBlockContent);
    }
    self.assessmentBlock = assessmentBlock;
  }),

  setLinearAssessmentIteration: flow(function* ({ linearAssessmentIterationId }) {
    const { linearAssessment } = self;
    if (!linearAssessment) return;
    const grandParentTypeLower = Util.pascalToCamel((linearAssessment.parent.nodeType));

    self.linearAssessmentIteration = yield self.getContent({
      type: Util.pascalToCamel(ContentNodeTypes.linearAssessmentIteration),
      apiParams: {
        [`${grandParentTypeLower}Key`]: self[grandParentTypeLower].key,
        linearAssessmentKey: linearAssessment.key,
        linearAssessmentIterationId,
      },
      apiFuncName: `get${linearAssessment.parent.nodeType}LinearAssessmentIteration`,
    });
  }),

  clearLinearAssessment: () => {
    if (Config.CLEAR_LINEAR_ASSESSMENT_DATA) {
      self.linearAssessment = undefined;
      self.linearAssessmentIteration = undefined;
      self.question = undefined;
    }
  },

  setPracticeLab: flow(function* (key) {
    if (!self.practiceLabs) return;
    const practiceLab = self.practiceLabs.find(pl => pl.key === key);

    if (!practiceLab) return;

    if (!practiceLab.detailsLoaded) {
      const practiceLabDetails = yield self.getPracticeLabDetails(key)
        .catch(ExceptionEmitter);
      applySnapshot(practiceLab, practiceLabDetails);
    }

    self.practiceLab = practiceLab;
  }),

  setPracticeLabSkill: (id) => {
    if (!self.practiceLab) return;

    const { labSkills } = self.practiceLab;

    const labSkill = labSkills.find(pls => pls.id === id);

    if (!labSkill) return;

    if (labSkill.asset) {
      labSkill.asset = labSkill.asset.replace(/\\"/g, '"').replace(/\\n/g, '\n');
    }

    self.labSkill = labSkill;
  },

  setQuestion(id) {
    let questions = [];
    if (self.assessmentBlock) {
      const { hard, medium } = self.assessmentBlock.questionTiers;
      questions = [...hard, ...medium];
    }
    const question = questions.find(s => s.id === id);
    question.shuffleAnswerChoices();
    self.question = question;
  },

  setAssignmentQuestion(assignmentQuestionId) {
    self.assignmentQuestion = self.assignmentQuestions.find(aq => aq.id === assignmentQuestionId);
  },

  loadSkillsets: flow(function* (key) {
    const { api } = getEnv(self);
    key = key || self.course.key;
    self.loading = true;
    const response = yield api.getCourseSkillsets({ courseKey: key });

    if (Util.shouldReject(response)) return Promise.reject(response);

    const skillsets = response.data.data;
    if (self.skillsets) {
      self.skillsets = skillsets.map((ss, i) => mergeDeepLeft(getSnapshot(self.skillsets[i]), ss));
    } else {
      self.skillsets = skillsets;
    }

    self.loading = false;
    return self.skillsets;
  }),

  loadPracticeLabs: flow(function* (key) {
    const { api } = getEnv(self);
    key = key || self.course.key;
    self.loading = true;
    const response = yield api.getCoursePracticeLabs({ courseKey: key });

    if (Util.shouldReject(response)) return Promise.reject(response);

    self.practiceLabs = response.data.data;
    self.loading = false;

    return self.practiceLabs;
  }),

  loadAssignmentQuestions: flow(function* () {
    self.assignmentQuestions = yield self.getAssignmentQuestions();
  }),

  getPracticeLabDetails: flow(function* (key) {
    const { api } = getEnv(self);
    key = key || self.practiceLab.key;

    self.loading = true;

    const response = yield api.getCoursePracticeLabDetails({ courseKey: self.course.key, practiceLabKey: key });

    if (Util.shouldReject(response)) return Promise.reject(response);

    self.loading = false;
    return response.data.practiceLab;
  }),

  setCurrent({ nodeData, type }) {
    // if called with nodeData and type, it's the raw data coming back from the API; if called with an actual node, there is no need to provide a type.
    self[type ? Util.pascalToCamel(type) : nodeData.lowerNodeType] = nodeData;
  },

  clearCurrentExcept(allowedNodeTypes) {
    const disallowedNodeTypes = Object.values(ContentNodeTypes).filter(nodeType => !allowedNodeTypes.includes(nodeType));

    disallowedNodeTypes.forEach((nodeType) => {
      self[Util.pascalToCamel(nodeType)] = undefined;
    });
  },

  createCourse(courseData) {
    return Course.create({
      ...courseData,
      id: (courseData.id) ? courseData.id : 0,
    });
  },

  setCourse(course) {
    self.course = course;
  },

  getSkillset(skillsetId) {
    return skillsetId ? self.skillsets.find(ss => ss.id === skillsetId) : self.skillsets[0];
  },

  getContent: flow(function* ({
    type,
    apiParams,
    apiFuncName,
    responseName,
  }) {
    const { api } = getEnv(self);
    if (self.course) apiParams.courseKey = self.course.key;

    self.loading = true;

    apiFuncName = apiFuncName || `get${type}`;
    const response = yield api[apiFuncName](apiParams);
    self.loading = false;

    if (Util.shouldReject(response)) return Promise.reject(response);

    return response.data[responseName || Util.pascalToCamel(type)];
  }),

  stepHasSuccessCriteria() {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line no-console
    console.warn('TODO: use Step model stepHasSuccessCriteria view and remove this one');
    return true;
  },

  stepTitle() {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line no-console
    console.warn('TODO: implement stepTitle');
    return true;
  },

  goToStep() {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line no-console
    console.warn('TODO: implement goToStep');
    return true;
  },

  getApprovedStates: flow(function* () {
    const { api } = getEnv(self);
    const response = yield api.getApprovedStates();

    if (Util.shouldReject(response)) return Promise.reject(response);

    return response.data.data.sort((a, b) => b.abbreviation < a.abbreviation);
  }),

  getPracticeLabSkills: flow(function* () {
    const { api } = getEnv(self);
    const response = yield api.getPracticeLabSkills(self.course.key, self.practiceLab.key);

    if (Util.shouldReject(response)) return Promise.reject(response);

    applySnapshot(self.practiceLab, response.data.data);
    return response.data.data;
  }),

  getAssignmentQuestions: flow(function* () {
    const { api } = getEnv(self);
    const response = yield api.getAssignmentQuestions({ courseKey: self.course.key })
      .catch(ExceptionEmitter);

    if (Util.shouldReject(response)) return Promise.reject(response);

    return response.data.data;
  }),

  activateLoading() {
    self.loading = true;
  },

  deactivateLoading() {
    self.loading = false;
  },

  loadQuestionnaire: flow(function* (questionnaireId) {
    const { api } = getEnv(self);
    const { sessionStore } = getParent(self);
    const { learner } = sessionStore;
    const response = yield api.getQuestionnaire(learner.id, questionnaireId).catch(ExceptionEmitter);

    if (Util.shouldReject(response)) return Promise.reject(response);

    const { payload, id } = response.data.questionnaire;

    self.questionnaire = payload;

    sessionStore.createQuestionnaireAnswers(id);

    return payload;
  }),
});

export default ContentStoreActions;
