import { flow, types, applySnapshot } from 'mobx-state-tree';
import { ExceptionEmitter } from '@nextstep/app/lib/ErrorUtils';
import Util from '@nextstep/app/services/Util';
import { trackAnalytics } from '@nextstep/app/services/Analytics';
import { Status, StatusCodes } from '@nextstep/app/Config/Constants';

const WithLeafNodeActions = types.model({}).actions(self => ({
  start: flow(function* () {
    if (self.isInProgress) return self;

    const response = yield self.api[`start${self.contentNodeType}`](self.id)
      .catch(ExceptionEmitter);

    if (Util.shouldReject(response)) return Promise.reject(response);

    const data = Util.updateNamingConvention(response.data);

    if (response.status === StatusCodes.unprocessableEntity) return self.fetch();

    trackAnalytics(`${self.lowerContentNodeType}Started`, self.store.processForAnalytics({ type: self.lowerContentNodeType, leafNodeId: self[`${self.lowerContentNodeType}Id`] }));
    applySnapshot(self, data[self.lowerNodeType]);

    return data;
  }),

  complete: flow(function* (correctQuestionCount = null) {
    if (self.isCompleted) return self;

    let response = null;

    if (correctQuestionCount) {
      response = yield self.api[`complete${self.contentNodeType}`](self.id, self.duration, correctQuestionCount)
        .catch(ExceptionEmitter);
    } else {
      response = yield self.api[`complete${self.contentNodeType}`](self.id, self.duration)
        .catch(ExceptionEmitter);
    }

    if (Util.shouldReject(response)) return Promise.reject(response);

    trackAnalytics(`${self.lowerContentNodeType}Completed`, self.store.processForAnalytics({ type: self.lowerContentNodeType, leafNodeId: self[`${self.lowerContentNodeType}Id`] }));
    self.store.updateTree(response.data.changedModels);

    return response.data;
  }),

  fail: flow(function* (correctQuestionCount = null) {
    if (self.isCompleted) return self;

    let response = null;

    if (correctQuestionCount) {
      response = yield self.api[`fail${self.contentNodeType}`](
        self.id,
        self.duration,
        correctQuestionCount,
      )
        .catch(ExceptionEmitter);
    } else {
      response = yield self.api[`fail${self.contentNodeType}`](
        self.id,
        self.duration,
      )
        .catch(ExceptionEmitter);
    }

    if (Util.shouldReject(response)) return Promise.reject(response);

    trackAnalytics(`${self.lowerContentNodeType}Failed`, self.store.processForAnalytics({ type: self.lowerContentNodeType, leafNodeId: self[`${self.lowerContentNodeType}Id`] }));
    self.status = Status.failed;
    self.store.updateTree(response.data.changedModels);

    return response.data;
  }),

  fetch: flow(function* () {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line no-console
    console.log(self.api[`get${self.nodeType}`]);
    const response = yield self.api[`get${self.nodeType}`](self.id)
      .catch(ExceptionEmitter);

    if (Util.shouldReject(response)) return Promise.reject(response);

    yield self.store[`sync${self.parent.nodeType}`]();

    return response.data;
  }),
}));

export default WithLeafNodeActions;
