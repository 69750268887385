import { types, flow } from 'mobx-state-tree';
import WithStatus from './WithStatus';
import WithDuration from './WithDuration';
import ProgressNode from './ProgressNode';
import WithLeafNodeActions from './WithLeafNodeActions';
import LinearAssessmentIteration from './LinearAssessmentIteration';

const LinearAssessmentIterationProgressModel = types.model({
  id: types.identifierNumber,
  linearAssessmentIterationId: types.maybe(types.number),
  linearAssessmentProgressId: types.maybe(types.number),
  content: types.maybe(types.safeReference(LinearAssessmentIteration)),
}).actions(self => ({
  setCurrent: () => {
    self.store.linearAssessmentIterationProgress = self;
    return self;
  },

  sendQuestionResponse: flow(function* ({ questionId, isAnsweredCorrectly }) {
    yield self.api.sendQuestionResponse({ questionId, isAnsweredCorrectly, assessmentIterationProgressId: self.id });
  }),

  isPassed: ({ correctQuestionCount }) => {
    const linearAssessmentIteration = self.getContent();
    const linearAssessment = self.parent.getContent();

    return ((correctQuestionCount / linearAssessmentIteration.questions?.length) >= (linearAssessment.requiredGrade / 100));
  },

  finish: flow(function* ({ isPassed, correctQuestionCount }) {
    const linearAssessmentProgress = self.parent;
    const linearAssessment = linearAssessmentProgress.getContent();

    linearAssessment.clearIteration();

    if (isPassed) {
      yield linearAssessmentProgress.complete(correctQuestionCount);
    } else {
      yield linearAssessmentProgress.fail(correctQuestionCount);
    }
  }),
}));

const LinearAssessmentIterationProgress = types.compose(LinearAssessmentIterationProgressModel, ProgressNode, WithStatus, WithDuration, WithLeafNodeActions).named('LinearAssessmentIterationProgress');

export default LinearAssessmentIterationProgress;
