import React, { useContext, useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import camelCase from 'lodash/camelCase';
import { DeviceType } from '@nextstep/app/lib/withDimensions';
import { RowContext } from './Row';
import { getGutterPadding } from './utils';

const styles = StyleSheet.create({ col: {} });

const columnsCount = 12;

const breakpoints = {
  lg: [DeviceType.Desktop],
  md: [DeviceType.Tablet, DeviceType.Desktop],
  sm: [DeviceType.Mobile, DeviceType.Tablet, DeviceType.Desktop],
};

const responsivePropNames = [
  'size',
  ...Object.keys(breakpoints).map((size) => `${size}Size`),
  'hidden',
  ...Object.keys(breakpoints).map((size) => `${size}Hidden`),
];

function getResponsiveProp(deviceType, props, name) {
  const sizeLookup = Object.keys(breakpoints).filter((sz) => breakpoints[sz].includes(deviceType));
  sizeLookup.push('');

  let colWidth;
  sizeLookup.find((size) => {
    colWidth = props[camelCase(`${size} ${name}`)];
    return colWidth !== undefined;
  });

  return colWidth;
}

function computeColumnStyle(props, options = {}) {
  const colWidth = getResponsiveProp(options.deviceType, props, 'size') || columnsCount;
  const hidden = getResponsiveProp(options.deviceType, props, 'hidden') || false;

  return {
    flexBasis: `${(colWidth / columnsCount) * 100}%`,
    display: hidden ? 'none' : 'flex',
    padding: getGutterPadding(options.gutter),
  };
}

export default function Column(props) {
  const { dimensions, gutter } = useContext(RowContext);
  const responsiveStyle = useMemo(
    () => computeColumnStyle(props, { deviceType: dimensions.deviceType, gutter }),
    // eslint-disable-next-line react/destructuring-assignment
    [dimensions.deviceType, gutter, ...responsivePropNames.map((name) => props[name])],
  );
  const { children, style } = props;

  return (
    <View style={[styles.col, responsiveStyle, style]}>
      {children}
    </View>
  );
}
