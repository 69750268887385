import { types, getRoot } from 'mobx-state-tree';
import BaseNode from './BaseNode';

// This provides convenience functions to progress nodes in the tree. eg [fooNode].store to get the progressStore
const ContentNodeModel = types.model({}).views(self => ({
  get progressNodeType() {
    return `${self.nodeType}Progress`;
  },
  get lowerProgressNodeType() {
    return `${self.lowerNodeType}Progress`;
  },
  get store() {
    const rootStore = getRoot(self);
    return rootStore.contentStore;
  },
}));

const ContentNode = types.compose(ContentNodeModel, BaseNode);

export default ContentNode;

