import React from 'react';

export default class LinearGradient extends React.Component {
  // eslint-disable-next-line class-methods-use-this
  setNativeProps() { }

  render() {
    return null;
  }
}
