import { types } from 'mobx-state-tree';
import { ContentNodeTypes } from '@nextstep/app/Config/Constants';
import ContentNode from './ContentNode';

const Video = types.compose(types.model({
  id: types.identifierNumber,
  key: types.string,
  url: types.string,
}), ContentNode).named('Video');

const IntroVideo = types.compose(types.model({
  id: types.identifierNumber,
  key: types.string,
  videos: types.array(Video),
}), ContentNode).named(ContentNodeTypes.introVideo);

export default IntroVideo;
