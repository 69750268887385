import React from 'react';
import { Image, View, StyleSheet } from 'react-native';
import PropTypes from 'prop-types';
import { Colors, Images } from '@nextstep/app/Themes/index';
import Styles from '@nextstep/app/components/styles/RenderAnswerChoiceStyles';
import DebouncedTouchableOpacity from '@nextstep/app/components/DebouncedTouchableOpacity';
import { Body1, Body2 } from '@nextstep/app/components/Text';

const RenderAnswerChoice = ({
  skillset, answerChoice, chooseAnswer,
  checked, index, showCheckOrX,
  isCorrect, append, prepend,
  showSymbols, optionStyleOverrides,
}) => {
  const correct = isCorrect !== null ? isCorrect : (answerChoice.score === 1);
  return (
    <DebouncedTouchableOpacity
      onPress={() => (chooseAnswer ? chooseAnswer(answerChoice) : {})}
      style={{
        ...StyleSheet.flatten(Styles.container),
        borderColor: skillset.colorTheme40,
        backgroundColor: checked ? skillset.colorTheme : Colors.transparent,
      }}
    >
      <View style={[Styles.optionBody, optionStyleOverrides]}>

        { showSymbols && (
          <Body2 color={checked ? Colors.white : skillset.colorTheme} style={{ paddingRight: 5 }}>
            {`${(index + 10).toString(36)}.`.toUpperCase()}
          </Body2>
        )}

        <View style={Styles.optionBodyAnswer}>

          { prepend }

          <Body1 textAlign="left" color={checked ? Colors.white : Colors.dark} style={{ flex: 1 }}>
            {answerChoice.answerChoice}
          </Body1>

          {!showCheckOrX && <View style={Styles.checked} />}

          {append}

          {checked && showCheckOrX && (
            <Image
              style={correct ? Styles.checked : Styles.xMark}
              source={correct ? Images.icons.checked : Images.icons.xMark}
            />
          )}

        </View>

      </View>
    </DebouncedTouchableOpacity>
  );
};

RenderAnswerChoice.propTypes = {
  answerChoice: PropTypes.object.isRequired,
  chooseAnswer: PropTypes.func,
  checked: PropTypes.bool.isRequired,
  showCheckOrX: PropTypes.bool.isRequired,
  isCorrect: PropTypes.bool,
  index: PropTypes.number.isRequired,
  append: PropTypes.node,
  prepend: PropTypes.node,
  showSymbols: PropTypes.bool,
  optionStyleOverrides: PropTypes.object,
};

RenderAnswerChoice.defaultProps = {
  chooseAnswer: null,
  append: null,
  prepend: null,
  isCorrect: null,
  showSymbols: true,
  optionStyleOverrides: {},
};

export default RenderAnswerChoice;
