import { types } from 'mobx-state-tree';
import User from '@nextstep/app/models/User';
import BasicLearnerData from '@nextstep/app/models/BasicLearnerData';
import inAppNotification from '@nextstep/app/models/InAppNotification';
import EnrollmentAgreement from '@nextstep/app/models/EnrollmentAgreement';
import QuestionnaireAnswers from '@nextstep/app/models/QuestionnaireAnswers';
import SessionStoreActions from './SessionStoreActions';

const SessionStore = types
  .model('SessionStore', {
    identifier: types.optional(types.identifier, 'SessionStore'),
    learner: types.maybeNull(User),
    inAppNotifications: types.maybe(types.array(inAppNotification)),
    enrollmentAgreements: types.optional(types.array(EnrollmentAgreement), []),
  })
  .views((self) => ({
    get isAuthenticated() {
      return Boolean(self.learner);
    },
  }))
  .volatile(() => ({
    questionnaireAnswers: types.maybe(QuestionnaireAnswers),
    loginFailed: types.optional(types.boolean, false),
    daysOfStudy: types.optional(types.string, ''),
    intercomPush: types.optional(types.string, ''),
    conversationId: types.optional(types.number, 0),
    tosAcceptedToastVisible: false,
    basicLearnerData: types.maybe(BasicLearnerData),
    hasInternet: types.optional(types.boolean, true),
  })).actions(SessionStoreActions);

export default SessionStore;
