import React from 'react';
import { View } from 'react-native';
import { AnimatedCircularProgress } from 'react-native-circular-progress';
import { inject, observer } from 'mobx-react';
import withDimensions from '@nextstep/app/lib/withDimensions';
import { Body1, Body2, H2, Subtitle2, Title } from '@nextstep/app/components/Text';
import Divider from '@nextstep/app/components/Divider';
import { Palette } from '@nextstep/app/Themes';
import HomeScreenStyles from '@nextstep/app/containers/styles/HomeScreenStyles';

const currentPalette = Palette.light;
const MAX_PERCENTAGE = 100;

const CourseProgress = (props) => {
  const { rootStore: { progressStore }, dimensions } = props;
  const { metrics } = progressStore;
  const Styles = HomeScreenStyles(dimensions.deviceType);

  const percentage = metrics.completedSkills === 1 ? 1 : metrics.percentage;

  return (
    <View style={Styles.section}>

      <View style={{ height: 26 }} />

      <Title>
        MY PROGRESS
      </Title>

      <View style={{ height: 15 }} />

      <Divider />

      <View style={{ height: 10 }} />

      <View style={[Styles.sectionContent, { alignItems: 'center' }]}>

        <View style={{ height: 190, width: 190 }}>
          <AnimatedCircularProgress
            size={190}
            width={16}
            fill={(percentage / MAX_PERCENTAGE) * 100}
            tintColor={currentPalette.primary.main}
            backgroundColor={currentPalette.background.secondary}
            rotation={0}
          />
        </View>

        <View style={Styles.myProgressSection}>

          <H2 color={currentPalette.primary.main}>
            {`${percentage}%`}
          </H2>

          <Body2>
            {'Complete!'}
          </Body2>

        </View>
      </View>

      {/* <View style={{ height: 20 }} />
      <Body2>
        {'Labs completion: '}
        <Subtitle2 color={currentPalette.primary.main}>
          {`${metrics.labPercentComplete}%`}
        </Subtitle2>
      </Body2>

      <Body2>
        {'Clinical completion: '}
        <Subtitle2 color={currentPalette.primary.main}>
          {`${metrics.clinicalPercentComplete}%`}
        </Subtitle2>
      </Body2> */}

      {metrics.percentage === MAX_PERCENTAGE && (
        <View>

          <View style={{ height: 20 }} />

          {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
          <Subtitle2>
            🎉 🎉Congratulations!🎉 🎉
          </Subtitle2>

          <Body1>
            You completed all the skills in the app!
            {'\n\n'}
            Come back to review skills for practice labs, your clinical rotation, or your CNA exam.
          </Body1>

        </View>
      )}

      <View style={{ height: 22 }} />

    </View>
  );
};

export default withDimensions(inject('rootStore')(observer(CourseProgress)));
