import { types } from 'mobx-state-tree';
import { ContentNodeTypes } from '@nextstep/app/Config/Constants';
import Skill from './Skill';
import WithColorTheme from './WithColorTheme';
import LinearAssessment from './LinearAssessment';
import ContentNode from './ContentNode';

const SkillsetModel = types.model('Skillset', {
  id: types.identifierNumber,
  key: types.string,
  version: types.string,
  title: types.string,
  summary: types.maybe(types.string),
  skillCount: types.maybe(types.number),
  skills: types.maybe(types.array(Skill)),
  linearAssessment: types.maybe(LinearAssessment),
  assignmentQuestionId: types.maybeNull(types.number),
  requiredMinimumTime: 0,
  estimatedTime: types.maybeNull(types.number),
  colorType: types.maybe(types.string),
  isFinalExam: types.maybe(types.boolean),
}).views(self => ({
  get loaded() {
    return !!self.summary;
  },
}));

const Skillset = types.compose(SkillsetModel, ContentNode, WithColorTheme)
  .named(ContentNodeTypes.skillset);

export default Skillset;
