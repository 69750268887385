import { types } from 'mobx-state-tree';
import WithStatus from './WithStatus';
import ProgressNode from './ProgressNode';
import Course from './Course';

const CourseProgressModel = types.model('CourseProgress', {
  id: types.number,
  courseKey: types.string,
  courseId: types.number,
  content: types.maybe(types.safeReference(Course)),
});

const CourseProgress = types.compose(CourseProgressModel, ProgressNode, WithStatus).named('CourseProgress');

export default CourseProgress;
