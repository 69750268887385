import { types } from 'mobx-state-tree';
import WithStatus from './WithStatus';
import WithDuration from './WithDuration';
import ProgressNode from './ProgressNode';
import WithLeafNodeActions from './WithLeafNodeActions';
import SuccessCriteria from './SuccessCriteria';

const SuccessCriteriaProgressModel = types.model({
  id: types.identifierNumber,
  successCriteriaId: types.number,
  learnerId: types.number,
  stepProgressId: types.number,
  courseKey: types.string,
  content: types.maybe(types.safeReference(SuccessCriteria)),
});

const SuccessCriteriaProgress = types.compose(SuccessCriteriaProgressModel, ProgressNode, WithStatus, WithDuration, WithLeafNodeActions).named('SuccessCriteriaProgress');

export default SuccessCriteriaProgress;
