import React from 'react';
import { Image, Platform, View } from 'react-native';
import { Images } from '@nextstep/app/Themes';
import { Body1, H3 } from '@nextstep/app/components/Text';
import { ButtonFilled, ButtonText } from '@nextstep/app/components/Button';
import withDimensions from '@nextstep/app/lib/withDimensions';
import ViewWrapper from '@nextstep/app/components/ViewWrapper';
import RootStore from '@nextstep/app/stores/RootStore';
import styles from './styles/LaunchScreenStyles';

function LaunchScreen(props) {
  const { navigation } = props;

  const isWeb = Platform.OS === 'web';

  return (
    <ViewWrapper smSize={12} mdSize={5} lgSize={4}>
      <View style={styles.textContainer}>

        {isWeb && <View style={{ height: 32 }} />}

        <Image
          style={{ height: 150, width: 150 }}
          resizeMode={'contain'}
          source={Images.login.PhoneWithBackground}
        />

        <View style={{ height: 26 }} />

        <H3>Preview the NextStep course!</H3>

        <View style={{ height: 40 }} />

        <ButtonFilled
          onPress={() => navigation.navigate('StateSelectionScreen', { tryCourse: true })}
          width={'100%'}
        >
          Try a Skill Set
        </ButtonFilled>

        <View style={{ height: 20 }} />

        <Body1>Already have an account?</Body1>

        <View style={{ height: 20 }} />

        <ButtonText
          onPress={() => {
            RootStore.resetStore();
            navigation.navigate('AccessScreen');
          }}
        >
          Sign In
        </ButtonText>

      </View>
      <View style={{ height: 30 }} />
    </ViewWrapper>
  );
}

export default withDimensions(LaunchScreen);

