import React from 'react';
import { inject, observer } from 'mobx-react';
import ViewWrapper from '@nextstep/app/components/ViewWrapper';
import withDimensions from '@nextstep/app/lib/withDimensions';
import { currentPalette } from '@nextstep/app/Config/AppConfig';
import { View, Text } from 'react-native';
import { H2, H3, Subtitle2, Body1, Body2 } from '@nextstep/app/components/Text';

const Styles = {
  bulletPoint: {
    textAlign: 'left',
    marginTop: 0,
    marginLeft: 25,
  },
  m15: {
    textAlign: 'left',
    marginTop: 15,
  },
  italic: { fontStyle: 'italic' },
};

const skillsets = [
  {
    title: 'SKILL SET 1: WORK READINESS (3 SKILLS)',
    pages: 'Chapter 1, pp 1-10; Chapter 2, p 59',
    theorySkills: ['Your First Step', 'Intro to Long-Term Care', 'Scope of Practice'],
  },
  {
    title: 'SKILL SET 2: COMMUNICATION (5 SKILLS)',
    pages: 'Chapter 2, pp 21-30',
    theorySkills: ['Active Listening',
      'Body Language & Tone',
      'Verbal & Nonverbal',
      'Sensory Impairment  ',
      'Communicate with Family',
    ],
  },
  {
    title: 'SKILL SET 3: PROMOTE RIGHTS (7 SKILLS)',
    pages: 'Chapter 1, pp 10-18;  Chapter 3, pp 62-65',
    theorySkills: ['Privacy & Confidentiality',
      'Rights: Personal Choice',
      'RIghts: Advanced Directives',
      'Empower Independence',
      'Rights: Information',
      'Rights: Engage in Activities',
      'Advocate for Others',
    ],
  },
  {
    title: 'SKILL SET 4: PROTECT RIGHTS (7 SKILLS)',
    pages: 'Chapter 3, pp 68-69; Chapter 7, pp 188-190, pp 201- 202',
    theorySkills: ['Right to Refuse Care',
      'Right to Avoid Restraints',
      'Security of Possessions',
      'Freedom From Abuse, Neglect & Exploitation',
      'Report Abuse, Neglect & Exploitation',
      'Rights: Grievance Resolution',
      'Avoid Ageism',
    ],
  },
  {
    title: 'SKILL SET 5: INFECTION CONTROL (7 SKILLS)',
    pages: 'Chapter 2, pp 45-59',
    theorySkills: ['Hand Hygiene',
      'Use Gloves',
      'Standard Precautions',
      'Use a Gown',
      'Use Mask & Goggles',
      'Transmission-Based Precautions',
      'Cleaning Products and Schedule',
    ],
  },
  {
    title: 'SKILL SET 6: PRE & POST CARE (5 SKILLS)',
    pages: 'Chapter 6, pp 124-125; Chapter 7, pp 200-202; pp 250-261',
    theorySkills: ['Care Prep & Care End',
      'Observe & Report',
      'Care Plan',
      'Documentation',
      'Aging & Human Development',
    ],
  },
  {
    title: 'SKILL SET 7: HEALTH & SAFETY (6 SKILLS)',
    pages: 'Chapter 2, pp 29-37; Chapter 6, p 163',
    theorySkills: ['Prevent Falls',
      'Ergonomics',
      'Promote Physical Activity',
      'Safety',
      'De-Escalate Violence',
      'Difficult Behaviors',
    ],
  },
  {
    title: 'SKILL SET 8: ACUTE RESPONSE (6 SKILLS)',
    pages: 'Chapter 2, pp 33-45',
    theorySkills: ['Respond to Falls',
      'Respond to Vomiting',
      'Respond to Fainting',
      'Respond to Seizure',
      'Respond to Emergency',
      'Respond During Disaster',
    ],
  },
  {
    title: 'SKILL SET 9: REPOSITION OR MOVE (7 SKILLS)',
    pages: 'Chapter 6, pp 157-168, Chapter 9, pp 232-233',
    theorySkills: ['Move to Side of Bed',
      'Reposition to Sitting',
      'Position on Side in Bed',
      'Move Up in Bed',
      'Sit Up on Side of Bed',
      'Turn & Reposition',
      'Adjust Bed Height',
    ],
  },
  {
    title: '‍SKILL SET 10: TRANSFER (4 SKILLS)',
    pages: 'Chapter 6, pp 163-168',
    theorySkills: ['Transfer to Tub or Shower',
      'Transfer: Bed to Wheelchair',
      'Mechanical Lift',
      'Transporting',
    ],
  },
  {
    title: '‍SKILL SET 11: BASIC CARE (7 SKILLS)',
    pages: 'Chapter 7, pp 173-188, pp 190-192',
    theorySkills: ['Blood Pressure',
      'Measure Pulse',
      'Measure Respirations',
      'Measure Intake & Output',
      'Measure Temperature',
      'Measure Pain',
      'Measure Height & Weight',
    ],
  },
  {
    title: '‍SKILL SET 12: ASSISTIVE DEVICES (4 SKILLS)',
    pages: 'Chapter 2, pp 26-27; Chapter 6, p 144-146; Chapter 9, pp 229-233',
    theorySkills: ['Anti-Pressure Positioning Devices',
      'Use Mobility Aids',
      'Hearing Aid Care',
      'Dress, Bathe & Toileting Aids',
    ],
  },
  {
    title: '‍SKILL SET 13: PERSONAL HYGIENE (7 SKILLS)',
    pages: 'Chapter 6, pp 129-152; Chapter 7, pp 203-208',
    theorySkills: ['Oral Care',
      'Denture Care',
      'Assist with Shaving',
      'Fingernail & Foot Care',
      'Shampoo in Bed',
      'Make a Bed',
      'Apply Skin Topicals',
    ],
  },
  {
    title: '‍SKILL SET 14: PERSONAL CARE (6 SKILLS)',
    pages: 'Chapter 6, pp 129-134; pp 143-146; Chapter 7, pp 199-200',
    theorySkills: ['Compression Devices',
      'Assist with Dressing',
      'Bed Bath',
      'Backrub',
      'Assist with Oxygen',
      'Undressing',
    ],
  },
  {
    title: '‍SKILL SET 15: RESTORATIVE CARE (7 SKILLS)',
    pages: 'Chapter 6, pp 125-128; Chapter 9, pp 234-240',
    theorySkills: ['Skin Care',
      'Range of Motion',
      'Bladder & Bowel Training',
      'Prosthetics & Orthotics Care',
      'Assist with Ambulation',
      'Coughing & Deep Breathing',
      'Post-Orthopedic Surgery Care',
    ],
  },
  {
    title: '‍SKILL SET 16: TOILETING (4 SKILLS)',
    pages: 'Chapter 6, pp 152-157',
    theorySkills: ['Assist with Bedpan',
      'Assist with Urinal',
      'Portable Commode',
      'Change Soiled Brief',
    ],
  },
  {
    title: '‍SKILL SET 17: SPECIAL PROCEDURES (4 SKILLS)',
    pages: 'Chapter 4, pp 97-101; Chapter 7, pp 196-199',
    theorySkills: ['Urinary Catheter Care',
      'Incontinence & Perineal Care',
      'Ostomy Care (emptying/bathing around pouch only)',
      'Empty Catheter Bag',
    ],
  },
  {
    title: '‍SKILL SET 18: NUTRITION (4 SKILLS)',
    pages: 'Chapter 8, pp 209-226',
    theorySkills: ['Assist with Eating',
      'Assist with Drinking',
      'Mealtime Aids',
      'Food Prep: Safety',
    ],
  },
  {
    title: '‍SKILL SET 19: SPECIAL POPULATIONS (5 SKILLS)',
    pages: 'Chapter 2, pp 28-29; Chapter 3, pp 70-72; Chapter 4, pp 85-96; Chapter 5, pp 114-123',
    theorySkills: ['Dementia/Alzheimers: Care',
      'Care for Mental Illness',
      'Mental Health Crisis',
      'Post-Stroke Care',
      'Developmental Disabilities',
    ],
  },
  {
    title: '‍SKILL SET 20: DISEASES & CONDITIONS (5 SKILLS)',
    pages: 'Chapter 3, pp 72-84',
    theorySkills: ['Care During & After Death',
      'Diabetes Care',
      'Skin Conditions Care',
      'Cancer Care',
      'Arthritis Care',
    ],
  },
];

export function DetailedCourseOutlineScreen() {
  return (
    <>
      <ViewWrapper
        smSize={12}
        mdSize={6}
        lgSize={6}
        navigationBar
      >
        <View style={{ paddingVertical: 25, paddingHorizontal: 40 }}>
          <H2 textAlign="left">NextStep Skill Sets and Individual Skills</H2>
          <H3 textAlign="left">REQUIRED TEXTBOOK:</H3>
          <Text><Body1 textAlign="left" color={currentPalette.primary.main}>Hartman’s Nursing Assistant Care: The Basics, Fifth Edition</Body1></Text>
          <Body1 textAlign="left">by Hartman Publishing, Inc. with Jetta Fuzzy, RN, MS</Body1>

          {skillsets.map((s) => (
            <>
              <Subtitle2 style={Styles.m15}>{s.title}</Subtitle2>
              <View style={{ flexDirection: 'row', width: '100%', alignItems: 'center' }}>
                <Body1 style={Styles.m15}>
                  <Body2 style={Styles.m15}>Read:</Body2>
                  {' '}
                  Hartman&apos;s Nursing Assistant Care: The Basics, 5th Edition:
                  {' '}
                  <Body1 style={Styles.italic}>{s.pages}</Body1>
                </Body1>
              </View>
              <Body1 style={Styles.m15}>Theory Skills:</Body1>
              {s.theorySkills.map((t) => (
                <Body1 style={Styles.bulletPoint}>
                  •
                  {' '}
                  {t}
                </Body1>
              ))}
            </>
          ))}

          <Subtitle2 style={Styles.m15}>LAB</Subtitle2>
          <Body1 style={Styles.m15}>Students are eligible to attend Lab after completing all Theory by the Theory Completion Deadline and all Lab Prerequisites by the Lab Prerequisite Deadline. Lab includes 6 lab blocks and exam prep. Each block is 4 hours. Exam prep is 8 hours and is completed after Clinical.</Body1>
          <Subtitle2 style={Styles.m15}>CLINICAL</Subtitle2>
          <Body1 style={Styles.m15}>Once a Student completes Theory, all Lab Prerequisites and passes all Lab Blocks, they are eligible to attend Clinical. Clinical is 40 hours scheduled Monday through Friday during day shift, which usually runs from 6:00 a.m. until 4:30 p.m.</Body1>
        </View>
      </ViewWrapper>
    </>
  );
}

export default withDimensions(inject('rootStore')(observer(DetailedCourseOutlineScreen)));
