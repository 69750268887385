import PropTypes from 'prop-types';
import { Component } from 'react';
import { InteractionManager } from 'react-native';


const afterInteractions = async (task) => InteractionManager.runAfterInteractions(task);

class RenderAfterInteractions extends Component {
  static propTypes = { children: PropTypes.any.isRequired };

  interactionHandle = null;

  constructor(props) {
    super(props);
    this.state = { interactionsComplete: false };
  }


  async componentDidMount() {
    this.interactionHandle = await afterInteractions();
    this.setState({ interactionsComplete: true });
  }

  componentWillUnmount() {
    if (this.interactionHandle) {
      this.interactionHandle.cancel();
    }
  }

  render() {
    const { children } = this.props;

    const { interactionsComplete } = this.state;

    if (interactionsComplete) {
      return children;
    }
    return null;
  }
}

export {
  afterInteractions,
  RenderAfterInteractions,
};
