/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { Image, Platform, View } from 'react-native';
import hexToRgba from 'hex-to-rgba';
import { Column, Row } from '@nextstep/app/lib/ResponsiveLayout';
import Images from '@nextstep/app/Themes/Images';
import Palette from '@nextstep/app/Themes/Palette';
import withDimensions from '@nextstep/app/lib/withDimensions';
import { Subtitle1, Title } from '@nextstep/app/components/Text';

const currentPalette = Palette.light;

const DefaultStyles = {
  mySkillBadgeBackground: {
    width: 156,
    height: 156,
    borderRadius: 100,
    alignItems: 'center',
    justifyContent: 'center',
  },

  mySkillBadge: {
    width: 140,
    height: 140,
    resizeMode: 'cover',
    borderRadius: 100,
    overflow: 'hidden',
    alignItems: 'center',
  },

  mySkillBadgeMentor: {
    top: 10,
    resizeMode: 'contain',
    height: 141,
    width: 80,
  },
};

const FailedQuizMessage = (props) => (
  <View style={[{ justifyContent: 'center' }, Platform.OS !== 'web' && { height: '100%' }]}>

    {Platform.OS === 'web' && <View style={{ height: 36 }} />}

    <Row>
      <Column smSize={10}>

        <View style={{ alignItems: 'center' }}>
          <View style={[DefaultStyles.mySkillBadgeBackground, { backgroundColor: hexToRgba(props.color, 0.4) }]}>
            <View style={[DefaultStyles.mySkillBadge, { backgroundColor: props.color }]}>
              <Image
                style={DefaultStyles.mySkillBadgeMentor}
                source={Images.mentor[props.colorType].board}
              />
            </View>
          </View>
        </View>

        <View style={{ height: 40 }} />

        <Title color={props.color}>
          {props.title.toUpperCase()}
        </Title>

        <View style={{ height: 24 }} />

        <Subtitle1>
          {props.subtitle}
        </Subtitle1>

      </Column>
    </Row>

    {Platform.OS === 'web' && <View style={{ height: 36 }} />}

  </View>
);


FailedQuizMessage.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  color: PropTypes.string,
  colorType: PropTypes.string,
};

FailedQuizMessage.defaultProps = {
  title: 'WE KNOW YOU’VE GOT THIS, SO LET’S TRY again.',
  subtitle: 'To continue on to the next skill set, you need to score at least 80% correct. Keep trying, we know you’ll nail it.',
  color: currentPalette.primary.main,
  colorType: 'main',
};

export default withDimensions(FailedQuizMessage);
