import { StyleSheet } from 'react-native';
import Colors from '@nextstep/app/Themes/Colors';

export default StyleSheet.create({
  container: {
    borderRadius: 8,
    borderStyle: 'solid',
    borderWidth: 1,
    flexDirection: 'column',
    alignItems: 'flex-start',
    overflow: 'hidden',
    flex: 1,
    margin: 4,
  },

  optionBody: {
    padding: 12,
    flexDirection: 'row',
    alignItems: 'center',
  },

  optionBodyAnswer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  imageWrap: {
    position: 'absolute',
    right: 0,
    top: 0,
    padding: 8,
  },
  checked: { width: 18, height: 13, resizeMode: 'contain' },

  xMark: { width: 10, height: 10, resizeMode: 'contain', tintColor: Colors.white },
});
