import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Platform, View } from 'react-native';
import { inject, observer } from 'mobx-react';
import LottieView from 'lottie-react-native';
import { ButtonFilled } from '@nextstep/app/components/Button';
import { Body1, H3 } from '@nextstep/app/components/Text';
import withDimensions from '@nextstep/app/lib/withDimensions';
import ViewWrapper from '@nextstep/app/components/ViewWrapper';
import congratsAnimation from '@nextstep/assets/animations/congrats.json';
import NavigationService from '@nextstep/app/services/NavigationService';
import { ResponsiveStyleSheet } from '@nextstep/app/lib/ResponsiveLayout';

const ConfirmationScreenStyle = ResponsiveStyleSheet.create({
  contentContainer: {
    minHeight: '90%',
    justifyContent: 'space-between',
  },

  section: {
    paddingHorizontal: 40,
    alignItems: 'center',
  },

  imageStyle: {
    height: 80,
    width: 80,
  },
});

class ConfirmationScreen extends Component {
  componentDidMount() {
    const { onLoad } = this.props;
    onLoad();
  }

  onNextPress = () => {
    const { onNextPress } = this.props;
    onNextPress();
  }

  render() {
    const { dimensions, title, body, buttonText } = this.props;
    const Styles = ConfirmationScreenStyle(dimensions.deviceType);

    return (
      <ViewWrapper smSize={12} mdSize={6} lgSize={4}>

        <View style={Styles.contentContainer}>

          <View style={Styles.header} />

          <View style={{ height: 40 }} />

          <View style={Styles.section}>

            <View style={{ height: 40 }} />

            <LottieView
              source={congratsAnimation}
              autoPlay
              loop={false}
              style={{ width: '100%', maxWidth: 400, margin: 0, padding: 0 }}
            />

            { title && (
              <H3>{title}</H3>
            )}

            <View style={{ height: 10 }} />

            <Body1>{body}</Body1>

          </View>

          <View style={{ flex: 1 }} />

          <View
            style={[
              Styles.section,
              Platform.OS === 'web' && { paddingVertical: 40 },
            ]}
          >
            <ButtonFilled
              onPress={() => this.onNextPress()}
            >
              {buttonText}
            </ButtonFilled>
          </View>

        </View>

      </ViewWrapper>
    );
  }
}

ConfirmationScreen.propTypes = {
  onLoad: PropTypes.func,
  onNextPress: PropTypes.func,
  title: PropTypes.string,
  body: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
};

ConfirmationScreen.defaultProps = {
  onLoad: () => {},
  onNextPress: () => NavigationService.navigateToLearnerInitialScreen('Learn'),
  title: undefined,
  body: undefined,
};

export default withDimensions(inject('rootStore')(observer(ConfirmationScreen)));
