/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { Platform, Text, View } from 'react-native';
import LottieView from 'lottie-react-native';
import { Column, Row } from '@nextstep/app/lib/ResponsiveLayout';
import Palette from '@nextstep/app/Themes/Palette';
import withDimensions from '@nextstep/app/lib/withDimensions';
import Amy from '@nextstep/assets/animations/amy.json';
import Iris from '@nextstep/assets/animations/iris.json';
import Julie from '@nextstep/assets/animations/julie.json';
import { Subtitle1, Title } from '@nextstep/app/components/Text';

const currentPalette = Palette.light;

const animations = [Amy, Iris, Julie];

const FinalExamFinished = (props) => (
  <View style={[{ justifyContent: 'center' }, Platform.OS !== 'web' && { height: '100%' }]}>

    {Platform.OS === 'web' && <View style={{ height: 36 }} />}

    <Row>
      <Column smSize={10}>

        <LottieView
          source={animations[Math.floor((Math.random() * animations.length))]}
          style={{ height: 217, alignSelf: 'center' }}
          autoPlay
          loop={false}
        />

        <View style={{ height: 40 }} />

        <Title color={props.color}>
          {'YOU COMPLETED THE FINAL EXAM!'}
        </Title>

        <View style={{ height: 24 }} />

        <Text style={{ alignSelf: 'center' }}>
          <Subtitle1>
            {"Now, time for lab work. We'll be in touch with your exam results soon."}
          </Subtitle1>
        </Text>

      </Column>
    </Row>

    {Platform.OS === 'web' && <View style={{ height: 36 }} />}

  </View>
);


FinalExamFinished.propTypes = { color: PropTypes.string };

FinalExamFinished.defaultProps = { color: currentPalette.primary.main };

export default withDimensions(FinalExamFinished);
