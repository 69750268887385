import React from 'react';
import { Platform, View, Image, ScrollView } from 'react-native';
import { inject, observer } from 'mobx-react';
import { ButtonFilled, ButtonText } from '@nextstep/app/components/Button';
import withDimensions from '@nextstep/app/lib/withDimensions';
import ViewWrapper from '@nextstep/app/components/ViewWrapper';
import { Column, Row } from '@nextstep/app/lib/ResponsiveLayout';
import Divider from '@nextstep/app/components/Divider';
import { Images, Palette } from '@nextstep/app/Themes';
import NavigationService from '@nextstep/app/services/NavigationService';
import { Body1, Title } from '@nextstep/app/components/Text';
import Util from '@nextstep/app/services/Util';
import Config from '@nextstep/app/Config/DebugConfig';

const currentPalette = Palette.light;

const DefaultStyles = {
  mySkillBadgeBackground: {
    width: 156,
    height: 156,
    borderRadius: 100,
    alignItems: 'center',
    justifyContent: 'center',
  },

  mySkillBadge: {
    width: 140,
    height: 140,
    resizeMode: 'cover',
    borderRadius: 100,
    overflow: 'hidden',
    alignItems: 'center',
  },

  mySkillBadgeMentor: {
    top: 10,
    resizeMode: 'contain',
    height: 144,
    width: 80,
  },
};

const CovidProof = (props) => {
  const { rootStore: { sessionStore } } = props;
  const { navigation } = props;
  const { learner } = sessionStore;

  if (!learner?.studentId) {
    navigation.navigate('LaunchScreen');
  }

  return (
    <ViewWrapper
      smSize={12}
      mdSize={7}
      lgSize={6}
    >
      <View style={{ height: 40 }} />

      <Title color={currentPalette.text.main}>
        PROOF OF COVID-19 VACCINE
      </Title>

      <View style={{ height: 26 }} />

      <Divider />

      <View style={{ height: 26 }} />

      <ScrollView>
        <View
          style={[
            { justifyContent: 'center' },
            Platform.OS !== 'web' && { height: '100%' },
          ]}
        >
          {Platform.OS === 'web' && <View style={{ height: 36 }} />}

          <Row style={{ flex: 1 }}>
            <Column smSize={10} mdSize={10} lgSize={6}>
              <View style={{ alignItems: 'center' }}>
                <View
                  style={[
                    DefaultStyles.mySkillBadgeBackground,
                    { backgroundColor: currentPalette.background.primary },
                  ]}
                >
                  <View
                    style={[
                      DefaultStyles.mySkillBadge,
                      { backgroundColor: currentPalette.background.primary },
                    ]}
                  >
                    <Image
                      style={DefaultStyles.mySkillBadgeMentor}
                      source={Images.icons.upload}
                    />
                  </View>
                </View>
              </View>

              <View style={{ height: 24 }} />

              <Body1>
                State and federal regulations require staff in health care facilities to be fully vaccinated for COVID-19.
              </Body1>

              <View style={{ height: 12 }} />

              <Body1>
                Proof of at least one COVID-19 vaccine shot is required to enroll in the NextStep program.
              </Body1>

              <View style={{ height: 40 }} />

              <ButtonFilled
                width="100%"
                style={{ alignSelf: 'center' }}
                onPress={() => Util.openURL(`https://nextstepcareers.typeform.com/to/lN9zlwjh#student_id=${learner.studentId}`, '_self')}
                onLongPress={() => (Config.staging ? sessionStore.logout() : {})}
              >
                Upload COVID-19 Vaccine Proof
              </ButtonFilled>

              <View style={{ height: 32 }} />

              <ButtonText onPress={() => NavigationService.navigate('VaccineBenefits')}>
                I haven&apos;t received the COVID-19 vaccine or lost my card
              </ButtonText>
            </Column>
          </Row>

          <View style={{ height: 36 }} />
        </View>
      </ScrollView>
    </ViewWrapper>
  );
};

export default withDimensions(inject('rootStore')(observer(CovidProof)));
