import { types } from 'mobx-state-tree';
import ProgressNode from './ProgressNode';
import PracticeLab from './PracticeLab';

const PracticeLabProgressModel = types.model({
  id: types.identifierNumber,
  practiceLabId: types.number,
  scheduledAt: types.maybe(types.string),
  remainingSkillCount: types.maybe(types.number),
  parentType: types.maybe(types.string), // use constants
  content: types.maybe(types.safeReference(PracticeLab)),
}).actions(self => ({
  setCurrent: () => {
    self.store.practiceLabProgress = self;
    return self;
  },
}));

const PracticeLabProgress = types.compose(PracticeLabProgressModel, ProgressNode).named('PracticeLabProgress');

export default PracticeLabProgress;
