/* eslint-disable react/prop-types,react/destructuring-assignment */
import React from 'react';
import { Image, TouchableOpacity, View } from 'react-native';
import { Images, Palette } from '@nextstep/app/Themes/index';
import CustomModal from '@nextstep/app/components/CustomModal';
import { Body1, Subtitle2 } from '@nextstep/app/components/Text';
import { ButtonFilled } from '@nextstep/app/components/Button';
import ApplicationStyles from '@nextstep/app/Themes/ApplicationStyles';
import ModalStyles from './styles/FirstTimeCompleteModalStyles';

const currentPalette = Palette.light;

const FirstTimeStepCompleteModal = ({
  toggleVisible, isVisible, colorType,
  colorTheme, backgroundColor,
}) => (
  <CustomModal
    isVisible={isVisible}
    onBackdropPress={() => { toggleVisible(); }}
    onSwipeComplete={() => { toggleVisible(); }}
    onModalHide={() => toggleVisible()}
  >
    <View style={ApplicationStyles.modalStyles.container}>

      <View style={{ height: 20 }} />

      <View style={ModalStyles.headerSection}>

        <TouchableOpacity onPress={() => { toggleVisible(); }}>
          <Image
            style={ModalStyles.exitBtn}
            source={Images.icons.exit}
          />
        </TouchableOpacity>

      </View>

      <View style={ModalStyles.contentSection}>

        <Image
          style={ModalStyles.contentHead}
          source={Images.icons[colorType].checkMark}
        />

        <View style={{ height: 8 }} />

        <Subtitle2>
          Completed Steps
        </Subtitle2>

        <Body1>
          Completed steps are displayed as check marks. You can always click on completed steps to review learning content.
        </Body1>

      </View>

      <View style={{ height: 24 }} />

      <View style={[ModalStyles.footerSection, { backgroundColor }]}>

        <ButtonFilled
          onPress={() => { toggleVisible(); }}
          style={{ backgroundColor: colorTheme }}
          textColor={currentPalette.primary.contrastText}
        >
          Got it!
        </ButtonFilled>

      </View>
    </View>
  </CustomModal>
);
export default FirstTimeStepCompleteModal;
