/* eslint-disable react/prop-types */
import React from 'react';
import { Image, TouchableOpacity, View, ScrollView, Platform } from 'react-native';
import { ApplicationStyles, Images } from '@nextstep/app/Themes/index';
import CustomModal from '@nextstep/app/components/CustomModal';
import { Body1, H3 } from '@nextstep/app/components/Text';
import { ButtonFilled, ButtonOutline } from '@nextstep/app/components/Button';
import AdaptiveContainerStyles from '@nextstep/app/containers/styles/AdaptiveContainerStyles';
import Styles from './styles/BasicCustomModalStyles';

const GlobalStyles = AdaptiveContainerStyles();

const BottomSheetModal = ({
  isVisible,
  toggleVisible = () => {},
  icon,
  messageTitle,
  messageContent,
  primaryAction,
  secondaryAction,
  dismissible,
  onDismiss = () => {},
}) => (
  <CustomModal
    animationIn={Platform.OS === 'web' ? 'fadeIn' : 'slideInUp'}
    animationOut={Platform.OS === 'web' ? 'fadeOut' : 'slideInDown'}
    isVisible={isVisible}
    onBackdropPress={() => { if (dismissible) { toggleVisible(false); onDismiss(); } }}
    onSwipeComplete={() => { if (dismissible) { toggleVisible(false); onDismiss(); } }}
    style={Platform.OS !== 'web' && { marginHorizontal: 0, marginBottom: 0 }}
  >
    <View style={[
      ApplicationStyles.modalStyles.container,
      { marginTop: 'auto' },
      Platform.OS === 'web' && { marginTop: 0, maxHeight: '75%' }]
    }
    >

      <ScrollView style={{ minHeight: '50%' }}>

        <View style={{ height: 16 }} />

        <View style={Styles.header}>

          { dismissible && (
          <TouchableOpacity onPress={() => { toggleVisible(false); onDismiss(); }}>

            <Image
              style={Styles.headerIcon}
              source={Images.icons.exit}
            />

          </TouchableOpacity>
          )}

        </View>

        <View style={[Styles.section, GlobalStyles.textContainer]}>

          <View style={{ height: 16 }} />

          <Image
            style={Styles.modalIcon}
            source={icon}
          />

          <View style={{ height: 22 }} />

          <H3>
            {messageTitle}
          </H3>

          <View style={{ height: 8 }} />

          <Body1>
            {messageContent}
          </Body1>

          <View style={{ height: 16 }} />

        </View>

        <View style={Styles.bottomButton}>
          {primaryAction && (
            <ButtonFilled
              onPress={() => {
                toggleVisible(false);
                primaryAction.action();
              }}
            >
              {primaryAction.text}
            </ButtonFilled>
          )}

          <View style={{ height: 22 }} />

          {secondaryAction && (
            <ButtonOutline
              onPress={() => {
                toggleVisible(false);
                secondaryAction.action();
              }}
            >
              {secondaryAction.text}
            </ButtonOutline>
          )}
        </View>

        <View style={{ height: 20 }} />

      </ScrollView>

    </View>

  </CustomModal>
);

export default BottomSheetModal;
