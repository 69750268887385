/* eslint-disable react/destructuring-assignment,no-unused-expressions */
import { debounce } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

export default (WrappedComponent) => {
  class PreventDoubleClick extends React.PureComponent {
    debouncedOnPress = () => {
      this.props.onPress && this.props.onPress();
    };

    onPress = debounce(this.debouncedOnPress, 700, { leading: true, trailing: false });

    render() {
      return <WrappedComponent {...this.props} onPress={this.onPress} />;
    }
  }

  PreventDoubleClick.propTypes = { onPress: PropTypes.func };

  PreventDoubleClick.defaultProps = { onPress: null };

  PreventDoubleClick.displayName = `withPreventDoubleClick(${WrappedComponent.displayName || WrappedComponent.name})`;
  return PreventDoubleClick;
};
