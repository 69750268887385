import { types } from 'mobx-state-tree';
import Constants from '@nextstep/app/Config/Constants';

const InAppNotification = types.model('InAppNotification', {
  id: types.identifierNumber,
  content: types.maybe(types.string),
  questionnaireId: types.maybe(types.number),
  skillsetIndex: types.maybe(types.number),
  dismissible: types.optional(types.boolean, true),
  priority: types.optional(
    types.union(
      types.literal(Constants.Priority.low),
      types.literal(Constants.Priority.medium),
      types.literal(Constants.Priority.high),
    ), Constants.Priority.low,
  ),
  style: types.optional(
    types.union(
      types.literal(Constants.InAppReminderTypes.banner),
      types.literal(Constants.InAppReminderTypes.inline),
    ), Constants.InAppReminderTypes.banner,
  ),
});

export default InAppNotification;
