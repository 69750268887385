import { StyleSheet } from 'react-native';
import { Palette } from '@nextstep/app/Themes/index';

const currentPalette = Palette.light;

export default StyleSheet.create({
  container: {
    backgroundColor: currentPalette.background.main,
    height: '100%',
  },

  section: { paddingVertical: 24 },

  actionButtons: { justifyContent: 'center' },
});
