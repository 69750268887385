/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import moment from 'moment';
import ReadMore from 'react-native-read-more-text';
import { Palette, Typography } from '@nextstep/app/Themes';
import { Body1, Body2, Caption1 } from './Text';
import { ButtonText } from './Button';

const currentPalette = Palette.light;

const DefaultStyles = {
  boxContainer: {
    borderColor: currentPalette.basic.border,
    borderWidth: 2,
    borderRadius: 8,
    marginVertical: 10,
    padding: 15,
  },
};

const renderTruncatedFooter = (handlePress) => (
  <ButtonText
    onPress={handlePress}
    color={currentPalette.primary.main}
    style={{ alignItems: 'flex-start', marginTop: 10 }}
    textStyle={Typography.caption2}
  >
    Expand
  </ButtonText>
);

const renderRevealedFooter = (handlePress) => (
  <ButtonText
    onPress={handlePress}
    color={currentPalette.primary.main}
    style={{ alignItems: 'flex-start', marginTop: 10 }}
    textStyle={Typography.caption2}
  >
    Collapse
  </ButtonText>
);

const AssignmentQuestionResponse = ({ content, isInstructorResponse, from, sentOn }) => (
  <View
    style={[
      isInstructorResponse && { backgroundColor: currentPalette.background.secondary },
      DefaultStyles.boxContainer,
    ]}
  >

    <Caption1 textAlign="left" color={currentPalette.text.secondary}>
      {`${moment(sentOn).format('MM/DD/YYYY, hh:mm a')} (${moment(sentOn).fromNow()})`}
    </Caption1>

    <View style={{ height: 5 }} />

    {isInstructorResponse && (
      <Body2 textAlign="left" color={currentPalette.text.main}>{`${from} - Nurse Instructor`}</Body2>
    )}

    <View style={{ height: 12 }} />

    <Body2 textAlign="left" color={currentPalette.text.gray}>{isInstructorResponse ? 'Response:' : 'Answer:'}</Body2>

    {isInstructorResponse ? (
      <Body1 textAlign="left">{content}</Body1>
    ) : (
      <ReadMore
        numberOfLines={5}
        renderTruncatedFooter={renderTruncatedFooter}
        renderRevealedFooter={renderRevealedFooter}
        textStyle={{ color: 'white' }}
      >
        <Body1 textAlign="left">{content}</Body1>
      </ReadMore>
    )}
  </View>
);

AssignmentQuestionResponse.propTypes = {
  content: PropTypes.string,
  isInstructorResponse: PropTypes.bool,
  from: PropTypes.string,
  sentOn: PropTypes.string,
};

AssignmentQuestionResponse.defaultProps = {
  content: '',
  isInstructorResponse: false,
  from: '',
  sentOn: moment(),
};

export default AssignmentQuestionResponse;
