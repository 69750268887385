import { StatusCodes, ErrorCodes, ErrorActions, Events } from '@nextstep/app/Config/Constants';
import Logger from './Logger';

const getErrorAction = (errorCode, isFatal) => {
  let action = ErrorActions.retry;
  if (isFatal || errorCode === ErrorCodes.fatalError) {
    action = ErrorActions.restart;
  } else if (errorCode === ErrorCodes.versionError) {
    action = ErrorActions.updateApp;
  } else if (errorCode === ErrorCodes.conflictError) {
    action = ErrorActions.conflict;
  }
  return { errorCode, action };
};

const getErrorMessage = (error) => {
  if (error.status) {
    const statusCode = parseInt(error.status, 10);

    if (statusCode === StatusCodes.preconditionFailed) {
      return ErrorCodes.versionError;
    } if (statusCode === StatusCodes.conflict) {
      return ErrorCodes.conflictError;
    }
  }

  const message = (typeof error === 'string') ? error : error.problem || error.message || error.data.message;

  Logger.error(error, message);
  return message;
};

const ExceptionEmitter = (error, isFatal) => {
  // eslint-disable-next-line no-console
  console.error('error', error);
  const message = getErrorMessage(error);
  const { errorCode, action } = getErrorAction(message, isFatal);
  global.emitter.emit(Events.showErrorEvent, { errorCode, action, message });
};

export default ExceptionEmitter;
