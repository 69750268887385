import React from 'react';
import { StyleSheet, View, SafeAreaView, ScrollView } from 'react-native';
import SlideItems from './SlideItems';

const styles = StyleSheet.create({
  sidebar: {
    width: '99%',
    flex: 1,
    zIndex: 10,
    backgroundColor: '#fff',
    shadowColor: '#000',
    shadowOpacity: 0.12,
    shadowRadius: 2,
    shadowOffset: {
      height: 0,
      width: 2,
    },
  },
});

function DefaultContentComponent(props) {
  return (
    <ScrollView>
      <SafeAreaView forceInset={{ top: 'always', horizontal: 'never' }}>
        <SlideItems {...props} />
      </SafeAreaView>
    </ScrollView>
  );
}

export default function Sidebar(props) {
  const { contentComponent: Content = DefaultContentComponent } = props;

  return (
    <View style={styles.sidebar}>
      <Content {...props} />
    </View>
  );
}
