import { types } from 'mobx-state-tree';
import QuestionTiersProgress from './QuestionTiersProgress';
import WithStatus from './WithStatus';
import WithDuration from './WithDuration';
import ProgressNode from './ProgressNode';
import AssessmentBlock from './AssessmentBlock';

const AssessmentBlockProgressModel = types.model({
  id: types.identifierNumber,
  assessmentBlockId: types.maybe(types.number),
  courseKey: types.maybe(types.string),
  questionTiers: types.maybe(QuestionTiersProgress),
  content: types.maybe(types.safeReference(AssessmentBlock)),
})
  .views(self => ({
    get loaded() {
      return !!self.questionTiers;
    },
    get isBlockFailed() {
      const { questionTiers } = self;
      return (questionTiers.medium[1].answeredCorrectly === false || questionTiers.hard[1].answeredCorrectly === false);
    },
    get isBlockPassed() {
      const { questionTiers } = self;
      return (questionTiers.medium.some(question => question.answeredCorrectly) && questionTiers.hard.some(question => question.answeredCorrectly));
    },
    get currentQuestion() {
      const { questionTiers } = self;
      const { medium, hard } = questionTiers;
      if (self.isBlockPassed || self.isBlockFailed) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-console
        console.tron.log(`no current question because assessmentBlock ${self.id} is already ${self.isBlockPassed ? 'passed' : 'failed'}`);
        return undefined;
      }

      const nextAvailableMediumIndex = medium.findIndex(question => !question.isAnswered);
      const nextAvailableHardIndex = hard.findIndex(question => !question.isAnswered);

      if (nextAvailableMediumIndex === -1 || (medium[0].answeredCorrectly && nextAvailableHardIndex === 0)) {
        return self.questionTiers.hard[nextAvailableHardIndex]; // or ['hard', nextAvailableHardIndex]?
      }
      return self.questionTiers.medium[nextAvailableMediumIndex];
    },
  }))
  .actions(self => ({
    resetAllAnswers() {
      const { questionTiers } = self;
      const { hard, medium } = questionTiers;
      const allQuestions = [...hard, ...medium];
      allQuestions.forEach(question => question.resetAnswer());
    },
  }));

const AssessmentBlockProgress = types.compose(AssessmentBlockProgressModel, ProgressNode, WithStatus, WithDuration).named('AssessmentBlockProgress');

export default AssessmentBlockProgress;
