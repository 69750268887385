/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { Platform, View } from 'react-native';
// eslint-disable-next-line import/no-unresolved
import CheckBox from '@react-native-community/checkbox';
import { Colors, Palette } from '@nextstep/app/Themes';
import { Button, Body1 } from './Text';
import DebouncedTouchableOpacity from './DebouncedTouchableOpacity';

const currentPalette = Palette.light;


const defaultStyle = {
  height: 48,
  width: '80%',
  paddingHorizontal: 5,
  borderWidth: 2,
};

const styleBase = {
  alignItems: 'center',
  justifyContent: 'center',
};

export const ButtonFilled = (props) => {
  const btnStyle = {
    ...styleBase,
    backgroundColor: props.color,
    height: props.height,
    width: props.width,
    paddingHorizontal: props.paddingHorizontal,
    ...props.style,
  };

  btnStyle.borderRadius = btnStyle.height / 2;

  const textStyle = {
    color: props.textColor,
    ...props.textStyle,
  };

  if (props.disabled) {
    btnStyle.backgroundColor = props.disabledColor;
  }

  return (
    <DebouncedTouchableOpacity
      style={btnStyle}
      disabled={props.disabled}
      onPress={props.onPress}
    >
      <Button style={textStyle}>
        {props.children}
      </Button>
    </DebouncedTouchableOpacity>
  );
};

ButtonFilled.propTypes = {
  onPress: PropTypes.func,
  disabled: PropTypes.bool,
  disabledColor: PropTypes.string,
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  paddingHorizontal: PropTypes.number,
  textColor: PropTypes.string,
  // textStyle: PropTypes.object, // ---- Hidden property
  // style: PropTypes.object, // ---- Hidden property
};

ButtonFilled.defaultProps = {
  onPress: () => {},
  disabled: false,
  disabledColor: currentPalette.primary.disable,
  color: currentPalette.primary.main,
  height: defaultStyle.height,
  width: defaultStyle.width,
  paddingHorizontal: defaultStyle.paddingHorizontal,
  textColor: currentPalette.primary.contrastText,
  // textStyle: {}, // ---- Hidden property
  // style: {}, // ---- Hidden property
};

// --------------------------------------------------------------------------------------------------------------------

export const ButtonOutline = (props) => {
  const btnStyle = {
    ...styleBase,
    backgroundColor: Colors.transparent,
    height: props.height,
    width: props.width,
    borderColor: props.color,
    paddingHorizontal: props.paddingHorizontal,
    borderWidth: props.borderWidth,
    ...props.style,
  };

  btnStyle.borderRadius = btnStyle.height / 2;

  const textStyle = {
    color: props.textColor,
    ...props.textStyle,
  };

  if (props.disabled) {
    btnStyle.borderColor = props.disabledColor;
    textStyle.color = props.disabledColor;
  }

  return (
    <DebouncedTouchableOpacity
      style={btnStyle}
      disabled={props.disabled}
      onPress={props.onPress}
    >
      <Button style={textStyle}>
        {props.children}
      </Button>
    </DebouncedTouchableOpacity>
  );
};

ButtonOutline.propTypes = {
  onPress: PropTypes.func,
  disabled: PropTypes.bool,
  disabledColor: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  paddingHorizontal: PropTypes.number,
  borderWidth: PropTypes.number,
  textColor: PropTypes.string,
  color: PropTypes.string,
  // textStyle: PropTypes.object, // ---- Hidden property
  // style: PropTypes.object, // ---- Hidden property
};

ButtonOutline.defaultProps = {
  onPress: () => {},
  disabled: false,
  disabledColor: currentPalette.primary.disable,
  height: defaultStyle.height,
  width: defaultStyle.width,
  paddingHorizontal: defaultStyle.paddingHorizontal,
  borderWidth: defaultStyle.borderWidth,
  textColor: currentPalette.primary.main,
  color: currentPalette.primary.main,
  // textStyle: {}, // ---- Hidden property
  // style: {}, // ---- Hidden property
};

// --------------------------------------------------------------------------------------------------------------------

export const ButtonText = (props) => {
  const btnStyle = {
    ...styleBase,
    ...props.style,
  };

  const textStyle = {
    color: props.color,
    ...props.textStyle,
  };

  if (props.disabled) {
    textStyle.color = props.disabledColor;
  }

  return (
    <DebouncedTouchableOpacity
      style={btnStyle}
      disabled={props.disabled}
      onPress={props.onPress}
      onLongPress={props.onLongPress}
    >
      <Button style={textStyle} textAlign={props.textAlign}>
        {props.children}
      </Button>
    </DebouncedTouchableOpacity>
  );
};

ButtonText.propTypes = {
  onPress: PropTypes.func,
  onLongPress: PropTypes.func,
  disabled: PropTypes.bool,
  disabledColor: PropTypes.string,
  color: PropTypes.string,
  textStyle: PropTypes.object,
  textAlign: PropTypes.string,
  style: PropTypes.object,
};

ButtonText.defaultProps = {
  onPress: () => {},
  onLongPress: () => {},
  disabled: false,
  disabledColor: currentPalette.primary.disable,
  color: currentPalette.primary.main,
  textAlign: 'center',
  textStyle: {},
  style: {},
};

// --------------------------------------------------------------------------------------------------------------------


const platformTintColor = ({ trueColor, falseColor }) => {
  if (Platform.OS === 'android') {
    return { tintColors: { true: trueColor, false: falseColor } };
  }

  return {
    tintColor: falseColor,
    onTintColor: trueColor,
    onCheckColor: trueColor,
  };
};

export const ButtonSelect = (props) => {
  const textStyle = {
    color: props.textColor,
    textAlign: 'left',
    paddingHorizontal: 10,
    ...props.textStyle,
  };

  if (props.disabled) {
    textStyle.color = props.disabledColor;
  }

  return (
    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
      <CheckBox
        boxType="square"
        disabled={props.disabled}
        value={props.value}
        onValueChange={(state) => props.onValueChange(state)}
        {...platformTintColor(props)}
        style={{ height: 20, width: 20 }}
      />
      <Body1 style={textStyle}>
        {props.children}
      </Body1>
    </View>
  );
};

ButtonSelect.propTypes = {
  disabled: PropTypes.bool,
  disabledColor: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  value: PropTypes.bool.isRequired,
  onValueChange: PropTypes.func,
  trueColor: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  falseColor: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  textColor: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  // textStyle: PropTypes.object, // ---- Hidden property
};

ButtonSelect.defaultProps = {
  disabled: false,
  disabledColor: currentPalette.primary.disable,
  onValueChange: () => {},
  trueColor: currentPalette.primary.main,
  falseColor: currentPalette.primary.disable,
  textColor: currentPalette.text.main,
  // textStyle: {}, // ---- Hidden property
};
