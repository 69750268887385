import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Intercom from 'react-native-intercom';
import { ScrollView, Text, View } from 'react-native';
import { Icon } from 'react-native-elements';
import { FullViewSpinner } from '@nextstep/app/components/SharedUI';
import { analyticsScreenView } from '@nextstep/app/services/Analytics';
import { afterInteractions } from '@nextstep/app/lib/afterInteractions';
import withDimensions, { DeviceType } from '@nextstep/app/lib/withDimensions';
import { Column, Row } from '@nextstep/app/lib/ResponsiveLayout';
import { ApplicationStyles } from '@nextstep/app/Themes';
import AppMainHeader from '@nextstep/app/components/AppMainHeader';
import IconCard from '@nextstep/app/components/IconCard';
import ViewWrapper from '@nextstep/app/components/ViewWrapper';
import InPersonCollapse from '@nextstep/app/components/InPersonCollapse';
import Util from '@nextstep/app/services/Util';
import { Body1, Body2, Subtitle2 } from '@nextstep/app/components/Text';
import NavigationService from '@nextstep/app/services/NavigationService';
import Images from '@nextstep/app/Themes/Images';
import { currentPalette } from '@nextstep/app/Config/AppConfig';
import InPersonScreenData from './InPersonScreenData';

class InPersonScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      expandedCardId: ['practice_labs'],
    };
  }

  async componentDidMount() {
    const { navigation } = this.props;

    analyticsScreenView('InPersonScreenViewed');

    await afterInteractions();

    this.focusListener = navigation?.addListener('focus', () => this.refreshPracticeLabProgress());

    // Intercom Web Integration - if it is available
    if (Intercom.webIntegration) Intercom.webIntegration();
  }

  refreshPracticeLabProgress = () => {
    const { rootStore: { progressStore } } = this.props;

    progressStore.loadCoursePracticeLabProgresses();
  }

  setExpandedCard = (id) => {
    const { expandedCardId } = this.state;

    const tempExpandedCardId = [...expandedCardId];

    // eslint-disable-next-line no-unused-expressions
    expandedCardId.find(currId => currId === id)
      ? tempExpandedCardId.pop(id)
      : tempExpandedCardId.push(id);

    this.setState({ expandedCardId: tempExpandedCardId });
  }

  externalLinks = (array) => (
    array.map((link, index) => (
      <Column key={index} smSize={12} mdSize={4} lgSize={4} style={{ flexDirection: 'row' }}>

        <Icon
          name={link.iconName}
          color={currentPalette.primary.main}
          underlayColor="transparent"
          style={{ paddingRight: 8 }}
        />

        <Text onPress={() => Util.openURL(link.url)}>
          <Body1
            textAlign={'left'}
            color={currentPalette.primary.main}
          >
            {link.title}
          </Body1>
        </Text>

      </Column>
    ))
  )

  setPracticeLab = async (key) => {
    const { rootStore: { contentStore } } = this.props;

    await contentStore.setPracticeLab(key);
    NavigationService.navigate('PracticeLabDetailsScreen');
  };

  render() {
    const { rootStore: { contentStore, sessionStore }, dimensions, navigation } = this.props;
    const { loading, expandedCardId } = this.state;
    const { practiceLabs } = contentStore;
    const { learner } = sessionStore;

    if (loading || !practiceLabs || !learner) return <FullViewSpinner showBackground />;

    const CNAData = InPersonScreenData.clinicalPrerequisites(learner.legacyState);
    const practiceLabData = InPersonScreenData.practiceLabData(learner.legacyState);
    const clinicalData = InPersonScreenData.clinicalData(learner.legacyState);

    return (
      <>
        {dimensions.deviceType !== DeviceType.Desktop && <AppMainHeader navigation={navigation} />}
        <ViewWrapper
          smSize={12}
          mdSize={12}
          lgSize={12}
          hideBorder
          backgroundColor={currentPalette.background.main}
          bottomBarColor={currentPalette.background.main}
        >
          <ScrollView
            style={[
              ApplicationStyles.screenContainer(),
              { backgroundColor: currentPalette.background.main, paddingTop: 20 },
            ]}
            contentContainerStyle={{ flexGrow: 1, justifyContent: 'flex-start' }}
            bounces={false}
          >
            <Row>
              <Column smSize={11} mdSize={11} lgSize={11}>
                {/* ----- Collapsable #1 ----- */}
                <InPersonCollapse
                  id={'clinical_prerequisites'}
                  title={(
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                      <Subtitle2 textAlign={'left'}>
                        {CNAData.header.title}
                      </Subtitle2>
                    </View>
                  )}
                  expandedCardId={expandedCardId}
                  onToggle={this.setExpandedCard}
                >
                  {CNAData.header.body}

                  <View style={{ height: 22 }} />

                  <Row gutter={27} style={{ justifyContent: 'flex-start' }}>
                    {CNAData.body.map((props, index) => (
                      <Column key={index} smSize={12} mdSize={6} lgSize={4}>
                        <IconCard {...props} />
                      </Column>
                    ))}
                  </Row>

                </InPersonCollapse>

                <View style={{ height: 16 }} />

                {/* ----- Collapsable #2 ----- */}
                <InPersonCollapse
                  id={'practice_labs'}
                  title={(
                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                      <Subtitle2 textAlign={'left'}>
                        {practiceLabData.header.title}
                      </Subtitle2>

                      <View style={{ width: 8 }} />
                    </View>
                  )}
                  expandedCardId={expandedCardId}
                  onToggle={this.setExpandedCard}
                >
                  {practiceLabData.header.body}

                  <View style={{ height: 22 }} />

                  <Row style={{ justifyContent: 'flex-start' }}>
                    {this.externalLinks(practiceLabData.links)}
                  </Row>

                  <View style={{ height: 22 }} />

                  <Row gutter={27} style={{ justifyContent: 'flex-start' }}>
                    {practiceLabs.map((practiceLab, index) => (
                      <Column key={index} smSize={12} mdSize={6} lgSize={4}>
                        <IconCard
                          title={practiceLab.title}
                          enableIcon={Images.inPersonTab.beakerBW}
                          onPress={practiceLab.requiredSkillsetKey ? () => this.setPracticeLab(practiceLab.key) : undefined}
                          // description={<IconTimer time={256} />} // TODO: check if amount of time is present
                        />
                        {/* NOTE: "practiceLab.requiredSkillsetKey" Prevents non skills labs to be clickable */}
                      </Column>
                    ))}
                  </Row>

                </InPersonCollapse>

                <View style={{ height: 16 }} />

                {/* ----- Collapsable #3 ----- */}
                <InPersonCollapse
                  id={'clinical_rotations'}
                  title={'Clinical Rotations'}
                  expandedCardId={expandedCardId}
                  onToggle={this.setExpandedCard}
                >
                  {clinicalData.header.body}

                  <View style={{ height: 22 }} />

                  <Body2 textAlign="left">Clinical Code of Conduct</Body2>

                  <View style={{ height: 12 }} />

                  <Row gutter={30} style={{ justifyContent: 'flex-start' }}>
                    {this.externalLinks(clinicalData.links)}
                  </Row>

                  <View style={{ height: 22 }} />

                  <Row gutter={27} style={{ justifyContent: 'flex-start' }}>
                    {clinicalData.body.map((props, index) => (
                      <Column key={index} smSize={12} mdSize={6} lgSize={4}>
                        <IconCard {...props} />
                      </Column>
                    ))}
                  </Row>

                </InPersonCollapse>

                <View style={{ height: 40 }} />

              </Column>
            </Row>
          </ScrollView>
        </ViewWrapper>
      </>
    );
  }
}

export default withDimensions(inject('rootStore')(observer(InPersonScreen)));
