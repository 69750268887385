import { Component } from 'react';
import NavigationService from '@nextstep/app/services/NavigationService';
import Constants, { SkillSectionTypes } from '@nextstep/app/Config/Constants';

// This is a parent object for all skill content screens. This will allow
//  us to abstract away generic concerns like moving from one content item
//  to another, and other common functionality among the screens.
export class SkillChildNavigator extends Component {
  navigateBack = () => {
    NavigationService.back();
  };

  navigateFromAssessment = ({ isPassed, isFinalExam }) => {
    const { navigation } = this.props;

    if (isFinalExam) {
      navigation.replace('OutcomeScreen', { status: Constants.OutcomeTypes.finalExamFinished });
    } else if (isPassed) {
      this.goToNextSkillChild();
    } else {
      navigation.replace('OutcomeScreen', { status: Constants.OutcomeTypes.knowledgeCheckFailed });
    }
  }

  goToNextSkillChild = async () => {
    const { navigation, rootStore: { progressStore, contentStore } } = this.props;

    const section = progressStore.getNextSkillSection();
    if (!section) {
      navigation.replace('OutcomeScreen');
      return;
    }

    let screen = section.type;
    if (section.type === SkillSectionTypes.step) {
      await contentStore.setStep(section.object.key);
      await progressStore.setStepProgress(section.object.id);
      screen = progressStore.getNextStepChild();
    }

    navigation.replace(screen, {
      stepKey: section.type === 'Step' && section.object.key,
      skillKey: contentStore.skill.key,
      skillsetKey: contentStore.skillset.key,
    });
  };
}

export default SkillChildNavigator;
