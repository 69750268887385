import React, { Component } from 'react';
import { View } from 'react-native';
import { inject, observer } from 'mobx-react';
import { ExceptionEmitter } from '@nextstep/app/lib/ErrorUtils';
import { Body1, Caption1, H3 } from '@nextstep/app/components/Text';
import { ButtonFilled, ButtonText } from '@nextstep/app/components/Button';
import { TextInput } from '@nextstep/app/components/TextInput';
import Constants, { Severity } from '@nextstep/app/Config/Constants';
import withDimensions from '@nextstep/app/lib/withDimensions';
import ViewWrapper from '@nextstep/app/components/ViewWrapper';
import NavigationService from '@nextstep/app/services/NavigationService';
import { Column, Row } from '@nextstep/app/lib/ResponsiveLayout';
import { FullViewSpinner } from '@nextstep/app/components/SharedUI';
import Util from '@nextstep/app/services/Util';
import AppBlockedScreen from '@nextstep/app/containers/AppBlockedScreen';
import Config from '@nextstep/app/Config/DebugConfig';

export class AccessVerificationCodeScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneNumber: props.route.params?.phoneNumber,
      forwardedCode: false,
      verificationCode: {
        value: undefined,
        showError: false,
        errorMessage: undefined,
      },
      loading: false,
    };
    this.onNextPressed = this.onNextPressed.bind(this);
  }

  async onNextPressed() {
    this.setState({ loading: true });

    if (!this.isVerificationCodeValid()) {
      this.setState({ loading: false });
      return;
    }

    this.performLogin().catch(ExceptionEmitter);
  }

  isVerificationCodeValid = () => {
    const { verificationCode } = this.state;

    if (!verificationCode.value || verificationCode.value.length !== 6) {
      this.setState({
        verificationCode: {
          ...verificationCode,
          showError: true,
          errorMessage: 'Verification code is 6 digits.',
        },
      });
      return false;
    }

    return true;
  };

  async performLogin() {
    const { rootStore: { sessionStore } } = this.props;
    const { verificationCode, phoneNumber } = this.state;

    const phoneNumberDigits = phoneNumber.match(/\d+/g).join([]);

    const response = await sessionStore.login({
      phone: phoneNumberDigits,
      password: verificationCode.value,
    });

    if (response && response.errors) {
      this.setState({
        verificationCode: {
          ...verificationCode,
          showError: true,
          errorMessage: 'This verification code is not valid. Please try again.',
        },
      });
      this.finishLoading();
      return;
    }

    if (Util.isAppBlockedWithReason(sessionStore, [Severity.high])) {
      this.blockLearner();
      return;
    }

    NavigationService.navigateToLearnerInitialScreen('Learn');

    this.finishLoading();
  }

  blockLearner = () => {
    const { rootStore: { sessionStore } } = this.props;
    const { learner } = sessionStore;
    AppBlockedScreen(learner);
  };

  finishLoading = () => {
    this.setState({ loading: false });
  };

  resendVerificationCode() {
    this.setState({ forwardedCode: true });
    this.checkPhoneNumber();
  }

  checkPhoneNumber() {
    const { rootStore } = this.props;
    const { sessionStore } = rootStore;
    const { phoneNumber } = this.state;

    const phone = phoneNumber.match(/\d+/g).join([]);
    sessionStore.checkPhoneNumber({ phone });
  }

  onVerificationCodeChange = (text) => {
    const { verificationCode } = this.state;

    if (verificationCode.showError) { this.setState({ verificationCode: { showError: false } }); }
    this.setState({ verificationCode: { value: text } });
  }

  render() {
    const { rootStore: { sessionStore } } = this.props;
    const { verificationCode, phoneNumber = '', forwardedCode, loading } = this.state;

    if (loading) return <FullViewSpinner message={'One moment while we load your progress...'} />;

    return (
      <ViewWrapper smSize={12} mdSize={5} lgSize={4}>

        <Row style={{ justifyContent: 'center' }}>
          <Column smSize={10} mdSize={10} lgSize={10}>

            <View style={{ height: 32 }} />

            <H3>
              Verification Code
            </H3>

            <View style={{ height: 25 }} />

            <Body1>
              {`Enter the 6 digit verification code sent to ${phoneNumber
                .replace(' ', '\u00A0')
                .replace('-', '\u2011')
              }.`}
            </Body1>

            <View style={{ height: 21 }} />

            <TextInput
              title={'Verify Code'}
              data={verificationCode}
              customAttributes={{
                placeholder: 'Verification Code',
                ref: (ref) => {
                  this.verificationCode = ref;
                },
                autoFocus: true,
              }}
              valueType={Constants.InputTextTypes.verificationCode}
              onClear={() => {
                this.onVerificationCodeChange('');
                this.verificationCode.focus();
              }}
              isFocused
              onChangeText={(text) => this.onVerificationCodeChange(text)}
              onSubmitEditing={() => this.onNextPressed()}
            />

            <View style={{ height: 43 }} />

            <ButtonFilled
              onPress={() => this.onNextPressed()}
              width={'100%'}
              disabled={loading}
              onLongPress={() => (Config.staging ? sessionStore.logout() : {})}
            >
              Verify
            </ButtonFilled>

            <View style={{ height: 48 }} />

            <Body1>Didn’t receive a text from us?</Body1>
            <ButtonText onPress={() => { this.resendVerificationCode(); }}>
              Resend code
            </ButtonText>
            { forwardedCode && <Caption1>It could take up to 30s</Caption1>}

            <View style={{ height: 34 }} />
          </Column>
        </Row>

      </ViewWrapper>
    );
  }
}

export default withDimensions(inject('rootStore')(observer(AccessVerificationCodeScreen)));
