import { types } from 'mobx-state-tree';
import { Status, StatusEnumeration } from '@nextstep/app/Config/Constants';

const WithStatus = types.model({ status: types.enumeration(StatusEnumeration) }).views(self => ({
  get isLocked() {
    return self.status === Status.locked;
  },
  get isUnlocked() {
    return self.status === Status.unlocked;
  },
  get isInProgress() {
    return self.status === Status.inProgress;
  },
  get isCompleted() {
    return self.status === Status.completed;
  },
  get isFailed() {
    return self.status === Status.failed;
  },
}));

export default WithStatus;
