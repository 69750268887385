const { skillset } = require('@nextstep/tests/fixtures/ZeusContentEndpoints/skillsets_id.json');
const { skill } = require('@nextstep/tests/fixtures/ZeusContentEndpoints/skills_id.json');
const { linearAssessment } = require('@nextstep/tests/fixtures/ZeusContentEndpoints/linearAssessments_id');
const { linearAssessmentProgress } = require('@nextstep/tests/fixtures/ZeusProgressPUTEndpoints/linearAssessmentProgress_id_start.json');
const { linearAssessmentIteration } = require('@nextstep/tests/fixtures/ZeusContentEndpoints/linearAssessmentIterations_id.json');
const AssignmentQuestions = require('@nextstep/tests/fixtures/ZeusContentEndpoints/assignment_questions.json');
const AssignmentQuestionsProgresses = require('@nextstep/tests/fixtures/ZeusProgressGETEndpoints/assignment_questions_progress.json');
const AssignmentQuestionsResponses = require('@nextstep/tests/fixtures/ZeusProgressGETEndpoints/assignment_question_responses.json');
const LearnerFixture = require('@nextstep/tests/fixtures/user.json');

export default {
  getUser: () => Promise.resolve({
    ok: true,
    data: { learner: LearnerFixture },
  }),

  login: () => Promise.resolve({
    ok: true,
    headers: {
      'ns-refresh': 'dummy',
      authorization: 'dummy ',
    },
    data: { learner: LearnerFixture },
  }),

  createGuest: () => Promise.resolve({
    ok: true,
    data: {
      id: 316,
      password: '772575',
    },
  }),

  reregister: () => Promise.resolve({
    ok: true,
    status: 200,
    data: { learner: LearnerFixture },
  }),

  checkEmail: () => Promise.resolve({
    ok: false,
    status: 404,
    data: null,
  }),

  signUp: () => Promise.resolve({
    ok: true,
    data: {
      learner: {
        id: 123,
        legalFirstName: 'Dummy',
        legalMiddleName: 'Dummy',
        legalLastName: 'Dummy',
        preferredFirstName: 'Dummy',
        preferredLastName: 'Dummy',
        email: 'Dummy',
        homeStreet1: 'Dummy',
        homeStreet2: 'Dummy',
        homeZipCode: 'Dummy',
        homeState: 'NJ',
        homeCity: 'Dummy',
        phone: 'Dummy',
        status: 'verified',
      },
    },
  }),

  guestSignUp: () => Promise.resolve({
    ok: true,
    data: {
      learner: {
        id: 123,
        legalFirstName: 'Dummy',
        legalMiddleName: 'Dummy',
        legalLastName: 'Dummy',
        preferredFirstName: 'Dummy',
        preferredLastName: 'Dummy',
        email: 'Dummy',
        homeStreet1: 'Dummy',
        homeStreet2: 'Dummy',
        homeZipCode: 'Dummy',
        homeState: 'NJ',
        homeCity: 'Dummy',
        phone: 'Dummy',
        status: 'verified',
        isGuest: false,
      },
    },
  }),

  checkPhoneNumber: () => Promise.resolve({
    ok: true,
    data: { success: true },
  }),

  sendAssignmentQuestionResponse: () => Promise.resolve({
    ok: true,
    data: null,
  }),

  getCourseSkillsets: () => Promise.resolve({
    ok: true,
    data: require('@nextstep/tests/fixtures/ZeusContentEndpoints/courses_id_skillsets'),
  }),

  getCoursePracticeLabs: () => Promise.resolve({
    ok: true,
    data: require('@nextstep/tests/fixtures/ZeusContentEndpoints/courses_id_practiceLabs'),
  }),

  getLearnerCourseMetrics: () => {
    const learnerCourseMetrics = require('@nextstep/tests/fixtures/ZeusProgressGETEndpoints/courseProgress_id_metrics.json');
    return Promise.resolve({
      ok: true,
      data: { metrics: learnerCourseMetrics },
    });
  },

  getCourseSkills: () => {
    const courseSkills = require('@nextstep/tests/fixtures/ZeusProgressGETEndpoints/course_id_skills_content.json');
    return Promise.resolve({
      ok: true,
      data: { data: courseSkills },
    });
  },

  getCourseSkillsetProgresses: () => {
    const data = require('@nextstep/tests/fixtures/ZeusProgressGETEndpoints/courseProgress_id_skillsets.json');
    return Promise.resolve({
      ok: true,
      data,
    });
  },

  getCoursePracticeLabProgresses: () => {
    const data = require('@nextstep/tests/fixtures/ZeusProgressGETEndpoints/courseProgress_id_practiceLabs.json');
    return Promise.resolve({
      ok: true,
      data,
    });
  },

  getSkillsetProgress: (id) => {
    const skillsetProgress = require('@nextstep/tests/fixtures/ZeusProgressGETEndpoints/skillsetProgress_id.json');
    return Promise.resolve({
      ok: true,
      data: { skillsetProgress: { ...skillsetProgress.skillsetProgress, ...id } },
    });
  },

  getSkillset: ({ skillsetKey }) => Promise.resolve({
    ok: true,
    data: { skillset: { ...skillset, key: skillsetKey } },
  }),

  getSkillsetLinearAssessment: () => Promise.resolve({
    ok: true,
    data: { linearAssessment: { ...linearAssessment, key: skillset.linearAssessment.key, id: skillset.linearAssessment.id } },
  }),

  getLinearAssessmentIteration: () => Promise.resolve({
    ok: true,
    data: { linearAssessmentIteration },
  }),

  getSkill: ({ skillKey }) => Promise.resolve({
    ok: true,
    data: { skill: { ...skill, key: skillKey, id: skillset.skills.find(s => s.key === skillKey)?.id ?? 1 } },
  }),

  getSkillLinearAssessment: () => Promise.resolve({
    ok: true,
    data: { linearAssessment: { ...linearAssessment, key: skill.linearAssessment.key, id: skill.linearAssessment.id } },
  }),

  getAssignmentQuestionProgresses: () => Promise.resolve({
    ok: true,
    data: AssignmentQuestionsProgresses,
  }),

  getAssignmentQuestionResponses: () => Promise.resolve({
    ok: true,
    data: AssignmentQuestionsResponses,
  }),

  startSkill: (id) => {
    const { skillProgress } = require('@nextstep/tests/fixtures/ZeusProgressPUTEndpoints/skillProgress_id.json');
    return Promise.resolve({
      ok: true,
      data: { skillProgress: { ...skillProgress, id } },
    });
  },

  getStep: ({ stepKey }) => {
    const step = require('@nextstep/tests/fixtures/ZeusContentEndpoints/steps_id');
    return Promise.resolve({
      ok: true,
      data: { step: { ...step.step, key: stepKey } },
    });
  },

  startStep: (id) => {
    const { stepProgress } = require('@nextstep/tests/fixtures/ZeusProgressPUTEndpoints/stepProgress_id.json');
    return Promise.resolve({
      ok: true,
      data: { stepProgress: { ...stepProgress, id } },
    });
  },

  startIntroVideo: (id) => {
    const { introProgress } = require('@nextstep/tests/fixtures/ZeusProgressPUTEndpoints/introProgress_id_start.json');
    return Promise.resolve({
      ok: true,
      data: { introProgress: { ...introProgress, id } },
    });
  },

  completeIntroVideo: (id) => {
    const { introProgress } = require('@nextstep/tests/fixtures/ZeusProgressPUTEndpoints/introProgress_id_complete.json');
    return Promise.resolve({
      ok: true,
      data: { introProgress: { ...introProgress, id } },
    });
  },

  getSuccessCriteriaProgress: (id) => {
    const { successCriteriaProgress } = require('@nextstep/tests/fixtures/ZeusProgressGETEndpoints/successCriteriaProgress_id_complete.json');
    return Promise.resolve({
      ok: true,
      data: { successCriteriaProgress: { ...successCriteriaProgress, id } },
    });
  },

  startAssessmentBlock: (id) => {
    const { assessmentBlockProgress } = require('@nextstep/tests/fixtures/ZeusProgressPUTEndpoints/assessmentBlockProgress_id_start.json');
    return Promise.resolve({
      ok: true,
      data: { assessmentBlockProgress: { ...assessmentBlockProgress, id } },
    });
  },

  completeAssessmentBlock: (id) => {
    const { assessmentBlockProgress } = require('@nextstep/tests/fixtures/ZeusProgressPUTEndpoints/assessmentBlockProgress_id_complete.json');
    return Promise.resolve({
      ok: true,
      data: { assessmentBlockProgress: { ...assessmentBlockProgress, id } },
    });
  },

  failAssessmentBlock: (id) => {
    const { assessmentBlockProgress } = require('@nextstep/tests/fixtures/ZeusProgressPUTEndpoints/assessmentBlockProgress_id_fail.json');
    return Promise.resolve({
      ok: true,
      data: { assessmentBlockProgress: { ...assessmentBlockProgress, id } },
    });
  },

  startSuccessCriteria: (id) => {
    const { successCriteriaProgress } = require('@nextstep/tests/fixtures/ZeusProgressPUTEndpoints/successCriteriaProgress_id_start.json');
    return Promise.resolve({
      ok: true,
      data: { successCriteriaProgress: { ...successCriteriaProgress, id } },
    });
  },

  completeSuccessCriteria: (id) => {
    const { successCriteriaProgress } = require('@nextstep/tests/fixtures/ZeusProgressPUTEndpoints/successCriteriaProgress_id_complete.json');
    return Promise.resolve({
      ok: true,
      data: { successCriteriaProgress: { ...successCriteriaProgress, id } },
    });
  },

  startDemoVideo: (id) => {
    const { demoVideoProgress } = require('@nextstep/tests/fixtures/ZeusProgressPUTEndpoints/demoVideoProgress_id_start.json');
    return Promise.resolve({
      ok: true,
      data: { demoVideoProgress: { ...demoVideoProgress, id } },
    });
  },

  completeDemoVideo: (id) => {
    const { demoVideoProgress } = require('@nextstep/tests/fixtures/ZeusProgressPUTEndpoints/demoVideoProgress_id_complete.json');
    return Promise.resolve({
      ok: true,
      data: { demoVideoProgress: { ...demoVideoProgress, id } },
    });
  },

  startLinearAssessment: (id) => Promise.resolve({
    ok: true,
    data: { linearAssessmentProgress: { ...linearAssessmentProgress, id } },
  }),

  completeLinearAssessment: (id) => Promise.resolve({
    ok: true,
    data: { linearAssessmentProgress: { ...linearAssessmentProgress, id } },
  }),

  failLinearAssessment: (id) => Promise.resolve({
    ok: true,
    data: { linearAssessmentProgress: { ...linearAssessmentProgress, id } },
  }),

  getAssessmentBlock: ({ key, assessmentBlockKey }) => {
    const data = require('@nextstep/tests/fixtures/ZeusContentEndpoints/assessmentBlocks_id');
    return Promise.resolve({
      ok: true,
      data: { assessmentBlock: { ...data.assessmentBlock, key: key || assessmentBlockKey } },
    });
  },

  getSkillProgress: (id) => {
    const { skillProgress } = require('@nextstep/tests/fixtures/ZeusProgressGETEndpoints/skillProgress_id.json');
    return Promise.resolve({
      ok: true,
      data: { skillProgress: { ...skillProgress, id } },
    });
  },

  getIntroVideoProgress: (id) => {
    const introVideo = require('@nextstep/tests/fixtures/ZeusProgressGETEndpoints/introProgress_id.json');
    return Promise.resolve({
      ok: true,
      data: { introProgress: { ...introVideo.introProgress, id } },
    });
  },

  getStepProgress: (id) => {
    const step = require('@nextstep/tests/fixtures/ZeusProgressGETEndpoints/stepProgress_id.json');
    return Promise.resolve({
      ok: true,
      data: { stepProgress: { ...step.stepProgress, id } },
    });
  },

  getAssessmentBlockProgress: (id) => {
    const data = require('@nextstep/tests/fixtures/ZeusProgressGETEndpoints/assessmentBlockProgress_id.json');
    return Promise.resolve({
      ok: true,
      data: { ...data.assessmentBlock, id },
    });
  },

  updateUser: (id, data) => Promise.resolve({
    ok: true,
    data: { learner: { ...LearnerFixture, ...data } },
  }),

  addUserVideo: () => ({
    ok: true,
    data: { success: true },
  }),

  getToken: () => Promise.resolve({
    ok: true,
    data: { success: true },
  }),

  createCourseProgress: ({ courseId }) => {
    const progress = require('@nextstep/tests/fixtures/ZeusProgressPOSTEndpoints/courseProgress_id.json');

    return Promise.resolve({
      ok: true,
      data: { courseProgress: { ...progress.courseProgress, courseId } },
    });
  },

  getCourseProgresses: () => Promise.resolve({
    ok: true,
    data: require('@nextstep/tests/fixtures/ZeusProgressGETEndpoints/courses.json'),
  }),

  anchorSkillset: (skillsetId) => {
    const progress = require('@nextstep/tests/fixtures/ZeusProgressPOSTEndpoints/anchorSkillset.json');
    Promise.resolve({
      ok: true,
      data: { data: { skillsetProgress: progress.skillsetProgress, skillsetId } },
    });
  },

  addMarketingTag: () => Promise.resolve({ result_code: 1 }),

  logEvent: () => ({ success: true }),

  refreshToken: () => Promise.resolve({
    ok: true,
    headers: {
      'ns-refresh': 'dummy',
      authorization: 'dummy ',
    },
  }),

  generates3url: () => Promise.resolve({
    ok: true,
    data: { key: 'https://reqres.in/api/fakeS3signedURL' },
  }),

  getInAppNotifications: () => Promise.resolve({
    ok: true,
    data: require('@nextstep/tests/fixtures/ZeusContentEndpoints/inAppNotifications.json'),
  }),

  inAppNotificationDismiss: () => Promise.resolve({
    ok: true,
    data: {},
  }),

  inAppNotificationDisplayed: () => Promise.resolve({
    ok: true,
    data: {},
  }),

  inAppNotificationInteracted: () => Promise.resolve({
    ok: true,
    data: {},
  }),

  postMessage: () => Promise.resolve({
    ok: true,
    data: { success: true, id: 42 },
  }),

  postNote: () => ({
    ok: true,
    data: { success: true },
  }),

  fetchConversations: () => Promise.resolve({
    ok: false,
    data: { conversations: { conversation_message: { id: 42 } } },
  }),

  getCourse: ({ key }) => {
    const course = require('@nextstep/tests/fixtures/ZeusContentEndpoints/courses_id');
    return Promise.resolve({
      ok: true,
      data: { course: { ...course.course, key } },
    });
  },

  acceptPaymentTrack: () => Promise.resolve({
    ok: true,
    data: {},
  }),

  skipPaymentTrack: () => Promise.resolve({
    ok: true,
    data: {},
  }),

  emailEnrollmentAgreement: () => Promise.resolve({
    ok: true,
    data: {},
  }),

  getEnrollmentAgreements: () => {
    const agreementsResponse = require('@nextstep/tests/fixtures/ZeusLearnerGETEndpoints/enrollment_agreements.json');
    return Promise.resolve({
      ok: true,
      data: { data: agreementsResponse.data },
    });
  },

  logTimer: () => Promise.resolve({
    ok: true,
    data: { remainingRequiredTime: 200 },
  }),

  createCheckoutSession: () => Promise.resolve({
    ok: true,
    data: { payment: { checkout_session_id: 1 } },
  }),

  createCardCaptureSession: () => Promise.resolve({
    ok: true,
    data: { payment: { checkout_session_id: 1 } },
  }),

  getApprovedStates: () => Promise.resolve({
    ok: true,
    data: {
      count: 1,
      data: [
        {
          id: 2,
          abbreviation: 'CO',
        },
      ],
    },
  }),

  getAssignmentQuestions: () => Promise.resolve({
    ok: true,
    data: AssignmentQuestions,
  }),

  acceptEnrollmentAgreement: () => Promise.resolve({
    ok: true,
    status: 200,
    data: {},
  }),

  acceptUpskillAgreement: () => Promise.resolve({
    ok: true,
    data: {},
  }),

  getQuestionnaire: () => Promise.resolve({
    ok: true,
    data: require('@nextstep/tests/fixtures/ZeusContentEndpoints/learner_questionnaire.json'),
  }),
};
