import RNRestart from 'react-native-restart';
import { Alert } from 'react-native';

import Logger from './Logger';

const JSExceptionHandler = (e, isFatal) => {
  if (isFatal) {
    Alert.alert(`Oops!
      An Error Occurred.`,
    'We will need to restart the app. \n Our team has been informed of the problem.', [{
      text: 'Restart',
      onPress: () => {
        RNRestart.Restart();
      },
    }]);
    Logger.error(e, isFatal);
  } else {
    Logger.log(e, isFatal);
  }
};

export default JSExceptionHandler;
