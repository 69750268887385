import { Platform } from 'react-native';
import Config from 'react-native-config';

export default {
  useFixtures: false,
  ezLogin: false,
  logBox: false,
  includeExamples: __DEV__,
  useReactotron: __DEV__ && Platform.OS !== 'web',
  answerSolver: Config.APP_BUNDLE_ID === 'com.nextstep.careers.dev',
  staging: Config.APP_BUNDLE_ID === 'com.nextstep.careers.dev',
  production: !__DEV__ && Config.APP_BUNDLE_ID === 'com.nextstep.careers.app',
  printSnapshots: false,
};
