import React from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { Alert, Animated, Platform, View } from 'react-native';
import { Body2 } from '@nextstep/app/components/Text';
import withDimensions from '@nextstep/app/lib/withDimensions';
import ViewWrapper from '@nextstep/app/components/ViewWrapper';
import { FullViewSpinner } from '@nextstep/app/components/SharedUI';
import QuestionHeader from '@nextstep/app/components/linearAssessment/QuestionHeader';
import QuestionFooter from '@nextstep/app/components/linearAssessment/QuestionFooter';
import QuestionText from '@nextstep/app/components/linearAssessment/QuestionText';
import AnswerChoice from '@nextstep/app/components/linearAssessment/AnswerChoice';
import Constants, { ContentNodeTypes, ProgressNodeTypes, QuestionTypes } from '@nextstep/app/Config/Constants';
import DebouncedTouchableOpacity from '@nextstep/app/components/DebouncedTouchableOpacity';
import NavigationService from '@nextstep/app/services/NavigationService';
import Config from '@nextstep/app/Config/DebugConfig';
import AdaptiveContainerStyles from '@nextstep/app/containers/styles/AdaptiveContainerStyles';
import BottomSheetModal from '@nextstep/app/modals/BottomSheetModal';
import { Images } from '@nextstep/app/Themes';
import NetInfo from '@react-native-community/netinfo';
import SkillChildNavigator from './SkillChildNavigator';

class LinearAssessmentScreen extends SkillChildNavigator {
  static propTypes = {
    navigation: PropTypes.object.isRequired,
    rootStore: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isAnsweredCorrectly: null,
      loading: true,
      isKnowledgeCheck: false,
      currentQuestionIndex: 0,
      correctQuestionCount: 0,
      selectedAnswers: [],
      fadeBanner: new Animated.Value(100),
      showingResult: false,
      finalExamTimer: null,
    };
  }

  async componentDidMount() {
    try {
      const { rootStore: { progressStore, contentStore }, route } = this.props;

      contentStore.clearCurrentExcept([ContentNodeTypes.course, ContentNodeTypes.skillset]);
      progressStore.clearCurrentExcept([ProgressNodeTypes.courseProgress, ProgressNodeTypes.skillsetProgress]);

      const skillsetKey = route.params?.skillsetKey;
      const skillKey = route.params?.skillKey;

      await contentStore.setSkillset(skillsetKey);
      await progressStore.setSkillsetProgress(contentStore.skillset.id);

      let linearAssessmentProgress;
      if (skillKey) {
        await contentStore.setSkill(skillKey);
        await progressStore.setSkillProgress(contentStore.skill.id);
        if (progressStore.skillProgress.isUnlocked) {
          await progressStore.startSkill();
        }
        ({ linearAssessmentProgress } = progressStore.skillProgress);
      } else {
        ({ linearAssessmentProgress } = progressStore.skillsetProgress);
      }
      linearAssessmentProgress.setCurrent();

      if (linearAssessmentProgress.isInProgress) {
        await linearAssessmentProgress.fail();
      }
      await linearAssessmentProgress.start();

      progressStore.linearAssessmentProgress.linearAssessmentIterationProgress.setCurrent();
      if (skillKey) {
        contentStore.skill.linearAssessment.setCurrent();
      } else {
        contentStore.skillset.linearAssessment.setCurrent();
      }
      await contentStore.linearAssessment.getIteration(progressStore.linearAssessmentIterationProgress.linearAssessmentIterationId);
      await contentStore.linearAssessment.linearAssessmentIteration.setCurrent();

      let finalExamTimer = null;
      if (contentStore.linearAssessment.isFinalExam) {
        const twoHoursInMilliseconds = 1000 * 60 * 60 * 2;
        finalExamTimer = setTimeout(this.finalExamTimeout, twoHoursInMilliseconds);
      }

      this.setState({
        isKnowledgeCheck: linearAssessmentProgress.parent.contentNodeType === ContentNodeTypes.skillset,
        finalExamTimer,
      });
      this.setAndShuffleCurrentQuestion();
      if (Platform.OS === 'web') this.backButtonHandler();
    } catch (error) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      this.setState({ loading: false });
    }
  }

  finalExamTimeout = () => {
    const { navigation } = this.props;
    const { correctQuestionCount } = this.state;
    const { linearAssessmentIterationProgress } = this.props.rootStore.progressStore;

    const isPassed = linearAssessmentIterationProgress.isPassed({ correctQuestionCount });
    linearAssessmentIterationProgress.finish({ isPassed, correctQuestionCount });

    navigation.replace('OutcomeScreen', { status: Constants.OutcomeTypes.finalExamTimeOut });
  }

  setAndShuffleCurrentQuestion = () => {
    const { questions } = this.props.rootStore.contentStore.linearAssessmentIteration;

    const { currentQuestionIndex } = this.state;
    const currentQuestion = questions[currentQuestionIndex];
    currentQuestion.shuffleAnswerChoices();

    this.setState({ currentQuestion });
  }

  exitAssessmentAlert = () => {
    if (Platform.OS === 'web') {
      // eslint-disable-next-line no-restricted-globals,no-alert
      if (confirm(
        'Do you want to quit your knowledge check?\n\n'
        + 'If you quit, your progress will be lost, and you’ll need to start the knowledge check over.',
      )) {
        NavigationService.navigate('App', { screen: 'Learn' });
      }
    } else {
      Alert.alert(
        'Do you want to quit your knowledge check?',
        'If you quit, your progress will be lost, and you’ll need to start the knowledge check over.',
        [
          {
            text: 'Quit Check',
            onPress: () => NavigationService.navigate('App', { screen: 'Learn' }),
            style: 'destructive',
          },
          { text: 'Continue Check' },
        ],
        { cancelable: false },
      );
    }
  }

  backButtonHandler = () => {
    window.history.pushState(null, '', window.location.href);

    window.onpopstate = (event) => {
      event.preventDefault();
      window.history.replaceState(null, '', window.location.href);
      this.exitAssessmentAlert();
    };
  };

  componentWillUnmount() {
    window.onpopstate = undefined;
  }

  customNavigationBar = {
    left: {
      icon: 'arrow-back',
      text: 'Quit Knowledge Check',
      action: () => this.exitAssessmentAlert(),
    },
  }

  retryQuestion = () => {
    const { questions } = this.props.rootStore.contentStore.linearAssessmentIteration;

    const { currentQuestionIndex } = this.state;

    const currentQuestion = questions[currentQuestionIndex];
    currentQuestion.shuffleAnswerChoices();
    this.setState({
      showingResult: false,
      selectedAnswers: [],
    });
  }

  completeQuestion = async () => {
    const {
      progressStore: { linearAssessmentIterationProgress },
      contentStore: { linearAssessmentIteration: { questions }, linearAssessment: { isFinalExam } },
    } = this.props.rootStore;

    const {
      currentQuestionIndex,
      correctQuestionCount,
      isAnsweredCorrectly,
      currentQuestion,
      finalExamTimer,
    } = this.state;

    const newCorrectQuestionCount = isAnsweredCorrectly ? correctQuestionCount + 1 : correctQuestionCount;

    linearAssessmentIterationProgress.sendQuestionResponse({ questionId: currentQuestion.id, isAnsweredCorrectly });

    if (currentQuestionIndex === questions.length - 1) {
      const isPassed = linearAssessmentIterationProgress.isPassed({ correctQuestionCount: newCorrectQuestionCount });
      await linearAssessmentIterationProgress.finish({ isPassed, correctQuestionCount: newCorrectQuestionCount });
      if (finalExamTimer) clearInterval(finalExamTimer);
      this.navigateFromAssessment({ isPassed, isFinalExam });
      return;
    }

    this.setState({
      currentQuestionIndex: currentQuestionIndex + 1,
      correctQuestionCount: newCorrectQuestionCount,
      selectedAnswers: [],
      showingResult: false,
    }, this.setAndShuffleCurrentQuestion);
  }

  showResult = (isAnsweredCorrectly) => {
    const { fadeBanner } = this.state;
    this.setState({
      showingResult: true,
      isAnsweredCorrectly,
    }, () => {
      Animated.spring(
        fadeBanner,
        {
          toValue: 0,
          velocity: 3,
          tension: 2,
          friction: 8,
        },
      )
        .start();
    });
  }

  continueHandler = () => {
    const { linearAssessment } = this.props.rootStore.contentStore;
    const {
      showingResult,
      selectedAnswers,
      currentQuestion,
    } = this.state;

    if (!selectedAnswers.length) return;

    const answerCount = currentQuestion.answerChoices.filter(answer => answer.order > 0)?.length;
    if ((currentQuestion.type === QuestionTypes.fillInTheBlanks) && selectedAnswers.length < answerCount) return;

    const isAnsweredCorrectly = currentQuestion.checkAnswers(selectedAnswers);
    if ((showingResult && isAnsweredCorrectly) || linearAssessment.isFinalExam) {
      // Move to next question
      this.setState({ isAnsweredCorrectly }, this.completeQuestion);
    } else if (showingResult) {
      this.retryQuestion();
    } else {
      this.showResult(isAnsweredCorrectly);
    }
  };

  render() {
    const { rootStore: { contentStore, sessionStore }, dimensions } = this.props;
    const {
      showingResult,
      currentQuestionIndex,
      currentQuestion,
      fadeBanner,
      isAnsweredCorrectly,
      isKnowledgeCheck,
      loading,
      selectedAnswers,
    } = this.state;

    const { skillset, linearAssessment, linearAssessmentIteration } = contentStore;
    if (!skillset) return false;

    const { colorTheme } = skillset;
    if (loading) {
      return (
        <FullViewSpinner
          color={skillset.colorTheme}
          message={'We’re getting everything set up for you. Just one moment...'}
          textColor={skillset.colorTheme}
        />
      );
    }

    const { questions } = linearAssessmentIteration;
    const answerCount = currentQuestion.answerChoices.filter(answer => answer.order > 0)?.length;

    let continueText;
    if ((showingResult && isAnsweredCorrectly) || linearAssessment.isFinalExam) {
      continueText = 'Continue';
    } else if (showingResult) {
      continueText = 'Retry';
    } else {
      continueText = 'Submit';
    }

    let headerTitle;
    if (skillset.isFinalExam) {
      headerTitle = skillset.title;
    } else if (isKnowledgeCheck) {
      headerTitle = `${skillset.title} Knowledge Check`;
    } else {
      headerTitle = `${skillset.title} Knowledge Quiz`;
    }

    const MainStyles = AdaptiveContainerStyles(dimensions.deviceType);

    const questionHeader = (
      <QuestionHeader
        colorTheme={colorTheme}
        title={headerTitle}
        currentQuestionIndex={currentQuestionIndex}
        totalQuestionsCount={questions.length}
      />
    );

    const questionFooter = (
      <QuestionFooter
        fadeBanner={fadeBanner}
        showingResult={showingResult}
        correct={isAnsweredCorrectly}
        colorTheme={colorTheme}
        continueText={continueText}
        continueHandler={this.continueHandler}
      />
    );

    const ResetSelectionsButton = ({ resetHandler }) => (
      <DebouncedTouchableOpacity
        onPress={resetHandler}
        style={{
          alignSelf: 'flex-start',
          marginBottom: 20,
        }}
      >
        <Body2 color={colorTheme}>
          Reset Selections
        </Body2>
      </DebouncedTouchableOpacity>
    );

    const getOrder = (answerChoice) => {
      if ((currentQuestion.type === QuestionTypes.fillInTheBlanks) || (currentQuestion.type === QuestionTypes.orderedSelect)) {
        return selectedAnswers.indexOf(answerChoice);
      }
      return null;
    };

    const selectAnswer = (answerChoice) => {
      if (showingResult) return;

      if (selectedAnswers.includes(answerChoice)) {
        this.setState({ selectedAnswers: selectedAnswers.filter(answer => answer.id !== answerChoice.id) });
        return;
      }

      switch (currentQuestion.type) {
        case QuestionTypes.multipleChoice:
          this.setState({ selectedAnswers: [answerChoice] });
          break;

        case QuestionTypes.fillInTheBlanks:
          if (selectedAnswers.length < answerCount) {
            this.setState({ selectedAnswers: [...selectedAnswers, answerChoice] });
          }
          break;

        // Ordered and unordered select question types
        default:
          this.setState({ selectedAnswers: [...selectedAnswers, answerChoice] });
          break;
      }
    };

    const resetSelection = () => {
      if (!showingResult) this.setState({ selectedAnswers: [] });
    };

    return (
      <ViewWrapper
        smSize={12}
        mdSize={6}
        lgSize={6}
        header={questionHeader}
        footer={questionFooter}
        navigationBar={skillset.isFinalExam ? null : this.customNavigationBar}
        bottomBarColor={colorTheme}
        scrollToTop
      >

        <BottomSheetModal
          isVisible={!sessionStore.hasInternet}
          dismissable={false}
          icon={Images.icons.noWifi}
          messageTitle={'No internet Connection!'}
          messageContent={'Please wait a couple of seconds and try again'}
          primaryAction={{
            text: 'Retry',
            action: () => NetInfo.fetch().then(state => sessionStore.updateInternetStatus(state.isInternetReachable)),
          }}
        />

        <View style={[MainStyles.textContainer]}>

          <QuestionText
            colorTheme={colorTheme}
            isFitb={currentQuestion.type === QuestionTypes.fillInTheBlanks}
            fitbBackgroundColor={skillset.colorTheme20}
            selectedAnswers={selectedAnswers}
            question={currentQuestion}
          />

          <ResetSelectionsButton resetHandler={resetSelection} />

          { currentQuestion.getShuffledAnswerChoices().map((answerChoice, index) => (
            <AnswerChoice
              key={index}
              showingResult={Config.answerSolver ? true : showingResult}
              selected={selectedAnswers.includes(answerChoice)}
              selectAnswer={() => selectAnswer(answerChoice)}
              lightThemeColor={skillset.colorTheme40}
              themeColor={skillset.colorTheme}
              index={index}
              order={getOrder(answerChoice)}
              questionType={currentQuestion.type}
              answerChoice={answerChoice}
            />
          )) }

        </View>
      </ViewWrapper>
    );
  }
}

export default withDimensions(inject('rootStore')(observer(LinearAssessmentScreen)));
