import React from 'react';
import { View, Platform, StatusBar } from 'react-native';
import { Icon } from 'react-native-elements';
import { inject, observer } from 'mobx-react';
import VideoPlayer from 'react-native-af-video-player';
import once from 'lodash/once';
import throttle from 'lodash/throttle';
import { Images, Palette } from '@nextstep/app/Themes';
import { afterInteractions } from '@nextstep/app/lib/afterInteractions';
import { H3, Body1, Body2 } from '@nextstep/app/components/Text';
import ScreenTimer from '@nextstep/app/components/ScreenTimer';
import { ButtonText } from '@nextstep/app/components/Button';
import Config from '@nextstep/app/Config/DebugConfig';
import { FullViewSpinner } from '@nextstep/app/components/SharedUI';
import withDimensions from '@nextstep/app/lib/withDimensions';
import ViewWrapper from '@nextstep/app/components/ViewWrapper';
import DebouncedTouchableOpacity from '@nextstep/app/components/DebouncedTouchableOpacity';
import VideoScreenStyles from '@nextstep/app/containers/styles/VideoScreenStyles';
import AdaptiveContainerStyles from '@nextstep/app/containers/styles/AdaptiveContainerStyles';
import { ContentNodeTypes, ProgressNodeTypes } from '@nextstep/app/Config/Constants';
import NavigationService from '@nextstep/app/services/NavigationService';
import SkillChildNavigator from './SkillChildNavigator';

const currentPalette = Palette.light;

class IntroVideoScreen extends SkillChildNavigator {
  constructor(props) {
    super(props);

    this.state = {
      isVideoWatched: false,
      isLoading: true,
    };
  }

  async componentDidMount() {
    const { route, rootStore } = this.props;
    const { progressStore, contentStore } = rootStore;

    await afterInteractions();

    contentStore.clearCurrentExcept([
      ContentNodeTypes.course, ContentNodeTypes.skillset,
      ContentNodeTypes.skill]);
    progressStore.clearCurrentExcept([
      ProgressNodeTypes.courseProgress, ProgressNodeTypes.skillsetProgress,
      ProgressNodeTypes.skillProgress]);

    const skillsetKey = route.params?.skillsetKey;
    const skillKey = route.params?.skillKey;

    await contentStore.setSkillset(skillsetKey);
    await contentStore.setSkill(skillKey);
    await progressStore.setSkillsetProgress(contentStore.skillset.id);
    await progressStore.setSkillProgress(contentStore.skill.id);

    if (progressStore.skillProgress.isUnlocked) {
      await progressStore.startSkill();
    }

    if (Platform.OS === 'ios') StatusBar.setBarStyle('dark-content', true);

    progressStore.setIntroVideoProgress();
    const { introVideoProgress } = progressStore;

    if (introVideoProgress.isUnlocked) {
      progressStore.startIntroVideo();
    }

    this.setState({ isLoading: false, isVideoWatched: introVideoProgress.isCompleted });
  }

  completeLearningObjectOnce = once(this.completeLearningObject);

  handleVideoProgress = throttle(progress => {
    const { currentTime, seekableDuration } = progress;
    const percent = currentTime && (currentTime / seekableDuration) * 100;
    const triggerPercentage = 95;
    if (percent > triggerPercentage) {
      this.completeLearningObjectOnce();
    }
  }, 1000)

  async completeLearningObject() {
    const { rootStore } = this.props;
    const { contentStore, progressStore } = rootStore;
    const { skillProgress } = progressStore;
    const { introVideoProgress } = skillProgress;
    const { requiredMinimumTime } = contentStore.skillset;
    if (introVideoProgress.isInProgress) {
      await progressStore.completeIntroVideo();

      const hasTimeConstraint = requiredMinimumTime > 0;
      await progressStore.logTimer(hasTimeConstraint);
    }
    this.setState({ isVideoWatched: true });
  }

  async onContinuePressed() {
    const { isVideoWatched } = this.state;

    if (!isVideoWatched) await this.completeLearningObject();
    await this.goToNextSkillChild();
  }

  updateScreenTime = (increment) => {
    const { rootStore } = this.props;
    const { progressStore } = rootStore;
    const { skillProgress } = progressStore;

    if (!skillProgress || !skillProgress.introVideoProgress) return;

    const { introVideoProgress } = skillProgress;
    introVideoProgress.incrementDuration(increment);
  }

  renderBottomBar() {
    const { rootStore, dimensions } = this.props;
    const { sessionStore, contentStore } = rootStore;
    const { config } = sessionStore.learner;
    const { isVideoWatched } = this.state;
    const { colorTheme } = contentStore.skillset;
    const Styles = VideoScreenStyles(dimensions.deviceType);
    const MainStyles = AdaptiveContainerStyles(dimensions.deviceType);

    const disabledInteraction = !isVideoWatched && !config.fastForwardEnabled;

    return (
      <View
        style={[
          MainStyles.textContainer,
          MainStyles.bottomBar,
          { backgroundColor: colorTheme, justifyContent: 'flex-end' },
        ]}
      >

        <DebouncedTouchableOpacity
          disabled={disabledInteraction}
          onPress={() => { this.onContinuePressed(); }}
          style={Styles.iconButton}
        >

          <Body2 color={disabledInteraction ? currentPalette.basic.transparent : currentPalette.primary.contrastText}>
            Continue
          </Body2>

          <Icon
            name="keyboard-arrow-right"
            color={disabledInteraction ? currentPalette.basic.transparent : currentPalette.primary.contrastText}
            underlayColor="transparent"
          />
        </DebouncedTouchableOpacity>

        {Config.answerSolver && disabledInteraction && (
          <ButtonText
            color={currentPalette.basic.white}
            onPress={() => this.onContinuePressed()}
          >
            SKIP
          </ButtonText>
        )}

      </View>
    );
  }

  renderVideo() {
    const { isVideoWatched } = this.state;
    const { rootStore: { sessionStore, contentStore }, dimensions } = this.props;
    const { config } = sessionStore.learner;
    const { skill: { introVideo: { videos } }, skill, skillset } = contentStore;

    const MainStyles = AdaptiveContainerStyles(dimensions.deviceType);

    return (
      <View>
        <View
          style={[
            MainStyles.textContainer,
            Platform.OS === 'web' && { paddingVertical: 20 },
          ]}
        >

          <View style={{ paddingBottom: 20 }}>
            <Body2 textAlign={'left'}>
              {skill.title}
            </Body2>
          </View>

        </View>

        {videos.map(p => (
          <VideoPlayer
            key={p.id}
            url={p.url}
            ref={(videoPlayer) => { this.videoPlayer = videoPlayer; }}
            onProgress={(progress) => this.handleVideoProgress(progress)}
            ignoreSilentSwitch="ignore"
            inlineOnly
            logo="https://s3.amazonaws.com/nextstep.ui/course/images/home-active.png"
            placeholder={Images.other.videoPlaceholder}
            disableForwardSeek={!isVideoWatched && !config.fastForwardEnabled}
            autoPlay={config.videoAutoPlay}
            theme={{ progress: skillset.colorTheme }}
          />
        ))}
      </View>
    );
  }

  renderHeader() {
    const { rootStore: { contentStore }, dimensions } = this.props;
    const { skillset } = contentStore;
    const Styles = VideoScreenStyles(dimensions.deviceType);
    const MainStyles = AdaptiveContainerStyles(dimensions.deviceType);

    return (
      <>
        <View style={[MainStyles.textContainer, Styles.skillSetInfoContainer, { backgroundColor: skillset.colorTheme }]}>

          <H3 textAlign={'left'} color={currentPalette.primary.contrastText}>
            {skillset.title}
          </H3>

          <Body1 textAlign={'left'} color={currentPalette.primary.contrastText}>
            Intro video
          </Body1>

        </View>
      </>
    );
  }

  render() {
    const { rootStore: { contentStore } } = this.props;
    const { isLoading } = this.state;
    const { skillset } = contentStore;

    if (isLoading) return <FullViewSpinner color={skillset && skillset.colorTheme} />;

    return (
      <ViewWrapper
        smSize={12}
        mdSize={6}
        lgSize={6}
        navigationBar
        bottomBarColor={skillset.colorTheme}
        header={this.renderHeader()}
        footer={this.renderBottomBar()}
        backOverride={() => NavigationService.navigate('App', { screen: 'Learn' })}
      >

        {this.renderVideo()}

        <ScreenTimer onUpdateTime={this.updateScreenTime} />

      </ViewWrapper>
    );
  }
}

export default withDimensions(inject('rootStore')(observer(IntroVideoScreen)));
