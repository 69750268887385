import React, { useState, useEffect } from 'react';
import { AppState } from 'react-native';
import throttle from 'lodash/throttle';

const ScreenTimer = (props) => {
  const {
    increment = 5,
    interval = 1,
    children,
    onUpdateTime = () => {},
    onMinutePassed = () => {},
  } = props;

  const intervalInMS = interval * 1000;
  const incrementInMS = increment * 1000;

  const [appState, setAppState] = useState(AppState.currentState);

  const delayedUpdate = throttle(onUpdateTime, incrementInMS);
  const delayedMinuteUpdate = throttle(onMinutePassed, incrementInMS * (60 / increment));

  const incrementElapsedTime = () => {
    delayedUpdate(increment);
    delayedMinuteUpdate();
  };

  useEffect(() => {
    const timer = setInterval(() => {
      if (appState === 'active') {
        incrementElapsedTime();
      }
    }, intervalInMS);
    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    function handleAppState(status) {
      setAppState(status);
    }
    AppState.addEventListener('change', handleAppState);
    return () => {
      AppState.removeEventListener('change', handleAppState);
    };
  }, []);

  return (
    <>
      {children}
    </>
  );
};

export default ScreenTimer;
