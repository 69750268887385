import React from 'react';
import { Platform, View, Image, ScrollView } from 'react-native';
import { inject, observer } from 'mobx-react';
import { ButtonFilled } from '@nextstep/app/components/Button';
import withDimensions from '@nextstep/app/lib/withDimensions';
import ViewWrapper from '@nextstep/app/components/ViewWrapper';
import { Column, Row } from '@nextstep/app/lib/ResponsiveLayout';
import Divider from '@nextstep/app/components/Divider';
import { Images, Palette } from '@nextstep/app/Themes';
import { Subtitle1, Title } from '@nextstep/app/components/Text';
import NavigationService from '@nextstep/app/services/NavigationService';
import Config from '@nextstep/app/Config/DebugConfig';

const currentPalette = Palette.light;

const DefaultStyles = {
  mySkillBadgeBackground: {
    width: 156,
    height: 156,
    borderRadius: 100,
    alignItems: 'center',
    justifyContent: 'center',
  },

  mySkillBadge: {
    width: 140,
    height: 140,
    resizeMode: 'cover',
    borderRadius: 100,
    overflow: 'hidden',
    alignItems: 'center',
  },

  mySkillBadgeMentor: {
    top: 10,
    resizeMode: 'contain',
    height: 144,
    width: 80,
  },
};

const CovidProofUploadComplete = (props) => {
  const { rootStore: { sessionStore } } = props;
  const { navigation } = props;
  const { learner } = sessionStore;

  if (!learner?.studentId) {
    navigation.navigate('LaunchScreen');
  }

  return (
    <ViewWrapper
      smSize={12}
      mdSize={7}
      lgSize={6}
      topNavBarColor={currentPalette.primary.main}
    >
      <View style={{ height: 40 }} />

      <Title color={currentPalette.text.main}>
        UPLOAD COMPLETE
      </Title>

      <View style={{ height: 26 }} />

      <Divider />

      <ScrollView>
        <View
          style={[
            { justifyContent: 'center' },
            Platform.OS !== 'web' && { height: '100%' },
          ]}
        >
          {Platform.OS === 'web' && <View style={{ height: 36 }} />}

          <Row style={{ flex: 1 }}>
            <Column smSize={11} mdSize={9} lgSize={6}>
              <View style={{ alignItems: 'center' }}>
                <Image
                  style={DefaultStyles.mySkillBadgeMentor}
                  source={Images.icons.green.checkMark}
                />
              </View>

              <View style={{ height: 24 }} />

              <Subtitle1>
                Thanks for uploading proof of your COVID-19 vaccination!
              </Subtitle1>

              <View style={{ height: 24 }} />

              <ButtonFilled
                width="100%"
                style={{ alignSelf: 'center' }}
                onPress={() => NavigationService.navigate('App', { screen: 'Learn' })}
                onLongPress={() => (Config.staging ? sessionStore.logout() : {})}
              >
                Continue Enrolling
              </ButtonFilled>

              <View style={{ height: 32 }} />
            </Column>
          </Row>

          <View style={{ height: 36 }} />
        </View>
      </ScrollView>
    </ViewWrapper>
  );
};

export default withDimensions(inject('rootStore')(observer(CovidProofUploadComplete)));
