import React, { Component } from 'react';
import { Image, View } from 'react-native';
import { inject, observer } from 'mobx-react';
import { Images } from '@nextstep/app/Themes';
import NavigationService from '@nextstep/app/services/NavigationService';
import Styles from './styles/LoadingScreenStyles';

class LoadingScreen extends Component {
  async componentDidMount() {
    await this.checkAuth();
  }

  async logout() {
    const { rootStore, navigation } = this.props;
    const { sessionStore } = rootStore;
    if (sessionStore.learner) await sessionStore.logout();
    navigation.navigate('LaunchScreen');
  }

  async checkAuth() {
    const { rootStore } = this.props;
    const { sessionStore } = rootStore;
    const refreshToken = await sessionStore.getRefreshToken();

    if (!refreshToken) {
      this.logout();
      return;
    }

    try {
      await sessionStore.intercomStart();
      await NavigationService.waitForNavigation();
      NavigationService.navigateToLearnerInitialScreen();
    } catch (err) {
      this.logout();
    }
  }


  render() {
    return (
      <View style={Styles.container}>
        <Image resizeMode="contain" style={Styles.logo} source={Images.other.nextStepLogo} />
      </View>
    );
  }
}

export default inject('rootStore')(observer(LoadingScreen));
