import { Client as RollbarClient, Configuration } from 'rollbar-react-native';
import Config from 'react-native-config';
import { Platform } from 'react-native';
import { getSnapshot } from 'mobx-state-tree';
import { PersistenceKeys } from './Constants';

export const makeTransformer = (sessionUrl) => payload => {
  payload.state = { [PersistenceKeys.app]: { skillsetProgresses: JSON.stringify(getSnapshot(global.rootStore.progressStore.skillsetProgresses)) } };
  if (sessionUrl) payload.sessionUrl = sessionUrl;
};

const config = new Configuration(Config.ROLLBAR_CLIENT_ACCESS_TOKEN, {
  environment: Config.ROLLBAR_ENVIRONMENT,
  enabled: !__DEV__,
  captureDeviceInfo: false,
  payload: {
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: `${Config.BUILD_NUMBER}.${Platform.OS}`,
      },
    },
  },
  transform: makeTransformer(),
});

const rollbar = new RollbarClient(config);

export const identifyRollbarLearner = (learnerId) => {
  if (!learnerId || __DEV__) return;
  rollbar.setPerson(learnerId.toString());
};

export const rollbarLogout = () => {
  rollbar.clearPerson();
};

export default rollbar;
