import { types } from 'mobx-state-tree';

const Metrics = types.model('Metrics', {
  percentage: types.number,
  totalSkills: types.number,
  completedSkills: types.number,
  labPercentComplete: types.maybe(types.number),
  clinicalPercentComplete: types.maybe(types.number),
});

export default Metrics;
