/* eslint-disable react/destructuring-assignment,no-unused-expressions */
import React from 'react';
import Modal from 'react-native-modal';
import { Dimensions, Platform } from 'react-native';
import withDimensions, { DeviceType } from '@nextstep/app/lib/withDimensions';

class CustomModal extends React.PureComponent {
  render() {
    const { dimensions } = this.props;
    const deviceWidth = Dimensions.get('window').width;
    const deviceHeight = Platform.OS !== 'android'
      ? Dimensions.get('window').height
      : require('react-native-extra-dimensions-android').get('REAL_WINDOW_HEIGHT');

    let style = { ...this.props.style };

    if (Platform.OS === 'web') {
      let maxSize;
      if (dimensions.deviceType === DeviceType.Desktop) maxSize = '50%';
      if (dimensions.deviceType === DeviceType.Tablet) maxSize = '60%';
      if (dimensions.deviceType === DeviceType.Mobile) maxSize = '90%';
      style = { ...style, width: maxSize, margin: 'auto' };
    }

    return (
      <Modal
        {...this.props}
        deviceWidth={deviceWidth}
        deviceHeight={deviceHeight}
        useNativeDriver={typeof jest === 'undefined'} // workaround for jest
        hardwareAccelerated
        hideModalContentWhileAnimating
        backdropTransitionOutTiming={0}
        style={style}
      >
        {this.props.children}
      </Modal>
    );
  }
}

export default withDimensions(CustomModal);
