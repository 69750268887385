import React from 'react';
import { Text, View } from 'react-native';
import moment from 'moment';
import { inject, observer } from 'mobx-react';
import withDimensions from '@nextstep/app/lib/withDimensions';
import { Body1, Body2, Caption1, Caption2, Title } from '@nextstep/app/components/Text';
import Divider from '@nextstep/app/components/Divider';
import { Palette } from '@nextstep/app/Themes';
import Util from '@nextstep/app/services/Util';
import AdaptiveContainerStyles from '@nextstep/app/containers/styles/AdaptiveContainerStyles';
import HomeScreenStyles from '@nextstep/app/containers/styles/HomeScreenStyles';

const currentPalette = Palette.light;

const CourseTimeline = (props) => {
  const { rootStore: { sessionStore }, dimensions } = props;
  const { learner } = sessionStore;
  const Styles = HomeScreenStyles(dimensions.deviceType);

  return (
    <View style={Styles.section}>

      <View style={{ height: 26 }} />

      <Title>
        MY COURSE TIMELINE
      </Title>

      <View style={{ height: 15 }} />

      <Divider />

      <View style={{ height: 18 }} />

      <View style={AdaptiveContainerStyles(dimensions.deviceType).textContainer}>

        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <Body1 textAlign="left">Registration Date</Body1>
          <Body1 color={currentPalette.primary.main}>
            {`${moment(learner?.registeredAt, 'YYYY-MM-DD').format('l')}`}
          </Body1>
        </View>

        <Divider style={{ marginTop: 18, marginBottom: 8 }} />

        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <Body2 textAlign="left">Graduation Goal</Body2>
          <Body2 color={currentPalette.primary.main}>
            {`${moment(learner?.courseCompletionGoalOn, 'YYYY-MM-DD').format('l')}`}
          </Body2>
        </View>

        <View style={{ height: 8 }} />

        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <Body1 textAlign="left">Last Day to Graduate</Body1>
          <Body1 color={currentPalette.primary.main}>
            {`${moment(learner?.courseCompletionDeadlineOn, 'YYYY-MM-DD').format('l')}`}
          </Body1>
        </View>

        <View style={{ height: 30 }} />

        <Text>
          <Caption1 textAlign="left">
            {`You must complete the course including Theory, Lab, and Clinical within ${learner?.config.weeksToCompleteCourse} weeks from the date you became a NextStep Learner.`}
          </Caption1>
          <Text onPress={() => (learner?.config ? Util.openURL(learner.config.coursePolicyUrl) : {})}>
            <Caption2 color={currentPalette.primary.main}>
              {' Read More in the Course Policies'}
            </Caption2>
          </Text>
        </Text>

      </View>

      <View style={{ height: 16 }} />

    </View>
  );
};

export default withDimensions(inject('rootStore')(observer(CourseTimeline)));
