import { StyleSheet } from 'react-native';
import Colors from '@nextstep/app/Themes/Colors';

export default StyleSheet.create({
  container: {
    borderRadius: 8,
    borderStyle: 'solid',
    borderWidth: 1,
    marginBottom: 8,
  },

  optionBody: {
    padding: 12,
    flexDirection: 'row',
    alignItems: 'center',
  },

  optionBodyAnswer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 8,
  },

  checked: { width: 18, height: 13, resizeMode: 'contain', marginLeft: 10 },

  xMark: { width: 10, height: 10, resizeMode: 'contain', marginLeft: 10, tintColor: Colors.white },

  answerStatusIcon: {
    width: 12,
    height: 12,
    resizeMode: 'contain',
  },
});
