import { flow, getEnv, getParent, hasParent } from 'mobx-state-tree';
import AsyncStorage from '@react-native-community/async-storage';
import moment from 'moment';
import qs from 'qs';
import branch, { BranchEvent } from 'react-native-branch';
import { Platform } from 'react-native';
import Intercom from 'react-native-intercom';
import _ from 'lodash';
import { Formats, PersistenceKeys, StatusCodes } from '@nextstep/app/Config/Constants';
import User from '@nextstep/app/models/User';
import Util from '@nextstep/app/services/Util';
import { analyticsIdentifyUser, analyticsReset, trackAnalytics } from '@nextstep/app/services/Analytics';
import NavigationService from '@nextstep/app/services/NavigationService';
import { identifyRollbarLearner, rollbarLogout } from '@nextstep/app/Config/RollbarConfig';

const SessionStoreActions = self => ({
  createLearner(userData) {
    return User.create({ ...userData, id: (userData.id) ? userData.id : 0 });
  },

  async setCurrentLearner(user) {
    let learner;
    if (user) {
      let daysOfStudy;
      if (user.daysOfStudy === null || user.daysOfStudy === undefined) daysOfStudy = user.daysOfStudy;
      else if (typeof user.daysOfStudy === 'string') daysOfStudy = JSON.parse(user.daysOfStudy);
      else daysOfStudy = [...user.daysOfStudy];
      learner = { ...user, daysOfStudy };
    }
    self.learner = learner;
    if (learner) await self.intercomVerifyIdentity().catch();
  },

  async logout(screen) {
    try {
      NavigationService.reset(screen || 'LaunchScreen');
      await self.setCurrentLearner(null);
      await self.deleteAuthToken();
      await self.deleteRefreshToken();
      await Intercom.logout();
      rollbarLogout();
      await analyticsReset();
      await branch.logout();
      if (hasParent(self)) {
        const root = getParent(self);
        await root.deleteSnapshot();
        root.resetStore();
      }
    } catch (err) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line no-console
      console.error(err);
    }
  },

  loginAsGuest: flow(function* loginAsGuest(learnerLocationData) {
    const { api } = getEnv(self);
    const root = getParent(self);

    const utmSource = (yield AsyncStorage.getItem(PersistenceKeys.utmSource)) || undefined;
    const utmMedium = (yield AsyncStorage.getItem(PersistenceKeys.utmMedium)) || undefined;
    const utmContent = (yield AsyncStorage.getItem(PersistenceKeys.utmContent)) || undefined;
    const utmCampaign = (yield AsyncStorage.getItem(PersistenceKeys.utmCampaign)) || undefined;


    const response = yield api.createGuest({ learner: { utmSource, utmMedium, utmContent, utmCampaign, ...learnerLocationData } });
    const { data } = response;

    if (response.ok && data) {
      trackAnalytics('guestLearnerCreated', {});

      try {
        const res = yield api.login({ learner: data });
        const { learner } = res.data;

        if (res.ok && learner) {
          const { headers } = res;

          const accessToken = headers.authorization.replace(/Bearer /gi, '');
          const refreshToken = headers['ns-refresh'];

          identifyRollbarLearner(learner.id);

          yield Promise.all([
            self.saveAuthToken(accessToken),
            self.saveRefreshToken(refreshToken),
            root.saveSnapshotVersion(),
          ]);

          yield self.setCurrentLearner(learner);
          yield self.syncCurrentLearner({ ...learner, ...learnerLocationData });

          yield self.intercomStart();
          yield self.intercomVerifyIdentity();
          branch.setIdentity(self.learner.externalId);
          trackAnalytics('loggedInGuest', {});
        } else if (response.status === 401) {
          return data;
        } else {
          return Promise.reject(response);
        }
      } catch (err) {
        yield self.setCurrentLearner(null);
        self.loginFailed = true;

        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-console
        console.tron.log('login error: ', err);
        return Promise.reject(err);
      }
    }

    AsyncStorage.removeItem(PersistenceKeys.utmSource);
    AsyncStorage.removeItem(PersistenceKeys.utmMedium);
    AsyncStorage.removeItem(PersistenceKeys.utmContent);
    AsyncStorage.removeItem(PersistenceKeys.utmCampaign);

    return self.learner;
  }),

  login: flow(function* login(body) {
    const { api } = getEnv(self);
    const root = getParent(self);

    try {
      const response = yield api.login({ learner: body });
      const { data } = response;

      if (response.ok && data.learner) {
        const { headers } = response;

        const accessToken = headers.authorization.replace(/Bearer /gi, '');
        const refreshToken = headers['ns-refresh'];

        yield Promise.all([
          self.saveAuthToken(accessToken),
          self.saveRefreshToken(refreshToken),
          root.saveSnapshotVersion(),
        ]);

        const learner = yield self.getLearner(data.learner.id);
        identifyRollbarLearner(learner.id);
        yield self.intercomStart();
        yield self.intercomVerifyIdentity();
        branch.setIdentity(self.learner.externalId);
        trackAnalytics('loggedIn', {});
        analyticsIdentifyUser(learner);

        yield root.loadContent();
      } else if (response.status === 401) {
        return data;
      } else {
        return Promise.reject(response);
      }
    } catch (err) {
      yield self.setCurrentLearner(null);
      self.loginFailed = true;

      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line no-console
      console.tron.log('login error: ', err);
      return Promise.reject(err);
    }

    return self.learner;
  }),

  guestSignUp: flow(function* guestSignUp(body) {
    const { api } = getEnv(self);

    const response = yield api.guestSignUp({ learner: body });

    if (Util.shouldReject(response)) return Promise.reject(response);

    return response.data;
  }),

  signUp: flow(function* signUp(body) {
    const { api } = getEnv(self);

    const utmSource = (yield AsyncStorage.getItem(PersistenceKeys.utmSource)) || undefined;
    const utmMedium = (yield AsyncStorage.getItem(PersistenceKeys.utmMedium)) || undefined;
    const utmContent = (yield AsyncStorage.getItem(PersistenceKeys.utmContent)) || undefined;
    const utmCampaign = (yield AsyncStorage.getItem(PersistenceKeys.utmCampaign)) || undefined;

    const response = yield api.signUp({ learner: { ...body, utmSource, utmMedium, utmContent, utmCampaign } });

    if (Util.shouldReject(response)) return Promise.reject(response);

    if (response.status === StatusCodes.unprocessableEntity) {
      return response.data;
    }

    AsyncStorage.removeItem(PersistenceKeys.utmSource);
    AsyncStorage.removeItem(PersistenceKeys.utmMedium);
    AsyncStorage.removeItem(PersistenceKeys.utmContent);
    AsyncStorage.removeItem(PersistenceKeys.utmCampaign);

    return response.data;
  }),

  getLearner: flow(function* getLearner(id) {
    const { api } = getEnv(self);
    const response = yield api.getUser(id);
    const { learner } = response.data;
    yield self.setCurrentLearner(learner);
    return learner;
  }),

  reregister: flow(function* reregister(id) {
    const { api } = getEnv(self);
    const response = yield api.reregister(id);

    if (response.ok) {
      yield self.syncCurrentLearner();
      return response;
    }

    self.logout();
    return Promise.reject(response);
  }),

  acceptEnrollmentAgreement: flow(function* acceptEnrollmentAgreement(body) {
    const { api } = getEnv(self);
    const { learner } = self;
    const response = yield api.acceptEnrollmentAgreement(learner.id, body);

    if (response.ok) {
      yield self.syncCurrentLearner();
      return response;
    }

    self.logout();
    return Promise.reject(response);
  }),

  acceptUpskillAgreement: flow(function* acceptUpskillAgreement() {
    const { api } = getEnv(self);
    const { learner } = self;
    const response = yield api.acceptUpskillAgreement(learner.id);

    if (response.ok) {
      yield self.syncCurrentLearner();
      return response;
    }

    self.logout();
    return Promise.reject(response);
  }),

  syncCurrentLearner: flow(function* syncCurrentLearner(updatedInfo) {
    const { api } = getEnv(self);

    const { learner } = self;

    if (_.isEmpty(updatedInfo)) return self.getLearner(learner.id);

    const response = yield api.updateUser(learner.id, updatedInfo);

    if (Util.shouldReject(response)) return Promise.reject(response);

    const updatedLearner = response.data.learner;

    yield self.setCurrentLearner({ ...learner, ...updatedLearner });

    return self.learner;
  }),

  updateStudyPlan: flow(function* updateStudyPlan(planInfo) {
    const { api } = getEnv(self);

    const { learner } = self;
    const response = yield api.updateStudyPlan(learner.id, planInfo);

    if (Util.shouldReject(response)) return Promise.reject(response);

    return response;
  }),

  refreshTokenFunc: flow(function* refreshTokenFunc(tk) {
    const { api } = getEnv(self);
    const response = yield api.refreshToken(tk);

    if (Util.shouldReject(response)) return Promise.reject(response);

    const { headers } = response;
    const accessToken = headers.authorization && headers.authorization.replace(/Bearer /gi, '');
    const refreshToken = headers['ns-refresh'];

    self.saveRefreshToken(refreshToken);
    self.saveAuthToken(accessToken);

    return Promise.resolve({ accessToken, refreshToken });
  }),

  checkPhoneNumber: flow(function* checkPhoneNumber(body) {
    const { api } = getEnv(self);
    const response = yield api.checkPhoneNumber({ learner: body });

    if (response.ok || response.status === 404) {
      return response.data;
    }

    return Promise.reject(response);
  }),

  checkEmail: flow(function* checkEmail(body) {
    const { api } = getEnv(self);
    const response = yield api.checkEmail(body);

    if (response.ok || response.status === 404) {
      return response.ok;
    }
    return Promise.reject(response);
  }),

  clearStoreData: flow(function* clearProgressData() {
    const rootStore = getParent(self);
    const { contentStore, progressStore } = rootStore;

    progressStore.deleteSnapshot();
    contentStore.deleteSnapshot();

    yield rootStore.loadContent();
  }),

  // Call this if there is a data conflict. This results should be
  //   that the learner has their progress reset and a new auth
  //   token is fetched.
  resolveConflict: flow(function* resolveConflict() {
    try {
      // Force a refresh of the auth token
      yield self.deleteAuthToken(); // Maybe not needed?
      yield self.refreshTokenFunc(global.REFRESH_TOKEN).then(({ accessToken }) => {
        global.API_TOKEN = accessToken.replace(/Bearer /gi, '');
      });

      // Clear progress data and reload it
      const rootStore = getParent(self);
      const { contentStore, progressStore } = rootStore;

      const skillsetKey = contentStore?.skillset?.key;
      const { expandedSkillsetKey } = progressStore;

      yield self.clearStoreData();

      if (skillsetKey) yield contentStore.setSkillset(skillsetKey);
      progressStore.setExpandedSkillsetKey(expandedSkillsetKey);
    } catch (err) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line no-console
      console.error(err);
      self.logout(); // If something breaks, we can't recover
    }
  }),

  saveAuthToken: flow(function* (token) {
    if (!token) return;

    try {
      global.API_TOKEN = token;
      yield AsyncStorage.setItem(PersistenceKeys.authTokenKey, token);
    } catch (err) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }),

  saveRefreshToken: flow(function* saveRefreshToken(refreshToken) {
    if (!refreshToken) return;
    try {
      global.REFRESH_TOKEN = refreshToken;
      yield AsyncStorage.setItem(PersistenceKeys.refreshTokenKey, refreshToken);
    } catch (err) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }),

  getAuthToken: flow(function* getAuthToken() {
    let token = null;
    try {
      token = yield AsyncStorage.getItem(PersistenceKeys.authTokenKey);
    } catch (err) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line no-console
      console.error(err);
    }

    return token;
  }),

  getRefreshToken: async () => AsyncStorage.getItem(PersistenceKeys.refreshTokenKey),

  deleteAuthToken: flow(function* deleteAuthToken() {
    try {
      global.API_TOKEN = null;
      yield AsyncStorage.removeItem(PersistenceKeys.authTokenKey);
    } catch (err) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }),

  deleteRefreshToken: flow(function* deleteRefreshToken() {
    try {
      global.REFRESH_TOKEN = null;
      yield AsyncStorage.removeItem(PersistenceKeys.refreshTokenKey);
    } catch (err) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }),

  getCourseProgresses: flow(function* getCourseProgresses(email) {
    const { api } = getEnv(self);
    const response = yield api.getCourseProgresses(email);

    if (Util.shouldReject(response)) return Promise.reject(response);
    return response.data;
  }),

  assignCourse: flow(function* (courseKey = 'ltcw') {
    if (!courseKey) return Promise.reject();

    // COURSE ALREADY ASSIGNED
    const rootStore = getParent(self);
    if (rootStore.contentStore.course) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line no-console
      console.log('--- COURSE ALREADY ASSIGNED ---');
      return Promise.resolve();
    }

    const { api } = getEnv(self);
    const res = yield api.getCourse({ key: courseKey });

    if (res.ok) {
      const { course } = res.data;
      const { learner } = self;

      yield rootStore.progressStore.setCourseProgress(course.id);
      rootStore.contentStore.setCourse(course);

      // FIXME: ANALYTICS REFACTOR => Move Rollbar, Intercom, Branch into Analytics
      yield self.intercomUpdate();

      yield rootStore.loadContent();

      if (!learner.isGuest) self.addMarketingTag({ email: self.learner.email, tags: 'has_enrolled' });
      new BranchEvent('ENROLLED').logEvent();

      return course;
    }
    return Promise.reject(res);
  }),

  addMarketingTag: flow(function* addMarketingTag(action) {
    const { activeCampaignApi } = getEnv(self);

    const { body } = action;
    const response = yield activeCampaignApi.addMarketingTag(body);

    if (response.result_code !== 1) {
      return Promise.reject(response);
    }

    return response.data;
  }),

  async intercomVerifyIdentity() {
    const { learner } = self;
    if (!learner) return;

    let intercomIdentityHash = null;
    if (Platform.OS === 'android') {
      const { intercomIdentityHashAndroid } = learner;
      intercomIdentityHash = intercomIdentityHashAndroid;
    } else if (Platform.OS === 'ios') {
      const { intercomIdentityHashIos } = learner;
      intercomIdentityHash = intercomIdentityHashIos;
    } else if (Platform.OS === 'web') {
      const { intercomIdentityHashWeb } = learner;
      intercomIdentityHash = intercomIdentityHashWeb;
      // Inject web integration again in case the app is reload via callback
      Intercom.injectWebIntegration();
    }

    if (!intercomIdentityHash) return;
    await Intercom.setUserHash(intercomIdentityHash);
  },

  async intercomStart() {
    const { learner } = self;
    const userId = learner.externalId; // To accommodate 1.7.2 => 2.0.0, I'd set externalId elsewhere and start intercom only after I'd set the externalId.

    try {
      await Intercom.registerIdentifiedUser({
        userId, // User id passed by the app integration
        user_id: userId, // User id passed by the web integration
        name: learner.fullName,
        email: learner.email,
      });
      await Intercom.handlePushMessage();
    } catch (e) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line no-console
      console.warn(e);
    }
  },

  async intercomUpdate() {
    const rootStore = getParent(self);
    const { course } = rootStore.contentStore;
    const { learner } = self;
    const now = moment().format('YYYY-MM-DD');

    await self.intercomStart();
    await self.intercomVerifyIdentity();

    const customAttributes = {
      state: Util.safeToString(learner.homeState),
      profileImage: learner.profileImage,
      studyDuration: learner.studyDuration,
      daysOfStudy: Util.safeToString(learner.daysOfStudy.map(Util.getDayName)),
      city: Util.safeToString(learner.homeCity),
      zipCode: Util.safeToString(learner.homeZipCode),
      currentCourseId: Util.safeToString(course?.id),
      cnaLicenseId: Util.safeToString(learner.cnaLicenseNumber),
      cnaLicenseExp: Util.safeToString(learner.cnaLicenseExpiresOn),
      enrollmentDate: learner.enrolledAt || now,
      tosAcceptedAt: learner.tosAcceptedAt,
      tosSkillsetKey: learner.tosSkillsetKey,
    };

    analyticsIdentifyUser(learner);
    const payload = { custom_attributes: customAttributes };

    if (learner.email) payload.email = learner.email;
    if (learner.phone) payload.phone = learner.phone;
    if (learner.fullName) payload.fullName = learner.fullName;

    return Intercom.updateUser(payload);
  },
  uploadAvatar: flow(function* (uri, learner) {
    const now = moment().format(Formats.date);
    const fileName = `${parseInt(learner.id, 10)}/photos/profile_${now}`;
    const fileExtension = 'jpg';
    const type = 'image/jpeg';
    const encodedKey = qs.stringify({ fileName });
    const s3url = yield self.generates3url(encodedKey);
    yield self.uploadToS3({
      s3url,
      fileName,
      fileExtension,
      type,
      uri,
    });
    return s3url.key.substring(0, s3url.key.indexOf('?'));
  }),

  async uploadToS3({
    s3url,
    fileName,
    fileExtension,
    type,
    uri,
    progressCb,
  }) {
    let body;
    if (/^blob:/.test(uri)) {
      body = await fetch(uri).then(r => r.blob());
    } else {
      body = { uri, type, name: `${fileName}.${fileExtension}` };
    }

    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('PUT', s3url.key);
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve(xhr.response);
          } else {
            reject(Error(`error uploading ${type} to S3: ${xhr.status}`));
          }
        }
      };
      if (progressCb) xhr.upload.onprogress = progressCb;
      xhr.setRequestHeader('Content-Type', type);
      xhr.send(body);
    });
  },
  generates3url: flow(function* generates3url(key) {
    const { api } = getEnv(self);

    const { fileName } = qs.parse(key);
    const response = yield api.generates3url({ key: fileName });

    if (Util.shouldReject(response)) {
      throw new Error(`error generating s3 url for ${fileName}: ${response.originalError}`);
    }

    return response.data;
  }),

  getRedirectUrls(webSuccessScreen, webCancelScreen) {
    const isWeb = Platform.OS === 'web';
    let webSuccessUrl = null;
    let webCancelUrl = null;
    if (isWeb) {
      if (!webSuccessScreen || !webCancelScreen) {
        throw new Error('webSuccessScreen and webCancelScreen needs to be defined for web');
      }
      const { host, protocol } = window.location;
      const appUrl = `${protocol}//${host}/`;
      webSuccessUrl = appUrl + webSuccessScreen;
      webCancelUrl = appUrl + webCancelScreen;
    }
    return [webSuccessUrl, webCancelUrl];
  },
  createCheckoutSession: flow(function* (webSuccessScreen, webCancelScreen) {
    const { api } = getEnv(self);

    const [webSuccessUrl, webCancelUrl] = self.getRedirectUrls(webSuccessScreen, webCancelScreen);
    const response = yield api.createCheckoutSession(webSuccessUrl, webCancelUrl);

    if (Util.shouldReject(response)) return Promise.reject(response);

    const { payment } = response.data;
    return payment.checkoutSessionId;
  }),

  createCardCaptureSession: flow(function* (webSuccessScreen, webCancelScreen) {
    const { api } = getEnv(self);
    const [webSuccessUrl, webCancelUrl] = self.getRedirectUrls(webSuccessScreen, webCancelScreen);

    const response = yield api.createCardCaptureSession(webSuccessUrl, webCancelUrl);
    if (Util.shouldReject(response)) return Promise.reject(response);

    const { payment } = response.data;
    return payment.checkoutSessionId;
  }),

  createRegistrationFeeSession: flow(function* (webSuccessScreen, webCancelScreen) {
    const { api } = getEnv(self);
    const [webSuccessUrl, webCancelUrl] = self.getRedirectUrls(webSuccessScreen, webCancelScreen);

    const response = yield api.createRegistrationFeeSession(webSuccessUrl, webCancelUrl);
    if (Util.shouldReject(response)) return Promise.reject(response);

    const { payment } = response.data;
    return payment.checkoutSessionId;
  }),

  emailEnrollmentAgreement: flow(function* (agreementId) {
    const { api } = getEnv(self);
    const response = yield api.emailEnrollmentAgreement(self.learner.id, agreementId);

    if (Util.shouldReject(response)) return Promise.reject(response);
    return response.data;
  }),

  // Volatile learner
  setBasicLearnerData(learner) { self.basicLearnerData = learner; },
  resetBasicLearnerData() { self.basicLearnerData = undefined; },

  syncInAppNotifications: flow(function* syncInAppNotifications() {
    const { api } = getEnv(self);
    const { learner } = self;

    const response = yield api.getInAppNotifications(learner.id);

    if (Util.shouldReject(response)) return Promise.reject(response);

    self.inAppNotifications = response.data.data;

    return self.inAppNotifications;
  }),

  inAppNotificationDismiss: flow(function* inAppNotificationDismiss(id) {
    const { api } = getEnv(self);

    const { learner } = self;

    const response = yield api.inAppNotificationDismiss(learner.id, id);

    if (Util.shouldReject(response)) return Promise.reject(response);

    yield self.syncInAppNotifications(learner.id);

    return response.data;
  }),

  inAppNotificationDisplayed: flow(function* inAppNotificationDisplayed(id) {
    const { api } = getEnv(self);

    const { learner } = self;

    const response = yield api.inAppNotificationDisplayed(learner.id, id);

    return response.data;
  }),

  inAppNotificationInteracted: flow(function* inAppNotificationInteracted(id) {
    const { api } = getEnv(self);

    const { learner } = self;

    const response = yield api.inAppNotificationInteracted(learner.id, id);

    return response.data;
  }),

  getEnrollmentAgreements: flow(function* getEnrollmentAgreements() {
    const { api } = getEnv(self);
    const { learner } = self;
    const response = yield api.getEnrollmentAgreements(learner.id);

    if (Util.shouldReject(response)) return Promise.reject(response);

    self.enrollmentAgreements = response.data.data;
    return self.enrollmentAgreements;
  }),

  createQuestionnaireAnswers(id) { self.questionnaireAnswers = { id, answers: [] }; },

  addQuestionnaireAnswer(answer) {
    const { questionnaireAnswers } = self;
    let hasAnswer = false;

    let tempQuestionnaireAnswers = questionnaireAnswers.answers.map(questionAnswer => {
      if (questionAnswer.id === answer.questionId) {
        hasAnswer = true;
        return answer;
      }
      return questionAnswer;
    });

    if (!hasAnswer) tempQuestionnaireAnswers = [...tempQuestionnaireAnswers, answer];

    self.questionnaireAnswers.answers = tempQuestionnaireAnswers;
  },

  validateQuestionnaireAnswers: flow(function* validateQuestionnaireAnswers(questionnaireId, body) {
    const { api } = getEnv(self);
    const { learner } = self;
    const response = yield api.sendQuestionnaireResponse(learner.id, questionnaireId, body);

    if (Util.shouldReject(response)) return Promise.reject(response);

    return response;
  }),

  updateInternetStatus(state) { self.hasInternet = state; },
});

export default SessionStoreActions;
