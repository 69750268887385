import React from 'react';
import { inject, observer } from 'mobx-react';
import { Images } from '@nextstep/app/Themes';
import TermModal from '@nextstep/app/modals/TermModal';
import { afterInteractions } from '@nextstep/app/lib/afterInteractions';
import ScreenTimer from '@nextstep/app/components/ScreenTimer';
import { FullViewSpinner } from '@nextstep/app/components/SharedUI';
import withDimensions from '@nextstep/app/lib/withDimensions';
import ViewWrapper from '@nextstep/app/components/ViewWrapper';
import SuccessCriteria from '@nextstep/app/components/skillset/SuccessCriteria';
import BaseHeader from '@nextstep/app/components/baseStructure/BaseHeader';
import BaseFooter from '@nextstep/app/components/baseStructure/BaseFooter';
import { ContentNodeTypes, ProgressNodeTypes } from '@nextstep/app/Config/Constants';
import NavigationService from '@nextstep/app/services/NavigationService';
import BottomSheetModal from '@nextstep/app/modals/BottomSheetModal';
import NetInfo from '@react-native-community/netinfo';
import SkillChildNavigator from './SkillChildNavigator';

class SuccessCriteriaScreen extends SkillChildNavigator {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isVisibleTerm: false,
      currentTerm: null,
    };

    this.goToNextLearningObject = this.goToNextLearningObject.bind(this);
  }

  async componentDidMount() {
    const { rootStore, route } = this.props;
    const { contentStore, progressStore } = rootStore;

    await afterInteractions();

    contentStore.clearCurrentExcept([
      ContentNodeTypes.course, ContentNodeTypes.skillset,
      ContentNodeTypes.skill, ContentNodeTypes.step]);
    progressStore.clearCurrentExcept([
      ProgressNodeTypes.courseProgress, ProgressNodeTypes.skillsetProgress,
      ProgressNodeTypes.skillProgress, ProgressNodeTypes.stepProgress]);

    const skillsetKey = route.params?.skillsetKey;
    const skillKey = route.params?.skillKey;
    const stepKey = route.params?.stepKey;

    await contentStore.setSkillset(skillsetKey);
    await contentStore.setSkill(skillKey);
    await contentStore.setStep(stepKey);
    await progressStore.setSkillsetProgress(contentStore.skillset.id);
    await progressStore.setSkillProgress(contentStore.skill.id);
    await progressStore.setStepProgress(contentStore.step.id);

    if (progressStore.stepProgress.isUnlocked) {
      await progressStore.startStep();
    }

    progressStore.setSuccessCriteriaProgress();

    const { successCriteriaProgress } = progressStore;
    if (successCriteriaProgress.isCompleted) {
      successCriteriaProgress.fetch();
    }

    if (successCriteriaProgress.isUnlocked) {
      await successCriteriaProgress.start();
    }

    this.setState({ isLoading: false });
  }

  async completeLearningObject() {
    const { rootStore } = this.props;
    const { contentStore, progressStore } = rootStore;
    const { successCriteriaProgress } = progressStore;
    const { requiredMinimumTime } = contentStore.skillset;
    await successCriteriaProgress.complete();

    const hasTimeConstraint = requiredMinimumTime > 0;
    await progressStore.logTimer(hasTimeConstraint);
    this.goToNextLearningObject();
  }

  goToNextLearningObject = async () => {
    const { rootStore, navigation } = this.props;
    const { contentStore, progressStore: { stepProgress }, progressStore } = rootStore;
    const navParams = {
      stepKey: contentStore.step.key,
      skillKey: contentStore.skill.key,
      skillsetKey: contentStore.skillset.key,
    };

    if (stepProgress.isCompleted) {
      await this.goToNextSkillChild();
    } else {
      const learningObject = progressStore.getNextStepChild();
      navigation.replace(learningObject, navParams);
    }
  }

  toggleTermModal(state) {
    this.setState({ isVisibleTerm: state });
  }

  onTermPress = (term) => {
    if (!term) return;

    this.setState({ currentTerm: term });
    this.toggleTermModal(true);
  };

  getColorResource() {
    const { rootStore } = this.props;
    const { contentStore } = rootStore;
    const { colorType } = contentStore.skillset;

    return Images.icons[colorType];
  }

  updateScreenTime = (increment) => {
    const { rootStore } = this.props;
    const { progressStore } = rootStore;
    const { successCriteriaProgress } = progressStore;
    if (!successCriteriaProgress) return;
    successCriteriaProgress.incrementDuration(increment);
  }

  getSubtitle = () => {
    const { rootStore: { contentStore } } = this.props;
    const { skillset, skill } = contentStore;

    const amountOfSkills = skillset.skillCount || 0;

    return `${skillset.title} - Skill ${skillset.skills.indexOf(skill) + 1} of ${amountOfSkills}`;
  }

  render() {
    const { rootStore: { contentStore, sessionStore } } = this.props;
    const { currentTerm, isVisibleTerm, isLoading } = this.state;
    const { skillset, skill, step } = contentStore;

    if (isLoading || !skillset) return <FullViewSpinner color={skillset && skillset.colorTheme} />;

    const { successCriteria } = step;

    return (
      <ViewWrapper
        smSize={12}
        mdSize={6}
        lgSize={6}
        navigationBar
        bottomBarColor={skillset.colorTheme}
        header={<BaseHeader title={skill.title} subtitle={this.getSubtitle()} color={skillset.colorTheme} />}
        footer={<BaseFooter buttonText={'Continue'} color={skillset.colorTheme} onPress={() => this.completeLearningObject()} />}
        backOverride={() => NavigationService.navigate('App', { screen: 'Learn' })}
      >

        <BottomSheetModal
          isVisible={!sessionStore.hasInternet}
          dismissable={false}
          icon={Images.icons.noWifi}
          messageTitle={'No internet Connection!'}
          messageContent={'Please wait a couple of seconds and try again'}
          primaryAction={{
            text: 'Retry',
            action: () => NetInfo.fetch().then(state => sessionStore.updateInternetStatus(state.isInternetReachable)),
          }}
        />

        <SuccessCriteria
          color={skillset.colorTheme}
          title={step.title}
          subtitle={`Step ${skill.steps.indexOf(step) + 1}`}
          content={successCriteria.content}
          onTermPress={this.onTermPress}
        />

        <TermModal
          term={currentTerm}
          isVisible={isVisibleTerm}
          toggleVisible={() => { this.toggleTermModal(false); }}
        />

        <ScreenTimer onUpdateTime={this.updateScreenTime} />

      </ViewWrapper>
    );
  }
}

export default withDimensions(inject('rootStore')(observer(SuccessCriteriaScreen)));
