import { types } from 'mobx-state-tree';
import { ContentNodeTypes } from '@nextstep/app/Config/Constants';
import QuestionTiers from './QuestionTiers';
import ContentNode from './ContentNode';

const AssessmentBlockModel = types.model('AssessmentBlock', {
  id: types.identifierNumber,
  key: types.string,
  questionTiers: types.maybe(QuestionTiers),
  version: types.maybe(types.string),
}).views(self => ({
  get loaded() {
    return !!self.questionTiers;
  },
}));

const AssessmentBlock = types.compose(AssessmentBlockModel, ContentNode)
  .named(ContentNodeTypes.assessmentBlock);

export default AssessmentBlock;
