import { StyleSheet } from 'react-native';
import hexToRgba from 'hex-to-rgba';
import { Colors } from '@nextstep/app/Themes';

export default StyleSheet.create({
  resultBanner: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: 14,
  },

  bannerIcon: {
    width: 45,
    height: 45,
  },

  imageChoiceRow: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },

  mySkillBadgeBackground: {
    width: 156,
    height: 156,
    borderRadius: 100,
    alignItems: 'center',
    justifyContent: 'center',
  },

  mySkillBadge: {
    width: 140,
    height: 140,
    resizeMode: 'cover',
    borderRadius: 100,
  },

  bookIcon: {
    width: 24,
    height: 24,
    resizeMode: 'contain',
    tintColor: Colors.white,
  },

  iconButton: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },

  autoAdvance: {
    position: 'absolute',
    height: '100%',
    backgroundColor: hexToRgba('#fff', 0.4),
  },
});
