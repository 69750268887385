import { types } from 'mobx-state-tree';
import CourseProgress from '@nextstep/app/models/CourseProgress';
import SkillsetProgress from '@nextstep/app/models/SkillsetProgress';
import PracticeLabProgress from '@nextstep/app/models/PracticeLabProgress';
import SkillProgress from '@nextstep/app/models/SkillProgress';
import CompletedSkill from '@nextstep/app/models/CompletedSkill';
import StepProgress from '@nextstep/app/models/StepProgress';
import QuestionProgress from '@nextstep/app/models/QuestionProgress';
import AssessmentBlockProgress from '@nextstep/app/models/AssessmentBlockProgress';
import Metrics from '@nextstep/app/models/Metrics';
import AssignmentQuestionProgress from '@nextstep/app/models/AssignmentQuestionProgress';
import ProgressStoreActions from './ProgressStoreActions';
import ProgressStoreViews from './ProgressStoreViews';

const ProgressStore = types
  .model('ProgressStore', {
    // References
    skillsetProgress: types.safeReference(SkillsetProgress),
    skillProgress: types.safeReference(SkillProgress),
    stepProgress: types.safeReference(StepProgress),
    assessmentBlockProgress: types.safeReference(AssessmentBlockProgress),
    questionProgress: types.safeReference(QuestionProgress),
    assignmentQuestionProgress: types.safeReference(AssignmentQuestionProgress),

    // Attributes
    identifier: types.optional(types.identifier, 'ProgressStore'),
    metrics: types.maybe(Metrics),
    skillsetProgresses: types.maybe(types.array(SkillsetProgress)),
    practiceLabProgresses: types.maybe(types.array(PracticeLabProgress)),
    courseProgress: types.maybe(CourseProgress),
    completedSkills: types.optional(types.array(CompletedSkill), []),
    assignmentQuestionProgresses: types.maybe(types.array(AssignmentQuestionProgress)),

    // Below here isn't really progress - maybe UIStateStore?
    isStudyMaterialsModalVisible: types.optional(types.boolean, false),
    isAssignmentQuestionModalVisible: types.optional(types.boolean, false),
    expandedSkillsetKey: types.optional(types.string, ''),
    activeTab: types.maybe(types.string),
  })
  .volatile(() => ({ loading: types.optional(types.boolean, false) }))
  .actions(ProgressStoreActions)
  .views(ProgressStoreViews);

export default ProgressStore;
