import React from 'react';
import { View } from 'react-native';
import { inject, observer } from 'mobx-react';
import { Icon } from 'react-native-elements';
import withDimensions, { DeviceType } from '@nextstep/app/lib/withDimensions';
import InAppNotification from '@nextstep/app/components/InAppNotification';
import Swiper from 'react-native-swiper';
import { Body2 } from '@nextstep/app/components/Text';
import Constants from '@nextstep/app/Config/Constants';

const DefaultStyles = {
  transitionButton: {
    borderRadius: 4,
    borderWidth: 2,
    paddingHorizontal: 8,
    paddingVertical: 5,
  },
};


class InAppNotifications extends React.Component {
  constructor(props) {
    super(props);
    this.state = { currentNotificationIndex: 0 };
  }

  onNotificationDismissed = (id) => {
    const { rootStore: { sessionStore } } = this.props;
    sessionStore.inAppNotificationDismiss(id);
  };

  onNotificationInteracted = (id) => {
    const { rootStore: { sessionStore } } = this.props;
    sessionStore.inAppNotificationInteracted(id);
  }

  setPreviousNotificationIndex = () => {
    const { notifications } = this.props;
    const { currentNotificationIndex } = this.state;

    this.onNotificationInteracted(this.currentNotification().id);
    const idx = (notifications.length + (currentNotificationIndex - 1)) % notifications.length;
    this.setCurrentNotificationIndex(idx);
  }

  setNextNotificationIndex = () => {
    const { notifications } = this.props;
    const { currentNotificationIndex } = this.state;

    this.onNotificationInteracted(this.currentNotification().id);
    const idx = (notifications.length + (currentNotificationIndex + 1)) % notifications.length;
    this.setCurrentNotificationIndex(idx);
  }

  currentNotification = () => {
    const { notifications } = this.props;
    const { currentNotificationIndex } = this.state;
    return notifications[currentNotificationIndex];
  }

  setCurrentNotificationIndex = (idx) => {
    this.setState({ currentNotificationIndex: idx });
  }

  render() {
    const { currentNotificationIndex } = this.state;
    const { rootStore: { contentStore } } = this.props;
    const { notifications, dimensions } = this.props;
    if (!notifications || notifications.length <= 0) return <></>;

    // Mobile Web and Native
    if (dimensions.deviceType === DeviceType.Mobile) {
      return (
        <View style={{ height: 160 }}>
          <Swiper
            showsButtons={false}
            showsPagination
            activeDotColor={'orange'}
            paginationStyle={{ bottom: 0 }}
            onIndexChanged={(index) => this.onNotificationInteracted(notifications[index].id)}
          >
            {notifications.map(notification => (
              <InAppNotification
                key={notification.id}
                notification={notification}
                style={{ flex: 1 }}
                onDismissed={() => this.onNotificationDismissed(notification.id)}
                contentStore={contentStore}
              />
            ))}
          </Swiper>
        </View>
      );
    }

    // Desktop Web
    return (
      <InAppNotification
        notification={this.currentNotification()}
        currentNotificationIndex={currentNotificationIndex}
        onDismissed={() => this.onNotificationDismissed(this.currentNotification().id)}
        style={{ paddingVertical: 10 }}
        contentStore={contentStore}
      >
        {notifications.filter(IAN => IAN.style === Constants.InAppReminderTypes.banner).length > 1 && (
          <>
            <View style={{ flexDirection: 'row', alignSelf: 'center' }}>
              <Icon
                id={'notifications_back_button'}
                name={'arrow-back'}
                style={[DefaultStyles.transitionButton]}
                onPress={() => this.setPreviousNotificationIndex()}
              />
              <View style={{ width: 8 }} />
              <Icon
                id={'notifications_forward_button'}
                name={'arrow-forward'}
                style={[DefaultStyles.transitionButton]}
                onPress={() => this.setNextNotificationIndex()}
              />
            </View>

            <View style={{ alignSelf: 'center', marginHorizontal: 10, paddingTop: 5 }}>
              <Body2>{`${currentNotificationIndex + 1} of ${notifications.length}`}</Body2>
            </View>
          </>
        )}
      </InAppNotification>
    );
  }
}

export default withDimensions(inject('rootStore')(observer(InAppNotifications)));
