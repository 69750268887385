/* eslint-disable no-restricted-syntax */
/* eslint-disable class-methods-use-this */
import '@nextstep/app/Config';
import React, { useEffect, useState } from 'react';
import DeepLinking from 'react-native-deep-linking';
import SplashScreen from 'react-native-splash-screen';
import { Text, TextInput, Linking, AppState } from 'react-native';
import NavigationService from '@nextstep/app/services/NavigationService';
import Orientation from 'react-native-orientation';
import DeviceInfo from 'react-native-device-info';
import {
  setJSExceptionHandler,
  setNativeExceptionHandler,
} from 'react-native-exception-handler';
import mitt from 'mitt';
import { Provider } from 'mobx-react';
import Reactotron from 'reactotron-react-native';
import codePush from 'react-native-code-push';
import DebugConfig from '@nextstep/app/Config/DebugConfig';
import {
  ContainerErrorBoundary,
  JSExceptionHandler,
  NativeExceptionHandler,
} from '@nextstep/app/lib/ErrorUtils';
import RootStore from '@nextstep/app/stores/RootStore';
import RootContainer from './RootContainer';

if (DebugConfig.useReactotron) {
  Reactotron.trackMstNode(RootStore);
}

if (DebugConfig.production) {
  (async () => {
    const { configureLogRocket } = await import(
      '@nextstep/app/Config/LogRocketConfig'
    );
    configureLogRocket();
  })();
}

global.rootStore = RootStore;
global.emitter = mitt();

setJSExceptionHandler(JSExceptionHandler, false);
setNativeExceptionHandler(NativeExceptionHandler, false, true);

Text.defaultProps = {};
Text.defaultProps.allowFontScaling = false;
Text.defaultProps.maxFontSizeMultiplier = 1;

TextInput.defaultProps = {};
TextInput.defaultProps.allowFontScaling = false;

const codePushConfig = {
  checkFrequency: codePush.CheckFrequency.ON_APP_RESUME,
  installMode: codePush.InstallMode.ON_NEXT_RESUME,
};

const App = () => {
  useEffect(() => {
    if (!DeviceInfo.isTablet()) Orientation.lockToPortrait();
    SplashScreen.hide();
  }, []);

  // add URL schemes to `DeepLinking`
  DeepLinking.addScheme('nextstep://');

  const DEEPLINKING_ROUTES = [
    'LaunchScreen',
  ];

  // configure routes
  DEEPLINKING_ROUTES.forEach(route => {
    DeepLinking.addRoute(`/${route}`, () => {
      NavigationService.navigate(route);
    });
  });

  // evaluate every incoming URL
  const handleOpenURL = (event) => {
    DeepLinking.evaluateUrl(event.url);
  };

  // manage Linking event listener with useEffect
  useEffect(() => {
    Linking.addEventListener('url', handleOpenURL);
    return () => {
      Linking.removeEventListener('url', handleOpenURL);
    };
  }, []);

  // Universal link

  // checking if an initialURL is present
  const [initialised, setInitialised] = useState(false);

  const handleAppStateChange = async () => {
    const initial = await Linking.getInitialURL();

    if (initial !== null && !initialised) {
      setInitialised(true);
      // app was opened by a Universal Link
      // custom setup dependant on URL...
    }
  };

  useEffect(() => {
    AppState.addEventListener('change', handleAppStateChange);
    if (Linking.getInitialURL() !== null) {
      AppState.removeEventListener('change', handleAppStateChange);
    }
  }, []);

  return (
    <Provider rootStore={RootStore}>
      <ContainerErrorBoundary>
        <RootContainer />
      </ContainerErrorBoundary>
    </Provider>
  );
};

// allow reactotron overlay for fast design in dev mode
export default // TODO: Fix this the next time the file is edited.
// eslint-disable-next-line no-console
DebugConfig.useReactotron ? console.tron.overlay(codePush(codePushConfig)(App)) : codePush(codePushConfig)(App);
