/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { Image, Platform, View } from 'react-native';
import hexToRgba from 'hex-to-rgba';
import { Column, Row } from '@nextstep/app/lib/ResponsiveLayout';
import Palette from '@nextstep/app/Themes/Palette';
import withDimensions from '@nextstep/app/lib/withDimensions';
import { Subtitle1, Subtitle2, Title } from '@nextstep/app/components/Text';

const currentPalette = Palette.light;

const DefaultStyles = {
  mySkillBadgeBackground: {
    width: 156,
    height: 156,
    borderRadius: 100,
    alignItems: 'center',
    justifyContent: 'center',
  },

  mySkillBadge: {
    width: 140,
    height: 140,
    resizeMode: 'cover',
    borderRadius: 100,
    overflow: 'hidden',
    alignItems: 'center',
  },
};

const SkillCelebration = (props) => (
  <View style={[{ justifyContent: 'center' }, Platform.OS !== 'web' && { height: '100%' }]}>

    {Platform.OS === 'web' && <View style={{ height: 36 }} />}

    <Row>
      <Column smSize={10}>

        <View style={{ alignItems: 'center' }}>
          <View style={[DefaultStyles.mySkillBadgeBackground, { backgroundColor: hexToRgba(props.color, 0.4) }]}>
            <Image
              style={[DefaultStyles.mySkillBadge, { backgroundColor: props.color }]}
              source={{ uri: props.icon }}
            />
          </View>
        </View>

        <View style={{ height: 40 }} />

        <Title color={props.color}>
          CONGRATS!
        </Title>

        <View style={{ height: 24 }} />

        <Subtitle1 color={props.color}>
          You’ve earned the
        </Subtitle1>

        <Subtitle2 color={props.color}>
          {props.skillName}
        </Subtitle2>

        <Subtitle1 color={props.color}>
          skill badge!
        </Subtitle1>

      </Column>
    </Row>

    {Platform.OS === 'web' && <View style={{ height: 36 }} />}

  </View>
);


SkillCelebration.propTypes = {
  skillName: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.string,
};

SkillCelebration.defaultProps = {
  skillName: 'Skill #1',
  color: currentPalette.primary.main,
  icon: '',
};

export default withDimensions(SkillCelebration);
