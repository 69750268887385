import React from 'react';
import PropTypes from 'prop-types';
import { Image, View } from 'react-native';
import { AnimatedCircularProgress } from 'react-native-circular-progress';
import Util from '@nextstep/app/services/Util';
import { Images, Palette } from '@nextstep/app/Themes';
import SkillsetChildIconStyles from './styles/SkillsetChildIconStyles';

const currentPalette = Palette.light;

const SkillsetChildIcon = (props) => {
  const {
    skillsetChild,
    disabled,
    completed,
    progressValue,
    colorTheme,
    colorType,
    dimensions,
  } = props;

  const Styles = SkillsetChildIconStyles(dimensions.deviceType);
  const tintColor = disabled || !skillsetChild.icon ? currentPalette.basic.skeletonPlaceholder : colorTheme;

  return (
    <View style={Styles.icon}>
      <AnimatedCircularProgress
        size={104}
        width={11}
        fill={progressValue * 100}
        tintColor={Util.colorWithTint(tintColor, 0.6)}
        backgroundColor={Util.colorWithTint(tintColor, 0.2)}
        rotation={0}
        duration={0}
      />

      <View
        disabled={disabled}
        style={Styles.circle}
      >
        <Image
          style={[Styles.img, { backgroundColor: tintColor }]}
          source={{ uri: skillsetChild.icon }}
        />
      </View>

      {completed && (
        <Image
          style={Styles.completeIcon}
          source={Images.icons[colorType].skillComplete}
        />
      )}
    </View>
  );
};

SkillsetChildIcon.propTypes = {
  skillsetChild: PropTypes.object,
  disabled: PropTypes.bool,
  completed: PropTypes.bool,
  progressValue: PropTypes.number,
  colorTheme: PropTypes.string,
  colorType: PropTypes.string,
  dimensions: PropTypes.object,
};

SkillsetChildIcon.defaultProps = {
  skillsetChild: { icon: '' },
  disabled: true,
  completed: false,
  progressValue: 0,
  colorTheme: '',
  colorType: 'green',
  dimensions: {},
};

export default SkillsetChildIcon;
