import { types } from 'mobx-state-tree';
import QuestionProgress from './QuestionProgress';
import ProgressNode from './ProgressNode';

const QuestionTiersProgressModel = types.model({
  medium: types.array(QuestionProgress),
  hard: types.array(QuestionProgress),
});
const QuestionTiersProgress = types.compose(QuestionTiersProgressModel, ProgressNode);

export default QuestionTiersProgress;
