import { types } from 'mobx-state-tree';
import AssessmentBlockProgress from './AssessmentBlockProgress';
import DemoVideoProgress from './DemoVideoProgress';
import SuccessCriteriaProgress from './SuccessCriteriaProgress';
import WithStatus from './WithStatus';
import ProgressNode from './ProgressNode';
import Step from './Step';

const StepProgressModel = types.model('StepProgress', {
  id: types.identifierNumber,
  stepId: types.number,
  childOrder: types.array(types.string),
  successCriteriaProgress: types.maybe(SuccessCriteriaProgress),
  demoVideoProgresses: types.maybe(types.array(DemoVideoProgress)),
  assessmentBlockProgresses: types.maybe(types.array(AssessmentBlockProgress)),
  content: types.maybe(types.safeReference(Step)),
}).views(self => ({
  get loaded() {
    return !!self.successCriteriaProgress;
  },
  get assessmentBlocksCompleted() {
    return self.assessmentBlockProgresses.every(a => a.isCompleted);
  },
}));

// eslint-disable-next-line no-undef
const StepProgress = types.compose(StepProgressModel, ProgressNode, WithStatus).named('StepProgress');

export default StepProgress;
