import { StyleSheet } from 'react-native';
import { Palette } from '@nextstep/app/Themes';

export default StyleSheet.create({
  header: { padding: 30 },

  headerCompact: {
    paddingHorizontal: 20,
    paddingVertical: 16,
  },

  title: {
    fontSize: 24,
    lineHeight: 40,
    fontFamily: 'Poppins-Bold',
    color: Palette.light.primary.contrastText,
  },

  titleCompact: {
    fontSize: 18,
    lineHeight: 28,
    fontFamily: 'Poppins-Bold',
    color: Palette.light.primary.contrastText,
  },
});
