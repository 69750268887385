/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { Platform, Text, View } from 'react-native';
import LottieView from 'lottie-react-native';
import { Column, Row } from '@nextstep/app/lib/ResponsiveLayout';
import Palette from '@nextstep/app/Themes/Palette';
import withDimensions from '@nextstep/app/lib/withDimensions';
import Amy from '@nextstep/assets/animations/amy.json';
import Iris from '@nextstep/assets/animations/iris.json';
import Julie from '@nextstep/assets/animations/julie.json';
import { Subtitle1, Subtitle2, Title } from '@nextstep/app/components/Text';
import celebrationMessages from './celebrationMessages.json';

const currentPalette = Palette.light;

const animations = [Amy, Iris, Julie];

export const getCelebrationMessage = (index) => {
  const amountOfAvailableMessages = [...celebrationMessages].length; // counting 0
  return celebrationMessages[index % amountOfAvailableMessages];
};

const SkillsetCelebration = (props) => (
  <View style={[{ justifyContent: 'center' }, Platform.OS !== 'web' && { height: '100%' }]}>

    {Platform.OS === 'web' && <View style={{ height: 36 }} />}

    <Row>
      <Column smSize={10}>

        <LottieView
          source={animations[Math.floor((Math.random() * animations.length))]}
          style={{ height: 217, alignSelf: 'center' }}
          autoPlay
          loop={false}
        />

        <View style={{ height: 40 }} />

        <Title color={props.color}>
          {getCelebrationMessage(props.index)}
        </Title>

        <View style={{ height: 24 }} />

        <Text style={{ alignSelf: 'center' }}>
          <Subtitle1 color={props.color}>
            The
          </Subtitle1>

          <Subtitle2 color={props.color}>
            {` ${props.skillsetName} `}
          </Subtitle2>

          <Subtitle1 color={props.color}>
            skill set is complete!
          </Subtitle1>
        </Text>

      </Column>
    </Row>

    {Platform.OS === 'web' && <View style={{ height: 36 }} />}

  </View>
);


SkillsetCelebration.propTypes = {
  skillsetName: PropTypes.string,
  color: PropTypes.string,
  index: PropTypes.number,
};

SkillsetCelebration.defaultProps = {
  skillsetName: 'Skill Set Name',
  color: currentPalette.primary.main,
  index: 0,
};

export default withDimensions(SkillsetCelebration);
