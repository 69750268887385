/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import { Palette } from '@nextstep/app/Themes';
import typography from '@nextstep/app/Themes/Typography';
import { Body1 } from './Text';

const currentPalette = Palette.light;

const BulletItem = (props) => (
  <View style={{ flexDirection: 'row', paddingHorizontal: 18, paddingVertical: 6 }}>

    <Body1 color={currentPalette.text.main}>
      {'\u25CF'}
    </Body1>

    <View style={{ paddingLeft: 18 }} />

    <Body1 textAlign="left" style={{ fontFamily: props.bold ? typography.body2.fontFamily : typography.body1.fontFamily }}>
      {props.children}
    </Body1>

  </View>
);

BulletItem.propTypes = {
  children: PropTypes.node,
  bold: PropTypes.bool,
};

BulletItem.defaultProps = {
  children: null,
  bold: false,
};

export default BulletItem;
