/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Platform, TextInput as TI, TouchableOpacity, View } from 'react-native';
import { Icon } from 'react-native-elements';
import { Palette } from '@nextstep/app/Themes';
import Constants from '@nextstep/app/Config/Constants';
import Styles from '@nextstep/app/Themes/ApplicationStyles';
import Util from '@nextstep/app/services/Util';
import InformationButton from './InformationButton';
import { Body1, Caption1, FormLabel } from './Text';

const currentPalette = Palette.light;

const defaultStyles = {
  rightSideButtonContainer: {
    position: 'absolute',
    right: 0,
    marginRight: 12,
    marginTop: 12,
  },
};

const defaultAttributes = {
  underlineColorAndroid: 'transparent',
  autoCorrect: false,
  returnKeyType: 'next',
  placeholderTextColor: currentPalette.text.placeHolder,
};

const defaultTextAttributes = {
  keyboardType: 'default',
  autoCapitalize: 'words',
};

const {
  firstName, middleName, lastName, emailAddress, zipCode,
  phoneNumber, password, verificationCode, custom,
  addressCity, addressState, streetAddressLine1,
  streetAddressLine2, fullName,
} = Constants.InputTextTypes;

const DefaultStyle = (hasValue, isFocused, showError, isEditable) => {
  if (!isEditable) return ({ ...Styles.newInput, ...Styles.inputDisabled });

  if (showError) {
    return ({
      ...Styles.newInput,
      ...Styles.inputError,
    });
  }

  if (isFocused) {
    return ({
      ...Styles.newInput,
      ...Styles.inputFocus,
    });
  }

  if (hasValue) {
    return ({
      ...Styles.newInput,
      borderColor: currentPalette.primary.main,
    });
  }

  return Styles.newInput;
};

const getTitleColor = (props) => {
  if (!props.editable) return currentPalette.text.disabled;
  if (props.data.showError) return currentPalette.text.error;
  return props.titleTextColor || currentPalette.primary.main;
};

const defaultValueTypeAttributes = (props) => {
  const { valueType } = props;

  switch (valueType) {
    case firstName:
      return {
        ...defaultAttributes,
        ...defaultTextAttributes,
        placeholder: 'First Name',
        textContentType: 'givenName',
        ...props.customAttributes,
      };
    case middleName:
      return {
        ...defaultAttributes,
        ...defaultTextAttributes,
        placeholder: 'Middle Name',
        textContentType: 'middleName',
        ...props.customAttributes,
      };
    case lastName:
      return {
        ...defaultAttributes,
        ...defaultTextAttributes,
        placeholder: 'Last Name',
        textContentType: 'familyName',
        ...props.customAttributes,
      };
    case fullName:
      return {
        ...defaultAttributes,
        ...defaultTextAttributes,
        placeholder: 'Full Name',
        textContentType: 'name',
        autoCompleteType: 'name',
        ...props.customAttributes,
      };
    case emailAddress:
      return {
        ...defaultAttributes,
        keyboardType: 'email-address',
        autoCapitalize: 'none',
        placeholder: 'Email Address',
        textContentType: 'emailAddress',
        autoCompleteType: 'email',
        ...props.customAttributes,
      };
    case streetAddressLine1:
      return {
        ...defaultAttributes,
        ...defaultTextAttributes,
        placeholder: 'Street Address',
        textContentType: 'streetAddressLine1',
        autocomplete: 'street-address',
        ...props.customAttributes,
      };
    case streetAddressLine2:
      return {
        ...defaultAttributes,
        ...defaultTextAttributes,
        placeholder: 'Apartment, Unit, Etc.',
        textContentType: 'streetAddressLine2',
        ...props.customAttributes,
      };
    case addressCity:
      return {
        ...defaultAttributes,
        ...defaultTextAttributes,
        placeholder: 'City',
        textContentType: 'addressCity',
        ...props.customAttributes,
      };
    case addressState:
      return {
        ...defaultAttributes,
        ...defaultTextAttributes,
        placeholder: 'State',
        textContentType: 'addressState',
        ...props.customAttributes,
      };
    case zipCode:
      return {
        ...defaultAttributes,
        keyboardType: 'number-pad',
        autoCapitalize: 'none',
        placeholder: 'Zip Code',
        textContentType: 'postalCode',
        autoCompleteType: 'postal-code',
        ...props.customAttributes,
      };
    case phoneNumber:
      return {
        ...defaultAttributes,
        keyboardType: 'phone-pad',
        autoCapitalize: 'none',
        placeholder: 'Cellphone number',
        textContentType: 'telephoneNumber',
        autoCompleteType: 'tel',
        ...props.customAttributes,
      };
    case password:
      return {
        ...defaultAttributes,
        ...defaultTextAttributes,
        placeholder: 'Password',
        textContentType: 'password',
        autoCompleteType: 'password',
        secureTextEntry: true,
        ...props.customAttributes,
      };
    case verificationCode:
      return {
        ...defaultAttributes,
        keyboardType: 'number-pad',
        autoCapitalize: 'none',
        placeholder: 'Verification Code',
        textContentType: 'oneTimeCode',
        ...props.customAttributes,
      };
    case custom:
      return {
        ...defaultAttributes,
        ...props.customAttributes,
      };
    default:
      return { ...props };
  }
};

export const InputErrorMessage = (props) => {
  const msgStyle = {
    paddingVertical: 8,
    ...props.style,
  };

  const textStyle = {
    color: props.textColor,
    textAlign: props.textAlign,
    ...props.textStyle,
  };

  return (
    <View style={msgStyle}>
      <Caption1 style={textStyle}>
        {props.children}
      </Caption1>
    </View>
  );
};

InputErrorMessage.propTypes = {
  style: PropTypes.object,
  textStyle: PropTypes.object,
  textColor: PropTypes.string,
  textAlign: PropTypes.string,
};

InputErrorMessage.defaultProps = {
  style: {},
  textStyle: {},
  textColor: currentPalette.text.error,
  textAlign: 'left',
};

export const TextInput = (props) => {
  const titleTextStyle = {
    color: getTitleColor(props),
    textAlign: props.titleTextAlign,
    ...props.titleTextStyle,
  };

  const [showTooltip, setTooltipVisibility] = useState(false);

  const showClearButton = props.onClear && props.isFocused && props.data.value && props.editable;

  return (
    <View style={{ display: 'flex' }}>

      <TI
        style={[
          DefaultStyle(props.data.value, props.isFocused, props.data.showError, props.editable),
          Platform.OS === 'web' && { outlineStyle: 'none' },
          props.customAttributes?.multiline && { height: 150 },
        ]}
        value={props.data.value}
        onChangeText={text => props.onChangeText(text)}
        onEndEditing={() => props.onEndEditing()}
        onSubmitEditing={() => props.onSubmitEditing()}
        onClear={() => props.onClear()}
        onFocus={() => props.onFocus()}
        editable={props.editable}
        {...defaultValueTypeAttributes(props)}
      />

      {props.title && (
        <View style={Styles.inputTitle}>
          <FormLabel style={titleTextStyle}>
            {props.title}
          </FormLabel>
        </View>
      )}

      {props.tooltip ? (
        <View style={defaultStyles.rightSideButtonContainer}>
          <InformationButton
            show={showTooltip}
            action={(state) => setTooltipVisibility(state)}
            size={25}
          >
            {Util.isStringLiteral(props.tooltip) ? (
              <Body1 textAlign="left">
                {props.tooltip}
              </Body1>
            ) : props.tooltip}
          </InformationButton>
        </View>
      ) : Boolean(showClearButton) && (
        <TouchableOpacity
          accessible={false}
          style={defaultStyles.rightSideButtonContainer}
          onPress={() => props.onClear()}
        >
          <Icon
            name={'close'}
            activeOpacity={0}
            color={currentPalette.primary.main}
            underlayColor="transparent"
          />
        </TouchableOpacity>
      )}

      {props.data.showError
        ? <InputErrorMessage>{props.data.errorMessage}</InputErrorMessage>
        : props.showErrorSpace && <View style={{ height: 32 }} />
      }
    </View>
  );
};

TextInput.propTypes = {
  data: PropTypes.shape({
    value: PropTypes.string,
    showError: PropTypes.bool,
    errorMessage: PropTypes.string,
  }),
  customAttributes: PropTypes.object,
  titleTextStyle: PropTypes.object,
  titleTextColor: PropTypes.string,
  titleTextAlign: PropTypes.string,
  valueType: PropTypes.string,
  onChangeText: PropTypes.func,
  onEndEditing: PropTypes.func,
  onSubmitEditing: PropTypes.func,
  onFocus: PropTypes.func,
  editable: PropTypes.bool,
  isFocused: PropTypes.bool,
  showErrorSpace: PropTypes.bool,
  tooltip: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
};

TextInput.defaultProps = {
  data: {
    value: '',
    showError: false,
    errorMessage: '',
  },
  customAttributes: {},
  titleTextStyle: {},
  titleTextColor: currentPalette.primary.main,
  titleTextAlign: 'left',
  valueType: '',
  onChangeText: () => {},
  onEndEditing: () => {},
  onSubmitEditing: () => {},
  onFocus: () => {},
  editable: true,
  isFocused: false,
  showErrorSpace: true,
  tooltip: undefined,
};
