import { types } from 'mobx-state-tree';

const AnswerChoice = types.model('AnswerChoice', {
  id: types.identifierNumber,
  score: types.maybeNull(types.number),
  order: types.maybeNull(types.number),
  answerChoice: types.string,
});

export default AnswerChoice;
