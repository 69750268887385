import React from 'react';
import { View, Image } from 'react-native';
import { inject, observer } from 'mobx-react';
import NavigationService from '@nextstep/app/services/NavigationService';
import Styles from './styles/SkillBadgeStyles';
import { Caption2 } from './Text';
import DebouncedTouchableOpacity from './DebouncedTouchableOpacity';

const SkillBadge = ({ skill, progressStore }) => (
  <View
    key={skill.id}
    style={Styles.mySkillBadgesContainer}
  >
    <DebouncedTouchableOpacity
      onPress={async () => {
        await progressStore.setExpandedSkillsetKey(skill.skillsetKey);
        NavigationService.navigate('App', { screen: 'Learn', predefinedSkillset: true });
      }}
      style={{ alignItems: 'center' }}
    >
      <View style={[Styles.mySkillBadgeBackground, { backgroundColor: skill.colorTheme40 }]}>
        <Image
          style={[Styles.mySkillBadge, { backgroundColor: skill.colorTheme }]}
          source={{ uri: skill.icon }}
        />
      </View>

      <View style={{ height: 7 }} />

      <Caption2>
        {skill.title}
      </Caption2>

    </DebouncedTouchableOpacity>

  </View>
);

export default inject(({ rootStore: { progressStore } }) => ({ progressStore }))(observer(SkillBadge));
