import { StyleSheet } from 'react-native';
import ApplicationStyles from '@nextstep/app/Themes/ApplicationStyles';
import Palette from '@nextstep/app/Themes/Palette';

const currentPalette = Palette.light;

export default StyleSheet.create({
  container: {
    paddingTop: 10,
    borderRadius: 8,
    overflow: 'hidden',
    backgroundColor: currentPalette.basic.white,
  },

  title: {
    ...ApplicationStyles.modalStyles.row,
    flexWrap: 'nowrap',
    paddingHorizontal: 20,
    justifyContent: 'space-between',
  },

  icon: {
    width: 14,
    height: 14,
    margin: 8,
  },

  body: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: 10,
    paddingHorizontal: 20,
  },

  imgIcon: {
    width: 35,
    height: 35,
    resizeMode: 'contain',
  },
});
