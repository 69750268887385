import { TabActions } from '@react-navigation/native';
import React from 'react';
import SlideItem from './SlideItem';

export default function SlideItems({ navigation, onItemPress, state, descriptors, contentOptions = {} }) {
  const activeKey = state.routes[state.index]?.key;

  const onPress = onItemPress || ((route) => {
    navigation.dispatch({
      ...TabActions.jumpTo(route.name),
      target: state.key,
    });
  });

  return state.routes.map((item) => (
    <SlideItem
      key={item.key}
      label={descriptors[item.key]?.options?.label || item.name}
      icon={descriptors[item.key]?.options?.icon && descriptors[item.key].options.icon({ focused: item.key === activeKey })}
      onPress={() => onPress(item)}
      backgroundColor={item.key === activeKey && contentOptions.activeBackgroundColor}
      textColor={item.key === activeKey && contentOptions.activeTintColor}
    />
  ));
}
