import { Text, View } from 'react-native';
import React from 'react';
import { Icon } from 'react-native-elements';
import { Colors } from '@nextstep/app/Themes';
import withDimensions, { DeviceType } from '@nextstep/app/lib/withDimensions';
import IconTimer from '@nextstep/app/components/IconTimer';
import IconPracticeLab from '@nextstep/app/components/IconPracticeLab';
import { currentPalette } from '@nextstep/app/Config/AppConfig';
import Styles from './styles/SkillsetHeaderStyles';
import { Body1 } from './Text';

export default withDimensions(({
  onLayout, skillset, practiceLab, locked, compact,
  currentSkillsetKey, skillsetProgress,
  dimensions,
}) => {
  const isSelected = skillset.key === currentSkillsetKey;

  const backgroundColor = () => {
    if (locked) return Colors.locked;
    return skillset.colorTheme;
  };

  const statusIcon = () => {
    if (!skillsetProgress) return null;
    if (skillsetProgress.isInProgress) return 'ios-contrast';
    if (skillsetProgress.isCompleted) return 'ios-checkmark-circle';

    return null;
  };

  return (
    <View onLayout={onLayout}>
      <View style={[compact ? Styles.headerCompact : Styles.header, { backgroundColor: backgroundColor() }]}>

        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>

          <View>
            <Text style={compact ? Styles.titleCompact : Styles.title}>
              {skillset.title}
            </Text>

            <View style={{ flexDirection: 'row' }}>
              <Body1 color={currentPalette.primary.contrastText} textAlign={'left'}>
                {`${skillset.skillCount} skills`}
              </Body1>

              {skillset && compact && skillset.estimatedTime && (
                <>
                  <Body1 color={currentPalette.primary.contrastText}>{' - '}</Body1>
                  <IconTimer time={skillset.estimatedTime * 60} color={currentPalette.primary.contrastText} />
                </>
              )}

              {practiceLab && (
                <>
                  <Body1 color={currentPalette.primary.contrastText}>{' - '}</Body1>
                  <IconPracticeLab />
                </>
              )}
            </View>

          </View>

          {compact && (
            <View style={{ flexDirection: 'row', alignSelf: 'center' }}>
              <Icon name={statusIcon()} type="ionicon" color="white" style={{ width: 26 }} />
              {dimensions.deviceType !== DeviceType.Mobile && (
                <>
                  <View style={{ width: 14 }} />
                  { isSelected
                    ? <Icon name="keyboard-arrow-right" color="white" />
                    : <View style={{ width: 9 }} />
                  }
                </>
              )}
            </View>
          )}

          {skillset && skillset.estimatedTime && !compact && (
            <IconTimer time={skillset.estimatedTime * 60} color={currentPalette.primary.contrastText} compact={false} />
          )}
        </View>

        <View />
      </View>
    </View>
  );
});
