// polyfills
import 'core-js/stable';
import 'regenerator-runtime/runtime';
// import 'react-native-get-random-values';

import './app/Config/ReactotronConfig';
import './app/Config/RollbarConfig';
import { AppRegistry } from 'react-native';
// import ReactAppzi from 'react-appzi';

import './assets/styles/index.web.css';
// import Config from 'react-native-config';
import App from './app/containers/App';

AppRegistry.registerComponent('NextStepCareers', () => App);

AppRegistry.runApplication('NextStepCareers', {
  callback: () => {
    // ReactAppzi.initialize(Config.APPZI_TOKEN);
    // console.log('React rendering has finished');
  },
  hydrate: true,
  initialProps: {},
  rootTag: document.getElementById('root'),
});
