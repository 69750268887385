import { StyleSheet } from 'react-native';
import ApplicationStyles from '@nextstep/app/Themes/ApplicationStyles';

export default StyleSheet.create({
  headerSection: {
    ...ApplicationStyles.modalStyles.row,
    paddingRight: 20,
    justifyContent: 'flex-end',
  },

  footerSection: {
    flexDirection: 'row',
    justifyContent: 'center',
    padding: 18,
  },

  exitBtn: {
    width: 14,
    height: 14,
    margin: 8,
  },

  contentHead: {
    width: 78,
    height: 78,
    resizeMode: 'contain',
  },

  contentSection: {
    padding: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
