import { nanoid } from 'nanoid/non-secure';
import { TabRouter, TabActions } from '@react-navigation/native';

export const SlideActions = {
  ...TabActions,
  openMenu() {
    return { type: 'OPEN_MENU' };
  },
  closeMenu() {
    return { type: 'CLOSE_MENU' };
  },
  toggleMenu() {
    return { type: 'TOGGLE_MENU' };
  },
};

const openMenu = (state) => {
  if (!state.menuHidden) {
    return state;
  }

  return {
    ...state,
    menuHidden: false,
  };
};

const closeMenu = (state) => {
  if (state.menuHidden) {
    return state;
  }

  return {
    ...state,
    menuHidden: true,
  };
};

export default function SlideRouter(props) {
  const router = TabRouter(props);

  return {
    ...router,

    type: 'slide',

    getInitialState({ routeNames, routeParamList }) {
      let state = router.getInitialState({ routeNames, routeParamList });

      state = openMenu(state);

      return {
        ...state,
        stale: false,
        type: 'slide',
        key: `slide-${nanoid()}`,
      };
    },

    getRehydratedState(partialState, { routeNames, routeParamList }) {
      if (partialState.stale === false) {
        return partialState;
      }

      let state = router.getRehydratedState(partialState, {
        routeNames,
        routeParamList,
      });

      if (!partialState.menuHidden) {
        state = openMenu(state);
      }

      return {
        ...state,
        type: 'slide',
        key: `slide-${nanoid()}`,
      };
    },

    getStateForRouteFocus(state) {
      return openMenu(state);
    },

    getStateForAction(state, action, options) {
      switch (action.type) {
        case 'OPEN_MENU':
          return openMenu(state);

        case 'CLOSE_DRAWER':
          return closeMenu(state);

        case 'TOGGLE_MENU':
          if (!state.menuHidden) {
            return closeMenu(state);
          }

          return openMenu(state);
        default:
          return router.getStateForAction(state, action, options);
      }
    },

    actionCreators: SlideActions,
  };
}
