import React, { Fragment } from 'react';
import { Text } from 'react-native-elements';
import { QuestionTypes } from '@nextstep/app/Config/Constants';
import { Body2 } from '@nextstep/app/components/Text';
import FillInBlankAnswer from './FillInBlankAnswer';

const QuestionText = ({ skillset, question, chosenAnswers }) => {
  const questionType = question.type.toLowerCase();
  if (questionType !== QuestionTypes.fillInTheBlanks) {
    return (
      <Body2 textAlign="left">
        {question.question.replace(/<b>|<\/b>/g, '')}
      </Body2>
    );
  }
  return (
    <Text>
      {(question.questionParts || []).map((block, index) => (
        <Fragment key={block.part}>

          <Body2 textAlign="left">
            {block.part}
          </Body2>

          {block.postpendAnswer && (
            <FillInBlankAnswer
              chosenAnswers={chosenAnswers}
              skillset={skillset}
              index={index}
            />
          )}

        </Fragment>
      ))}
    </Text>
  );
};

export default QuestionText;
