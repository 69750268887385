import { flow, types } from 'mobx-state-tree';
import Constants, { ContentNodeTypes } from '@nextstep/app/Config/Constants';
import LinearAssessmentIteration from './LinearAssessmentIteration';
import ContentNode from './ContentNode';

// LinearAssessments hold the metadata for linear linearAssessments; they are the datatype for both quizzes and tests.
// LinearAssessments have children 'linearAssessmentIterations' that are the individual set of questions a learner attempts on until they get a passing grade. At that point, they will have passed the linearAssessment and the last linearAssessment iteration in the array.
const LinearAssessmentModel = types.model('LinearAssessment', {
  id: types.identifierNumber,
  key: types.string,
  questionCount: types.maybe(types.number),
  requiredGrade: types.maybe(types.number),
  version: types.maybe(types.string),
  linearAssessmentIteration: types.maybe(LinearAssessmentIteration),
}).actions(self => ({
  setCurrent() {
    const { parent } = self;
    if (parent !== self.store[parent.lowerNodeType]) return;
    self.store.setCurrent({ nodeData: self });
  },
  clearIteration() {
    self.linearAssessmentIteration = {
      id: self.linearAssessmentIteration?.id,
      key: self.linearAssessmentIteration?.key,
      questions: [],
    };
  },
  getIteration: flow(function* (iterationId) {
    self.linearAssessmentIteration = yield self.store.getContent({
      type: Constants.LearningObjectTypes.linearAssessmentIteration,
      apiParams: {
        linearAssessmentKey: self.key,
        iterationId,
      },
    });
  }),
})).views(self => ({
  get loaded() {
    return Boolean(self.id);
  },
  get isFinalExam() {
    return Boolean(self.parent.isFinalExam || self.parent.parent.isFinalExam);
  },
}));

const LinearAssessment = types.compose(LinearAssessmentModel, ContentNode)
  .named(ContentNodeTypes.linearAssessment);

export default LinearAssessment;
