import { types } from 'mobx-state-tree';
import Constants from '@nextstep/app/Config/Constants';
import QuestionnaireQuestionSelectAnswerChoices from '@nextstep/app/models/QuestionnaireQuestionSelect';

const QuestionnaireQuestion = types.model('QuestionnaireQuestion', {
  // Question independent
  id: types.identifier,
  type: types.union(
    types.literal(Constants.QuestionnaireQuestionTypes.text),
    types.literal(Constants.QuestionnaireQuestionTypes.boolean),
    types.literal(Constants.QuestionnaireQuestionTypes.select),
  ),
  text: types.string,
  required: types.optional(types.boolean, false),

  // Question dependant
  answerChoice: types.maybe(types.union(
    types.array(QuestionnaireQuestionSelectAnswerChoices),
    types.string,
  )),
  other: types.maybe(types.string),
  maxSelections: types.maybe(types.number),
  placeholderText: types.maybe(types.string),
  maxLength: types.maybe(types.number),
  multiline: types.maybe(types.boolean),
});

export default QuestionnaireQuestion;
