import React, { useState, useEffect } from 'react';
import { View, Platform } from 'react-native';
import { Card, Icon } from 'react-native-elements';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import Intercom from 'react-native-intercom';
import { Images, Palette } from '@nextstep/app/Themes';
import { analyticsScreenView, trackAnalytics } from '@nextstep/app/services/Analytics';
import MentorHeader from '@nextstep/app/components/MentorHeader';
import { Body1, Body2 } from '@nextstep/app/components/Text';
import { ButtonFilled, ButtonText } from '@nextstep/app/components/Button';
import { FullViewSpinner } from '@nextstep/app/components/SharedUI';
import Constants from '@nextstep/app/Config/Constants';
import ViewWrapper from '@nextstep/app/components/ViewWrapper';
import { Row, Column } from '@nextstep/app/lib/ResponsiveLayout';
import withDimensions, { getDeviceType } from '@nextstep/app/lib/withDimensions';
import Divider from '@nextstep/app/components/Divider';
import { TextInput } from '@nextstep/app/components/TextInput';
import Util from '@nextstep/app/services/Util';
import NavigationService from '@nextstep/app/services/NavigationService';
import AdaptiveContainerStyles from '@nextstep/app/containers/styles/AdaptiveContainerStyles';
import ExternalLink from '@nextstep/app/components/ExternalLink';
import Config from '@nextstep/app/Config/DebugConfig';

const currentPalette = Palette.light;

const DefaultStyle = {
  cardContainerStyle: {
    display: 'flex',
    borderRadius: 8,
    borderColor: 'rgba(43, 35, 58, 0.05)',
    backgroundColor: '#FBFBFB',
    margin: 0,
  },
};

const EnrollmentAgreement = (props) => {
  const [legalName, setLegalName] = useState('');
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [selectedInput, setSelectedInput] = useState('');
  const [loading] = useState(false);
  const { rootStore: { sessionStore }, dimensions } = props;
  const { learner, enrollmentAgreements } = sessionStore;
  const deviceType = getDeviceType(dimensions);
  const Styles = AdaptiveContainerStyles(deviceType);

  if (loading) return (<FullViewSpinner showBackground />);

  useEffect(() => {
    analyticsScreenView('enrollmentAgreementViewed');
    sessionStore.getEnrollmentAgreements().then();
  }, []);

  const openIntercom = async () => {
    await sessionStore.intercomVerifyIdentity();
    await Intercom.displayMessageComposer();
    trackAnalytics('enrollmentAgreementQuestions', {});
    trackAnalytics('intercomOpened', {});
  };

  const validateFields = () => {
    let validated = true;

    if (!legalName) {
      setShowError(true);
      setErrorMessage('Please enter your full name.');
      validated = false;
    }

    return validated;
  };

  const onNextPressed = async () => {
    setButtonDisabled(true);

    if (validateFields()) {
      await sessionStore.acceptEnrollmentAgreement({ signature: legalName });
      NavigationService.navigateToLearnerInitialScreen('Learn');
    }

    setButtonDisabled(false);
  };

  const onChangeLegalName = (event) => {
    if (showError) {
      setShowError(false);
    }
    setLegalName(event);
  };

  return (
    <ViewWrapper smSize={12} mdSize={9} lgSize={6} topNavBarColor={currentPalette.background.purple}>

      <MentorHeader
        mentorContainerStyle={{ backgroundColor: currentPalette.background.purple }}
        customMentorImage={Images.login.GradJobs}
        customMentorImageStyle={{ height: 200, width: 200 }}
        text={'Ready to Start?'}
      />

      {/* Re-Agreement Message */}
      {enrollmentAgreements.length > 0
          && (
            <View style={Styles.textContainer}>
              <Body1>
                The content or dates of your enrollment agreement have changed.
                Please review and sign the updated agreement to continue.
              </Body1>
              <View style={{ height: 22 }} />
            </View>
          )
        }

      {/* Enrollment Start/End Date */}
      <View style={Styles.textContainer}>

        <Card containerStyle={DefaultStyle.cardContainerStyle}>

          <ExternalLink
            text={`View ${Util.getStateFromAbbreviation(learner.legacyState)} Enrollment Agreement & Disclosure`}
            link={'https://www.nextstep.com/policy-agreement'}
          />

          <View style={{ height: 17 }} />

          <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
            <Icon
              name={'md-book'}
              type="ionicon"
              color={currentPalette.primary.main}
              size={25}
            />

            <View style={{ width: 8 }} />

            <View style={{ alignItems: 'flex-start' }}>
              <Body1>
                Enrollment Agreement Start
              </Body1>

              <Body1 color={currentPalette.text.gray}>
                {`${moment(learner?.enrollmentAgreementStartOn, 'YYYY-MM-DD').format('l')}`}
              </Body1>
            </View>
          </View>

          <View style={{ height: 17 }} />

          <View style={{ flexDirection: 'row', flexWrap: 'wrap' }}>
            <Icon
              name={'md-school'}
              type="ionicon"
              color={currentPalette.primary.main}
              size={25}
            />

            <View style={{ width: 10 }} />

            <View style={{ alignItems: 'flex-start' }}>
              <Body1>
                Enrollment Agreement End Date
              </Body1>

              <Body1 color={currentPalette.text.gray}>
                {`${moment(learner?.courseCompletionDeadlineOn, 'YYYY-MM-DD').format('l')}`}
              </Body1>
            </View>
          </View>
        </Card>
      </View>

      <View style={{ height: 26 }} />

      <View style={Styles.textContainer}>

        <Body2 textAlign="left">
          Commitment to the NextStep Program
        </Body2>

        <View style={{ height: 8 }} />

        {/* TODO: use `weeksToCompleteCourse` from course object */}
        <Body1 textAlign="left">
          {`The NextStep course must be completed within ${learner?.config.weeksToCompleteCourse} weeks. When you are ready to begin, click continue to enroll in the course.`}
        </Body1>

        <View style={{ height: 26 }} />

        <TextInput
          title={'Sign your Name'}
          data={{ value: legalName, errorMessage, showError }}
          customAttributes={{
            placeholder: 'Full Legal Name',
            returnKeyType: 'done',
          }}
          valueType={Constants.InputTextTypes.custom}
          onFocus={() => setSelectedInput('legalName')}
          isFocused={selectedInput === 'legalName'}
          onChangeText={onChangeLegalName}
          onEndEditing={() => trackAnalytics('agreementLegalNameInteracted')}
        />
      </View>

      {Platform.OS === 'web' ? <Divider /> : <View style={{ height: 20 }} />}

      <Row>
        <Column smSize={12} mdSize={10} lgSize={10} style={{ alignItems: 'center' }}>

          <ButtonFilled
            disabled={buttonDisabled}
            onPress={onNextPressed}
            onLongPress={() => (Config.staging ? sessionStore.logout() : {})}
          >
            Continue
          </ButtonFilled>

          <View style={{ height: 34 }} />

          <ButtonText
            id="course_details"
            onPress={openIntercom}
          >
            Need more info? Speak with a mentor
          </ButtonText>

          <View style={{ height: 34 }} />

        </Column>
      </Row>

      <View style={{ height: 26 }} />

    </ViewWrapper>
  );
};

export default withDimensions(inject('rootStore')(observer(EnrollmentAgreement)));
