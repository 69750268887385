import { applySnapshot, flow, getEnv, getParent } from 'mobx-state-tree';
import { find, prop, propEq, sortBy } from 'ramda';
import {
  ProgressNodeTypes, QuestionTypes, SkillSectionTypes,
  Status, StatusCodes, StepLearningObjectTypes,
} from '@nextstep/app/Config/Constants';
import { ExceptionEmitter } from '@nextstep/app/lib/ErrorUtils';
import SkillsetProgress from '@nextstep/app/models/SkillsetProgress';
import { analyticsScreenView, trackAnalytics } from '@nextstep/app/services/Analytics';
import Util from '@nextstep/app/services/Util';
import NavigationService from '@nextstep/app/services/NavigationService';

const ProgressStoreActions = self => ({
  load: flow(function* () {
    yield self.loadCourseProgress();

    if (self.courseProgress) {
      const loadSkillsetProgress = self.loadCourseSkillsetProgresses();
      const loadPracticeLabProgresses = self.loadCoursePracticeLabProgresses();
      const syncLearnerCourseMetrics = self.syncLearnerCourseMetrics();

      yield Promise.all([
        loadSkillsetProgress,
        loadPracticeLabProgresses,
        syncLearnerCourseMetrics,
      ]);
    }
  }),

  deleteSnapshot: () => {
    applySnapshot(self, {});
  },

  setCourseProgress: flow(function* (courseId) {
    const { api } = getEnv(self);
    const { data, ok } = yield api.createCourseProgress({ courseId });

    if (ok) {
      self.courseProgress = data.courseProgress;
    }
    return self.courseProgress;
  }),

  // FIXME: this should be specifying the course by key or ID
  loadCourseProgress: flow(function* () {
    const { api } = getEnv(self);

    const res = yield api.getCourseProgresses();
    if (!res.ok) throw res;

    [self.courseProgress] = res.data.data;
  }),

  loadCourseSkillsetProgresses: flow(function* () {
    self.skillsetProgresses = yield self.getCourseSkillsetProgresses(self.courseProgress.id);
  }),

  loadCoursePracticeLabProgresses: flow(function* () {
    self.practiceLabProgresses = yield self.getCoursePracticeLabProgresses(self.courseProgress.id);
  }),

  loadAssignmentQuestionProgresses: flow(function* () {
    self.assignmentQuestionProgresses = yield self.getAssignmentQuestionProgresses();
  }),

  loadAssignmentQuestionResponses: flow(function* () {
    const questionInteraction = yield self.getAssignmentQuestionResponses(self.assignmentQuestionProgress.id);
    applySnapshot(self.assignmentQuestionProgress.questionInteraction, questionInteraction);
  }),

  setAssignmentQuestionProgress(assignmentQuestionId) {
    self.assignmentQuestionProgress = self.assignmentQuestionProgresses.find(aqp => aqp.assignmentQuestionId === assignmentQuestionId);
  },

  clearCurrentExcept(allowedNodeTypes) {
    const disallowedNodeTypes = Object.values(ProgressNodeTypes).filter(nodeType => !allowedNodeTypes.includes(nodeType));

    disallowedNodeTypes.forEach((nodeType) => {
      self[Util.pascalToCamel(nodeType)] = undefined;
    });
  },

  syncLearnerCourseMetrics: flow(function* () {
    self.metrics = yield self.getLearnerCourseMetrics(self.courseProgress.id);
  }),

  sendAssignmentQuestionResponse: flow(function* (questionAnswer) {
    const { api } = getEnv(self);
    const response = yield api.sendAssignmentQuestionResponse({ id: self.assignmentQuestionProgress.id, body: { content: questionAnswer } });

    if (Util.shouldReject(response)) return Promise.reject(response);
    return response.data;
  }),

  setSkillsetProgress: flow(function* (skillsetId) {
    const id = self.skillsetProgresses.findIndex(s => s.skillsetId === skillsetId);
    let skillsetProgress = self.skillsetProgresses[id];

    if (!skillsetProgress) return false;

    let skillsetProgressData;
    if (!skillsetProgress.id) {
      skillsetProgressData = yield self.anchorSkillset(skillsetId);
      skillsetProgress = SkillsetProgress.create(skillsetProgressData);
      self.skillsetProgresses.splice(id, 1, skillsetProgress);

      if (skillsetProgress.id) {
        // eslint-disable-next-line no-restricted-syntax
        for (const skillProgress of skillsetProgress.skillProgresses) {
          const skillProgressData = yield self.getSkillProgress(skillProgress.id);
          applySnapshot(skillProgress, skillProgressData);
        }
        // here I need to add an assessment progress
      }
    } else if (!skillsetProgress.skillProgresses) {
      skillsetProgressData = yield self.getSkillsetProgress(skillsetProgress.id);
      applySnapshot(skillsetProgress, skillsetProgressData);
    }

    if (skillsetProgress.id) self.skillsetProgress = skillsetProgress;
    return skillsetProgress;
  }),

  setSkillProgress: flow(function* (id) {
    if (!self.skillsetProgress) return false;

    const { skillProgresses } = self.skillsetProgress;
    const skillProgress = skillProgresses.find(s => s.skillId === id);

    if (!skillProgress) return false;

    // Get skill progress
    if (skillProgress && !skillProgress.stepProgresses) {
      const skillProgressData = yield self.getSkillProgress(skillProgress.id);
      applySnapshot(skillProgress, skillProgressData);
    }

    self.skillProgress = skillProgress;
    return skillProgress;
  }),

  loadCompletedSkills: flow(function* () {
    const { api } = getEnv(self);
    const { courseId } = self.courseProgress;

    const response = yield api.getCourseSkills({ courseId, status: Status.completed })
      .catch(ExceptionEmitter);

    if (Util.shouldReject(response)) return Promise.reject(response);

    const completedSkills = response.data.data;
    self.completedSkills = completedSkills;
    return completedSkills;
  }),

  setStepProgress: flow(function* (id, force = false) {
    if (!self.skillProgress) return false;

    const { stepProgresses } = self.skillProgress;
    const stepProgress = stepProgresses.find(s => s.stepId === id);

    if (!stepProgress) return false;

    if (!stepProgress.loaded || force) {
      const stepProgressData = yield self.getStepProgress(stepProgress.id);
      Util.applyMergedSnapshot(stepProgress, stepProgressData);
    }
    self.stepProgress = stepProgress;
    return stepProgress;
  }),

  setStudyMaterialsModalVisibility(isVisible) {
    self.isStudyMaterialsModalVisible = isVisible;
  },

  setAssignmentQuestionUnlockedModalVisibility(isVisible) {
    self.isAssignmentQuestionModalVisible = isVisible;
  },

  setExpandedSkillsetKey: flow(function* (key) {
    if (!key) return;
    const { contentStore } = getParent(self);

    yield contentStore.setSkillset(key);
    analyticsScreenView('skillsetViewed', self.processForAnalytics({ type: 'skillset' }));

    const { skillset } = contentStore;

    // eslint-disable-next-line no-restricted-syntax
    for (const skill of skillset.skills) {
      yield contentStore.setSkill(skill.key);
    }

    const idx = self.skillsetProgresses.findIndex(s => s.skillsetId === skillset.id);

    yield self.setSkillsetProgress(skillset.id, idx);

    self.expandedSkillsetKey = key;
  }),

  resetExpandedSkillSetKey() {
    self.expandedSkillsetKey = '';
  },

  setActiveTab(tabName) {
    self.activeTab = tabName;
  },

  processForAnalytics({ type, leafNodeId }) {
    const { contentStore } = getParent(self);
    const { skillset } = contentStore;
    let data = {};
    let id; let key; let title; let url;

    ({ id, key, title } = skillset);
    data = {
      ...data,
      skillsetId: id,
      skillsetKey: key,
      skillsetTitle: title,
    };

    if (type === 'skillset') return data;

    const { skill } = contentStore;

    if (!skill) return data;

    ({ id, key, title } = skill);
    data = {
      ...data,
      skillId: id,
      skillKey: key,
      skillTitle: title,
    };

    if (type === 'skill') return data;

    if (type === 'introVideo') {
      ({ introVideo: { id, key, url } } = skill);
      data = {
        ...data,
        introVideoId: id,
        introVideoKey: key,
        introVideoUrl: url,
      };
      return data;
    }

    const { step } = contentStore;

    ({ id, key } = skill);
    data = {
      ...data,
      stepId: id,
      stepKey: key,
    };

    if (type === 'step') return data;

    if (type === 'successCriteria') {
      data = {
        ...data,
        successCriteriaId: id,
        successCriteriaKey: key,
      };
    } else if (type === 'demoVideo') {
      const demoVideo = step.demoVideos.find(dv => dv.id === leafNodeId);
      ({ id, key, url } = demoVideo);
      data = {
        ...data,
        demoVideoId: id,
        demoVideoKey: key,
        demoVideoUrl: url,
      };
    } else if (type === 'assessmentBlock') {
      const assessmentBlock = step.assessmentBlocks.find(ab => ab.id === leafNodeId);
      ({ id, key } = assessmentBlock);
      data = {
        ...data,
        assessmentBlockId: id,
        assessmentBlockKey: key,
      };
    }

    return data;
  },

  fetchSkillProgress: flow(function* () {
    const skillProgress = yield self.getSkillProgress(self.skillProgress.id);

    applySnapshot(self.skillProgress, skillProgress);

    return self.skillProgress;
  }),

  startSkill: flow(function* () {
    const { api } = getEnv(self);
    const response = yield api.startSkill(self.skillProgress.id)
      .catch(ExceptionEmitter);

    if (Util.shouldReject(response)) return Promise.reject(response);

    if (response.status === StatusCodes.unprocessableEntity) return self.fetchSkillProgress();

    trackAnalytics('skillStarted', self.processForAnalytics({ type: 'skill' }));
    applySnapshot(self.skillProgress, Util.updateNamingConvention(response.data.skillProgress));
    return self.skillProgress;
  }),

  fetchStepProgress: flow(function* () {
    const { api } = getEnv(self);

    const response = yield api.getStepProgress(self.stepProgress.id)
      .catch(ExceptionEmitter);

    if (Util.shouldReject(response)) return Promise.reject(response);

    applySnapshot(self.stepProgress, response.data.stepProgress);

    return self.stepProgress;
  }),

  startStep: flow(function* () {
    const { api } = getEnv(self);

    const response = yield api.startStep(self.stepProgress.id)
      .catch(ExceptionEmitter);

    if (Util.shouldReject(response)) return Promise.reject(response);

    if (response.status === StatusCodes.unprocessableEntity) return self.fetchStepProgress();

    trackAnalytics('stepStarted', self.processForAnalytics({ type: 'step' }));

    applySnapshot(self.stepProgress, response.data.stepProgress);

    return self.stepProgress;
  }),

  fetchAssessmentBlock: flow(function* (id) {
    const { api } = getEnv(self);
    const { assessmentBlockProgresses } = self.stepProgress;

    const assessmentBlockProgress = assessmentBlockProgresses.find(s => s.id === id);

    const response = yield api.getAssessmentBlockProgress(id).catch(ExceptionEmitter);
    applySnapshot(assessmentBlockProgress, response.data.assessmentBlockProgress);

    yield self.setStepProgress(assessmentBlockProgress.stepProgressId, true);
    self.assessmentBlockProgress = assessmentBlockProgress;
  }),

  setAssessmentBlockProgress: () => {
    const { assessmentBlockProgresses } = self.stepProgress;

    const assessmentBlockProgress = assessmentBlockProgresses.find(
      s => s.isInProgress || s.isUnlocked || s.isFailed,
    );
    self.assessmentBlockProgress = assessmentBlockProgress;
    return assessmentBlockProgress;
  },

  startAssessmentBlock: flow(function* () {
    const { api } = getEnv(self);

    const { assessmentBlockProgress } = self;

    if (!assessmentBlockProgress.isUnlocked || assessmentBlockProgress.isFailed) {
      yield self.setStepProgress(assessmentBlockProgress.stepProgressId, true);
      return false;
    }

    const response = yield api.startAssessmentBlock(assessmentBlockProgress.id)
      .catch(ExceptionEmitter);
    if (response.status === StatusCodes.unprocessableEntity) return self.fetchAssessmentBlock(assessmentBlockProgress.id);

    trackAnalytics('assessmentBlockStarted', self.processForAnalytics({
      type: 'assessmentBlock',
      leafNodeId: assessmentBlockProgress.assessmentBlockId,
    }));

    applySnapshot(assessmentBlockProgress, response.data.assessmentBlockProgress);

    yield self.setStepProgress(assessmentBlockProgress.stepProgressId, true);
    return self.assessmentBlockProgress;
  }),

  completeAssessmentBlock: flow(function* () {
    const { assessmentBlockProgress } = self;
    const { api } = getEnv(self);
    if (assessmentBlockProgress.isCompleted) return;

    const response = yield api.completeAssessmentBlock(assessmentBlockProgress.id, assessmentBlockProgress.duration).catch(ExceptionEmitter);
    self.updateTree(response.data.changedModels);
    yield self.logTimer();
  }),

  failAssessmentBlock: flow(function* () {
    const { assessmentBlockProgress } = self;
    const { api } = getEnv(self);
    if (assessmentBlockProgress.isCompleted) return;

    const response = yield api.failAssessmentBlock(assessmentBlockProgress.id, assessmentBlockProgress.duration).catch(ExceptionEmitter);
    self.updateTree(response.data.changedModels);
    yield self.logTimer();
  }),

  mapAssessmentBlockQuestions() {
    if (!self.assessmentBlockProgress) return;
    if (self.assessmentBlockProgress.questionTiers) return;
    const parent = getParent(self);
    const { assessmentBlock } = parent.contentStore;
    const { questionTiers } = assessmentBlock;

    if (!questionTiers) {
      NavigationService.navigate('OutcomeScreen');
      return;
    }

    let { medium, hard } = questionTiers;

    medium = medium.map(q => ({ id: q.id }));
    hard = hard.map(q => ({ id: q.id }));

    self.assessmentBlockProgress.questionTiers = { medium, hard };
  },

  setQuestionProgress() {
    if (!self.assessmentBlockProgress) return false;
    const questionProgress = self.assessmentBlockProgress.currentQuestion;

    self.questionProgress = questionProgress;
    return questionProgress;
  },

  anchorSkillset: flow(function* (skillsetId) {
    const { api } = getEnv(self);
    const response = yield api.anchorSkillset(skillsetId);
    if (Util.shouldReject(response)) return Promise.reject(response);

    trackAnalytics('skillsetStarted', self.processForAnalytics({ type: 'skillset' }));
    return response.data.skillsetProgress;
  }),

  getSkillsetProgress: flow(function* (skillsetProgressId) {
    const { api } = getEnv(self);
    const response = yield api.getSkillsetProgress(skillsetProgressId);
    if (Util.shouldReject(response)) return Promise.reject(response);
    let skillsetProgressData = response.data.skillsetProgress;
    skillsetProgressData = {
      ...skillsetProgressData,
      skillProgresses: skillsetProgressData.skillProgresses.map(skillProgress => Util.updateNamingConvention(skillProgress)),
    };
    return skillsetProgressData;
  }),

  getSkillProgress: flow(function* (skillProgressId) {
    const { api } = getEnv(self);
    const response = yield api.getSkillProgress(skillProgressId);
    if (Util.shouldReject(response)) return Promise.reject(response);
    return Util.updateNamingConvention(response.data.skillProgress);
  }),

  getStepProgress: flow(function* (stepProgressId) {
    const { api } = getEnv(self);
    const response = yield api.getStepProgress(stepProgressId);
    if (Util.shouldReject(response)) return Promise.reject(response);
    return response.data.stepProgress;
  }),

  getCourseSkillsetProgresses: flow(function* (courseProgressId) {
    const { api } = getEnv(self);
    const response = yield api.getCourseSkillsetProgresses(courseProgressId)
      .catch(ExceptionEmitter);
    if (Util.shouldReject(response)) return Promise.reject(response);
    return response.data.data;
  }),

  getCoursePracticeLabProgresses: flow(function* (courseProgressId) {
    const { api } = getEnv(self);
    const response = yield api.getCoursePracticeLabProgresses(courseProgressId)
      .catch(ExceptionEmitter);
    if (Util.shouldReject(response)) return Promise.reject(response);
    return response.data.data;
  }),

  getAssignmentQuestionProgresses: flow(function* () {
    const { api } = getEnv(self);
    const response = yield api.getAssignmentQuestionProgresses().catch(ExceptionEmitter);
    if (Util.shouldReject(response)) return Promise.reject(response);
    return response.data.data;
  }),

  getAssignmentQuestionResponses: flow(function* (assignmentQuestionProgressId) {
    const { api } = getEnv(self);

    const response = yield api.getAssignmentQuestionResponses({ id: assignmentQuestionProgressId });

    if (Util.shouldReject(response)) return Promise.reject(response);
    return response.data.data;
  }),

  syncStepProgress: flow(function* () {
    const stepProgressData = yield self.getStepProgress(self.stepProgress.id);
    applySnapshot(self.stepProgress, stepProgressData);
  }),

  syncSkillProgress: flow(function* () {
    const skillProgressData = yield self.getSkillProgress(self.skillProgress.id);
    applySnapshot(self.skillProgress, skillProgressData);
  }),

  updateTree: (changedModels = []) => {
    changedModels.forEach((changedModel) => {
      let progress;
      switch (changedModel.type) {
        case 'success_criteria_progress':
          progress = self.stepProgress?.successCriteriaProgress;
          if (progress && progress.id === changedModel.id) {
            progress.status = changedModel.status;
            if (progress.status === Status.completed) self.onCompleteSuccessCriteria(progress);
          }
          break;

        case 'demo_video_progress':
          progress = self.stepProgress?.demoVideoProgresses?.find(p => p.id === changedModel.id);
          if (progress) progress.status = changedModel.status;
          if (progress && progress.status === Status.completed) {
            self.onCompleteDemoVideo(progress);
          }
          break;

        case 'assessment_block_progress':
          progress = self.stepProgress?.assessmentBlockProgresses?.find(p => p.id === changedModel.id);
          if (progress) progress.status = changedModel.status;
          if (progress) {
            if (progress.status === Status.completed) {
              self.onCompleteAssessmentBlock(progress);
            } else if (progress.status === Status.failed) {
              self.onFailAssessmentBlock(progress);
            }
          }
          break;

        case 'linear_assessment_progress':
          if (self.skillProgress?.linearAssessmentProgress?.id === changedModel.id) {
            progress = self.skillProgress.linearAssessmentProgress;
          } else if (self.skillsetProgress?.linearAssessmentProgress?.id === changedModel.id) {
            progress = self.skillsetProgress.linearAssessmentProgress;
          }
          if (progress) {
            progress.status = changedModel.status;
            if (progress.status === Status.completed) {
              self.onCompleteLinearAssessment(progress);
            }
          }
          break;

        case 'step_progress':
          progress = self.skillProgress?.stepProgresses?.find(p => p.id === changedModel.id);
          if (progress) progress.status = changedModel.status;
          if (progress && progress.status === Status.completed) {
            self.onCompleteStep(progress);
          }
          break;

        case 'intro_progress':
          progress = self.skillProgress?.introVideoProgress;
          if (progress && progress.id === changedModel.id) {
            progress.status = changedModel.status;
            if (progress.status === Status.completed) self.onCompleteIntroVideo(progress);
          }
          break;

        case 'skill_progress':
          progress = self.skillProgress;
          if (progress && progress.id === changedModel.id) {
            progress.status = changedModel.status;
            if (progress.status === Status.completed) self.onCompleteSkill(progress);
          }
          break;

        case 'skillset_progress':
          progress = self.skillsetProgresses?.find(p => p.id === changedModel.id);
          if (progress) progress.status = changedModel.status;
          if (progress.status === Status.completed) self.onCompleteSkillset(progress);
          break;

        default: break;
      }
    });
  },

  fetchIntroVideoProgress: flow(function* () {
    const { api } = getEnv(self);
    const { introVideoProgress } = self;

    const response = yield api.getIntroVideoProgress(introVideoProgress.id)
      .catch(ExceptionEmitter);

    if (Util.shouldReject(response)) return Promise.reject(response);

    yield self.syncSkillProgress();

    return response.data;
  }),

  setIntroVideoProgress: () => {
    const { introVideoProgress } = self.skillProgress;
    self.introVideoProgress = introVideoProgress;
    return introVideoProgress;
  },

  startIntroVideo: flow(function* () {
    const { api } = getEnv(self);
    const { introVideoProgress } = self;

    if (introVideoProgress.isInProgress) return introVideoProgress;

    const response = yield api.startIntroVideo(introVideoProgress.id)
      .catch(ExceptionEmitter);

    if (Util.shouldReject(response)) return Promise.reject(response);

    if (response.status === StatusCodes.unprocessableEntity) return self.fetchIntroVideoProgress();

    trackAnalytics('introVideoStarted', self.processForAnalytics({ type: 'introVideo' }));
    applySnapshot(introVideoProgress, response.data.introProgress);

    return response.data;
  }),

  completeIntroVideo: flow(function* () {
    const { api } = getEnv(self);

    const { introVideoProgress } = self.skillProgress;
    if (introVideoProgress.isCompleted) return introVideoProgress;

    const response = yield api.completeIntroVideo(introVideoProgress.id, introVideoProgress.duration)
      .catch(ExceptionEmitter);

    if (Util.shouldReject(response)) return Promise.reject(response);

    self.updateTree(response.data.changedModels);
    return response.data;
  }),

  fetchSuccessCriteriaProgress: flow(function* () {
    const { api } = getEnv(self);

    const response = yield api.getSuccessCriteriaProgress(self.stepProgress.successCriteriaProgress.id)
      .catch(ExceptionEmitter);

    if (Util.shouldReject(response)) return Promise.reject(response);

    yield self.syncStepProgress();

    return response.data;
  }),

  setSuccessCriteriaProgress: () => {
    const { successCriteriaProgress } = self.stepProgress;
    self.successCriteriaProgress = successCriteriaProgress;
    return successCriteriaProgress;
  },

  setDemoVideoProgress: () => {
    const demoVideoProgress = self.stepProgress.demoVideoProgresses.find(dvp => (dvp.isUnlocked || dvp.isInProgress));
    self.demoVideoProgress = demoVideoProgress;
    return demoVideoProgress;
  },

  minutesLeftInCourse() {
    const { totalSkills, completedSkills } = self.metrics;
    const MINUTES_PER_SKILL = 60;
    return ((totalSkills - completedSkills) * MINUTES_PER_SKILL);
  },

  getLearnerCourseMetrics: flow(function* () {
    const { api } = getEnv(self);

    const response = yield api.getLearnerCourseMetrics(self.courseProgress.id)
      .catch(ExceptionEmitter);

    if (Util.shouldReject(response)) return Promise.reject(response);

    return response.data.metrics;
  }),

  getCourseSkills: flow(function* (status) {
    const { api } = getEnv(self);
    const { courseId } = self.courseProgress;

    const response = yield api.getCourseSkills({ courseId, status })
      .catch(ExceptionEmitter);

    if (Util.shouldReject(response)) return Promise.reject(response);

    return response.data.data;
  }),

  activateLoading() {
    self.loading = true;
  },

  deactivateLoading() {
    self.loading = false;
  },

  areChosenAnswersCorrect(answers) {
    const parent = getParent(self);
    const { question } = parent.contentStore;

    const questionType = question.type.toLowerCase();

    if (questionType === QuestionTypes.fillInTheBlanks || questionType === QuestionTypes.orderedSelect) {
      let correct = true;

      const requiredAnswers = question.answerChoices
        .filter(sa => sa.order !== -1 || sa.score > 0)
        .sort((a, b) => {
          if (a.order < b.order) { return -1; }
          if (a.order > b.order) { return 1; }
          return 0;
        });

      requiredAnswers.forEach((a, i) => {
        if (!answers[i] || answers[i].id !== a.id) correct = false;
      });

      return correct && requiredAnswers.length === answers.length;
    }

    if (questionType === QuestionTypes.unorderedSelect) {
      if (find(propEq('score', 0))(answers)) return false;
      if ((sortBy(prop('score'), question.answerChoices.filter(a => a.score))).length !== answers.length) {
        return false;
      }
    }
    return (answers[0] || {}).score === 1;
  },

  trackQuestionAttempt(answers) {
    return answers;
  },

  // TODO: remove with assessment blocks
  async answerQuestion(answers) {
    const { assessmentBlockProgress } = self;
    if (!assessmentBlockProgress) return;
    if (!answers) return;
    self.trackQuestionAttempt(answers);
    const isCorrect = self.areChosenAnswersCorrect(answers);
    self.questionProgress.setAnswer(isCorrect);
    if (assessmentBlockProgress.isBlockFailed) {
      assessmentBlockProgress.resetAllAnswers();
      await self.failAssessmentBlock();
    }
    if (assessmentBlockProgress.isBlockPassed) await self.completeAssessmentBlock();
  },

  canGoToSuccessCriteria() {
    const parent = getParent(self);
    const { step } = parent.contentStore;
    const { stepProgress } = self;
    return (step.successCriteria && !stepProgress.successCriteriaProgress.isCompleted);
  },

  canGoToDemoVideos() {
    const parent = getParent(self);
    const { step } = parent.contentStore;
    const { stepProgress } = self;
    return (step.demoVideos && stepProgress.demoVideoProgresses.find(video => !video.isCompleted));
  },

  canGoToAssessmentBlocks() {
    const parent = getParent(self);
    const { step } = parent.contentStore;
    return (step.assessmentBlocks);
  },

  getNextStepChild() {
    const { childOrder } = self.stepProgress;
    const learningObjectOrder = childOrder.length ? childOrder : [
      StepLearningObjectTypes.successCriteria,
      StepLearningObjectTypes.demoVideos,
      StepLearningObjectTypes.assessmentBlocks,
    ];
    const nextLearningObject = learningObjectOrder.find(learningObjectType => {
      switch (learningObjectType) {
        case StepLearningObjectTypes.successCriteria:
          return self.canGoToSuccessCriteria();
        case StepLearningObjectTypes.demoVideos:
          return self.canGoToDemoVideos();
        case StepLearningObjectTypes.assessmentBlocks:
          return self.canGoToAssessmentBlocks();
        default:
          throw new Error(`LearningObject ${learningObjectType} does not exist`);
      }
    });

    return Util.pascalize(nextLearningObject);
  },

  getNextSkillSection() {
    if (!self.skillProgress) return null;
    const sections = [
      self.skillProgress.introVideoProgress,
      ...self.skillProgress.stepProgresses,
      self.skillProgress.linearAssessmentProgress,
    ].filter(Boolean);

    const nextSection = sections.find(section => !section.isCompleted);
    if (!nextSection) return null;

    const { contentStore } = getParent(self);
    if (nextSection.introId) {
      return { type: SkillSectionTypes.introVideo, object: contentStore.skill.introVideo };
    }
    if (nextSection.stepId) {
      return { type: SkillSectionTypes.step, object: contentStore.skill.steps.find(s => s.id === nextSection.stepId) };
    }
    if (nextSection.linearAssessmentId) {
      return { type: SkillSectionTypes.linearAssessment, object: contentStore.skill.linearAssessment };
    }

    return null;
  },

  reduceRemainingRequiredTime(amount) {
    self.skillsetProgress.remainingRequiredTime -= amount;
  },

  logTimer: flow(function* (hasTimeConstrain = false, seconds = 60) {
    const { api } = getEnv(self);
    const { id } = self.skillsetProgress;

    const response = yield api.logTimer({ skillsetProgressId: id, requiredTimeSpent: seconds })
      .catch(ExceptionEmitter);

    if (Util.shouldReject(response)) return Promise.reject(response);

    const { data } = response;
    const { skillsetProgress } = self;

    // Re-fetch skillsetProgresses
    if (hasTimeConstrain && skillsetProgress.isInProgress && data.remainingRequiredTime <= 0) {
      yield self.loadCourseSkillsetProgresses();
    }

    // Update local value
    self.skillsetProgress.remainingRequiredTime = data.remainingRequiredTime;

    return response.data.data;
  }),

  onCompleteSkill: flow(function* (/* skillProgress */) {
    trackAnalytics('skillCompleted', self.processForAnalytics({ type: 'skill' }), true);
    yield self.syncLearnerCourseMetrics();
    yield self.loadCompletedSkills();
  }),

  onCompleteIntroVideo(/* introVideoProgress */) {
    trackAnalytics('introVideoCompleted', self.processForAnalytics({ type: 'introVideo' }));
  },

  onCompleteStep(/* stepProgress */) {
    trackAnalytics('stepCompleted', self.processForAnalytics({ type: 'step' }));
  },

  onCompleteSuccessCriteria(/* successCriteriaProgress */) {
    trackAnalytics('successCriteriaCompleted', self.processForAnalytics({ type: 'successCriteria' }));
  },

  onCompleteDemoVideo(demoVideoProgress) {
    trackAnalytics('demoVideoCompleted', self.processForAnalytics({ type: 'demoVideo', leafNodeId: demoVideoProgress.demoVideoId }));
  },

  onCompleteLinearAssessment(linearAssessmentProgress) {
    trackAnalytics('linearAssessmentCompleted', self.processForAnalytics({ type: 'linearAssessment', leafNodeId: linearAssessmentProgress.linearAssessmentId }));
  },

  onFailLinearAssessment(linearAssessmentProgress) {
    trackAnalytics('linearAssessmentFailed', self.processForAnalytics({ type: 'linearAssessment', leafNodeId: linearAssessmentProgress.linearAssessmentId }));
  },

  onCompleteAssessmentBlock(assessmentBlockProgress) {
    trackAnalytics('assessmentBlockCompleted', self.processForAnalytics({ type: 'assessmentBlock', leafNodeId: assessmentBlockProgress.assessmentBlockId }));
  },

  onFailAssessmentBlock(assessmentBlockProgress) {
    trackAnalytics('assessmentBlockFailed', self.processForAnalytics({ type: 'assessmentBlock', leafNodeId: assessmentBlockProgress.assessmentBlockId }));
  },

  onCompleteSkillset(/* skillsetProgress */) {
    const { contentStore } = getParent(self);
    const { assignmentQuestionId, key } = contentStore.skillset;
    const formattedKey = key.toUpperCase().replace(/-/g, ' ');
    trackAnalytics(`skillsetCompleted-${formattedKey}`, self.processForAnalytics({ type: 'skillset' }), true);
    trackAnalytics('skillsetCompleted', self.processForAnalytics({ type: 'skillset' }));

    if (assignmentQuestionId) self.setAssignmentQuestionUnlockedModalVisibility(true);
  },
});

export default ProgressStoreActions;
