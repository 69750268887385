/* eslint-disable global-require */
// leave off @2x/@3x
const images = {
  icons: {
    back: require('@nextstep/assets/images/icons/other/ico_back.png'),
    beaker: require('@nextstep/assets/images/icons/other/beaker_active.png'),
    beakerActive: require('@nextstep/assets/images/icons/other/beaker.png'),
    bookIcon: require('@nextstep/assets/images/icons/other/study_materials.png'),
    bookOrange: require('@nextstep/assets/images/icons/other/book.png'),
    checkMark: require('@nextstep/assets/images/icons/other/checkMark.png'),
    checked: require('@nextstep/assets/images/icons/other/checked.png'),
    darkLogOut: require('@nextstep/assets/images/icons/other/dark-log-out.png'),
    exit: require('@nextstep/assets/images/icons/other/ico_exit_black.png'),
    externalLink: require('@nextstep/assets/images/icons/other/external-link.png'),
    forum: require('@nextstep/assets/images/icons/other/forumNav.png'),
    forumActive: require('@nextstep/assets/images/icons/other/forumNavActive.png'),
    home: require('@nextstep/assets/images/icons/other/home-normal.png'),
    homeActive: require('@nextstep/assets/images/icons/other/home-active.png'),
    learn: require('@nextstep/assets/images/icons/other/learn-normal.png'),
    learnActive: require('@nextstep/assets/images/icons/other/learn-active.png'),
    lockedStep: require('@nextstep/assets/images/icons/other/locked_step.png'),
    logOut: require('@nextstep/assets/images/icons/other/log-out.png'),
    menu: require('@nextstep/assets/images/icons/other/menu.png'),
    pencil: require('@nextstep/assets/images/icons/other/pencil.png'),
    redWarning: require('@nextstep/assets/images/icons/other/redWarning.png'),
    requirements: require('@nextstep/assets/images/icons/other/requirements.png'),
    requirementsActive: require('@nextstep/assets/images/icons/other/requirements-active.png'),
    correctMark: require('@nextstep/assets/images/icons/other/vMark.png'),
    wrongMark: require('@nextstep/assets/images/icons/other/xCircle.png'),
    upload: require('@nextstep/assets/images/icons/other/upload.png'),
    uploadCloud: require('@nextstep/assets/images/icons/other/uploadCloud.png'),
    inProgress: require('@nextstep/assets/images/icons/other/inProgress.png'),
    stethoscope: require('@nextstep/assets/images/icons/other/stethoscope.png'),
    noWifi: require('@nextstep/assets/images/icons/other/noWifi.png'),
    main: {
      challenge: require('@nextstep/assets/images/icons/main/ico_challenge_72_px.png'),
      mentor: require('@nextstep/assets/images/icons/main/ico_mentor_72_px.png'),
      thumb: require('@nextstep/assets/images/icons/main/ico_thumbsup_72_px.png'),
      video: require('@nextstep/assets/images/icons/main/ico_video_72_px.png'),
      trophy: require('@nextstep/assets/images/icons/main/trophy.png'),
      defaultAvatar: require('@nextstep/assets/images/icons/main/profileAvi.png'),
      forumIcon: require('@nextstep/assets/images/icons/main/forum.png'),
      intercomChat: require('@nextstep/assets/images/icons/main/chat_bubble.png'),
      oneOfTwoStepper: require('@nextstep/assets/images/icons/main/oneOfTwo.png'),
      partFourStepper: require('@nextstep/assets/images/icons/main/stepper_4.png'),
      partOneStepper: require('@nextstep/assets/images/icons/main/stepper_1.png'),
      partThreeStepper: require('@nextstep/assets/images/icons/main/stepper_3.png'),
      partTwoStepper: require('@nextstep/assets/images/icons/main/stepper_2.png'),
      twoOfTwoStepper: require('@nextstep/assets/images/icons/main/twoOfTwo.png'),
      warning: require('@nextstep/assets/images/icons/main/warning.png'),
    },
    pink: {
      stepUnlocked: require('@nextstep/assets/images/icons/pink/step0.png'),
      step1: require('@nextstep/assets/images/icons/pink/step1.png'),
      step2: require('@nextstep/assets/images/icons/pink/step2.png'),
      checkMark: require('@nextstep/assets/images/icons/pink/checkMark.png'),
      wrongMark: require('@nextstep/assets/images/icons/other/xCircle.png'),
      challengeStart: require('@nextstep/assets/images/icons/pink/challenge_start.png'),
      learningContent: require('@nextstep/assets/images/icons/pink/learningContent.png'),
      successCriteria: require('@nextstep/assets/images/icons/pink/successCriteria.png'),
      exercise: require('@nextstep/assets/images/icons/pink/exercise.png'),
      skillComplete: require('@nextstep/assets/images/icons/pink/skillComplete.png'),
      warning: require('@nextstep/assets/images/icons/pink/warning.png'),
      timer: require('@nextstep/assets/images/icons/pink/timer.png'),
      fastLearner: require('@nextstep/assets/images/icons/pink/fastlearner.png'),
    },
    blue: {
      stepUnlocked: require('@nextstep/assets/images/icons/blue/step0.png'),
      step1: require('@nextstep/assets/images/icons/blue/step1.png'),
      step2: require('@nextstep/assets/images/icons/blue/step2.png'),
      checkMark: require('@nextstep/assets/images/icons/blue/checkMark.png'),
      wrongMark: require('@nextstep/assets/images/icons/other/xCircle.png'),
      challengeStart: require('@nextstep/assets/images/icons/blue/challenge_start.png'),
      learningContent: require('@nextstep/assets/images/icons/blue/learningContent.png'),
      successCriteria: require('@nextstep/assets/images/icons/blue/successCriteria.png'),
      exercise: require('@nextstep/assets/images/icons/blue/exercise.png'),
      skillComplete: require('@nextstep/assets/images/icons/blue/skillComplete.png'),
      warning: require('@nextstep/assets/images/icons/blue/warning.png'),
      timer: require('@nextstep/assets/images/icons/blue/timer.png'),
      fastLearner: require('@nextstep/assets/images/icons/blue/fastlearner.png'),
    },
    green: {
      stepUnlocked: require('@nextstep/assets/images/icons/green/step0.png'),
      step1: require('@nextstep/assets/images/icons/green/step1.png'),
      step2: require('@nextstep/assets/images/icons/green/step2.png'),
      checkMark: require('@nextstep/assets/images/icons/green/checkMark.png'),
      wrongMark: require('@nextstep/assets/images/icons/other/xCircle.png'),
      challengeStart: require('@nextstep/assets/images/icons/green/challenge_start.png'),
      learningContent: require('@nextstep/assets/images/icons/green/learningContent.png'),
      successCriteria: require('@nextstep/assets/images/icons/green/successCriteria.png'),
      exercise: require('@nextstep/assets/images/icons/green/exercise.png'),
      skillComplete: require('@nextstep/assets/images/icons/green/skillComplete.png'),
      warning: require('@nextstep/assets/images/icons/green/warning.png'),
      timer: require('@nextstep/assets/images/icons/green/timer.png'),
      fastLearner: require('@nextstep/assets/images/icons/green/fastlearner.png'),
    },
    magenta: {
      stepUnlocked: require('@nextstep/assets/images/icons/magenta/step0.png'),
      step1: require('@nextstep/assets/images/icons/magenta/step1.png'),
      step2: require('@nextstep/assets/images/icons/magenta/step2.png'),
      checkMark: require('@nextstep/assets/images/icons/magenta/checkMark.png'),
      wrongMark: require('@nextstep/assets/images/icons/other/xCircle.png'),
      challengeStart: require('@nextstep/assets/images/icons/magenta/challenge_start.png'),
      learningContent: require('@nextstep/assets/images/icons/magenta/learningContent.png'),
      successCriteria: require('@nextstep/assets/images/icons/magenta/successCriteria.png'),
      exercise: require('@nextstep/assets/images/icons/magenta/exercise.png'),
      skillComplete: require('@nextstep/assets/images/icons/magenta/skillComplete.png'),
      warning: require('@nextstep/assets/images/icons/magenta/warning.png'),
      timer: require('@nextstep/assets/images/icons/magenta/timer.png'),
      fastLearner: require('@nextstep/assets/images/icons/magenta/fastlearner.png'),
    },
    orange: {
      stepUnlocked: require('@nextstep/assets/images/icons/orange/step0.png'),
      step1: require('@nextstep/assets/images/icons/orange/step1.png'),
      step2: require('@nextstep/assets/images/icons/orange/step2.png'),
      checkMark: require('@nextstep/assets/images/icons/orange/checkMark.png'),
      wrongMark: require('@nextstep/assets/images/icons/other/xCircle.png'),
      challengeStart: require('@nextstep/assets/images/icons/orange/challenge_start.png'),
      learningContent: require('@nextstep/assets/images/icons/orange/learningContent.png'),
      successCriteria: require('@nextstep/assets/images/icons/orange/successCriteria.png'),
      exercise: require('@nextstep/assets/images/icons/orange/exercise.png'),
      skillComplete: require('@nextstep/assets/images/icons/orange/skillComplete.png'),
      warning: require('@nextstep/assets/images/icons/orange/warning.png'),
      timer: require('@nextstep/assets/images/icons/orange/timer.png'),
      fastLearner: require('@nextstep/assets/images/icons/orange/fastlearner.png'),
    },
  },

  other: {
    appStore: require('@nextstep/assets/images/external/app-store.png'),
    nextStepLogo: require('@nextstep/assets/images/illustrations/other/nextstep_logo.png'),
    playStore: require('@nextstep/assets/images/external/google-play.png'),
    videoPlaceholder: require('@nextstep/assets/images/illustrations/other/video_placeholder.png'),
    magnifyingGlassGlobe: require('@nextstep/assets/images/icons/gray/magnifying_glass_globe.png'),
  },

  mentor: {
    main: {
      board: require('@nextstep/assets/images/illustrations/main/clipboard.png'),
      cell: require('@nextstep/assets/images/illustrations/main/cell.png'),
      computer: require('@nextstep/assets/images/illustrations/main/computer.png'),
      oops: require('@nextstep/assets/images/illustrations/main/oops.png'),
      pencil: require('@nextstep/assets/images/illustrations/main/pencil.png'),
    },
    pink: {
      board: require('@nextstep/assets/images/illustrations/pink/clipboard_ShortCurly.png'),
      cell: require('@nextstep/assets/images/illustrations/pink/cell_ShortCurly.png'),
      computer: require('@nextstep/assets/images/illustrations/pink/computer_ShortCurly.png'),
      oops: require('@nextstep/assets/images/illustrations/pink/oops_ShortCurly.png'),
      pencil: require('@nextstep/assets/images/illustrations/pink/pencil_ShortCurly.png'),
    },
    orange: {
      board: require('@nextstep/assets/images/illustrations/orange/clipboard_Long.png'),
      cell: require('@nextstep/assets/images/illustrations/orange/cell_Long.png'),
      computer: require('@nextstep/assets/images/illustrations/orange/computer_Long.png'),
      oops: require('@nextstep/assets/images/illustrations/orange/oops_Long.png'),
      pencil: require('@nextstep/assets/images/illustrations/orange/pencil_Long.png'),
    },
    green: {
      board: require('@nextstep/assets/images/illustrations/green/clipboard_Bald.png'),
      cell: require('@nextstep/assets/images/illustrations/green/cell_Bald.png'),
      computer: require('@nextstep/assets/images/illustrations/green/computer_Bald.png'),
      oops: require('@nextstep/assets/images/illustrations/green/oops_Bald.png'),
      pencil: require('@nextstep/assets/images/illustrations/green/pencil_Bald.png'),
    },
    blue: {
      board: require('@nextstep/assets/images/illustrations/blue/clipboard_Bun.png'),
      cell: require('@nextstep/assets/images/illustrations/blue/cell_Bun.png'),
      computer: require('@nextstep/assets/images/illustrations/blue/computer_Bun.png'),
      oops: require('@nextstep/assets/images/illustrations/blue/oops_Bun.png'),
      pencil: require('@nextstep/assets/images/illustrations/blue/pencil_Bun.png'),
    },
    magenta: {
      board: require('@nextstep/assets/images/illustrations/magenta/clipboard_ShortStraight.png'),
      cell: require('@nextstep/assets/images/illustrations/magenta/cell_ShortStraight.png'),
      computer: require('@nextstep/assets/images/illustrations/magenta/computer_ShortStraight.png'),
      oops: require('@nextstep/assets/images/illustrations/magenta/oops_ShortStraight.png'),
      pencil: require('@nextstep/assets/images/illustrations/magenta/pencil_ShortStraight.png'),
    },
    extra: {
      agreement: require('@nextstep/assets/images/illustrations/main/phone-happy.png'),
      graduation: require('@nextstep/assets/images/illustrations/other/avi_graduate.png'),
      noFee: require('@nextstep/assets/images/illustrations/orange/peach_mentor.png'),
      welcome: require('@nextstep/assets/images/illustrations/orange/clipboard_Long_simple.png'),
    },
  },

  assignmentQuestion: {
    completed: require('@nextstep/assets/images/icons/green/approved.png'),
    failed: require('@nextstep/assets/images/icons/pink/resubmit.png'),
    inProgress: require('@nextstep/assets/images/icons/orange/review.png'),
    locked: require('@nextstep/assets/images/icons/gray/locked.png'),
    unlocked: require('@nextstep/assets/images/icons/magenta/unlocked.png'),
  },

  inPersonTab: {
    clinicalRotation: require('@nextstep/assets/images/icons/gray/clinical_rotation.png'),
    backgroundCheck: require('@nextstep/assets/images/icons/gray/background_check.png'),
    beakerBW: require('@nextstep/assets/images/icons/gray/beaker_bw.png'),
    bls: require('@nextstep/assets/images/icons/gray/cpr.png'),
    needle: require('@nextstep/assets/images/icons/gray/needle.png'),
    skin: require('@nextstep/assets/images/icons/gray/skin.png'),
    id: require('@nextstep/assets/images/icons/gray/id.png'),
  },

  login: {
    PhoneWithBackground: require('@nextstep/assets/images/illustrations/other/mentorPhone.png'),
    GradJobs: require('@nextstep/assets/images/illustrations/other/grad_jobs.png'),
    slide1: require('@nextstep/assets/images/illustrations/other/selfpacedlearning.png'),
    slide2: require('@nextstep/assets/images/illustrations/other/dedicated_mentors.png'),
    slide3: require('@nextstep/assets/images/illustrations/other/affordable.png'),
    slide4: require('@nextstep/assets/images/illustrations/other/jobplayment.png'),
    slide5: require('@nextstep/assets/images/illustrations/other/continuing_ed.png'),
  },

  states: {
    CA: require('@nextstep/assets/images/illustrations/states/California.png'),
    CO: require('@nextstep/assets/images/illustrations/states/Colorado.png'),
    KS: require('@nextstep/assets/images/illustrations/states/Kansas.png'),
    OK: require('@nextstep/assets/images/illustrations/states/Oklahoma.png'),
    OR: require('@nextstep/assets/images/illustrations/states/Oregon.png'),
    US: require('@nextstep/assets/images/illustrations/states/USA.png'),
    WA: require('@nextstep/assets/images/illustrations/states/Washington.png'),
    TX: require('@nextstep/assets/images/illustrations/states/Texas.png'),
  },
};

export default images;
