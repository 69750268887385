import React, { Component } from 'react';
import { View } from 'react-native';
import { inject, observer } from 'mobx-react';
import withDimensions, { DeviceType } from '@nextstep/app/lib/withDimensions';
import { Body1, Body2, H3 } from '@nextstep/app/components/Text';
import ViewWrapper from '@nextstep/app/components/ViewWrapper';
import { Column, Row } from '@nextstep/app/lib/ResponsiveLayout';
import { Colors, Palette } from '@nextstep/app/Themes';
import { ButtonOutline, ButtonText } from '@nextstep/app/components/Button';
import Util from '@nextstep/app/services/Util';
import Divider from '@nextstep/app/components/Divider';
import AdaptiveContainerStyles from '@nextstep/app/containers/styles/AdaptiveContainerStyles';

const currentPalette = Palette.light;

const EMAIL = 'program_enrollment@nextstep.com';
const EMAIL_SUBJECT = 'NextStep Program Eligibility Question';

const defaultHeaderStyles = {
  container: {
    height: DeviceType.Mobile ? 216 : 270,
    alignItems: 'center',
    marginBottom: 40,
    backgroundColor: currentPalette.background.purple,
  },

  textContainer: {
    justifyContent: 'center',
    flex: 1,
  },

  text: { color: Colors.very_light_peach },
};

export class DisabledAccountScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { rootStore: { sessionStore }, dimensions } = this.props;

    const Styles = AdaptiveContainerStyles(dimensions.deviceType);

    return (
      <ViewWrapper
        smSize={12}
        mdSize={7}
        lgSize={6}
        topNavBarColor={currentPalette.background.purple}
      >

        <View style={defaultHeaderStyles.container}>
          <View style={defaultHeaderStyles.textContainer}>
            <H3 style={defaultHeaderStyles.text}>
              Disabled Account
            </H3>
          </View>
        </View>

        <Row style={{ justifyContent: 'center' }}>
          <Column smSize={12} mdSize={8} lgSize={8} style={Styles.textContainer}>

            <Body2>
              Your account has been disabled.
            </Body2>

            <View style={{ height: 16 }} />

            <Body1>
              It looks like your account has been disabled. This could have been for a various number of reasons. If you feel you have received this message by mistake please contact us at
              {' '}
              <ButtonText
                onPress={() => Util.openURL(
                  `mailto:${EMAIL}?subject=${EMAIL_SUBJECT}`,
                  '_self',
                )}
              >
                {EMAIL}
              </ButtonText>
              .
            </Body1>

          </Column>

          <Divider gutter={30} />

          <Column smSize={11} mdSize={7} lgSize={7} style={Styles.textContainer}>

            <ButtonOutline
              onPress={() => sessionStore.logout()}
              width="100%"
            >
              Sign Out
            </ButtonOutline>

            <View style={{ height: 34 }} />

          </Column>
        </Row>
      </ViewWrapper>
    );
  }
}

export default withDimensions(inject('rootStore')(observer(DisabledAccountScreen)));
