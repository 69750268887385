import { Image, Platform, SafeAreaView, View } from 'react-native';
import React from 'react';
import PropTypes from 'prop-types';
import { ApplicationStyles, Palette } from '@nextstep/app/Themes';
import Images from '@nextstep/app/Themes/Images';
import Column from '@nextstep/app/lib/ResponsiveLayout/Column';
import withDimensions from '@nextstep/app/lib/withDimensions';
import HeaderAction from './HeaderAction';

const currentPalette = Palette.light;

const Styles = {
  container: {
    justifyContent: 'center',
    flex: 1,
  },

  mainContainer: {
    flexDirection: 'row',
    paddingHorizontal: 12,
  },

  leftContainer: { alignItems: 'flex-start' },

  centerContainer: {
    alignItems: 'center',
    flex: 1,
  },

  rightContainer: { alignItems: 'flex-end' },
};

const AuthHeader = ({
  left, right, logo,
  color = currentPalette.background.white,
}) => (
  <Column style={Styles.shadow}>
    {Platform.OS !== 'web' && <SafeAreaView style={{ backgroundColor: currentPalette.background.white }} />}
    <View style={[Styles.mainContainer, { backgroundColor: color }]}>

      {left && (
        <View style={[Styles.container, Styles.leftContainer]}>
          <HeaderAction object={left} side="LEFT" />
        </View>
      )}

      {logo && (
        <View style={[Styles.centerContainer]}>
          <Image
            style={ApplicationStyles.navbarTitle}
            source={Images.other.nextStepLogo}
          />
        </View>
      )}

      { right && (
        <View style={[Styles.container, Styles.rightContainer]}>
          <HeaderAction object={right} side="RIGHT" />
        </View>
      )}

    </View>
  </Column>
);

AuthHeader.propTypes = {
  left: PropTypes.shape({
    icon: PropTypes.string,
    text: PropTypes.string,
    action: PropTypes.func,
  }),
  right: PropTypes.shape({
    icon: PropTypes.string,
    text: PropTypes.string,
    action: PropTypes.func,
  }),
  logo: PropTypes.bool,
  color: PropTypes.string,
};

AuthHeader.defaultProps = {
  left: undefined,
  right: undefined,
  logo: false,
  color: currentPalette.background.white,
};

export default withDimensions(AuthHeader);
