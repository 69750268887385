import React from 'react';
import { StyleSheet, View } from 'react-native';
import withDimensions from '@nextstep/app/lib/withDimensions';
import { getGutterPadding } from './utils';

const styles = StyleSheet.create({
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
  },
});

export const RowContext = React.createContext({ dimensions: {}, gutter: 0 });

export default withDimensions(({ children, gutter, dimensions, style }) => {
  const margin = gutter ? `-${getGutterPadding(gutter).toString()}` : 0;
  return (
    <View style={[styles.row, style, { margin: Number(margin) || margin }]}>
      <RowContext.Provider value={{ dimensions, gutter }}>
        {children}
      </RowContext.Provider>
    </View>
  );
});
