import React, { Component } from 'react';
import { Image, Linking, Platform, SafeAreaView, ScrollView, StatusBar, View } from 'react-native';
import ImagePicker from 'react-native-image-picker';
import ImageResizer from 'react-native-image-resizer';
import VersionNumber from 'react-native-version-number';
import { inject, observer } from 'mobx-react';
import { AsYouType } from 'libphonenumber-js';
import { AndroidBackHandler } from 'react-navigation-backhandler';
import { getStateNameByStateCode, sanitizeStateName } from 'us-state-codes';
import codePush from 'react-native-code-push';
import { ApplicationStyles, Images, Palette } from '@nextstep/app/Themes';
import { Body1, Body2, Caption1, Title } from '@nextstep/app/components/Text';
import { TextInput } from '@nextstep/app/components/TextInput';
import BasicCustomModal from '@nextstep/app/modals/BasicCustomModal';
import { ButtonFilled, ButtonText } from '@nextstep/app/components/Button';
import Util from '@nextstep/app/services/Util';
import { analyticsIdentifyUser, analyticsScreenView } from '@nextstep/app/services/Analytics';
import Constants from '@nextstep/app/Config/Constants';
import withDimensions, { DeviceType } from '@nextstep/app/lib/withDimensions';
import { Column, Row } from '@nextstep/app/lib/ResponsiveLayout';
import { FullViewSpinner } from '@nextstep/app/components/SharedUI';
import AppMainHeader from '@nextstep/app/components/AppMainHeader';
import DebouncedTouchableOpacity from '@nextstep/app/components/DebouncedTouchableOpacity';
import Divider from '@nextstep/app/components/Divider';
import { InputSelect } from '@nextstep/app/components/InputSelect';
import EnrollmentAgreementHistoryCard from '@nextstep/app/components/EnrollmentAgreementHistoryCard';
import AdaptiveContainerStyles from './styles/AdaptiveContainerStyles';
import Styles from './styles/SettingScreenStyles';

const currentPalette = Palette.light;

class SettingsScreen extends Component {
  constructor(props) {
    super(props);

    const { sessionStore } = props;
    const { learner } = sessionStore;

    this.state = {
      profileImage: {
        value: learner.profileImage,
        showError: false,
        errorMessage: undefined,
        uploadingAvatar: false,
      },
      legalFirstName: {
        value: learner.legalFirstName,
        showError: false,
        errorMessage: undefined,
      },
      legalMiddleName: {
        value: learner.legalMiddleName,
        showError: false,
        errorMessage: undefined,
      },
      legalLastName: {
        value: learner.legalLastName,
        showError: false,
        errorMessage: undefined,
      },
      preferredFirstName: {
        value: learner.preferredFirstName,
        showError: false,
        errorMessage: undefined,
      },
      preferredLastName: {
        value: learner.preferredLastName,
        showError: false,
        errorMessage: undefined,
      },
      homeStreet1: {
        value: learner.homeStreet1,
        showError: false,
        errorMessage: undefined,
      },
      homeStreet2: {
        value: learner.homeStreet2,
        showError: false,
        errorMessage: undefined,
      },
      homeCity: {
        value: learner.homeCity,
        showError: false,
        errorMessage: undefined,
      },
      homeState: {
        value: learner.homeState,
        showError: false,
        errorMessage: undefined,
      },
      homeZipCode: {
        value: learner.homeZipCode,
        showError: false,
        errorMessage: undefined,
      },
      hasDifferentPreferredName: learner.hasDifferentPreferredName,
      loading: false,
      selectedInput: null,
      errorModal: {
        state: false,
        errorMessage: undefined,
        messageTitle: undefined,
        buttonText: undefined,
        toggleAction: () => {},
      },
    };
    this.toggleErrorModal = this.toggleErrorModal.bind(this);
    this.updateUserData = this.updateUserData.bind(this);
    this.uploadAvatar = this.uploadAvatar.bind(this);
  }

  getFormattedStudentId = (studentId) => studentId && studentId.toString().replace(/([0-9]{2})([0-9]{3})(.*)/, '$1-$2-$3')

  componentDidMount() {
    const { sessionStore } = this.props;
    analyticsScreenView('settingsViewed');
    if (sessionStore?.learner?.config?.agreementUrl) sessionStore.getEnrollmentAgreements().then();
  }

  componentWillUnmount() {
    this.setState({});
  }

  updateStateField = (objectName: string, text: string, valueFieldName: string = 'value') => {
    const { [objectName]: object } = this.state;

    if (object.showError) {
      this.setState({ [objectName]: { showError: false } });
    }
    this.setState({ [objectName]: { [valueFieldName]: text } });
  }

  async cancel() {
    const { navigation } = this.props;
    navigation.replace('App', { screen: 'Home' });
  }

  async validateFields() {
    const {
      legalFirstName, legalLastName, preferredFirstName,
      preferredLastName, homeStreet1, homeCity, homeState,
      homeZipCode, hasDifferentPreferredName,
    } = this.state;
    let validated = true;

    if (!legalFirstName.value) {
      this.setState({
        legalFirstName: {
          value: legalFirstName.value,
          showError: true,
          errorMessage: 'Please enter your legal first name.',
        },
      });
      validated = false;
    }

    if (!legalLastName.value) {
      this.setState({
        legalLastName: {
          value: legalLastName.value,
          showError: true,
          errorMessage: 'Please enter your legal last name.',
        },
      });
      validated = false;
    }

    if (hasDifferentPreferredName) {
      if (!preferredFirstName.value) {
        this.setState({
          preferredFirstName: {
            value: preferredFirstName.value,
            showError: true,
            errorMessage: 'Please enter your preferred first name.',
          },
        });
        validated = false;
      }

      if (!preferredLastName.value) {
        this.setState({
          preferredLastName: {
            value: preferredLastName.value,
            showError: true,
            errorMessage: 'Please enter your preferred last name.',
          },
        });
        validated = false;
      }
    }

    if (!homeStreet1.value) {
      this.setState({
        homeStreet1: {
          value: homeStreet1.value,
          showError: true,
          errorMessage: 'Please enter your home address.',
        },
      });
      validated = false;
    }

    if (!homeCity.value) {
      this.setState({
        homeCity: {
          value: homeCity.value,
          showError: true,
          errorMessage: 'Please enter the city you live in.',
        },
      });
      validated = false;
    }

    if (!homeState.value || !Util.isValidUsaState(homeState.value)) {
      this.setState({
        homeState: {
          value: homeState.value,
          showError: true,
          errorMessage: 'Please enter a valid US state.',
        },
      });
      validated = false;
    }

    if (!homeZipCode.value || !Util.isValidZipcode(homeZipCode.value)) {
      this.setState({
        homeZipCode: {
          value: homeZipCode.value,
          showError: true,
          errorMessage: 'Please enter a valid 5-digit zip code.',
        },
      });
      validated = false;
    }

    return validated;
  }

  async uploadAvatar(response) {
    const { sessionStore } = this.props;
    const { learner } = sessionStore;

    this.setState({ uploadingAvatar: true });

    const imageObject = await ImageResizer.createResizedImage(response.uri, 200, 200, 'JPEG', 92, 0);
    const avatarUrl = await sessionStore.uploadAvatar(imageObject.uri, learner);
    learner.setProfileImage(avatarUrl);

    this.setState({ uploadingAvatar: false });
  }

  async updateUserData() {
    if (await this.validateFields()) {
      const {
        legalFirstName, legalLastName, legalMiddleName,
        hasDifferentPreferredName, preferredFirstName, preferredLastName,
        homeStreet1, homeStreet2, homeCity, homeState, homeZipCode,
      } = this.state;
      const { navigation, sessionStore } = this.props;
      const { syncCurrentLearner } = sessionStore;

      let sanitizedSettings = {
        legalFirstName: legalFirstName.value.trim(),
        legalLastName: legalLastName.value.trim(),
        homeStreet1: homeStreet1.value,
        homeStreet2: homeStreet2.value,
        homeCity: homeCity.value,
        homeState: sanitizeStateName(homeState.value) || getStateNameByStateCode(homeState.value),
        homeZipCode: homeZipCode.value,
      };

      if (hasDifferentPreferredName) {
        sanitizedSettings = {
          ...sanitizedSettings,
          hasDifferentPreferredName,
          preferredFirstName: preferredFirstName.value.trim(),
          preferredLastName: preferredLastName.value.trim(),
        };
      }

      if (legalMiddleName.value) {
        sanitizedSettings = {
          ...sanitizedSettings,
          legalMiddleName: legalMiddleName.value.trim(),
        };
      }

      await syncCurrentLearner(sanitizedSettings);
      analyticsIdentifyUser(sessionStore.learner);
      navigation.navigate('App', { screen: 'Home' });
    }
  }

  toggleErrorModal(
    state = false,
    errorMessage = undefined,
    messageTitle = undefined,
    buttonText = undefined,
    toggleAction = () => {},
  ) {
    this.setState({
      errorModal: {
        state,
        errorMessage,
        messageTitle,
        buttonText,
        toggleAction,
      },
    });
  }

  errorModal() {
    const { errorModal } = this.state;
    return (
      <BasicCustomModal
        isVisible={errorModal.state}
        toggleVisible={this.toggleErrorModal}
        messageTitle={errorModal.messageTitle}
        icon={Images.icons.main.warning}
        messageContent={errorModal.errorMessage}
        buttonText={errorModal.buttonText}
        toggleAction={errorModal.toggleAction}
      />
    );
  }

  launchImagePicker() {
    const options = {
      title: 'Select Avatar',
      storageOptions: {
        skipBackup: true,
        path: 'images',
      },
      cameraType: 'front',
      rotation: 360,
    };

    ImagePicker.showImagePicker(options, async (response) => {
      if (response.didCancel) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-console
        console.log('User cancelled image picker');
      } else if (response.error) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-console
        console.log('ImagePicker Error: ', response.error);
        this.toggleErrorModal(
          true,
          response.error,
          'Warning!',
          'Go to settings',
          () => Linking.openURL('app-settings://'),
        );
      } else if (response.customButton) {
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line no-console
        console.log('User tapped custom button: ', response.customButton);
      } else {
        this.uploadAvatar(response);
      }
      return response;
    });
  }

  getPaymentTrackInfo = () => {
    const { sessionStore } = this.props;
    const { learner } = sessionStore;
    if (!learner) return {};

    const { paymentTrack } = learner;
    const { PaymentTrackTypes } = Constants;

    switch (paymentTrack) {
      case PaymentTrackTypes.scholarship:
        return {
          icon: 'md-school',
          title: 'Scholarship Accepted',
          paymentTrack,
        };
      case PaymentTrackTypes.tuition:
        return {
          icon: 'md-card',
          title: 'Tuition Payment',
          paymentTrack,
        };
      case PaymentTrackTypes.noFee:
        return {
          icon: 'md-school',
          title: 'Scholarship Accepted',
          paymentTrack,
        };
      case PaymentTrackTypes.tuitionEmployerPaid:
        return {
          icon: 'md-card',
          title: 'Employer Paid Tuition',
          paymentTrack,
        };
      default:
        return {
          icon: 'md-close',
          title: 'No track selected',
          paymentTrack,
        };
    }
  };

  togglePreferredNameCheckBox = (state) => {
    this.setState({ hasDifferentPreferredName: state });
  };

  preferredNameFields = () => {
    const { preferredFirstName, preferredLastName, selectedInput } = this.state;

    return (
      <>
        <Column smSize={12} mdSize={6} lgSize={6}>
          <TextInput
            title={'Preferred First Name'}
            data={preferredFirstName}
            customAttributes={{ placeholder: 'Jaz', ref: (ref) => { this.preferredFirstName = ref; } }}
            valueType={Constants.InputTextTypes.firstName}
            onFocus={() => this.setState({ selectedInput: this.preferredFirstName })}
            isFocused={selectedInput === this.preferredFirstName}
            onChangeText={(text) => this.updateStateField('preferredFirstName', text)}
            onSubmitEditing={() => this.preferredLastName.focus()}
          />
        </Column>

        <Column smSize={12} mdSize={6} lgSize={6}>
          <TextInput
            title={'Preferred Last Name'}
            data={preferredLastName}
            customAttributes={{ placeholder: 'Collins', ref: (ref) => { this.preferredLastName = ref; } }}
            valueType={Constants.InputTextTypes.lastName}
            onFocus={() => this.setState({ selectedInput: this.preferredLastName })}
            isFocused={selectedInput === this.preferredLastName}
            onChangeText={(text) => this.updateStateField('preferredLastName', text)}
            // onSubmitEditing={() => this.zipCode.focus()}
          />
        </Column>
      </>
    );
  }


  async logout() {
    const { sessionStore } = this.props;
    this.setState({ loading: true });
    await sessionStore.logout();
  }

  render() {
    const { sessionStore, dimensions, navigation } = this.props;
    const { learner, enrollmentAgreements } = sessionStore;
    const {
      legalFirstName, legalMiddleName, legalLastName, profileImage,
      loading, uploadingAvatar, selectedInput, hasDifferentPreferredName,
      homeStreet1, homeStreet2, homeCity, homeState, homeZipCode,
    } = this.state;

    if (loading || !learner) return <FullViewSpinner showBackground />;

    const versionText = `Version: ${VersionNumber.appVersion}`;
    const buildText = `Build: ${VersionNumber.buildVersion}`;

    const paymentTrackInfo = this.getPaymentTrackInfo();

    const GlobalStyles = AdaptiveContainerStyles();
    return (
      <>
        <SafeAreaView style={ApplicationStyles.safeAreaTop} />
        <StatusBar barStyle="dark-content" animated />
        <AndroidBackHandler onBackPress={() => true} />
        { dimensions.deviceType !== DeviceType.Desktop && <AppMainHeader navigation={navigation} /> }
        <ScrollView
          style={[Styles.scrollView, ApplicationStyles.screenContainer()]}
          contentContainerStyle={{ flexGrow: 1, justifyContent: 'flex-start' }}
          bounces={false}
          keyboardShouldPersistTaps="always"
        >
          <Row>
            <Column smSize={11} mdSize={10} lgSize={6}>
              {this.errorModal()}

              {/* MY PROFILE */}
              <View style={[GlobalStyles.card]}>

                {/* TITLE */}
                <View style={[GlobalStyles.textContainer, Styles.section]}>
                  <Title>MY PROFILE</Title>
                </View>

                {/* CONTENT */}
                <View style={[GlobalStyles.textContainer, Styles.section]}>

                  {/* PROFILE IMAGE */}
                  <View style={Styles.profilePictureContainer}>

                    <Image
                      source={profileImage.value ? { uri: profileImage.value } : Images.icons.main.defaultAvatar}
                      style={Styles.profilePicture}
                    />

                    {Platform.OS !== 'web' && (
                      <DebouncedTouchableOpacity
                        id="upload_image_button"
                        onPress={() => this.launchImagePicker()}
                        style={Styles.editPictureIcon}
                      >
                        <Image
                          source={Images.icons.pencil}
                        />
                      </DebouncedTouchableOpacity>
                    )}

                    <Body1>
                      {uploadingAvatar && 'Uploading...'}
                    </Body1>

                  </View>

                  <View style={{ height: 24 }} />

                  {/* USER INPUTS */}
                  <View style={{ alignContent: 'center' }}>
                    <Row gutter={16} style={{ justifyContent: 'flex-start' }}>
                      <Column smSize={12} mdSize={12} lgSize={12}>
                        <TextInput
                          title={'Legal First Name'}
                          data={legalFirstName}
                          customAttributes={{ placeholder: 'Jazzie', ref: (ref) => { this.legalFirstName = ref; } }}
                          valueType={Constants.InputTextTypes.firstName}
                          onFocus={() => this.setState({ selectedInput: this.legalFirstName })}
                          isFocused={selectedInput === this.legalFirstName}
                          onChangeText={(text) => this.updateStateField('legalFirstName', text)}
                          onSubmitEditing={() => this.legalLastName.focus()}
                        />
                      </Column>

                      <Column smSize={12} mdSize={12} lgSize={12}>
                        <TextInput
                          title={'Legal Middle Name'}
                          data={legalMiddleName}
                          customAttributes={{ placeholder: '', ref: (ref) => { this.legalMiddleName = ref; } }}
                          valueType={Constants.InputTextTypes.middleName}
                          onFocus={() => this.setState({ selectedInput: this.legalMiddleName })}
                          isFocused={selectedInput === this.legalMiddleName}
                          onChangeText={(text) => this.updateStateField('legalMiddleName', text)}
                          onSubmitEditing={() => this.legalLastName.focus()}
                        />
                      </Column>

                      <Column smSize={12} mdSize={12} lgSize={12}>
                        <TextInput
                          title={'Legal Last Name'}
                          data={legalLastName}
                          customAttributes={{ placeholder: 'Collinsworth', ref: (ref) => { this.legalLastName = ref; } }}
                          valueType={Constants.InputTextTypes.lastName}
                          onFocus={() => this.setState({ selectedInput: this.legalLastName })}
                          isFocused={selectedInput === this.legalLastName}
                          onChangeText={(text) => this.updateStateField('legalLastName', text)}
                        />
                      </Column>

                      <Column smSize={12} mdSize={12} lgSize={12}>
                        <InputSelect
                          value={!hasDifferentPreferredName}
                          onValueChange={(state) => this.togglePreferredNameCheckBox(!state)}
                          falseColor={currentPalette.text.disabled}
                        >
                          Preferred name is the same as legal name
                        </InputSelect>
                        <View style={{ height: 16 }} />
                      </Column>

                      {hasDifferentPreferredName && this.preferredNameFields()}
                    </Row>
                  </View>

                </View>

                {/* DIVIDER */}
                <Divider />
                <View style={{ height: 24 }} />

                <Title>Home Address</Title>

                {/* HOME ADDRESS */}
                <View style={[GlobalStyles.textContainer, Styles.section]}>
                  <View style={{ alignContent: 'center' }}>
                    <Row gutter={16} style={{ justifyContent: 'flex-start' }}>
                      <Column smSize={12} mdSize={12} lgSize={12}>

                        <TextInput
                          title={'Street Address'}
                          data={homeStreet1}
                          customAttributes={{ ref: (ref) => { this.homeStreet1 = ref; } }}
                          valueType={Constants.InputTextTypes.streetAddressLine1}
                          onFocus={() => this.setState({ selectedInput: this.homeStreet1 })}
                          isFocused={selectedInput === this.homeStreet1}
                          onChangeText={(text) => this.updateStateField('homeStreet1', text)}
                          onSubmitEditing={() => this.homeStreet2.focus()}
                        />
                      </Column>

                      <Column smSize={12} mdSize={12} lgSize={12}>
                        <TextInput
                          title={'Apartment, Unit, Etc.'}
                          data={homeStreet2}
                          customAttributes={{ ref: (ref) => { this.homeStreet2 = ref; } }}
                          valueType={Constants.InputTextTypes.streetAddressLine2}
                          onFocus={() => this.setState({ selectedInput: this.homeStreet2 })}
                          isFocused={selectedInput === this.homeStreet2}
                          onChangeText={(text) => this.updateStateField('homeStreet2', text)}
                          onSubmitEditing={() => this.homeCity.focus()}
                        />
                      </Column>

                      <Column smSize={12} mdSize={4} lgSize={4}>
                        <TextInput
                          title={'City'}
                          data={homeCity}
                          customAttributes={{ ref: (ref) => { this.homeCity = ref; } }}
                          valueType={Constants.InputTextTypes.addressCity}
                          onFocus={() => this.setState({ selectedInput: this.homeCity })}
                          isFocused={selectedInput === this.homeCity}
                          onChangeText={(text) => this.updateStateField('homeCity', text)}
                          onSubmitEditing={() => this.homeState.focus()}
                        />
                      </Column>

                      <Column smSize={12} mdSize={4} lgSize={4}>
                        <TextInput
                          title={'State'}
                          data={homeState}
                          customAttributes={{ ref: (ref) => { this.homeState = ref; } }}
                          valueType={Constants.InputTextTypes.addressState}
                          onFocus={() => this.setState({ selectedInput: this.homeState })}
                          isFocused={selectedInput === this.homeState}
                          onChangeText={(text) => this.updateStateField('homeState', text)}
                          onSubmitEditing={() => this.homeZipCode.focus()}
                        />
                      </Column>

                      <Column smSize={12} mdSize={4} lgSize={4}>
                        <TextInput
                          title={'Zip Code'}
                          data={homeZipCode}
                          customAttributes={{
                            placeholder: '00000',
                            ref: (ref) => { this.homeZipCode = ref; },
                          }}
                          valueType={Constants.InputTextTypes.zipCode}
                          onFocus={() => this.setState({ selectedInput: this.homeZipCode })}
                          isFocused={selectedInput === this.homeZipCode}
                          onChangeText={(text) => this.updateStateField('homeZipCode', text)}
                        />
                      </Column>
                    </Row>
                  </View>
                </View>

                {/* DIVIDER */}
                <Divider />

                {/* ACTION BUTTONS */}
                <Row style={[GlobalStyles.textContainer, Styles.section, Styles.actionButtons]}>
                  <Column smSize={12} mdSize={8} lgSize={8} style={{ alignItems: 'center' }}>

                    <ButtonFilled width="100%" onPress={() => this.updateUserData()}>
                      Save Changes
                    </ButtonFilled>

                    <View style={{ height: 16 }} />

                    <ButtonText onPress={() => this.cancel()}>
                      Cancel
                    </ButtonText>

                  </Column>
                </Row>

              </View>

              {/* CARD DIVIDER */}
              <View style={{ height: 24 }} />

              {/* STUDENT INFORMATION */}
              <View style={[GlobalStyles.card]}>

                {/* TITLE */}
                <View style={[GlobalStyles.textContainer, Styles.section]}>
                  <Title>Student Information</Title>
                </View>

                {/* CONTENT */}
                <View style={[GlobalStyles.textContainer, Styles.section]}>
                  <View style={{ alignContent: 'center' }}>
                    <Row gutter={16} style={{ justifyContent: 'flex-start' }}>
                      <Column smSize={12} mdSize={12} lgSize={12}>
                        <TextInput
                          title={'Student ID'}
                          data={{ value: this.getFormattedStudentId(learner.studentId) }}
                          customAttributes={{ placeholder: 'Student ID' }}
                          editable={false}
                        />

                        <TextInput
                          title={'Mobile Phone Number'}
                          data={{ value: new AsYouType('US').input(learner.phone.replace('+1', '')) }}
                          customAttributes={{ placeholder: '(000) 000 -0000', ref: (ref) => { this.phoneNumber = ref; } }}
                          editable={false}
                        />

                        <TextInput
                          title={'Email Address'}
                          data={{ value: learner.email.replace('-', '\u2011') }}
                          customAttributes={{ placeholder: 'Jazzie@nextstep.com', ref: (ref) => { this.emailAddress = ref; } }}
                          editable={false}
                        />

                        <TextInput
                          title={'Certification State'}
                          data={{ value: Util.getStateFromAbbreviation(learner.legacyState) }}
                          editable={false}
                        />

                        <TextInput
                          title={'Learning Track'}
                          data={{ value: paymentTrackInfo.title }}
                          editable={false}
                        />
                      </Column>
                    </Row>
                  </View>
                </View>

              </View>

              {/* CARD DIVIDER */}
              <View style={{ height: 24 }} />

              {/* OKLAHOMA & OREGON AGREEMENT */}
              { sessionStore.learner.legacyState === 'OR' || sessionStore.learner.legacyState === 'OK' && (
                <View style={[GlobalStyles.card]}>

                  {/* TITLE */}
                  <View style={[GlobalStyles.textContainer, { paddingTop: 24 }]}>
                    <Title>ENROLLMENT AGREEMENT & DISCLOSURE</Title>
                  </View>

                  {/* CONTENT */}
                  {enrollmentAgreements.map(agreement => <EnrollmentAgreementHistoryCard key={agreement.id} {...agreement} />)}

                  <View style={{ height: 15 }} />
                </View>
              )}

              {/* LINKS */}
              <View style={Styles.links}>

                <DebouncedTouchableOpacity
                  id={'profileLogOutButton'}
                  style={{ flexDirection: 'row' }}
                  onPress={() => this.logout()}
                >
                  <Image
                    source={Images.icons.darkLogOut}
                    style={[ApplicationStyles.icon, { marginRight: 8 }]}
                  />
                  <Body2>Logout</Body2>
                </DebouncedTouchableOpacity>

                {/* <View style={{ height: 16 }} />

                <ButtonText onPress={() => Util.openURL('https://www.nextstep.com/privacy')}>
                  Privacy Policy
                </ButtonText>

                <View style={{ height: 16 }} />

                <ButtonText onPress={() => (learner.config ? Util.openURL(learner.config.coursePolicyUrl) : {})}>
                  Course Policy
                </ButtonText>

                <View style={{ height: 16 }} />

                <ButtonText onPress={() => (learner.config ? Util.openURL(learner.config.eulaUrl) : {})}>
                  End License User Agreement
                </ButtonText> */}

                <View style={{ height: 16 }} />

                {Platform.OS !== 'web' && (
                  <DebouncedTouchableOpacity
                    delayLongPress={1000}
                    onLongPress={() => codePush.sync({ installMode: codePush.InstallMode.IMMEDIATE })}
                  >
                    <Caption1 color={currentPalette.text.disabled}>
                      {versionText}
                      {' '}
                      {buildText}
                    </Caption1>
                    <View style={{ height: 16 }} />
                  </DebouncedTouchableOpacity>
                )}

                <Caption1 color={currentPalette.text.disabled}>
                  ©
                  {` ${new Date().getFullYear()} `}
                  NextStep Interactive Inc.
                </Caption1>

                <View style={{ height: 30 }} />
              </View>
            </Column>
          </Row>
        </ScrollView>
      </>
    );
  }
}

export default withDimensions(inject(({ rootStore: { sessionStore } }) => ({ sessionStore }))(observer(SettingsScreen)));
