/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { Image, Platform, ScrollView, View } from 'react-native';
import ReadMore from 'react-native-read-more-text';
import Modal from 'react-native-modal';
import withDimensions, { DeviceType } from '@nextstep/app/lib/withDimensions';
import { Images, Palette } from '@nextstep/app/Themes';
import AppMainHeader from '@nextstep/app/components/AppMainHeader';
import ViewWrapper from '@nextstep/app/components/ViewWrapper';
import { Body1, Body2, Caption2, Subtitle2 } from '@nextstep/app/components/Text';
import IconCard from '@nextstep/app/components/IconCard';
import { Column, Row } from '@nextstep/app/lib/ResponsiveLayout';
import { ButtonText } from '@nextstep/app/components/Button';
import { analyticsScreenView } from '@nextstep/app/services/Analytics';
import { FullViewSpinner } from '@nextstep/app/components/SharedUI';
import NavigationService from '@nextstep/app/services/NavigationService';
import MarkdownRender from '@nextstep/app/components/MarkdownRender';
import Util from '@nextstep/app/services/Util';
import AdaptiveContainerStyles from './styles/AdaptiveContainerStyles';

const currentPalette = Palette.light;

const DefaultStyles = {
  detailsHeader: {
    backgroundColor: currentPalette.background.purple,
    flexDirection: 'row',
  },
  headerText: { flex: 1 },

  headerImageContainer: { width: '30%' },

  centerImage: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: 'auto',
    maxHeight: '100%',
    maxWidth: '100%',
    position: 'absolute',
  },

  readMore: {
    alignItems: 'flex-start',
    marginTop: 10,
  },

  modal: {
    width: '50%',
    backgroundColor: 'white',
    height: '100vh',
    alignSelf: 'flex-end',
    margin: 0,
  },
};

class PracticeLabDetailsScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: props.loading,
      showModal: false,
    };
  }

  componentDidMount() {
    analyticsScreenView('PracticeLabDetailsViewed');
  }

  practiceLabTitle = (title, skillType) => (
    <>
      {skillType && (
        <Caption2 textAlign="left" color={currentPalette.text.secondary}>
          {skillType.toUpperCase()}
        </Caption2>
      )}
      <Body2 textAlign="left">
        {title}
      </Body2>
    </>
  );

  renderTruncatedFooter = (handlePress) => (
    <ButtonText onPress={handlePress} color={currentPalette.text.white} style={DefaultStyles.readMore}>
      Read More
    </ButtonText>
  );

  renderRevealedFooter = (handlePress) => (
    <ButtonText onPress={handlePress} color={currentPalette.text.white} style={DefaultStyles.readMore}>
      Show Less
    </ButtonText>
  );

  renderLabSkillAsset = (labSkill) => {
    const { rootStore: { contentStore }, dimensions } = this.props;
    const { id, asset } = labSkill;

    if (asset.startsWith('https://') || asset.startsWith('http://')) {
      Util.openURL(asset);
    } else if (Platform.OS === 'web' && dimensions.deviceType !== DeviceType.Mobile) {
      contentStore.setPracticeLabSkill(id);
      this.toggleModal(true);
    } else {
      contentStore.setPracticeLabSkill(id);
      NavigationService.navigate('PracticeLabAssetScreen');
    }
  }

  renderBody = () => {
    const { rootStore: { contentStore }, dimensions } = this.props;

    const { practiceLab } = contentStore;

    return (
      <>
        <View style={DefaultStyles.detailsHeader}>

          <View style={DefaultStyles.headerImageContainer}>
            <Image
              style={DefaultStyles.centerImage}
              source={{ uri: practiceLab.image }}
            />
          </View>

          <View
            style={[
              AdaptiveContainerStyles(dimensions.deviceType).textContainerPadding,
              DefaultStyles.headerText,
            ]}
          >

            <Subtitle2 color={currentPalette.text.white} textAlign="left">
              {practiceLab.title}
            </Subtitle2>

            <View style={{ height: 8 }} />

            <ReadMore
              numberOfLines={dimensions.deviceType !== DeviceType.Mobile ? 10 : 3}
              renderTruncatedFooter={this.renderTruncatedFooter}
              renderRevealedFooter={this.renderRevealedFooter}
              textStyle={{ color: 'white' }}
            >
              <Body1 color={currentPalette.text.white} textAlign="left">
                {practiceLab.description}
              </Body1>
            </ReadMore>

          </View>
        </View>

        <View style={{ height: 24 }} />

        <Row>
          <Column smSize={11} mdSize={11} lgSize={11}>

            <Row gutter={27} style={{ justifyContent: 'flex-start' }}>

              {practiceLab.labSkills.map((labSkill, index) => (
                <Column key={index} smSize={12} mdSize={6} lgSize={4}>
                  <IconCard
                    key={index}
                    {...labSkill}
                    title={this.practiceLabTitle(labSkill.title, labSkill.skillType)}
                    enableIcon={Images.inPersonTab.beakerBW}
                    onPress={labSkill.asset ? () => this.renderLabSkillAsset(labSkill) : undefined}
                  />
                </Column>
              ))}

            </Row>

          </Column>

        </Row>

        <View style={{ height: 40 }} />
      </>
    );
  }

  renderLoading = () => {
    const { dimensions } = this.props;

    return (
      <>
        <View style={dimensions.deviceType === DeviceType.Mobile ? { flex: 1 } : { height: '80vh' }}>
          <FullViewSpinner />
        </View>
      </>
    );
  }

  toggleModal = (state) => {
    this.setState({ showModal: state });
  };

  render() {
    const { rootStore: { contentStore }, navigation, dimensions } = this.props;
    const { loading, showModal } = this.state;
    const { practiceLab, labSkill } = contentStore;

    return (
      <>
        {dimensions.deviceType !== DeviceType.Desktop && <AppMainHeader navigation={navigation} />}

        {Platform.OS === 'web' && (
          <Modal
            isVisible={showModal}
            coverScreen={false}
            backdropOpacity={0.2}
            animationIn="fadeInRight"
            animationOut="fadeOutRight"
            onBackdropPress={() => this.toggleModal(false)}
            style={DefaultStyles.modal}
            propagateSwipe
          >
            <ScrollView style={{ paddingVertical: 36 }}>
              <MarkdownRender data={labSkill?.asset || undefined} />
            </ScrollView>
          </Modal>
        )}

        <ViewWrapper
          smSize={12}
          mdSize={11}
          lgSize={11}
          navigationBar
          backOverride={() => NavigationService.navigate('App', { screen: 'In-Person' })}
        >

          { (loading || !practiceLab) ? this.renderLoading() : this.renderBody()}

        </ViewWrapper>
      </>
    );
  }
}

PracticeLabDetailsScreen.propTypes = { loading: PropTypes.bool };

PracticeLabDetailsScreen.defaultProps = { loading: false };

export default withDimensions(inject('rootStore')(observer(PracticeLabDetailsScreen)));
