import { ApplicationStyles } from '@nextstep/app/Themes';
import { DeviceType } from '@nextstep/app/lib/withDimensions';
import { ResponsiveStyleSheet } from '@nextstep/app/lib/ResponsiveLayout';
import { currentPalette } from '@nextstep/app/Config/AppConfig';

export default ResponsiveStyleSheet.create({
  body: { [DeviceType.Mobile]: { alignContent: 'flex-start' } },

  safeArea: { ...ApplicationStyles.safeArea },

  container: {
    maxHeight: '100%',

    [DeviceType.Mobile]: { height: '100%' },
  },

  card: {
    maxHeight: '100%',
    borderRadius: 8,
    shadowRadius: 2,
    shadowOffset: { width: 1, height: 0.5 },
    shadowColor: currentPalette.shadow.main,
    backgroundColor: currentPalette.background.white,
    overflow: 'hidden',
    [DeviceType.Mobile]: {
      maxHeight: null,
      flexGrow: 1,
    },
  },

  mobileWebContainer: {
    backgroundColor: currentPalette.background.white,
    flexShrink: 1,
    borderRadius: 0,
    shadowRadius: 0,
    overflow: 'hidden',
  },

  section: {
    borderRadius: 8,
    shadowRadius: 2,
    shadowOffset: { width: 1, height: 0.5 },
    shadowColor: currentPalette.shadow.main,
    backgroundColor: currentPalette.background.white,
    flex: null,
    flexGrow: 0,
    [DeviceType.Mobile]: {
      maxHeight: null,
      flexGrow: 1,
      borderRadius: 0,
      shadowRadius: 0,
    },
  },

  scrollView: { flex: 1 },

  sectionContainer: { justifyContent: 'space-between' },

  textContainer: {
    paddingHorizontal: 30,
    [DeviceType.Mobile]: { paddingHorizontal: 16 },
  },

  textContainerPadding: {
    padding: 30,
    [DeviceType.Mobile]: { padding: 16 },
  },

  bottomBar: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingVertical: 20,
  },

  actionsContainer: { alignItems: 'center' },

  navBar: {
    height: '3%',
    [DeviceType.Mobile]: { height: 20 },
  },
});
