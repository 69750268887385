import React from 'react';
import { Text, View } from 'react-native';
import { Body2, Caption1 } from '@nextstep/app/components/Text';
import { TextInput } from '@nextstep/app/components/TextInput';
import Constants from '@nextstep/app/Config/Constants';
import { currentPalette } from '@nextstep/app/Config/AppConfig';
import { inject } from 'mobx-react';

class TextQuestion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      questionState: {
        value: undefined,
        showError: false,
        errorMessage: 'Question is required',
      },
    };
  }

  updateAnswer = () => {
    const { rootStore: { sessionStore } } = this.props;
    const { questionState: { value } } = this.state;
    const { question } = this.props;

    sessionStore.addQuestionnaireAnswer({
      questionId: question.id,
      answerChoice: value,
    });
  }

  updateStateField = async (objectName: string, text: string, valueFieldName: string = 'value') => {
    const { [objectName]: object } = this.state;

    if (object.showError) {
      this.setState({ [objectName]: { showError: false } });
    }
    await this.setState({ [objectName]: { [valueFieldName]: text } });
    this.updateAnswer();
  }

  render() {
    const { questionState } = this.state;
    const { question, showError, errorMessage } = this.props;

    return (
      <View style={{}}>
        <Text>
          <Body2 textAlign="left">{question.text}</Body2>
          {question.required && <Body2 color={currentPalette.text.error}>*</Body2>}
        </Text>

        <View style={{ height: 12 }} />

        <TextInput
          data={{ ...questionState, showError, errorMessage }}
          customAttributes={{
            placeholder: question.placeholderText,
            ref: (ref) => { this.questionState = ref; },
            multiline: question.multiline,
          }}
          valueType={Constants.InputTextTypes.custom}
          onChangeText={(text) => this.updateStateField('questionState', text)}
        />
        {question.multiline && (
          <View style={{ position: 'relative', bottom: 55, right: 10 }}>
            <Caption1
              textAlign="right"
              color={questionState.value?.length >= question.maxlength ? currentPalette.text.error : currentPalette.text.gray}
            >
              {`${Math.abs(questionState.value?.length || 0)}/${question.maxLength}`}
            </Caption1>
          </View>
        )}
      </View>
    );
  }
}

export default inject('rootStore')(TextQuestion);
