/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { Text, StyleSheet } from 'react-native';
import { Typography, Palette } from '@nextstep/app/Themes';

const currentPalette = Palette.light;

const styleBase = {};

const generalPropTypes = {
  textAlign: PropTypes.string,
  color: PropTypes.string,
  style: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
  ]),
};

const generalDefaultProps = {
  textAlign: 'center',
  color: currentPalette.text.main,
  style: {},
};

export const H1 = (props) => {
  const style = StyleSheet.flatten([
    Typography.h1,
    styleBase,
    {
      textAlign: props.textAlign,
      color: props.color,
    },
    props.style,
  ]);
  return <Text style={style}>{props.children}</Text>;
};

H1.propTypes = generalPropTypes;
H1.defaultProps = generalDefaultProps;

export const H2 = (props) => {
  const style = StyleSheet.flatten([
    Typography.h2,
    styleBase,
    {
      textAlign: props.textAlign,
      color: props.color,
    },
    props.style,
  ]);
  return <Text style={style}>{props.children}</Text>;
};

H2.propTypes = generalPropTypes;
H2.defaultProps = generalDefaultProps;

export const H3 = (props) => {
  const style = StyleSheet.flatten([
    Typography.h3,
    styleBase,
    {
      textAlign: props.textAlign,
      color: props.color,
    },
    props.style,
  ]);
  return <Text style={style}>{props.children}</Text>;
};

H3.propTypes = generalPropTypes;
H3.defaultProps = generalDefaultProps;

export const H4 = (props) => {
  const style = StyleSheet.flatten([
    Typography.h4,
    styleBase,
    {
      textAlign: props.textAlign,
      color: props.color,
    },
    props.style,
  ]);
  return <Text style={style}>{props.children}</Text>;
};

H4.propTypes = generalPropTypes;
H4.defaultProps = generalDefaultProps;

export const Title = (props) => {
  const style = StyleSheet.flatten([
    Typography.title,
    styleBase,
    {
      textAlign: props.textAlign,
      color: props.color,
    },
    props.style,
  ]);
  return <Text style={style}>{props.children}</Text>;
};

Title.propTypes = generalPropTypes;
Title.defaultProps = generalDefaultProps;

export const Subtitle1 = (props) => {
  const style = StyleSheet.flatten([
    Typography.subtitle1,
    styleBase,
    {
      textAlign: props.textAlign,
      color: props.color,
    },
    props.style,
  ]);
  return <Text style={style}>{props.children}</Text>;
};

Subtitle1.propTypes = generalPropTypes;
Subtitle1.defaultProps = generalDefaultProps;

export const Subtitle2 = (props) => {
  const style = StyleSheet.flatten([
    Typography.subtitle2,
    styleBase,
    {
      textAlign: props.textAlign,
      color: props.color,
    },
    props.style,
  ]);
  return <Text style={style}>{props.children}</Text>;
};

Subtitle2.propTypes = generalPropTypes;
Subtitle2.defaultProps = generalDefaultProps;

export const Body1 = (props) => {
  const style = StyleSheet.flatten([
    Typography.body1,
    styleBase,
    {
      textAlign: props.textAlign,
      color: props.color,
    },
    props.style,
  ]);
  return <Text numberOfLines={props.numberOfLines} style={style}>{props.children}</Text>;
};

Body1.propTypes = generalPropTypes;
Body1.defaultProps = generalDefaultProps;

export const Body2 = (props) => {
  const style = StyleSheet.flatten([
    Typography.body2,
    styleBase,
    {
      textAlign: props.textAlign,
      color: props.color,
    },
    props.style,
  ]);
  return <Text style={style}>{props.children}</Text>;
};

Body2.propTypes = generalPropTypes;
Body2.defaultProps = generalDefaultProps;


export const Button = (props) => {
  const style = StyleSheet.flatten([
    Typography.button,
    styleBase,
    {
      textAlign: props.textAlign,
      color: props.color,
    },
    props.style,
  ]);
  return <Text style={style}>{props.children}</Text>;
};

Button.propTypes = generalPropTypes;
Button.defaultProps = generalDefaultProps;


export const Caption1 = (props) => {
  const style = StyleSheet.flatten([
    Typography.caption1,
    styleBase,
    {
      textAlign: props.textAlign,
      color: props.color,
    },
    props.style,
  ]);
  return <Text style={style}>{props.children}</Text>;
};

Caption1.propTypes = generalPropTypes;
Caption1.defaultProps = generalDefaultProps;


export const Caption2 = (props) => {
  const style = StyleSheet.flatten([
    Typography.caption2,
    styleBase,
    {
      textAlign: props.textAlign,
      color: props.color,
    },
    props.style,
  ]);
  return <Text style={style}>{props.children}</Text>;
};

Caption2.propTypes = generalPropTypes;
Caption2.defaultProps = generalDefaultProps;

export const FormLabel = (props) => {
  const style = StyleSheet.flatten([
    Typography.formLabel,
    styleBase,
    {
      textAlign: props.textAlign,
      color: props.color,
    },
    props.style,
  ]);
  return <Text style={style}>{props.children}</Text>;
};

FormLabel.propTypes = generalPropTypes;
FormLabel.defaultProps = generalDefaultProps;
