import React from 'react';
import { View } from 'react-native';
import { inject, observer } from 'mobx-react';
import withDimensions from '@nextstep/app/lib/withDimensions';
import { Body1, Body2 } from '@nextstep/app/components/Text';
import ViewWrapper from '@nextstep/app/components/ViewWrapper';
import { Column, Row } from '@nextstep/app/lib/ResponsiveLayout';
import { Images } from '@nextstep/app/Themes';
import MentorHeader from '@nextstep/app/components/MentorHeader';
import { ButtonFilled, ButtonText } from '@nextstep/app/components/Button';
import Divider from '@nextstep/app/components/Divider';
import Util from '@nextstep/app/services/Util';
import AdaptiveContainerStyles from '@nextstep/app/containers/styles/AdaptiveContainerStyles';
import { currentPalette } from '@nextstep/app/Config/AppConfig';

const EMAIL = 'program_enrollment@nextstep.com';
const EMAIL_SUBJECT = 'NextStep Program Eligibility Question';

export function DisEnrolledAccountScreen(props) {
  const { rootStore: { sessionStore }, dimensions } = props;

  const Styles = AdaptiveContainerStyles(dimensions.deviceType);

  return (
    <ViewWrapper
      smSize={12}
      mdSize={7}
      lgSize={6}
      topNavBarColor={currentPalette.background.purple}
    >

      <MentorHeader
        text={'Disenrolled from the NextStep Program'}
        mentorContainerStyle={{ backgroundColor: currentPalette.background.purple }}
        customMentorImage={Images.mentor.extra.agreement}
        customMentorImageStyle={{ width: 100, height: 198 }}
        mentorStyle="cell"
      />

      <Row style={{ justifyContent: 'center' }}>
        <Column smSize={11} mdSize={10} lgSize={10} style={Styles.textContainer}>

          <Body2>
            You have been disenrolled from the NextStep Program.
          </Body2>

          <View style={{ height: 16 }} />

          <Body1>
            Have questions? Contact us at
            {' '}
            <ButtonText
              onPress={() => Util.openURL(
                `mailto:${EMAIL}?subject=${EMAIL_SUBJECT}`,
                '_self',
              )}
            >
              {EMAIL}
            </ButtonText>
          </Body1>

        </Column>

        <Divider gutter={30} />

        <Column smSize={11} mdSize={7} lgSize={7} style={Styles.textContainer}>

          <ButtonFilled width={'100%'} onPress={() => sessionStore.logout()}>
            Sign Out
          </ButtonFilled>

          <View style={{ height: 34 }} />

        </Column>
      </Row>
    </ViewWrapper>
  );
}

export default withDimensions(inject('rootStore')(observer(DisEnrolledAccountScreen)));
