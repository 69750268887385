import { Colors, Palette } from '@nextstep/app/Themes';
import { DeviceType } from '@nextstep/app/lib/withDimensions';
import { ResponsiveStyleSheet } from '@nextstep/app/lib/ResponsiveLayout';

const currentPalette = Palette.light;

export default ResponsiveStyleSheet.create({
  container: {
    backgroundColor: currentPalette.background.main,
    paddingHorizontal: 32,

    [DeviceType.Mobile]: { paddingHorizontal: 10 },
  },

  navbarProfileImageStyles: {
    width: 32,
    height: 32,
    borderRadius: 32 / 2,
    overflow: 'hidden',
  },

  profileButtonStyle: {
    flexDirection: 'row',
    alignItems: 'center',
  },

  section: {
    backgroundColor: Colors.white,
    borderRadius: 8,
    shadowColor: 'rgb(43, 35, 58)',
    shadowOpacity: 0.12,
    shadowRadius: 2,
    shadowOffset: {
      height: 1,
      width: 0.5,
    },
    width: '100%',
    flex: 1,
  },

  sectionContent: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 20,
  },

  myEnrollmentImageStyle: {
    width: 124,
    height: 124,
  },

  myProgressSection: {
    position: 'absolute',
    width: 190,
    height: 190,
    justifyContent: 'center',
  },

  skillsCompleted: {},

  emptySkills: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    minHeight: 300,
  },

  emptySkillsImage: {
    width: '100%',
    height: '50%',
    resizeMode: 'contain',
    marginBottom: 15,

    [DeviceType.Mobile]: { height: 200 },
  },

  footer: {
    marginTop: 30,
    marginBottom: 10,
  },

  footerText: { color: Colors.lockedGray },
});
