import { StyleSheet } from 'react-native';
import omit from 'lodash/omit';
import { DeviceType } from '@nextstep/app/lib/withDimensions';

export default {
  create(styles) {
    const sheets = [];
    return (deviceType) => {
      if (!sheets[deviceType]) {
        const sheet = {};
        Object.keys(styles)
          .forEach((key) => {
            sheet[key] = StyleSheet.flatten([omit(styles[key], Object.values(DeviceType)), styles[key][deviceType]]);
          });
        sheets[deviceType] = StyleSheet.flatten(sheet);
      }

      return sheets[deviceType];
    };
  },
};
