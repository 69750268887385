import React from 'react';
import { Image, ScrollView, View } from 'react-native';
import { inject, observer } from 'mobx-react';
import withDimensions from '@nextstep/app/lib/withDimensions';
import { Body1, Title } from '@nextstep/app/components/Text';
import Divider from '@nextstep/app/components/Divider';
import { Images } from '@nextstep/app/Themes';
import { Column, Row } from '@nextstep/app/lib/ResponsiveLayout';
import SkillBadge from '@nextstep/app/components/SkillBadge';
import HomeScreenStyles from '@nextstep/app/containers/styles/HomeScreenStyles';

const CourseSkillBadges = (props) => {
  const { rootStore: { sessionStore, progressStore }, dimensions, navigation } = props;
  const { learner } = sessionStore;
  const { completedSkills } = progressStore;
  const Styles = HomeScreenStyles(dimensions.deviceType);

  return (
    <View style={[Styles.section, { flex: 1 }]}>

      <View style={{ height: 26 }} />

      <Title style={{ paddingHorizontal: 30 }}>
        MY DIGITAL SKILL BADGES
      </Title>

      <View style={{ height: 15 }} />

      <Divider />

      {learner && completedSkills.length > 0 ? (
        <ScrollView style={{ flex: 1 }}>
          <Row style={{ paddingVertical: 16, justifyContent: 'flex-start' }}>
            {completedSkills.slice().reverse().map((skill, index) => (
              <Column key={index} smSize={6} mdSize={3} lgSize={2} style={{ alignItems: 'center' }}>
                <SkillBadge skill={skill} navigation={navigation} />
              </Column>
            ))}
          </Row>
        </ScrollView>
      ) : (
        <View style={Styles.emptySkills}>
          <Image style={Styles.emptySkillsImage} source={Images.login.slide4} />
          <Body1>
            Once you complete skills they will appear here for you to review!
          </Body1>
        </View>
      )}
    </View>
  );
};

export default withDimensions(inject('rootStore')(observer(CourseSkillBadges)));
