import NavigationService from '@nextstep/app/services/NavigationService';
import SkillChildNavigator from './SkillChildNavigator';

export class SkillsetChildNavigator extends SkillChildNavigator {
  goToNextSkill = async () => {
    const { navigation, rootStore: { progressStore, contentStore } } = this.props;
    const { skillsetProgress } = progressStore;
    const { skillset, skill } = contentStore;

    if (skillsetProgress.isCompleted) {
      navigation.replace('OutcomeScreen');
      return;
    }

    const currentSkillIndex = skillset.skills.indexOf(skill);
    const nextSkill = skillset.skills[currentSkillIndex + 1];

    if (nextSkill) {
      await contentStore.setSkill(nextSkill.key);
      await progressStore.setSkillProgress(nextSkill.id);

      this.goToNextSkillChild();
    } else if (skillsetProgress.linearAssessmentProgress
      && !skillsetProgress.linearAssessmentProgress.isCompleted
    ) {
      NavigationService.navigateToNode(skillset.linearAssessment);
    } else navigation.navigate('App', { screen: 'Learn' });
  }
}

export default SkillsetChildNavigator;
