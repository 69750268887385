
import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  mySkillBadgesContainer: {
    marginVertical: 16,
    maxWidth: 148,
  },

  mySkillBadgeBackground: {
    width: 96,
    height: 96,
    borderRadius: 50,
    alignItems: 'center',
    justifyContent: 'center',
  },

  mySkillBadge: {
    width: 80,
    height: 80,
    resizeMode: 'cover',
    borderRadius: 50,
  },
});
