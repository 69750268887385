/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { View } from 'react-native';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import hexToRgba from 'hex-to-rgba';
import { Images } from '@nextstep/app/Themes';
import Util from '@nextstep/app/services/Util';
import withDimensions from '@nextstep/app/lib/withDimensions';
import { Body1, Body2 } from './Text';
import IconCard from './IconCard';

const TimeLeftCard = (props) => {
  const { skillsetProgress } = props;
  const skillset = skillsetProgress.getContent();

  const { remainingRequiredTime } = skillsetProgress;

  if (remainingRequiredTime > 0) {
    return (
      <>
        <IconCard
          backGroundColor={hexToRgba(skillset.colorTheme, 0.2)}
          description={(
            <>
              <Body1 textAlign="left">
                You are required to spend a minimum time of
                {` ${Util.timeFormatted(skillset.requiredMinimumTime, false, 'textLong')}:`}
              </Body1>

              <View style={{ height: 9 }} />

              <Body2 textAlign="left">
                {`Time Remaining - ${Util.timeFormatted(remainingRequiredTime, true, 'timer')}`}
              </Body2>

            </>
          )}
          showIconBorder={false}
          enableColor={skillset.colorTheme}
          enableIcon={Images.icons[skillset.colorType].timer}
        />
        <View style={{ height: 14 }} />
      </>
    );
  } return (<></>);
};

TimeLeftCard.propTypes = { skillsetProgress: PropTypes.object.isRequired };

export default withDimensions(inject('rootStore')(observer(TimeLeftCard)));
