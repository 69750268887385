import ShimmerPlaceholder from 'react-native-shimmer-placeholder';
import LinearGradient from 'react-native-linear-gradient';
import React from 'react';
import { View } from 'react-native';

const shimmerColors = ['#E1E9EE', '#E1E9EE', 'rgba(255,255,255,0.25)'];

const styleBase = {
  width: '100%',
  borderRadius: 8,
  marginBottom: 8,
};

const attributeBase = {
  LinearGradient,
  shimmerColors,
  duration: 1500,
};

const Question = () => (
  <>
    <ShimmerPlaceholder
      {...attributeBase}
      style={{
        ...styleBase,
        height: 20,
        marginBottom: 8,
      }}
    />
    <ShimmerPlaceholder
      {...attributeBase}
      style={{
        ...styleBase,
        height: 20,
        width: '60%',
      }}
    />
  </>
);

const Reset = () => (
  <ShimmerPlaceholder
    {...attributeBase}
    style={{
      ...styleBase,
      height: 20,
      width: 60,
    }}
  />
);

export const Answer = () => (
  <ShimmerPlaceholder
    {...attributeBase}
    style={{
      ...styleBase,
      height: 60,
      marginBottom: 8,
    }}
  />
);

export const Space = ({ size }) => (
  <View style={{ height: size || 8 }} />
);

export const QuestionPlaceholder = () => (
  <>
    <Reset />
    <Question />
    <Space />
    <Answer />
    <Answer />
    <Answer />
    <Answer />
  </>
);
