import { types } from 'mobx-state-tree';

const BasicLearnerData = types.model('BasicLearnerData', {
  legalFirstName: types.maybe(types.string),
  legalLastName: types.maybe(types.string),
  preferredFirstName: types.maybe(types.string),
  preferredLastName: types.maybe(types.string),
  certificationState: types.maybe(types.string),
  homeStreet1: types.maybe(types.string),
  homeStreet2: types.maybe(types.string),
  homeCity: types.maybe(types.string),
  homeState: types.maybe(types.string),
  homeZipCode: types.maybe(types.string),
  emailAddress: types.maybe(types.string),
  phoneNumber: types.maybe(types.string),
  eulaAccepted: types.maybe(types.boolean),
});

export default BasicLearnerData;
