import React from 'react';
import { Platform, View, ScrollView, Linking, Image } from 'react-native';
import { inject, observer } from 'mobx-react';
import { ButtonFilled, ButtonOutline, ButtonText } from '@nextstep/app/components/Button';
import withDimensions from '@nextstep/app/lib/withDimensions';
import ViewWrapper from '@nextstep/app/components/ViewWrapper';
import { Column, Row } from '@nextstep/app/lib/ResponsiveLayout';
import Divider from '@nextstep/app/components/Divider';
import { Palette, Images } from '@nextstep/app/Themes';
import { Body2, Title } from '@nextstep/app/components/Text';
import Util from '@nextstep/app/services/Util';

const currentPalette = Palette.light;

const DefaultStyles = {
  mySkillBadgeBackground: {
    width: 156,
    height: 156,
    borderRadius: 100,
    alignItems: 'center',
    justifyContent: 'center',
  },

  mySkillBadge: {
    width: 156,
    height: 156,
    resizeMode: 'cover',
    borderRadius: 100,
    overflow: 'hidden',
    alignItems: 'center',
  },

  mySkillBadgeMentor: {
    top: 10,
    resizeMode: 'contain',
    height: 125,
    width: 65,
  },

  warningText: {
    borderRadius: 8,
    padding: 10,
  },
};


const VaccineBenefits = (props) => {
  const { rootStore: { sessionStore } } = props;
  const { navigation } = props;
  const { learner } = sessionStore;

  if (!learner?.studentId) {
    navigation.navigate('LaunchScreen');
  }

  return (
    <ViewWrapper
      smSize={12}
      mdSize={7}
      lgSize={6}
    >
      <View style={{ height: 40 }} />

      <Title color={currentPalette.text.main}>
        PROOF OF COVID-19 VACCINE
      </Title>

      <View style={{ height: 26 }} />

      <Divider />

      <ScrollView>
        <View
          style={[
            { justifyContent: 'center' },
            Platform.OS !== 'web' && { height: '100%' },
          ]}
        >

          <View style={{ height: 36 }} />

          <Row style={{ flex: 1 }}>
            <Column smSize={10} mdSize={10} lgSize={6}>
              <View style={{ alignItems: 'center' }}>
                <View
                  style={[
                    DefaultStyles.mySkillBadgeBackground,
                    { backgroundColor: currentPalette.basic.lightFire },
                  ]}
                >
                  <View
                    style={[
                      DefaultStyles.mySkillBadge,
                      { backgroundColor: currentPalette.basic.lightFire },
                    ]}
                  >
                    <Image
                      style={DefaultStyles.mySkillBadgeMentor}
                      source={Images.icons.redWarning}
                    />
                  </View>
                </View>
              </View>

              <View style={{ height: 40 }} />

              <Body2
                style={[
                  DefaultStyles.warningText,
                  { backgroundColor: currentPalette.basic.lightFire },
                ]}
                color={currentPalette.basic.brandRed}
              >
                You must submit your COVID-19 vaccine proof to continue.
              </Body2>

              <View style={{ height: 40 }} />

              <ButtonFilled
                width="100%"
                style={{ alignSelf: 'center' }}
                onPress={() => Util.openURL(`https://nextstepcareers.typeform.com/to/lN9zlwjh#student_id=${learner.studentId}`, '_self')}
              >
                Upload COVID-19 Vaccine Proof
              </ButtonFilled>

              <View style={{ height: 12 }} />

              <ButtonOutline
                width="100%"
                onPress={() => {
                  Linking.openURL(
                    'https://www.vaccines.gov/search/',
                  );
                }}
              >
                Find COVID-19 Vaccine Location
              </ButtonOutline>

              <View style={{ height: 32 }} />

              <ButtonText onPress={() => sessionStore.logout()}>
                Sign Out
              </ButtonText>
            </Column>
          </Row>

          <View style={{ height: 36 }} />
        </View>
      </ScrollView>
    </ViewWrapper>
  );
};

export default withDimensions(inject('rootStore')(observer(VaccineBenefits)));
