const PromiseRejectionHandler = (notifier) => {
  // eslint-disable-next-line import/no-extraneous-dependencies
  const tracking = require('promise/setimmediate/rejection-tracking');
  tracking.enable({
    allRejections: true,
    onUnhandled(id, error) {
      // Discriminate to only display rejections caused by API calls
      if (error && error.problem) {
        notifier(error, false);
      }

      return error;
    },
  });
};

export default PromiseRejectionHandler;
