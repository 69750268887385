import React from 'react';

const videoOptions = {
  fluid: true,
  controlBar: {
    pictureInPictureToggle: false,
    fullscreenToggle: false,
  },
};
export default class VideoPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.videoRef = React.createRef();
    this.state = { vjsLoaded: false };
  }

  async componentDidMount() {
    const { default: videojs } = await import('video.js');
    this.setState({ vjsLoaded: true }, () => {
      this.player = videojs(this.videoRef.current, videoOptions);
      this.toggleForwardSeek();
      this.toggleProgressHandler();
    });
  }

  componentDidUpdate(prevProps) {
    const { disableForwardSeek, onProgress } = this.props;
    if (prevProps.disableForwardSeek !== disableForwardSeek) this.toggleForwardSeek();
    if (prevProps.onProgress !== onProgress) this.toggleProgressHandler();
  }

  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  }

  toggleForwardSeek() {
    this.player.one('ready', () => {
      const { disableForwardSeek } = this.props;

      if (disableForwardSeek) {
        let previousTime = this.player.currentTime();
        let currentTime = this.player.currentTime();
        let position = this.player.currentTime();

        this.fsTimeUpdateHandler = () => {
          previousTime = currentTime;
          currentTime = Math.floor(this.player.currentTime());

          if (previousTime < currentTime) {
            position = previousTime;
            previousTime = currentTime;
          }
        };

        this.fsSeekHandler = () => {
          if (position < this.player.currentTime()) {
            this.player.currentTime(position);
          }
        };

        this.player.on('timeupdate', this.fsTimeUpdateHandler);
        this.player.on('seeking', this.fsSeekHandler);
        this.player.on('seeked', this.fsSeekHandler);
      } else {
        if (this.fsTimeUpdateHandler) this.player.off('timeupdate', this.fsTimeUpdateHandler);
        if (this.fsSeekHandler) this.player.off('seeking', this.fsSeekHandler);
        if (this.fsSeekHandler) this.player.off('seeked', this.fsSeekHandler);

        this.fsTimeUpdateHandler = null;
        this.fsSeekHandler = null;
      }
    });
  }

  toggleProgressHandler() {
    this.player.one('ready', () => {
      const { onProgress } = this.props;

      if (this.progressHandler) this.player.off('timeupdate', this.progressHandler);
      this.progressHandler = null;

      if (onProgress) {
        this.progressHandler = () => {
          onProgress({
            currentTime: this.player.currentTime(),
            seekableDuration: this.player.duration(),
          });
        };

        this.player.on('timeupdate', this.progressHandler);
      }
    });
  }

  seek(time) {
    this.player.currentTime(time);
  }

  seekTo(time) {
    this.player.currentTime(time);
  }

  play() {
    this.player.play();
  }

  pause() {
    this.player.pause();
  }

  render() {
    const { url, autoPlay } = this.props;
    const { vjsLoaded } = this.state;
    if (!vjsLoaded) return null;

    let parsedUrl;
    let type;
    // eslint-disable-next-line no-empty
    try { parsedUrl = new URL(url); } catch (_) {}
    if (parsedUrl?.protocol === 'blob:') type = 'video/webm'; // video data not yet uploaded to server
    else if (!/\.[0-9A-Z]+$/i.test(parsedUrl?.pathname)) type = 'video/mp4'; // no extension, assume it's mp4
    // else let videojs guess video type by extension

    return (
      // eslint-disable-next-line jsx-a11y/media-has-caption
      <div data-vjs-player>
        <video
          ref={this.videoRef}
          className="video-js vjs-big-play-centered"
          controls
          preload="auto"
          autoPlay={autoPlay}
        >
          { url && (
            <>
              <source src={url} type={type} />
              <p className="vjs-no-js">
                To view this video please enable JavaScript, and consider upgrading to a
                web browser that
                <a
                  href="https://videojs.com/html5-video-support/"
                  rel="noreferrer"
                  target="_blank"
                >
                  supports HTML5 video
                </a
                >
              </p>
            </>
          )}
        </video>
      </div>
    );
  }
}
