import * as React from 'react';
import { StyleSheet, View } from 'react-native';
import { Column, Row } from '@nextstep/app/lib/ResponsiveLayout';
import Sidebar from './Sidebar';

const styles = StyleSheet.create({
  headerContainer: {
    width: '100%',
    backgroundColor: '#fff',
    shadowColor: '#000',
    shadowOpacity: 0.4,
    shadowRadius: 2,
    zIndex: 100,
  },
});

export default function SlideView({ state, navigation, descriptors, header: Header, style, ...rest }) {
  const isMenuOpen = !state.menuHidden;
  return (
    <View style={style}>
      <View style={styles.headerContainer}>
        {Header && <Header descriptors={descriptors} navigation={navigation} state={state} /> }
      </View>
      <Row style={{ flex: 1 }}>
        { isMenuOpen && (
          <Column size={2}>
            <Sidebar
              state={state}
              navigation={navigation}
              descriptors={descriptors}
              {...rest}
            />
          </Column>
        )}
        <Column size={isMenuOpen ? 10 : 12}>
          {descriptors[state.routes[state.index].key].render()}
        </Column>
      </Row>
    </View>
  );
}

