import { types } from 'mobx-state-tree';
import { ContentNodeTypes } from '@nextstep/app/Config/Constants';
import ContentNode from './ContentNode';

const DemoVideoModel = types.model({
  id: types.identifierNumber,
  key: types.string,
  title: types.maybe(types.string),
  url: types.maybe(types.string),
  version: types.maybe(types.string),
});

const DemoVideo = types.compose(DemoVideoModel, ContentNode)
  .named(ContentNodeTypes.demoVideo);

export default DemoVideo;
