import { Palette } from '@nextstep/app/Themes';
import { ResponsiveStyleSheet } from '@nextstep/app/lib/ResponsiveLayout';
import { DeviceType } from '@nextstep/app/lib/withDimensions';

const currentPalette = Palette.light;

export default ResponsiveStyleSheet.create({
  container: {
    marginBottom: 30,
    borderRadius: 8,
    borderColor: currentPalette.basic.border,
    borderWidth: 2,
    backgroundColor: currentPalette.background.white,
    width: '100%',

    [DeviceType.Mobile]: { marginBottom: 20 },
  },

  header: {
    flexDirection: 'column',
    alignItems: 'center',
    marginHorizontal: 10,
    marginVertical: 15,
    [DeviceType.Desktop]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },

  headerText: {
    marginLeft: 30,
    flex: 1,

    [DeviceType.Mobile]: { marginLeft: 0 },
    [DeviceType.Tablet]: { marginLeft: 0 },
  },

  headerTextTitle: {
    marginBottom: 10,
    alignSelf: 'center',

    [DeviceType.Tablet]: { marginTop: 20 },
    [DeviceType.Desktop]: { marginTop: 20 },
  },

  stepsBody: {
    marginLeft: 40,

    [DeviceType.Mobile]: { marginLeft: 15 },
  },

  stepBody: {
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: 15,
  },

  chevronImage: {
    width: 20,
    height: 20,
    resizeMode: 'cover',
    marginRight: 15,
  },

  stepRowContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  stepRowBody: {
    flex: 1,
    textAlign: 'left',
  },

  stepBodyImage: {
    width: 40,
    height: 40,
    resizeMode: 'cover',
    borderRadius: 40 / 2,
    marginRight: 15,
  },

  badge: {
    paddingVertical: 8,
    paddingHorizontal: 15,
    borderRadius: 50,
  },
});
