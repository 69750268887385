/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import { Platform, View } from 'react-native';
import { Column, Row } from '@nextstep/app/lib/ResponsiveLayout';
import Palette from '@nextstep/app/Themes/Palette';
import withDimensions from '@nextstep/app/lib/withDimensions';
import { Caption1, Subtitle1 } from '@nextstep/app/components/Text';
import StyledHTMLView from '@nextstep/app/components/StyledHTMLView';

const currentPalette = Palette.light;

const SuccessCriteria = (props) => (
  <View style={[{ justifyContent: 'flex-start' }, Platform.OS !== 'web' && { height: '100%' }]}>

    {Platform.OS === 'web' && <View style={{ height: 36 }} />}

    <Row>
      <Column smSize={10}>

        <Caption1 textAlign="left" color={props.color}>
          {props.subtitle}
        </Caption1>

        <View style={{ height: 8 }} />

        <Subtitle1 textAlign="left">
          {props.title}
        </Subtitle1>

        <View style={{ height: 10 }} />

        <StyledHTMLView
          overrideHTMLStyles={{
            a: { color: props.color },
            p: { textAlign: 'left' },
            h4: { textAlign: 'left' },
          }}
          value={`<div>${props.content}</div>`}
          onTermPress={props.onTermPress}
          onSkillPress={() => {
          }}
        />

        <View style={{ height: 36 }} />

      </Column>
    </Row>

  </View>
);


SuccessCriteria.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  content: PropTypes.string,
  color: PropTypes.string,
  onTermPress: PropTypes.func,
};

SuccessCriteria.defaultProps = {
  title: 'Title',
  subtitle: 'Subtitle',
  content: '',
  color: currentPalette.primary.main,
  onTermPress: () => {},
};

export default withDimensions(SuccessCriteria);
