import React from 'react';
import { Text, View } from 'react-native';
import { Body2 } from '@nextstep/app/components/Text';
import InputSelect from '@nextstep/app/components/InputSelect';
import { currentPalette } from '@nextstep/app/Config/AppConfig';
import { inject } from 'mobx-react';
import { InputErrorMessage } from '@nextstep/app/components/TextInput';

class BooleanQuestion extends React.Component {
  constructor(props) {
    super(props);
    this.state = { questionState: false };
  }

  updateAnswer = () => {
    const { rootStore: { sessionStore } } = this.props;
    const { questionState } = this.state;
    const { question } = this.props;

    sessionStore.addQuestionnaireAnswer({
      questionId: question.id,
      answerChoice: questionState,
    });
  }

  toggleCheckBox = async (state) => {
    await this.setState({ questionState: state });
    this.updateAnswer();
  }

  render() {
    const { questionState } = this.state;
    const { question, showError, errorMessage } = this.props;

    return (
      <View style={{}}>
        <Text>
          <Body2 textAlign="left">{question.text}</Body2>
          {question.required && <Body2 color={currentPalette.text.error}>*</Body2>}
        </Text>

        <View style={{ height: 12 }} />

        <InputSelect
          value={questionState}
          onValueChange={(state) => this.toggleCheckBox(state)}
          showBorder
        >
          {question.answerChoice}
        </InputSelect>

        {showError
          ? <InputErrorMessage>{errorMessage}</InputErrorMessage>
          : <View style={{ height: 32 }} />
        }
      </View>
    );
  }
}

export default inject('rootStore')(BooleanQuestion);
