import React, { Component } from 'react';
import { ScrollView, TextInput, View } from 'react-native';
import { Palette } from '@nextstep/app/Themes';
import MarkdownRender from './MarkdownRender';
import { H2, Subtitle2 } from './Text';

const currentPalette = Palette.light;

const DefaultStyle = {
  markdown: {
    width: '50%',
    alignSelf: 'flex-end',
    height: '100vh',
    backgroundColor: currentPalette.background.white,
  },

  textInput: {
    width: '50%',
    height: '100vh',
    alignSelf: 'flex-start',
  },

  subtitle: {
    margin: 10,
    borderBottomColor: currentPalette.basic.gray,
    borderBottomWidth: 1,
  },
};

export class MarkdownPlayground extends Component {
  constructor(props) {
    super(props);
    this.state = { data: '' };
  }

  render() {
    const { data } = this.state;

    return (
      <View style={{ backgroundColor: currentPalette.background.main }}>

        <H2 color={currentPalette.primary.main} style={{ margin: 10 }}>
          Markdown Playground
        </H2>

        <View style={{ flexDirection: 'row' }}>

          <View style={DefaultStyle.textInput}>

            <Subtitle2 style={DefaultStyle.subtitle}>
              Text
            </Subtitle2>

            <TextInput
              multiline
              style={{ height: '100%', padding: 20 }}
              value={data}
              onChangeText={(text) => this.setState({ data: text })}
            />

          </View>

          <ScrollView style={DefaultStyle.markdown}>
            <Subtitle2 style={DefaultStyle.subtitle}>
              Preview
            </Subtitle2>
            <MarkdownRender data={data} />
            <View style={{ height: 100 }} />
          </ScrollView>

        </View>

      </View>
    );
  }
}

export default MarkdownPlayground;

