import { types } from 'mobx-state-tree';
import WithStatus from './WithStatus';
import WithDuration from './WithDuration';
import ProgressNode from './ProgressNode';
import IntroVideo from './IntroVideo';

const IntroVideoProgressModel = types.model({
  id: types.identifierNumber,
  introId: types.number,
  courseKey: types.maybe(types.string),
  content: types.maybe(types.safeReference(IntroVideo)),
});

const IntroVideoProgress = types.compose(IntroVideoProgressModel, ProgressNode, WithStatus, WithDuration).named('IntroVideoProgress');

export default IntroVideoProgress;
