import React, { Fragment } from 'react';
import { Body2 } from '@nextstep/app/components/Text';

const FillInBlankAnswer = ({ chosenAnswers, skillset, index }) => (
  <Fragment>
    {' '}
    <Body2 textAlign="left" color={skillset.colorTheme} style={{ backgroundColor: skillset.colorTheme20 }}>
      {(chosenAnswers && chosenAnswers[index]) ? chosenAnswers[index].answerChoice : '\xa0'.repeat(20)}
    </Body2>
    {' '}
  </Fragment>
);
export default FillInBlankAnswer;
