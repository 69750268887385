import { types } from 'mobx-state-tree';
import WithColorTheme from './WithColorTheme';

const CourseModel = types.model('Course', {
  id: types.optional(types.identifierNumber, 0),
  key: types.optional(types.string, ''),
  body1: types.optional(types.string, ''),
  body2: types.optional(types.string, ''),
  body3: types.optional(types.string, ''),
  completionTime: types.optional(types.integer, 0),
  cost: types.optional(types.integer, 0),
  courseOverview: types.optional(types.string, ''),
  courseTitle: types.optional(types.string, ''),
  currentlyOffered: types.optional(types.boolean, false),
  duration: types.optional(types.integer, 0),
  description: types.optional(types.string, ''),
  hours: types.optional(types.integer, 0),
  image1: types.optional(types.string, ''),
  image2: types.optional(types.string, ''),
  image3: types.optional(types.string, ''),
  miles: types.optional(types.integer, 0),
  s3BannerImageRef: types.optional(types.string, ''),
  s3IconImageRef: types.optional(types.string, ''),
  summary: types.optional(types.string, ''),
  title1: types.optional(types.string, ''),
  title2: types.optional(types.string, ''),
  title3: types.optional(types.string, ''),
  title: types.optional(types.string, ''),
  versionedAt: types.optional(types.string, ''),
  wage: types.optional(types.number, 0),
});

const Course = types.compose(CourseModel, WithColorTheme).named('Course');

export default Course;
