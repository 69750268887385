import { getSnapshot, flow, applySnapshot } from 'mobx-state-tree';
import AsyncStorage from '@react-native-community/async-storage';
import DeviceInfo from 'react-native-device-info';
import { PersistenceKeys } from '@nextstep/app/Config/Constants';
import Config from '@nextstep/app/Config/DebugConfig';

const RootStoreActions = self => ({
  afterCreate() {
    self.checkSnapshotVersion();
  },

  async saveSnapshot() {
    const transformedSnapshot = getSnapshot(self);
    const json = JSON.stringify(transformedSnapshot);
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line no-console
    if (Config.printSnapshots) console.tron.log('[Root Snapshot]', transformedSnapshot);
    await AsyncStorage.setItem(PersistenceKeys.app, json);
  },

  async restoreSnapshot() {
    const appState = await AsyncStorage.getItem(PersistenceKeys.app);

    if (!appState) return;

    const appStateJSON = JSON.parse(appState);

    if (!appStateJSON.sessionStore || !appStateJSON.sessionStore.learner) {
      delete appStateJSON.progressStore;
      delete appStateJSON.contentStore;
      return;
    }

    applySnapshot(self, appStateJSON);
  },

  async deleteSnapshot() {
    return AsyncStorage.multiRemove(Object.values(PersistenceKeys));
  },

  resetStore() {
    applySnapshot(self, { });
  },

  async saveSnapshotVersion() {
    const releaseVersion = DeviceInfo.getVersion();
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line no-console
    console.log('saving snapshot version', releaseVersion);
    if (!AsyncStorage) return;
    await AsyncStorage.setItem(PersistenceKeys.release_version, releaseVersion);
  },

  getSnapshotVersion: flow(function* () {
    if (self.snapshotVersion) return self.snapshotVersion;
    self.snapshotVersion = yield AsyncStorage.getItem(PersistenceKeys.release_version);
    return self.snapshotVersion;
  }),

  async snapshotVersionDidChange() {
    const releaseVersion = DeviceInfo.getVersion();
    const snapshotVersion = await this.getSnapshotVersion();
    return snapshotVersion && (releaseVersion.toString() !== snapshotVersion);
  },

  checkSnapshotVersion: flow(function* () {
    const versionChange = yield self.snapshotVersionDidChange();
    if (!versionChange) return;

    self.snapshotUpdating = true;
    try {
      yield self.loadContent();
    } catch (e) {
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line no-console
      console.log('attempting to load content without a logged in user');
    } finally {
      self.snapshotUpdating = false;
    }
  }),

  loadContent: flow(function* () {
    yield self.progressStore.load();
    if (self.progressStore.enrolled) {
      self.progressStore.expandedSkillsetKey = undefined;
      yield self.contentStore.load(self.progressStore.currentCourseKey);
    }
  }),
});
export default RootStoreActions;
