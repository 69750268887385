import { LogBox, Platform, Text } from 'react-native';
import DebugConfig from './DebugConfig';
import AppConfig from './AppConfig';

// Allow/disallow font-scaling in app
Text.allowFontScaling = AppConfig.allowTextFontScaling;

if (__DEV__ && Platform.OS !== 'web') {
  LogBox.ignoreAllLogs(!DebugConfig.logBox);
}
