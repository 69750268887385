import { types } from 'mobx-state-tree';
import hexToRgba from 'hex-to-rgba';

const WithColorTheme = types.model({ colorTheme: types.optional(types.string, '') }).views(self => ({
  get colorTheme40() {
    return hexToRgba(self.colorTheme, 0.4);
  },
  get colorTheme20() {
    return hexToRgba(self.colorTheme, 0.2);
  },
}));

export default WithColorTheme;
