import { types } from 'mobx-state-tree';
import QuestionnaireQuestion from '@nextstep/app/models/QuestionnaireQuestion';

const Questionnaire = types.model('Questionnaire', {
  title: types.maybe(types.string),
  submissionText: types.maybe(types.string),
  successText: types.maybe(types.string),
  questions: types.maybe(types.array(QuestionnaireQuestion)),
});

export default Questionnaire;
