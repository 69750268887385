import React from 'react';
import { View, Platform, StatusBar } from 'react-native';
import { Icon } from 'react-native-elements';
import { inject, observer } from 'mobx-react';
import VideoPlayer from 'react-native-af-video-player';
import throttle from 'lodash/throttle';
import { Images, Palette } from '@nextstep/app/Themes';
import { afterInteractions } from '@nextstep/app/lib/afterInteractions';
import { Body1, Body2, H3 } from '@nextstep/app/components/Text';
import StudyMaterialsModal from '@nextstep/app/modals/StudyMaterialsModal';
import { FullViewSpinner } from '@nextstep/app/components/SharedUI';
import ScreenTimer from '@nextstep/app/components/ScreenTimer';
import Config from '@nextstep/app/Config/DebugConfig';
import { ButtonText } from '@nextstep/app/components/Button';
import withDimensions from '@nextstep/app/lib/withDimensions';
import ViewWrapper from '@nextstep/app/components/ViewWrapper';
import DebouncedTouchableOpacity from '@nextstep/app/components/DebouncedTouchableOpacity';
import VideoScreenStyles from '@nextstep/app/containers/styles/VideoScreenStyles';
import AdaptiveContainerStyles from '@nextstep/app/containers/styles/AdaptiveContainerStyles';
import { ContentNodeTypes, ProgressNodeTypes } from '@nextstep/app/Config/Constants';
import NavigationService from '@nextstep/app/services/NavigationService';
import SkillChildNavigator from './SkillChildNavigator';

const currentPalette = Palette.light;

class DemoVideosScreen extends SkillChildNavigator {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isVideoWatched: false,
      currentVideo: null,
    };

    this.onContinuePressed = this.onContinuePressed.bind(this);
  }

  async componentDidMount() {
    const { route, rootStore } = this.props;
    const { contentStore, progressStore } = rootStore;

    await afterInteractions();

    contentStore.clearCurrentExcept([
      ContentNodeTypes.course, ContentNodeTypes.skillset,
      ContentNodeTypes.skill, ContentNodeTypes.step]);
    progressStore.clearCurrentExcept([
      ProgressNodeTypes.courseProgress, ProgressNodeTypes.skillsetProgress,
      ProgressNodeTypes.skillProgress, ProgressNodeTypes.stepProgress]);

    const skillsetKey = route.params?.skillsetKey;
    const skillKey = route.params?.skillKey;
    const stepKey = route.params?.stepKey;

    await contentStore.setSkillset(skillsetKey);
    await contentStore.setSkill(skillKey);
    await contentStore.setStep(stepKey);
    await progressStore.setSkillsetProgress(contentStore.skillset.id);
    await progressStore.setSkillProgress(contentStore.skill.id);
    await progressStore.setStepProgress(contentStore.step.id);

    if (progressStore.stepProgress.isUnlocked) {
      await progressStore.startStep();
    }

    if (Platform.OS === 'ios') StatusBar.setBarStyle('dark-content', true);

    await this.setCurrentVideo();

    this.setState({ isLoading: false });
  }


  onUnreadChange({ count }) {
    const { navigation } = this.props;
    navigation.setParams({ unreadCount: count });
  }

  completeLearningObjectOnce(progress) {
    const { isVideoWatched } = this.state;
    const { currentTime, seekableDuration } = progress;
    const percent = currentTime && (currentTime / seekableDuration) * 100;
    const triggerPercentage = 95;

    if (!isVideoWatched && percent > triggerPercentage) {
      this.completeLearningObject();
    }
  }

  handleVideoProgress = throttle(progress => {
    this.completeLearningObjectOnce(progress);
  }, 1000);


  async completeLearningObject() {
    const { rootStore: { progressStore, contentStore } } = this.props;
    const { requiredMinimumTime } = contentStore.skillset;
    const { demoVideoProgress } = progressStore;

    await demoVideoProgress.complete();

    const hasTimeConstraint = requiredMinimumTime > 0;
    await progressStore.logTimer(hasTimeConstraint);

    this.setState({ isVideoWatched: true });
  }

  async onContinuePressed() {
    const { isVideoWatched } = this.state;

    if (!isVideoWatched) await this.completeLearningObject();

    const hasUncompletedVideo = await this.setCurrentVideo();

    if (hasUncompletedVideo) {
      this.videoPlayer.seek(0);
      this.videoPlayer.seekTo(0);
      this.videoPlayer.pause();

      this.setState({ isVideoWatched: false });
    } else {
      this.goToNextSkillChild();
    }
  }

  renderBottomBar() {
    const { rootStore: { contentStore, sessionStore }, dimensions } = this.props;
    const { isVideoWatched } = this.state;
    const { config } = sessionStore.learner;
    const { colorTheme } = contentStore.skillset;
    const Styles = VideoScreenStyles(dimensions.deviceType);
    const MainStyles = AdaptiveContainerStyles(dimensions.deviceType);

    const disabledInteraction = !isVideoWatched && !config.fastForwardEnabled;
    return (
      <View style={[MainStyles.textContainer, MainStyles.bottomBar, { backgroundColor: colorTheme, justifyContent: 'flex-end' }]}>

        <DebouncedTouchableOpacity
          disabled={disabledInteraction}
          onPress={() => { this.onContinuePressed(); }}
          style={Styles.iconButton}
        >

          <Body2 color={disabledInteraction ? currentPalette.basic.transparent : currentPalette.primary.contrastText}>
            Continue
          </Body2>

          <Icon
            name="keyboard-arrow-right"
            color={disabledInteraction ? currentPalette.basic.transparent : currentPalette.primary.contrastText}
            underlayColor="transparent"
          />
        </DebouncedTouchableOpacity>

        {Config.answerSolver && disabledInteraction && (
          <ButtonText
            color={currentPalette.basic.white}
            onPress={this.onContinuePressed}
          >
            SKIP
          </ButtonText>
        )}

      </View>
    );
  }

  async setCurrentVideo() {
    const { rootStore: { progressStore, contentStore } } = this.props;
    const { demoVideos } = contentStore.step;

    const demoVideoProgress = progressStore.setDemoVideoProgress();

    if (!demoVideoProgress) return false;

    if (demoVideoProgress.isUnlocked) {
      await demoVideoProgress.start();
    }

    const currentVideo = demoVideos.find(v => v.id === demoVideoProgress.demoVideoId);
    this.setState({ currentVideo, isVideoWatched: false });

    return true;
  }

  renderVideo() {
    const { rootStore: { sessionStore, contentStore }, dimensions } = this.props;
    const { isVideoWatched, currentVideo } = this.state;
    const { config } = sessionStore.learner;
    const { skillset, skill } = contentStore;

    const MainStyles = AdaptiveContainerStyles(dimensions.deviceType);

    return (
      <View>
        <View
          style={[
            MainStyles.textContainer,
            Platform.OS === 'web' && { paddingVertical: 20 },
          ]}
        >

          <View style={{ paddingBottom: 20 }}>
            <Body2 textAlign={'left'}>
              {skill.title}
            </Body2>
          </View>

        </View>

        <VideoPlayer
          url={currentVideo.url}
          onProgress={(progress) => this.handleVideoProgress(progress)}
          ref={(videoPlayer) => { this.videoPlayer = videoPlayer; }}
          ignoreSilentSwitch="ignore"
          inlineOnly
          logo="https://s3.amazonaws.com/nextstep.ui/course/images/home-active.png"
          placeholder={Images.other.videoPlaceholder}
          disableForwardSeek={!isVideoWatched && !config.fastForwardEnabled}
          autoPlay={config.videoAutoPlay}
          theme={{ progress: skillset.colorTheme }}
        />

      </View>
    );
  }

  renderHeader() {
    const { rootStore: { contentStore }, dimensions } = this.props;
    const { skillset } = contentStore;
    const Styles = VideoScreenStyles(dimensions.deviceType);
    const MainStyles = AdaptiveContainerStyles(dimensions.deviceType);

    return (
      <>
        <View style={[MainStyles.textContainer, Styles.skillSetInfoContainer, { backgroundColor: skillset.colorTheme }]}>

          <H3 textAlign={'left'} color={currentPalette.primary.contrastText}>
            {skillset.title}
          </H3>

          <Body1 textAlign={'left'} color={currentPalette.primary.contrastText}>
            Demo Video
          </Body1>

        </View>
      </>
    );
  }

  updateScreenTime = (increment) => {
    const { rootStore: { progressStore } } = this.props;
    const { currentVideo } = this.state;
    const { stepProgress: { demoVideoProgresses } } = progressStore;
    if (!progressStore.stepProgress || !demoVideoProgresses) return;
    const demoVideoProgress = demoVideoProgresses.find(video => video.demoVideoId === currentVideo.id);
    if (!demoVideoProgress) return;
    demoVideoProgress.incrementDuration(increment);
  }

  render() {
    const { rootStore: { progressStore, contentStore } } = this.props;
    const { currentVideo, isLoading } = this.state;
    const { skillset, skill, step } = contentStore;
    const { isStudyMaterialsModalVisible } = progressStore;

    if (!currentVideo || isLoading) return <FullViewSpinner color={skillset && skillset.colorTheme} showBackground />;

    return (
      <ViewWrapper
        smSize={12}
        mdSize={6}
        lgSize={6}
        navigationBar
        bottomBarColor={skillset.colorTheme}
        header={this.renderHeader()}
        footer={this.renderBottomBar()}
        backOverride={() => NavigationService.navigate('App', { screen: 'Learn' })}
      >

        {isStudyMaterialsModalVisible && (
          <StudyMaterialsModal
            skill={skill}
            step={step}
            colorTheme={skillset.colorTheme}
            colorType={skillset.colorType}
          />
        )}

        <View>

          {this.renderVideo()}

        </View>

        <ScreenTimer onUpdateTime={this.updateScreenTime} />

      </ViewWrapper>
    );
  }
}

export default withDimensions(inject('rootStore')(observer(DemoVideosScreen)));
