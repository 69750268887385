/* eslint-disable react/prop-types,react/destructuring-assignment */
import React from 'react';
import { ActivityIndicator, View } from 'react-native';
import PropTypes from 'prop-types';
import { Colors, Palette } from '@nextstep/app/Themes';
import { Body2 } from './Text';

const currentPalette = Palette.light;

// eslint-disable-next-line import/prefer-default-export
export const FullViewSpinner = (props) => {
  const spinnerStyle = {
    container: {
      flexDirection: 'column',
      flex: 5,
      justifyContent: 'center',
      backgroundColor: props.showBackground ? Colors.BG : 'transparent',
    },
    messageContainer: {
      maxWidth: 300,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    ...props.style,
  };

  return (
    <View style={spinnerStyle.container}>
      <ActivityIndicator size="large" color={props.color} />
      {props.message && (
        <>
          <View style={{ height: 16 }} />
          <View style={spinnerStyle.messageContainer}>
            <Body2 color={props.textColor}>
              {props.message}
            </Body2>
          </View>
        </>
      )}
    </View>
  );
};

FullViewSpinner.propTypes = {
  message: PropTypes.string,
  color: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  textColor: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  style: PropTypes.object,
};

FullViewSpinner.defaultProps = {
  message: undefined,
  color: currentPalette.primary.main,
  textColor: currentPalette.primary.main,
  style: {},
};
