import { StyleSheet } from 'react-native';
import { Colors } from '@nextstep/app/Themes/index';

export default StyleSheet.create({
  applicationView: { flex: 1 },
  safeArea: {
    flex: 1,
    backgroundColor: Colors.BG,
  },
});
