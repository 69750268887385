import React from 'react';
import { Image, View } from 'react-native';
import { Colors, Images } from '@nextstep/app/Themes/index';
import Styles from '@nextstep/app/components/styles/RenderAnswerChoiceStyles';

const AnswerStatusIcon = ({ correct }) => (
  <View style={{
    borderRadius: 50,
    padding: 6,
    marginLeft: 10,
    width: 24,
    backgroundColor: Colors.white,
  }}
  >
    <Image style={Styles.answerStatusIcon} source={correct ? Images.icons.correctMark : Images.icons.wrongMark} />
  </View>
);

export default AnswerStatusIcon;
