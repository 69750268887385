import Config from "react-native-config";

class Intercom {
  Notifications = {
    UNREAD_COUNT: 0,
  }

  static logout() {
    if(window.Intercom) {
      window.Intercom('shutdown');
    }

    window.intercomSettings = {
      app_id: Config.INTERCOM_APP_ID,
    };
  }

  static registerIdentifiedUser(userData) {
    window.intercomSettings = {
      ...userData,
      app_id: Config.INTERCOM_APP_ID
    };
    this.injectWebIntegration();
  }

  static updateUser(userData) {
    window.intercomSettings = {
      ...window.intercomSettings,
      ...userData,
      app_id: Config.INTERCOM_APP_ID,
    };
    if(window.Intercom) {
      window.Intercom('update', userData);
    }
  }

  static logEvent(){
    // UNUSED and not available on the web integration
  }

  static setUserHash(intercomIdentityHash) {
    window.intercomSettings = {
      ...window.intercomSettings,
      user_hash: intercomIdentityHash,
      app_id: Config.INTERCOM_APP_ID
    };
  }

  static handlePushMessage() {
    // TODO: future development
  }

  static addEventListener() {
    // UNUSED and not available on the web integration
  }

  static removeEventListener() {
    // UNUSED and not available on the web integration
  }

  static sendTokenToIntercom() {
    // TODO: This is for push notifications - future development
  }

  static displayMessageComposer() {
    if(window.Intercom) {
      window.Intercom('showNewMessage');
    }
  }

  static displayConversationsList() {
    if(window.Intercom) {
      window.Intercom('showMessages');
    }
  }

  static getUnreadConversationCount() {
    // Not available on the web integration
    return Promise.resolve(0)
  }

  static injectWebIntegration() {
    window.intercomSettings = {
      ...window.intercomSettings,
      app_id: Config.INTERCOM_APP_ID,
    };
  
    // Taken from: https://developers.intercom.com/installing-intercom/docs/basic-javascript
    // on 10/08/2020
    var w = window;
    var ic = w.Intercom;
    if (typeof ic === "function") {
        ic('reattach_activator');
        ic('update', w.intercomSettings);
    } else {
        var d = document;
        var i = function() {
            i.c(arguments);
        };
        i.q = [];
        i.c = function(args) {
            i.q.push(args);
        };
        w.Intercom = i;
        var l = function() {
            var s = d.createElement('script');
            s.type = 'text/javascript';
            s.async = true;
            s.src = 'https://widget.intercom.io/widget/' + Config.INTERCOM_APP_ID;
            var x = d.getElementsByTagName('script')[0];
            x.parentNode.insertBefore(s, x);
        };
        if (document.readyState === 'complete') {
            l();
        } else if (w.attachEvent) {
            w.attachEvent('onload', l);
        } else {
            w.addEventListener('load', l, false);
        }
    }
  }
}

export default Intercom;