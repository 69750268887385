import { types } from 'mobx-state-tree';
import WithColorTheme from './WithColorTheme';

const CompletedSkillModel = types.model('CompletedSkill', {
  id: types.identifierNumber,
  key: types.string,
  skillsetKey: types.string,
  version: types.maybe(types.string),
  title: types.maybe(types.string),
  icon: types.maybe(types.string),
}).views(self => ({
  get loaded() {
    return !!self.summary;
  },
}));

const CompletedSkill = types.compose(CompletedSkillModel, WithColorTheme).named('CompletedSkill');

export default CompletedSkill;
