import { Platform, View } from 'react-native';
import React from 'react';
import { inject, observer } from 'mobx-react';
import { QuestionTypes } from '@nextstep/app/Config/Constants';
import Styles from '@nextstep/app/containers/styles/AssessmentBlockScreenStyles';
import { Colors } from '@nextstep/app/Themes';
import Config from '@nextstep/app/Config/DebugConfig';
import { Body1 } from '@nextstep/app/components/Text';
import DebouncedTouchableOpacity from '@nextstep/app/components/DebouncedTouchableOpacity';
import { QuestionPlaceholder } from '@nextstep/app/components/SkeletonPlaceholder';
import { FullViewSpinner } from '@nextstep/app/components/SharedUI';
import QuestionText from './QuestionText';
import RenderAnswerChoice from './RenderAnswerChoice';
import RenderImageAnswerChoice from './RenderImageAnswerChoice';

const AssessmentBlockQuestion = ({
  contentStore, showCheckOrX, chosenAnswers, appendAnswer, chooseAnswer, resetAnswer,
  hasOrderedCorrectAnswers, hasUnorderedCorrectAnswers, setPrivateAnswer, loading,
}) => {
  const { loading: storeLoading, question, skillset } = contentStore;

  if (loading || storeLoading || !question || !question.answerChoices) {
    if (Platform.OS === 'web') {
      return (
        <View style={{ marginVertical: '20vh' }}>
          <FullViewSpinner color={skillset.colorTheme} />
        </View>
      );
    }
    return <QuestionPlaceholder />;
  }

  const shuffledAnswerChoices = question.getShuffledAnswerChoices();

  const questionType = question.type.toLowerCase();

  const numberSuffix = (n) => {
    switch (n) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  };

  const orderedAnswerChoiceSorting = (a, b) => {
    if (a.order < b.order) { return -1; }
    if (a.order > b.order) { return 1; }
    return 0;
  };

  const orderedAnswerChoiceFiltering = answerChoice => answerChoice.order !== -1 || answerChoice.score > 0;
  const answerCheckOrder = (answerChoice) => chosenAnswers.findIndex(ca => ca.id === answerChoice.id);
  const requiredAnswers = () => question.answerChoices.filter(orderedAnswerChoiceFiltering).sort(orderedAnswerChoiceSorting);

  const renderOrderAppend = (skset, answerChoice) => {
    const order = answerCheckOrder(answerChoice);
    return (
      <View style={{
        width: 30,
        height: 30,
        borderRadius: 100 / 2,
        borderColor: skset.colorTheme40,
        backgroundColor: order !== -1 ? '#fbf8f3' : Colors.transparent,
        borderWidth: 1,
        justifyContent: 'center',
        alignItems: 'center',
      }}
      >
        {order !== -1 && (
          <Body1>
            <Body1 color={skset.colorTheme}>{order + 1}</Body1>
            <Body1 color={skset.colorTheme} style={{ fontSize: 10 }}>
              {numberSuffix(order + 1)}
            </Body1>
          </Body1>
        )}
      </View>
    );
  };

  if (question && shuffledAnswerChoices && Config.answerSolver) {
    if (hasOrderedCorrectAnswers()) {
      setPrivateAnswer(shuffledAnswerChoices.filter(orderedAnswerChoiceFiltering).sort(orderedAnswerChoiceSorting));
    } else if (hasUnorderedCorrectAnswers()) {
      setPrivateAnswer(shuffledAnswerChoices.filter(sa => sa.score));
    } else {
      setPrivateAnswer([shuffledAnswerChoices.find(answer => answer.score === 1)]);
    }
  }

  if (questionType === QuestionTypes.imageSelect) {
    return (
      <View>
        <View style={{ paddingTop: 8 }} />

        <QuestionText chosenAnswers={chosenAnswers} question={question} skillset={skillset} />

        <View style={{ paddingTop: 24 }} />
        <View style={Styles.imageChoiceRow}>
          {shuffledAnswerChoices.slice(0, 2).map((ac, i) => (
            <RenderImageAnswerChoice
              skillset={skillset}
              showCheckOrX={showCheckOrX}
              chooseAnswer={chooseAnswer}
              index={i}
              answerChoice={ac}
              checked={answerCheckOrder(ac) !== -1}
              key={i}
            />
          ))}
        </View>
        <View style={Styles.imageChoiceRow}>
          {shuffledAnswerChoices.slice(2, 4).map((ac, i) => (
            <RenderImageAnswerChoice
              skillset={skillset}
              showCheckOrX={showCheckOrX}
              chooseAnswer={chooseAnswer}
              index={i + 2}
              answerChoice={ac}
              checked={answerCheckOrder(ac) !== -1}
              key={i}
            />
          ))}
        </View>
      </View>
    );
  }

  const maxAnswers = questionType === QuestionTypes.fillInTheBlanks
    ? question.questionParts.filter(p => p.postpendAnswer).length
    : Infinity;

  const resetColor = !showCheckOrX ? skillset.colorTheme : Colors.clear;

  return (
    <View style={{ paddingVertical: Platform.OS === 'web' ? 20 : 10 }}>

      <QuestionText chosenAnswers={chosenAnswers} skillset={skillset} question={question} />

      <View style={{ paddingTop: 12 }} />

      {hasOrderedCorrectAnswers() || hasUnorderedCorrectAnswers() ? (
        <DebouncedTouchableOpacity
          onPress={() => resetAnswer()}
          style={{ alignSelf: 'flex-start' }}
        >
          <Body1 color={resetColor}>
            Reset
          </Body1>
        </DebouncedTouchableOpacity>
      ) : null}

      <View style={{ paddingTop: 12 }} />

      {shuffledAnswerChoices?.map((answerChoice, i) => {
        const order = answerCheckOrder(answerChoice);
        return hasOrderedCorrectAnswers()
          ? (
            <RenderAnswerChoice
              skillset={skillset}
              showCheckOrX={showCheckOrX}
              isCorrect={(requiredAnswers()[order] || {}).id === (chosenAnswers[order] || {}).id}
              chooseAnswer={chosenAnswers.length < maxAnswers ? appendAnswer : null}
              index={i}
              answerChoice={answerChoice}
              checked={order !== -1}
              append={renderOrderAppend(skillset, answerChoice)}
              key={answerChoice}
            />
          ) : (
            <RenderAnswerChoice
              skillset={skillset}
              showCheckOrX={showCheckOrX}
              chooseAnswer={hasUnorderedCorrectAnswers() ? appendAnswer : chooseAnswer}
              index={i}
              answerChoice={answerChoice}
              checked={order !== -1}
              key={answerChoice}
            />
          );
      })}
    </View>
  );
};

export default inject(({ rootStore: { contentStore } }) => ({ contentStore }))(observer(AssessmentBlockQuestion));
