import { Component } from 'react';
import PropTypes from 'prop-types';
import Config from 'react-native-config';
import { ErrorCodes, Events, ErrorActions } from '@nextstep/app/Config/Constants';
import Logger from './Logger';

class ContainerErrorBoundary extends Component {
    static propTypes = { children: PropTypes.node.isRequired };

    constructor(props) {
      super(props);
      this.state = {
        errorCode: false,
        action: null,
      };
    }

    static getDerivedStateFromError(error) {
      let action;
      switch (error.message) {
        case ErrorCodes.networkError:
        case ErrorCodes.connectionError:
        case ErrorCodes.serverError:
        case ErrorCodes.timeoutError:
          action = ErrorActions.retry;
          break;
        case ErrorCodes.fatalError:
          action = ErrorActions.restart;
          break;
        default:
          action = null;
      }
      return { errorCode: error.message, action };
    }

    componentDidCatch(error, errorInfo) {
      const { errorCode, action } = this.state;
      if (ErrorCodes.fatalError || ErrorCodes.FatalErrors.includes(error.name)) {
        if (!Config.KEEP_STATE_ON_ERROR) global.rootStore.deleteSnapshot();
        Logger.error(error, errorInfo);
      }
      global.emitter.emit(Events.showErrorEvent, { errorCode, action, errorInfo });
    }

    render() {
      const { children } = this.props;
      return children;
    }
}


export default ContainerErrorBoundary;
