import hexToRgba from 'hex-to-rgba';
import Colors from './Colors';

const light = {
  basic: {
    black: Colors.black,
    white: Colors.white,
    gray: Colors.app_grey_grey_2,
    transparent: Colors.transparent,
    skeletonPlaceholder: Colors.skeleton_placeholder,
    border: hexToRgba(Colors.dark, 0.05),
    orangeish: Colors.orangeish,
    green: Colors.green,
    brandRed: Colors.brandRed,
    lightFire: hexToRgba(Colors.fire, 0.05),
  },
  brand: {
    pink: Colors.mainPink,
    orange: Colors.mainOrange,
    green: Colors.mainGreen,
    blue: Colors.mainBlue,
    magenta: Colors.mainMagenta,
  },
  primary: {
    main: Colors.bright_orange,
    disable: Colors.light_peach,
    contrastText: Colors.white,
  },
  text: {
    main: Colors.dark,
    white: Colors.white,
    secondary: Colors.dusk,
    gray: Colors.lockedGray,
    error: Colors.lipstick,
    disabled: Colors.pale_purple,
    placeHolder: Colors.placeholder,
  },
  background: {
    main: Colors.BG,
    primary: Colors.light_peach,
    secondary: Colors.beige_two,
    white: Colors.white,
    purple: Colors.dusk,
  },
  shadow: {
    main: Colors.shadow,
    secondary: Colors.shadow_two,
  },
};

const dark = {
  basic: {
    black: Colors.black,
    white: Colors.white,
  },
  primary: {
    main: Colors.bright_orange,
    disable: Colors.light_peach,
    contrastText: Colors.white,
  },
  text: { main: Colors.dark },
  background: { main: Colors.BG },
};

export default {
  light,
  dark,
};
