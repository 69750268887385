import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Text } from 'react-native-elements';
import { Platform, View } from 'react-native';
import { Body1, Caption1 } from '@nextstep/app/components/Text';

const QuestionText = ({
  colorTheme,
  fitbBackgroundColor,
  isFitb,
  question,
  selectedAnswers,
}) => {
  const FillInBlankAnswer = ({ index }) => (
    <>
      {' '}
      <Body1 color={colorTheme} style={{ backgroundColor: fitbBackgroundColor }}>
        {selectedAnswers[index] ? selectedAnswers[index].answerChoice : '\xa0'.repeat(20)}
      </Body1>
      {' '}
    </>
  );

  return (
    <View style={{ paddingVertical: Platform.OS === 'web' ? 20 : 5 }}>
      <Caption1 color={colorTheme} textAlign="left">
        Question
      </Caption1>

      <View style={{ height: 10 }} />

      { isFitb ? (
        <Text>
          {(question.questionParts || []).map((block, index) => (
            <Fragment key={block.part}>
              <Body1 textAlign="left">
                {block.part}
              </Body1>
              {block.postpendAnswer && <FillInBlankAnswer index={index} />}
            </Fragment>
          ))}
        </Text>
      ) : (
        <Body1 textAlign="left">
          {question.question.replace(/<b>|<\/b>/g, '')}
        </Body1>
      ) }

    </View>
  );
};

QuestionText.propTypes = {
  colorTheme: PropTypes.string.isRequired,
  fitbBackgroundColor: PropTypes.string.isRequired,
  isFitb: PropTypes.bool,
  question: PropTypes.object.isRequired,
  selectedAnswers: PropTypes.array,
};

QuestionText.defaultProps = {
  isFitb: false,
  selectedAnswers: [],
};

export default QuestionText;
