import React from 'react';
import { View, StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  header: {
    width: '100%',
    height: 64,
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    shadowColor: '#000',
    shadowOpacity: 0.4,
    shadowRadius: 2,
    zIndex: 100,
  },

  headerLeft: {},

  headerTitle: {
    flex: 1,
    alignItems: 'center',
  },

  headerRight: {},
});

export default function Header({ state, descriptors }) {
  const activeKey = state.routes[state.index].key;
  const descriptor = descriptors[activeKey];

  return (
    <View style={styles.header}>
      <View style={styles.headerLeft}>
        {descriptor.options.headerLeft && descriptor.options.headerLeft()}
      </View>
      <View style={styles.headerTitle}>
        {descriptor.options.headerTitle && descriptor.options.headerTitle()}
      </View>
      <View style={styles.headerRight}>
        {descriptor.options.headerRight && descriptor.options.headerRight()}
      </View>
    </View>
  );
}
