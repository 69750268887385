import { types, getParent } from 'mobx-state-tree';
import { ContentNodeTypes } from '@nextstep/app/Config/Constants';
import AssessmentBlock from './AssessmentBlock';
import DemoVideo from './DemoVideo';
import SuccessCriteria from './SuccessCriteria';
import ContentNode from './ContentNode';

const StepModel = types.model('Step', {
  id: types.identifierNumber,
  key: types.string,
  title: types.maybe(types.string),
  successCriteria: types.maybe(SuccessCriteria),
  demoVideos: types.maybe(types.array(DemoVideo)),
  assessmentBlocks: types.maybe(types.array(AssessmentBlock)),
})
  .views(self => ({
    get loaded() {
      return !!self.successCriteria;
    },
    get colorType() {
      const parent = getParent(self);
      return parent.colorType;
    },
  }))
  .actions(self => ({
    stepHasSuccessCriteria() {
      return !!self.successCriteria;
    },
  }));

const Step = types.compose(StepModel, ContentNode)
  .named(ContentNodeTypes.step);

export default Step;
