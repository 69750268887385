
import { CommonActions, StackActions } from '@react-navigation/native';
import { Platform } from 'react-native';
import AsyncStorage from '@react-native-community/async-storage';
import { PersistenceKeys } from '@nextstep/app/Config/Constants';

let navigator;
let setNavigationReady = null;
const readyPromise = new Promise((resolve => { setNavigationReady = resolve; }));

function setTopLevelNavigator(navigatorRef) {
  navigator = navigatorRef;
}

async function waitForNavigation() {
  return readyPromise;
}

function navigate(routeName, params) {
  if (navigator) {
    navigator.dispatch(
      CommonActions.navigate({
        name: routeName,
        params,
      }),
    );
  }
}

// Note: this function should be removed, as screens should not need all this info about their parents. However, it's a quick/temporary fix that allows us to remove a bunch of other messy code, without rewriting several screens.
function navigateToNode(node) {
  const params = {};
  let { parent } = node;

  while (parent.nodeType) {
    params[`${parent.lowerNodeType}Key`] = parent.key;
    parent = parent.parent;
  }

  navigate(node.nodeType, params);
}

function pop(count = 1) {
  navigator.dispatch(StackActions.pop(count));
}

function back(key) {
  if (Platform.OS === 'web' && !navigator.canGoBack() && window.history.length > 1) {
    window.history.back();
  }

  navigator.dispatch(
    CommonActions.goBack({ key }),
  );
}

function reset(routeName) {
  try {
    navigator.dispatch(
      CommonActions.reset({
        index: 1,
        routes: [
          { name: routeName },
        ],
      }),
    );
    // eslint-disable-next-line no-empty
  } catch {}
}

async function navigateToLearnerInitialScreen(defaultScreen = null) {
  const initialRoute = await AsyncStorage.getItem(PersistenceKeys.initialRoute);
  if (initialRoute) {
    try {
      navigator.dispatch(
        CommonActions.reset({
          index: 1,
          routes: JSON.parse(initialRoute).routes,
        }),
      );
      // eslint-disable-next-line no-empty
    } catch {}
    await AsyncStorage.removeItem(PersistenceKeys.initialRoute);
  }

  navigate('App', defaultScreen && { screen: defaultScreen });
}

function getActivePath() {
  if (!navigator) return [];

  let state = navigator.getRootState();
  const path = [];

  while (state) {
    const route = state.routes[state?.index || 0];
    path.push(route.name);
    state = route.state;
  }

  return path;
}

export default {
  navigate,
  navigateToNode,
  navigateToLearnerInitialScreen,
  back,
  reset,
  pop,
  setTopLevelNavigator,
  waitForNavigation,
  setNavigationReady,
  getActivePath,
};
