import React, { Component } from 'react';
import { View } from 'react-native';
import { inject, observer } from 'mobx-react';
import { Card, Icon } from 'react-native-elements';
import Intercom from 'react-native-intercom';
import withDimensions from '@nextstep/app/lib/withDimensions';
import { Body1, Body2, H3 } from '@nextstep/app/components/Text';
import ViewWrapper from '@nextstep/app/components/ViewWrapper';
import { Column, Row } from '@nextstep/app/lib/ResponsiveLayout';
import { Palette } from '@nextstep/app/Themes';
import { ButtonFilled, ButtonOutline, ButtonText } from '@nextstep/app/components/Button';
import Divider from '@nextstep/app/components/Divider';
import Util from '@nextstep/app/services/Util';
import NavigationService from '@nextstep/app/services/NavigationService';
import AdaptiveContainerStyles from '@nextstep/app/containers/styles/AdaptiveContainerStyles';

const currentPalette = Palette.light;
const CardStyle = {
  borderRadius: 8,
  borderColor: 'rgba(43, 35, 58, 0.05)',
  backgroundColor: '#FBFBFB',
  margin: 0,
};

export class NoPaymentAccountScreen extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: false };
  }

  openIntercom = async () => {
    const { rootStore: { sessionStore } } = this.props;
    sessionStore.intercomVerifyIdentity();
    await Intercom.displayMessageComposer();
  }

  refreshLearner = async () => {
    this.setState({ loading: true });
    const { rootStore: { sessionStore } } = this.props;
    const { learner } = sessionStore;

    await sessionStore.syncCurrentLearner();
    if (!learner.blocked || !learner.config.appBlockEnabled) {
      NavigationService.reset('App', { screen: 'Home' });
    }
    this.setState({ loading: false });
  }

  makePaymentHandler = async () => {
    const { rootStore: { sessionStore } } = this.props;
    const { learner } = sessionStore;
    const { failedInvoiceUrl } = learner;

    Util.openURL(failedInvoiceUrl);
  };

  render() {
    const { rootStore: { sessionStore }, dimensions } = this.props;
    const { loading } = this.state;
    const Styles = AdaptiveContainerStyles(dimensions.deviceType);

    return (
      <ViewWrapper smSize={11} mdSize={7} lgSize={6}>
        <Card containerStyle={CardStyle}>
          <Row style={{ justifyContent: 'center' }}>
            <Column smSize={12} mdSize={8} lgSize={8} style={Styles.textContainer}>

              <View style={{ height: 32 }} />

              <Icon name="md-card" type="ionicon" color={currentPalette.primary.main} size={43} />

              <H3>
                Payment Past Due
              </H3>

              <View style={{ height: 16 }} />

              <Body1>
                It looks like we are having some trouble processing a payment with your card.
                Please update your account with valid payment information.
              </Body1>

              <View style={{ height: 16 }} />

              <Body2>
                Once you have made a successful payment, please click the continue button.
              </Body2>

            </Column>

            <Divider gutter={30} />

            <Column smSize={11} mdSize={7} lgSize={7} style={Styles.textContainer}>

              <ButtonFilled
                onPress={() => this.makePaymentHandler()}
                width="100%"
              >
                Make a Payment
              </ButtonFilled>

              <View style={{ height: 22 }} />

              <ButtonOutline
                onPress={() => this.refreshLearner()}
                width="100%"
              >
                {loading ? 'Checking...' : 'Continue'}
              </ButtonOutline>

              <View style={{ height: 22 }} />

              <ButtonOutline
                onPress={() => this.openIntercom()}
                width="100%"
              >
                Chat with a Mentor
              </ButtonOutline>

              <View style={{ height: 22 }} />

              <ButtonText onPress={() => sessionStore.logout()}>
                Sign Out
              </ButtonText>

              <View style={{ height: 34 }} />

            </Column>
          </Row>
        </Card>
      </ViewWrapper>
    );
  }
}

export default withDimensions(inject('rootStore')(observer(NoPaymentAccountScreen)));
