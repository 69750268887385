/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Image, View, TouchableOpacity } from 'react-native';
import { Card as RNCard, Icon } from 'react-native-elements';
import PropTypes from 'prop-types';
import { Images } from '@nextstep/app/Themes';
import { currentPalette } from '@nextstep/app/Config/AppConfig';
import withDimensions, { DeviceType } from '@nextstep/app/lib/withDimensions';
import { Body1, Body2, Caption1 } from './Text';

const defaultStyle = {
  cardContainer: {
    borderRadius: 8,
    margin: 0,
    padding: 0,
    borderWidth: 1,
  },

  iconContainer: {
    justifyContent: 'center',
    borderBottomLeftRadius: 8,
    borderTopLeftRadius: 8,
  },

  icon: {
    margin: 29,
    height: 48,
    width: 48,
    resizeMode: 'contain',
  },

  badgeContainer: {
    paddingRight: 12,
    alignSelf: 'center',
  },

  badge: {
    paddingVertical: 8,
    paddingHorizontal: 15,
    borderRadius: 50,
  },
};

const LearnerTrackCard = (props) => (
  <TouchableOpacity
    onPress={() => props.onPress()}
    disabled={!props.onPress || props.disabled || props.isCompleted}
  >
    <RNCard
      containerStyle={[
        defaultStyle.cardContainer,
        {
          backgroundColor: props.disabled ? currentPalette.background.white : currentPalette.background.secondary,
          borderColor: props.disabled ? currentPalette.basic.gray : currentPalette.primary.main,
        },
      ]}
    >
      <View style={{ flexDirection: 'row' }}>

        <View
          style={[
            defaultStyle.iconContainer,
            { backgroundColor: props.disabled ? currentPalette.primary.disable : currentPalette.primary.main },
          ]}
        >
          <Image
            style={defaultStyle.icon}
            source={props.icon}
          />
        </View>

        <View style={[{ flex: 1, padding: 12 }]}>
          <Body2 style={props.titleStyle} textAlign="left">
            {props.title}
          </Body2>

          <View style={{ height: 8 }} />

          {props.onPress && (
            <View style={{ alignSelf: 'flex-start', marginLeft: -5 }}>

              {props.isCompleted && props.dimensions.deviceType === DeviceType.Mobile && (
                <View style={[defaultStyle.badge, { backgroundColor: currentPalette.background.primary }]}>
                  <Caption1 color={'#963300'}>Complete</Caption1>
                </View>
              )}

            </View>
          )}

          <View style={{ height: 8 }} />

          <Body1 numberOfLines={props.descriptionLines} style={props.descriptionStyle} textAlign="left">
            {props.description}
          </Body1>
        </View>

        {props.onPress && (
          <>
            {props.isCompleted && props.dimensions.deviceType !== DeviceType.Mobile && (
              <View style={defaultStyle.badgeContainer}>
                <View style={[defaultStyle.badge, { backgroundColor: currentPalette.background.primary }]}>
                  <Caption1 color={'#963300'}>Complete</Caption1>
                </View>
              </View>
            )}

            {!props.isCompleted && (
              <View style={defaultStyle.badgeContainer}>
                <Icon
                  name="keyboard-arrow-right"
                  color={props.disabled ? currentPalette.text.disabled : currentPalette.primary.main}
                  underlayColor="transparent"
                />
              </View>
            )}
          </>
        )}

      </View>
    </RNCard>
  </TouchableOpacity>
);

LearnerTrackCard.propTypes = {
  onPress: PropTypes.func,
  disabled: PropTypes.bool,
  isCompleted: PropTypes.bool,
  title: PropTypes.string,
  titleStyle: PropTypes.object,
  description: PropTypes.string,
  descriptionStyle: PropTypes.object,
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

LearnerTrackCard.defaultProps = {
  onPress: undefined,
  disabled: false,
  isCompleted: false,
  title: undefined,
  titleStyle: {},
  description: undefined,
  descriptionStyle: {},
  icon: Images.icons.homeActive,
};

export default withDimensions(LearnerTrackCard);
