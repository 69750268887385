import React from 'react';
import { Dimensions } from 'react-native';
import Orientation from 'react-native-orientation';
import debounce from 'lodash/debounce';
import hoistNonReactStatic from 'hoist-non-react-statics';

export const DeviceType = {
  Mobile: 'mobile',
  Tablet: 'tablet',
  Desktop: 'desktop',
};

export function getDeviceType(dimensions) {
  if (dimensions.width >= 1440) return DeviceType.Desktop;
  if (dimensions.width >= 768) return DeviceType.Tablet;
  return DeviceType.Mobile;
}

export default function withDimensions(WrappedComponent) {
  class WithDimensions extends React.Component {
    constructor(props) {
      super(props);

      const dimensions = Dimensions.get('window');
      this.state = { dimensions: { ...dimensions, deviceType: getDeviceType(dimensions) } };
    }

    componentDidMount() {
      Dimensions.addEventListener('change', this.onDimensionsChange);
      Orientation.addOrientationListener(this.onOrientationChange);
    }

    componentWillUnmount() {
      Dimensions.removeEventListener('change', this.onDimensionsChange);
      Orientation.removeOrientationListener(this.onOrientationChange);
    }

    onDimensionsChange = debounce((e) => {
      this.setState({ dimensions: { ...e.window, deviceType: getDeviceType(e.window) } });
    }, 400)

    onOrientationChange = () => {
      const dimensions = Dimensions.get('window');
      this.setState({ dimensions: { ...dimensions, deviceType: getDeviceType(dimensions) } });
    }

    render() {
      const { dimensions } = this.state;
      return <WrappedComponent dimensions={dimensions} {...this.props} />;
    }
  }

  hoistNonReactStatic(WithDimensions, WrappedComponent);

  return WithDimensions;
}
