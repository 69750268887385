import { types, getEnv, getParent, getType, isArrayType } from 'mobx-state-tree';
import Util from '@nextstep/app/services/Util';

// This provides convenience functions for content and progress nodes in the tree - eg. fooNode.parent will return the parent.
const BaseNode = types.model({}).views(self => ({
  get nodeType() {
    return getType(self).name;
  },
  get lowerNodeType() {
    return Util.pascalToCamel(self.nodeType);
  },
  get parent() {
    let parent = getParent(self);
    if (isArrayType(getType(parent))) {
      parent = getParent(parent);
    }
    return parent;
  },
  get api() {
    return getEnv(self.store).api;
  },
}));

export default BaseNode;

