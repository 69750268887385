import { Platform } from 'react-native';
import Colors from './Colors';
import Palette from './Palette';
import Typography from './Typography';

const currentPalette = Palette.light;

const ApplicationStyles = {
  safeArea: {
    flex: 1,
    backgroundColor: currentPalette.background.white,
  },

  safeAreaTop: {
    flex: 0,
    backgroundColor: currentPalette.background.white,
  },

  screenContainer: ({ navBarHeight = 48, bottomBarHeight = 0 } = {}) => ({ height: Platform.OS === 'web' ? `calc(100vh - ${navBarHeight}px - ${bottomBarHeight}px)` : '100%' }),

  navbarStyles: {
    headerStyle: {
      backgroundColor: currentPalette.primary.main,
      elevation: 0,
      shadowOpacity: 0,
      borderBottomWidth: 0,
    },
    headerTitleStyle: {
      ...Typography.subtitle2,
      fontWeight: null,
    },
    headerTintColor: currentPalette.primary.contrastText,
    headerLeftContainerStyle: { left: 16 },
    headerRightContainerStyle: { right: 16 },
  },

  navbarTransparentStyles: {
    headerStyle: {
      backgroundColor: currentPalette.background.main,
      elevation: 1,
      shadowOpacity: 1,
      borderBottomWidth: 0,
    },
  },

  navbarIntercomIconStyles: {
    width: 32,
    height: 32,
  },

  navbarProfile: { marginRight: 40 },

  navbarTitle: {
    height: 48,
    width: '100%',
    resizeMode: 'contain',
  },

  newInput: {
    color: currentPalette.text.main,
    borderRadius: 10,
    borderColor: currentPalette.primary.disable,
    borderWidth: 1,
    backgroundColor: currentPalette.basic.white,
    minHeight: 48,
    ...Typography.subtitle1,
    paddingVertical: 8,
    paddingHorizontal: 14,
    justifyContent: 'center',
  },

  inputTitle: {
    top: -12,
    left: 18,
    paddingHorizontal: 5,
    backgroundColor: currentPalette.background.white,
    position: 'absolute',
  },

  inputError: {
    color: currentPalette.text.error,
    borderColor: currentPalette.text.error,
  },

  inputFocus: {
    borderColor: currentPalette.primary.main,
    borderWidth: 2,
  },

  inputDisabled: {
    color: currentPalette.text.disabled,
    borderColor: currentPalette.text.placeHolder,
  },

  appAccess: {
    container: {
      flex: 1,
      backgroundColor: currentPalette.background.main,
      paddingHorizontal: 30,
    },
    courseCardImage: {
      width: 100,
      height: 100,
      marginBottom: 10,
      padding: 0,
      borderRadius: 100 / 2,
      overflow: 'hidden',
    },
  },

  scrollStyle: {
    backgroundColor: Colors.brand_modal_black,
    paddingTop: 12,
  },

  modalStyles: {
    container: {
      backgroundColor: currentPalette.background.main,
      borderRadius: 8,
      overflow: 'hidden',
    },
    row: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    col: {
      flexDirection: 'column',
      flex: 1,
    },
  },

  textLink: {
    color: currentPalette.primary.main,
    fontFamily: 'Poppins-Bold',
  },

  icon: {
    width: 24,
    height: 24,
    overflow: 'hidden',
    resizeMode: 'contain',
  },
};

export default ApplicationStyles;
