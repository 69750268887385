import { getSnapshot, applySnapshot, onSnapshot, types } from 'mobx-state-tree';
import { configure } from 'mobx';

import DebugConfig from '@nextstep/app/Config/DebugConfig';

import API from '@nextstep/app/services/Api';
import FixtureAPI from '@nextstep/app/services/FixtureApi';
import IntercomAPI from '@nextstep/app/services/IntercomApi';
import ActiveCampaignAPI from '@nextstep/app/services/ActiveCampaignApi';
import ProgressStore from './ProgressStore';
import ContentStore from './ContentStore';
import SessionStore from './SessionStore';
import RootStoreActions from './RootStoreActions';

configure({ useProxies: 'never', enforceActions: 'never' });

const api = DebugConfig.useFixtures ? FixtureAPI : API.create();
const intercomApi = DebugConfig.useFixtures ? FixtureAPI : IntercomAPI.create();
const activeCampaignApi = DebugConfig.useFixtures ? FixtureAPI : ActiveCampaignAPI.create();

const RootStore = types
  .model('RootStore', {
    sessionStore: types.optional(SessionStore, () => SessionStore.create({})),
    contentStore: types.optional(ContentStore, () => ContentStore.create({})),
    progressStore: types.optional(ProgressStore, () => ProgressStore.create({})),
  }).actions(RootStoreActions)
  .volatile(() => ({
    snapshotVersion: null,
    snapshotUpdating: null,
  }));

const RootStoreInstance = RootStore.create({}, { api, activeCampaignApi, intercomApi });
onSnapshot(RootStoreInstance, () => {
  RootStoreInstance.saveSnapshot();
});


// Save/Restore the state of the store while the app is hot reloaded
if (module.hot) {
  if (module.hot.data && module.hot.data.rootStore) {
    applySnapshot(RootStoreInstance, module.hot.data.rootStore);
  }
  module.hot.dispose((data) => {
    if (data) {
      data.rootStore = getSnapshot(RootStoreInstance);
    }
  });
}
export { RootStoreInstance as default, RootStore };
