/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { SafeAreaView, ScrollView, StatusBar, StyleSheet, Text, View } from 'react-native';
import Markdown, { MarkdownIt } from 'react-native-markdown-display';
import blockEmbedPlugin from 'markdown-it-block-embed';
import PropTypes from 'prop-types';
import VideoPlayer from 'react-native-af-video-player';
import { Images, Typography } from '@nextstep/app/Themes';
import AdaptiveContainerStyles from '@nextstep/app/containers/styles/AdaptiveContainerStyles';
import { currentPalette } from '@nextstep/app/Config/AppConfig';
import { H3 } from './Text';


const stylesC = (customStyles) => StyleSheet.create({
  body: {
    ...Typography.body1,
    color: currentPalette.text.main,
  },
  heading1: {
    ...Typography.h2,
    color: currentPalette.primary.main,
  },
  heading2: {
    ...Typography.subtitle1,
    color: currentPalette.primary.main,
  },
  heading3: { ...Typography.h2 },
  heading4: { ...Typography.body1 },
  heading5: { ...Typography.h4 },
  heading6: { ...Typography.h2 },
  ...customStyles,
  image: {
    maxWidth: 400,
    margin: 'auto',
  },
  strong: {
    fontFamily: Typography.body2.fontFamily,
    fontWeight: null,
    color: currentPalette.text.main,
  },
  link: {
    ...Typography.body1,
    color: currentPalette.primary.main,
  },
});

const rules = {
  heading3: (node, children) => (
    <H3 key={node.key}>
      {children}
    </H3>
  ),
  heading4: (node, children) => (
    <Text key={node.key} style={{ textAlign: 'center' }}>
      {children}
    </Text>
  ),
  hr: (node) => (
    <View
      key={node.key}
      style={{
        borderBottomColor: currentPalette.basic.gray,
        borderBottomWidth: 1,
        width: '100%',
        marginVertical: 40,
      }}
    />
  ),
  video: (node) => (
    <VideoPlayer
      key={node.key}
      url={node.sourceInfo.videoReference}
      ignoreSilentSwitch="ignore"
      inlineOnly
      logo="https://s3.amazonaws.com/nextstep.ui/course/images/home-active.png"
      placeholder={Images.other.videoPlaceholder}
      autoPlay={false}
    />
  ),
};

const GlobalStyles = AdaptiveContainerStyles();

const markdownItInstance = MarkdownIt({ typographer: true })
  .use(blockEmbedPlugin, { containerClassName: 'video-embed' });

const MarkdownRender = (props) => (
  <>
    <StatusBar barStyle="dark-content" />
    <SafeAreaView>
      <ScrollView
        contentInsetAdjustmentBehavior="automatic"
        style={[GlobalStyles.textContainer]}
        contentContainerStyle={{ flexGrow: 1, justifyContent: 'flex-start' }}
        bounces={false}
      >
        <Markdown
          style={stylesC(props.style)}
          rules={rules}
          markdownit={markdownItInstance}
        >
          {props.data}
        </Markdown>
      </ScrollView>
    </SafeAreaView>
  </>
);

MarkdownRender.propTypes = {
  style: PropTypes.object,
  data: PropTypes.string,
};

MarkdownRender.defaultProps = {
  style: {},
  data: '',
};

export default MarkdownRender;
