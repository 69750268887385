import React from 'react';
import { View } from 'react-native';
// import { Colors } from '@nextstep/app/Themes';
import { Body1 } from '@nextstep/app/components/Text';

const numberSuffix = (n) => {
  switch (n) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};

const OrdinalBadge = ({ borderColor, order, textColor }) => (
  <View style={{
    width: 30,
    height: 30,
    borderRadius: 100 / 2,
    borderColor,
    backgroundColor: '#fbf8f3',
    borderWidth: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 5,
  }}
  >
    <Body1>
      <Body1 color={textColor}>{order}</Body1>
      <Body1 color={textColor} style={{ fontSize: 10 }}>
        {numberSuffix(order)}
      </Body1>
    </Body1>
  </View>
);

export default OrdinalBadge;
