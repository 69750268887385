import { types } from 'mobx-state-tree';
import Constants from '@nextstep/app/Config/Constants';
import UserConfig from './UserConfig';
import PaymentMethod from './PaymentMethod';

const User = types.model('User', {
  id: types.maybe(types.identifierNumber),
  blocked: types.optional(types.boolean, false),
  blockReason: types.maybe(types.string),
  blockSeverity: types.optional(
    types.union(
      types.literal(Constants.Severity.low),
      types.literal(Constants.Severity.high),
    ), Constants.Severity.high,
  ),
  cnaLicenseExpiresOn: types.maybeNull(types.string),
  cnaLicenseNumber: types.maybeNull(types.string),
  config: types.maybe(UserConfig),
  courseCompletionDeadlineOn: types.maybe(types.string),
  courseCompletionGoalOn: types.maybe(types.string),
  createdAt: types.maybe(types.string),
  daysOfStudy: types.optional(types.array(types.number), []),
  email: types.optional(types.string, ''),
  enrolledAt: types.maybeNull(types.string),
  enrollmentAgreementStartOn: types.maybeNull(types.string),
  enrollmentReason: types.maybeNull(types.string),
  externalId: types.maybe(types.string),
  failedInvoiceUrl: types.optional(types.string, ''),
  firebaseRegistrationToken: types.maybeNull(types.string),
  graduationDeadlineEnabled: types.optional(types.boolean, false),
  guestConvertedAt: types.maybeNull(types.string),
  hasDifferentPreferredName: types.optional(types.boolean, false),
  homeCity: types.maybe(types.string),
  homeState: types.maybe(types.string),
  homeStreet1: types.maybe(types.string),
  homeStreet2: types.maybe(types.string),
  homeZipCode: types.maybe(types.string),
  intercomIdentityHashAndroid: types.maybe(types.string),
  intercomIdentityHashIos: types.maybe(types.string),
  intercomIdentityHashWeb: types.maybe(types.string),
  isGuest: types.optional(types.boolean, false),
  isLabAccount: types.optional(types.boolean, false),
  isSuperUser: types.optional(types.boolean, false),
  legacyConversionStatus: types.maybe(types.string),
  legacyState: types.maybe(types.string),
  legalFirstName: types.optional(types.string, ''),
  legalLastName: types.optional(types.string, ''),
  legalMiddleName: types.optional(types.string, ''),
  paymentBlocked: types.optional(types.boolean, false),
  paymentMethod: types.maybe(PaymentMethod),
  paymentTrack: types.optional(
    types.union(
      types.literal(Constants.PaymentTrackTypes.scholarship),
      types.literal(Constants.PaymentTrackTypes.tuition),
      types.literal(Constants.PaymentTrackTypes.deferred),
      types.literal(Constants.PaymentTrackTypes.notSelected),
      types.literal(Constants.PaymentTrackTypes.noFee),
      types.literal(Constants.PaymentTrackTypes.tuitionEmployerPaid),
    ), Constants.PaymentTrackTypes.notSelected,
  ),
  phone: types.optional(types.string, ''),
  preferredFirstName: types.optional(types.string, ''),
  preferredLastName: types.optional(types.string, ''),
  profileImage: types.maybeNull(types.string),
  registeredAt: types.maybe(types.string),
  status: types.optional(types.string, ''),
  studentId: types.maybe(types.string),
  studyDuration: types.optional(types.number, 10),
  tosAcceptedAt: types.maybeNull(types.string),
  tosSkillsetKey: types.maybeNull(types.string),
}).views(self => ({
  get isTestMode() {
    return self.isSuperUser;
  },
  get fullName() {
    const { hasDifferentPreferredName } = self;

    if (hasDifferentPreferredName) {
      const { preferredFirstName, preferredLastName } = self;
      return `${preferredFirstName} ${preferredLastName}`.trim();
    }
    const { legalFirstName, legalLastName } = self;
    return `${legalFirstName} ${legalLastName}`.trim();
  },
}))
  .actions(self => ({
    setProfileImage(url) {
      self.profileImage = url;
    },
  }));

export default User;
