import React from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import { Icon } from 'react-native-elements';
import { Palette } from '@nextstep/app/Themes';
import VideoScreenStyles from '@nextstep/app/containers/styles/VideoScreenStyles';
import AdaptiveContainerStyles from '@nextstep/app/containers/styles/AdaptiveContainerStyles';
import withDimensions, { DeviceType } from '@nextstep/app/lib/withDimensions';
import { Body2 } from '@nextstep/app/components/Text';
import DebouncedTouchableOpacity from '@nextstep/app/components/DebouncedTouchableOpacity';
import ResultBanner from './ResultBanner';

const currentPalette = Palette.light;

const QuestionFooter = (props) => {
  const {
    showingResult,
    colorTheme,
    continueHandler,
    continueText,
    correct,
    dimensions,
    fadeBanner,
  } = props;
  const Styles = VideoScreenStyles(dimensions.deviceType);
  const MainStyles = AdaptiveContainerStyles(dimensions.deviceType);

  return (
    <View style={{ flex: dimensions.deviceType !== DeviceType.Mobile ? 1 : null }}>

      {showingResult && <ResultBanner fadeBanner={fadeBanner} correct={correct} />}

      <View
        style={[
          MainStyles.bottomBar,
          MainStyles.textContainer,
          {
            backgroundColor: colorTheme,
            justifyContent: 'flex-end',
          },
        ]}
      >

        <DebouncedTouchableOpacity
          onPress={continueHandler}
          style={Styles.iconButton}
        >

          <Body2 color={currentPalette.primary.contrastText}>
            { continueText }
          </Body2>

          <Icon
            name="keyboard-arrow-right"
            color={currentPalette.primary.contrastText}
            underlayColor="transparent"
          />
        </DebouncedTouchableOpacity>
      </View>
    </View>
  );
};

QuestionFooter.propTypes = {
  continueText: PropTypes.string,
  colorTheme: PropTypes.string.isRequired,
  correct: PropTypes.bool,
  fadeBanner: PropTypes.object,
  continueHandler: PropTypes.func.isRequired,
  showingResult: PropTypes.bool,
};

QuestionFooter.defaultProps = {
  continueText: 'Next',
  correct: false,
  fadeBanner: {},
  showingResult: false,
};

export default withDimensions(QuestionFooter);
