import { types } from 'mobx-state-tree';

const AssignmentQuestionInteraction = types.model('AssignmentQuestionInteraction', {
  id: types.identifierNumber,
  assignmentQuestionId: types.number,
  content: types.string,
  isInstructorResponse: types.boolean,
  from: types.string,
  sentOn: types.string,
});

export default AssignmentQuestionInteraction;
