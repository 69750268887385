import { types } from 'mobx-state-tree';
import StepProgress from './StepProgress';
import IntroVideoProgress from './IntroVideoProgress';
import WithStatus from './WithStatus';
import ProgressNode from './ProgressNode';
import LinearAssessmentProgress from './LinearAssessmentProgress';
import Skill from './Skill';

const SkillProgressModel = types.model('SkillProgress', {
  id: types.identifierNumber,
  skillId: types.number,
  completedSteps: types.maybe(types.string),
  totalSteps: types.maybe(types.string),
  stepProgresses: types.maybe(types.array(StepProgress)),
  linearAssessmentProgress: types.maybe(LinearAssessmentProgress),
  introVideoProgress: types.maybe(IntroVideoProgress),
  content: types.maybe(types.safeReference(Skill)),
}).views(self => ({
  get loaded() {
    return !!self.stepProgresses;
  },
}));

const SkillProgress = types.compose(SkillProgressModel, ProgressNode, WithStatus).named('SkillProgress');

export default SkillProgress;
