import React from 'react';
import { NavigationContainer } from '@react-navigation/native';
import AppNavigation from './AppNavigation';

const AppNavigator = React.forwardRef((props, ref) => (
  <NavigationContainer {...props} ref={ref}>
    <AppNavigation />
  </NavigationContainer>
));

export default AppNavigator;
