import React from 'react';
import { View } from 'react-native';
import { inject, observer } from 'mobx-react';
import withDimensions from '@nextstep/app/lib/withDimensions';
import { Caption1, Subtitle2, Title } from '@nextstep/app/components/Text';
import Divider from '@nextstep/app/components/Divider';
import { Colors } from '@nextstep/app/Themes';
import { ButtonFilled, ButtonText } from '@nextstep/app/components/Button';
import NavigationService from '@nextstep/app/services/NavigationService';
import HomeScreenStyles from '@nextstep/app/containers/styles/HomeScreenStyles';

const CourseEnrollment = (props) => {
  const { rootStore: { sessionStore, contentStore, progressStore }, dimensions } = props;
  const { learner } = sessionStore;
  const { course } = contentStore;
  const Styles = HomeScreenStyles(dimensions.deviceType);

  const setLastUncompletedSkillset = async () => {
    if (contentStore?.skillsets?.length) {
      const { lastUncompletedSkillset } = progressStore;
      const skillset = contentStore.getSkillset(lastUncompletedSkillset?.skillsetId);
      await progressStore.setExpandedSkillsetKey(skillset?.key);
    }
  };

  return (
    <View style={Styles.section}>

      <View style={{ height: 26 }} />

      <Title>
        MY ENROLLMENT
      </Title>

      <View style={{ height: 15 }} />

      <Divider />

      <View style={{ height: 10 }} />

      <View style={Styles.sectionContent}>

        <View style={{ height: 24 }} />

        <Subtitle2>
          {learner.legacyState === 'OR' || !course ? 'Nursing Assistant' : course.courseTitle}
        </Subtitle2>

        <View style={{ height: 8 }} />

        <Caption1 style={{ color: Colors.lockedGray }}>
          Enrolled
        </Caption1>

        <View style={{ height: 40 }} />

        <ButtonText
          id="course_details"
          onPress={() => NavigationService.navigate('App', { screen: 'CourseDetailsScreen' })}
        >
          View Course Details
        </ButtonText>

        {learner.legacyState === 'WA'
          && (
          <>
            <View style={{ height: 8 }} />
            <ButtonText
              id="course_outline"
              onPress={() => NavigationService.navigate('App', { screen: 'DetailedCourseOutlineScreen' })}
            >
              View Course Outline
            </ButtonText>

          </>
          )
        }

        {learner.legacyState === 'TX'
          && (
          <>
            <View style={{ height: 8 }} />
            <ButtonText
              id="course_outline"
              onPress={() => NavigationService.navigate('App', { screen: 'TexasResourcesScreen' })}
            >
              Resources Page
            </ButtonText>

          </>
          )
        }
      </View>

      <View style={{ height: 32 }} />

      <ButtonFilled
        style={{ alignSelf: 'center' }}
        onPress={() => {
          setLastUncompletedSkillset();
          NavigationService.navigate('App', { screen: 'Learn' });
        }}
      >
        Start Learning
      </ButtonFilled>

      <View style={{ height: 22 }} />

    </View>
  );
};

export default withDimensions(inject('rootStore')(observer(CourseEnrollment)));
