import React from 'react';
import { Text, View } from 'react-native';
import { Body2 } from '@nextstep/app/components/Text';
import { InputErrorMessage, TextInput } from '@nextstep/app/components/TextInput';
import Constants from '@nextstep/app/Config/Constants';
import AnswerChoice from '@nextstep/app/components/linearAssessment/AnswerChoice';
import { currentPalette } from '@nextstep/app/Config/AppConfig';
import { inject } from 'mobx-react';

class SelectQuestion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      questionOtherState: {
        value: undefined,
        showError: false,
        errorMessage: undefined,
      },
      questionAnswers: {
        value: [],
        showError: false,
        errorMessage: undefined,
      },
    };
  }

  updateAnswer = () => {
    const { rootStore: { sessionStore } } = this.props;
    const { questionAnswers, questionOtherState } = this.state;
    const { question } = this.props;

    const tempSelectAnswers = {
      questionId: question.id,
      answerChoice: questionAnswers.value.map(qa => ({ id: qa.id })),
    };

    if (questionOtherState.value) {
      sessionStore.addQuestionnaireAnswer({ ...tempSelectAnswers, other: questionOtherState.value });
    } else sessionStore.addQuestionnaireAnswer(tempSelectAnswers);
  }

  selectAnswer = (answerChoice) => {
    const { questionAnswers } = this.state;

    if (questionAnswers.value.includes(answerChoice)) {
      this.updateStateField('questionAnswers', questionAnswers.value.filter(answer => answer.id !== answerChoice.id));
    } else {
      this.updateStateField('questionAnswers', [...questionAnswers.value, answerChoice]);
    }
  }

  updateStateField = async (objectName: string, text: string, valueFieldName: string = 'value') => {
    const { [objectName]: object } = this.state;

    if (object.showError) {
      this.setState({ [objectName]: { showError: false } });
    }
    await this.setState({ [objectName]: { [valueFieldName]: text } });

    this.updateAnswer();
  }

  isDisabled = () => {
    const { question } = this.props;
    const { questionOtherState, questionAnswers } = this.state;

    if (!question.maxSelections) return false;

    const isOtherEmpty = questionOtherState.value === '' || questionOtherState.value === undefined;
    const amountOfAnswers = questionAnswers.value.length + (isOtherEmpty ? 0 : 1);

    return amountOfAnswers >= question.maxSelections;
  }

  render() {
    const { questionOtherState, questionAnswers } = this.state;
    const { question, showError, errorMessage } = this.props;

    return (
      <View style={{}}>
        <Text>
          <Body2 textAlign="left">{question.text}</Body2>
          {question.required && <Body2 color={currentPalette.text.error}>*</Body2>}
        </Text>

        <View style={{ height: 12 }} />

        { question.answerChoice.map((answerChoice, index) => (
          <AnswerChoice
            key={index}
            selected={questionAnswers.value.includes(answerChoice)}
            selectAnswer={() => this.selectAnswer(answerChoice)}
            themeColor={currentPalette.primary.main}
            lightThemeColor={currentPalette.primary.disable}
            index={index}
            questionType={Constants.QuestionTypes.multipleChoice}
            answerChoice={{ answerChoice: answerChoice.text }}
            showingResult={false}
            tooltip={answerChoice.tooltip}
            disabled={this.isDisabled()}
          />
        )) }

        {question.other && (
          <TextInput
            data={questionOtherState}
            editable={question.maxSelections && questionAnswers.value.length < question.maxSelections}
            customAttributes={{
              placeholder: question.other,
              ref: (ref) => { this.questionOtherState = ref; },
              multiline: question.multiline,
            }}
            valueType={Constants.InputTextTypes.custom}
            onChangeText={(text) => this.updateStateField('questionOtherState', text)}
            showErrorSpace={false}
          />
        )}

        {showError
          ? <InputErrorMessage>{errorMessage}</InputErrorMessage>
          : <View style={{ height: 32 }} />
        }
      </View>
    );
  }
}

export default inject('rootStore')(SelectQuestion);
