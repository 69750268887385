import { StyleSheet } from 'react-native';
import ApplicationStyles from '@nextstep/app/Themes/ApplicationStyles';

export default StyleSheet.create({
  ...ApplicationStyles.modalStyles,
  header: {
    ...ApplicationStyles.modalStyles.row,
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: 20,
    paddingBottom: 8,
  },
  title: {
    fontSize: 18,
    lineHeight: 28,
  },
  pronunciation: {
    fontStyle: 'italic',
    lineHeight: 26,
  },
  body: {
    padding: 20,
    paddingTop: 13,
  },
});
