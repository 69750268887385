import { StyleSheet } from 'react-native';
import { Palette } from '@nextstep/app/Themes';

const currentPalette = Palette.light;

export default StyleSheet.create({
  scrollView: {
    paddingTop: 24,
    backgroundColor: currentPalette.background.main,
  },

  section: { paddingVertical: 24 },

  cardInfo: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  links: {
    paddingVertical: 24,
    paddingHorizontal: 16,
    alignItems: 'flex-start',
  },

  profilePictureContainer: { alignItems: 'center' },

  profilePicture: {
    width: 80,
    height: 80,
    borderRadius: 80 / 2,
    overflow: 'hidden',
  },

  actionButtons: { justifyContent: 'center' },

  editPictureIcon: {
    width: 24,
    height: 24,
    resizeMode: 'contain',
    position: 'relative',
    bottom: 24,
    left: 29,
  },

  cardContainerStyle: {
    display: 'flex',
    borderRadius: 8,
    borderColor: 'rgba(43, 35, 58, 0.05)',
  },

  smallIcon: {
    width: 25,
    height: 25,
    resizeMode: 'contain',
    tintColor: currentPalette.primary.main,
  },
});
