import { types } from 'mobx-state-tree';
import { ContentNodeTypes } from '@nextstep/app/Config/Constants';
import Step from './Step';
import IntroVideo from './IntroVideo';
import LinearAssessment from './LinearAssessment';
import ContentNode from './ContentNode';

const SkillModel = types.model('Skill', {
  id: types.identifierNumber,
  key: types.string,
  version: types.maybe(types.string),
  title: types.maybe(types.string),
  summary: types.maybe(types.string),
  icon: types.maybe(types.string),
  introVideo: types.maybeNull(IntroVideo),
  steps: types.maybe(types.array(Step)),
  linearAssessment: types.maybe(LinearAssessment),
}).views(self => ({
  get loaded() {
    return !!self.summary;
  },
}));

const Skill = types.compose(SkillModel, ContentNode)
  .named(ContentNodeTypes.skill);

export default Skill;
