import { Image, Platform, SafeAreaView, ScrollView } from 'react-native';
import Intercom from 'react-native-intercom';
import React from 'react';
import { createDrawerNavigator, DrawerContentScrollView, DrawerItem } from '@react-navigation/drawer';
import { inject, observer } from 'mobx-react';
import { createStackNavigator } from '@react-navigation/stack';
import HomeScreen from '@nextstep/app/containers/HomeScreen';
import LearnScreen from '@nextstep/app/containers/LearnScreen';
import { createSlideNavigator, SlideItem, SlideItems } from '@nextstep/app/lib/SlideNavigator';
import { ApplicationStyles, Colors, Images, Typography } from '@nextstep/app/Themes';
import SettingsScreen from '@nextstep/app/containers/SettingsScreen';
import InPersonScreen from '@nextstep/app/containers/InPersonScreen';
import PracticeLabDetailsScreen from '@nextstep/app/containers/PracticeLabDetailsScreen';
import PracticeLabAssetScreen from '@nextstep/app/containers/PracticeLabAssetScreen';
import CourseDetailsScreen from '@nextstep/app/containers/CourseDetailsScreen';
import DetailedCourseOutlineScreen from '@nextstep/app/containers/DetailedCourseOutlineScreen';
import TexasResourcesScreen from '@nextstep/app/containers/TexasResourcesScreen';
import NavigationService from '@nextstep/app/services/NavigationService';
import AssignmentScreen from '@nextstep/app/containers/assignments/AssignmentsScreen';
import AssignmentQuestionScreen from '@nextstep/app/containers/assignments/AssignmentQuestionScreen';
import { Body2 } from '@nextstep/app/components/Text';
import AppMainHeader from '@nextstep/app/components/AppMainHeader';
import { currentPalette } from '@nextstep/app/Config/AppConfig';

const Drawer = createDrawerNavigator();
const Slide = createSlideNavigator();
const Stack = createStackNavigator();

const additionalItems = (learner) => {
  const itemsArray = [];

  if (!learner || !learner.config || learner.isLabAccount) return itemsArray;

  if (learner.config.intercomEnabled) {
    itemsArray.push({
      label: 'Chat with a Mentor',
      icon: Images.icons.main.intercomChat,
      onPress: async (rootStore) => {
        await rootStore.sessionStore?.intercomVerifyIdentity();
        await Intercom.displayConversationsList();
      },
    });
  }

  itemsArray.push({
    label: 'Logout',
    icon: Images.icons.logOut,
    onPress: async (rootStore) => {
      await rootStore.sessionStore?.logout();
    },
  });

  return itemsArray;
};

function getRouteIcon(routeName, active) {
  let source;
  if (routeName === 'Home') {
    source = active ? Images.icons.homeActive : Images.icons.home;
  } else if (routeName === 'Learn') {
    source = active ? Images.icons.learnActive : Images.icons.learn;
  } else if (routeName === 'In-Person') {
    source = active ? Images.icons.beakerActive : Images.icons.beaker;
  } else if (routeName === 'Discussion Board') {
    source = active ? Images.icons.forumActive : Images.icons.forum;
  }

  return (
    <Image
      source={source}
      style={ApplicationStyles.icon}
    />
  );
}

function AssigmentQuestions() {
  return (
    <Stack.Navigator
      initialRouteName="Home"
      screenOptions={{ headerShown: false, gestureEnabled: false }}
    >
      <Stack.Screen name="Home" component={AssignmentScreen} />
      <Stack.Screen name="Question" component={AssignmentQuestionScreen} />
    </Stack.Navigator>
  );
}

export const SlideNavigator = inject('rootStore')(observer(({ rootStore, initialRoute }) => {
  const { sessionStore } = rootStore;
  const { learner } = sessionStore;

  const homeTabEnabled = !learner.isLabAccount && !learner.isGuest;
  const learnTabEnabled = !learner.isLabAccount;

  const CustomSlideContent = (props) => {
    const { state, ...rest } = props;
    const newState = { ...state };
    newState.routes = newState.routes.filter((item) => !(item.name === 'SettingsScreen'
      || item.name === 'CourseDetailsScreen'
      || item.name === 'PracticeLabDetailsScreen'
      || item.name === 'DetailedCourseOutlineScreen'
      || item.name === 'TexasResourcesScreen'
    ));

    return (
      <>
        <ScrollView>
          <SafeAreaView forceInset={{ top: 'always', horizontal: 'never' }}>
            <SlideItems
              onItemPress={(route) => NavigationService.navigate('App', { screen: route.name })}
              state={newState}
              {...rest}
            />
            {additionalItems(learner).map(item => (
              <SlideItem
                key={item.label}
                label={item.label}
                icon={(
                  <Image
                    source={item.icon}
                    style={ApplicationStyles.icon}
                  />
                )}
                onPress={() => item.onPress(rootStore)}
              />
            ))}
          </SafeAreaView>
        </ScrollView>
      </>
    );
  };

  return (
    <Slide.Navigator
      header={AppMainHeader}
      style={{ backgroundColor: Colors.BG, flex: 1 }}
      slideContentOptions={{
        activeTintColor: Colors.white,
        activeBackgroundColor: Colors.bright_orange,
        inactiveTintColor: Colors.black,
      }}
      slideContent={CustomSlideContent}
      initialRouteName={initialRoute || (learner && !learner.isGuest ? 'Home' : 'Learn')}
    >
      { learner && homeTabEnabled && (
        <Slide.Screen
          name="Home"
          component={HomeScreen}
          options={{ icon: ({ focused }) => getRouteIcon('Home', focused) }}
        />
      ) }
      {learner && learnTabEnabled && (
        <Slide.Screen
          name="Learn"
          component={LearnScreen}
          options={{ icon: ({ focused }) => getRouteIcon('Learn', focused) }}
        />
      )}
      { learner && learner.config.inPersonEnabled && (
        <Slide.Screen
          name="In-Person"
          component={InPersonScreen}
          options={{ icon: ({ focused }) => getRouteIcon('In-Person', focused) }}
        />
      ) }
      { learner && learner.config.discussionBoardsEnabled && (
        <Slide.Screen
          name="Discussion Board"
          component={AssigmentQuestions}
          options={{ label: 'Discussion Board', icon: ({ focused }) => getRouteIcon('Discussion Board', focused) }}
        />
      )}
      <Slide.Screen name="CourseDetailsScreen" component={CourseDetailsScreen} />
      <Slide.Screen name="DetailedCourseOutlineScreen" component={DetailedCourseOutlineScreen} />
      <Slide.Screen name="TexasResourcesScreen" component={TexasResourcesScreen} />
      <Slide.Screen name="SettingsScreen" component={SettingsScreen} />
      <Slide.Screen name="PracticeLabDetailsScreen" component={PracticeLabDetailsScreen} />
    </Slide.Navigator>
  );
}));

export const DrawerNavigator = inject('rootStore')(observer(({ rootStore, initialRoute }) => {
  const { sessionStore } = rootStore;
  const { learner } = sessionStore;

  const homeTabEnabled = !learner.isLabAccount && !learner.isGuest;
  const learnTabEnabled = !learner.isLabAccount;

  const CustomDrawerContent = (props) => {
    const { state } = props;
    return (
      <>
        <DrawerContentScrollView {...props}>
          {
            state.routes.filter((item) => !(item.name === 'SettingsScreen'
              || item.name === 'CourseDetailsScreen'
              || item.name === 'PracticeLabDetailsScreen'
              || item.name === 'PracticeLabAssetScreen'
              || item.name === 'DetailedCourseOutlineScreen'
              || item.name === 'TexasResourcesScreen'
            )).map((route, i) => {
              const focused = i === state.index;
              // eslint-disable-next-line react/destructuring-assignment
              const { title, drawerLabel, drawerIcon } = props.descriptors[route.key].options;

              return (
                <DrawerItem
                  key={route.key}
                  label={
                    // eslint-disable-next-line no-nested-ternary
                    drawerLabel !== undefined
                      ? drawerLabel
                      : title !== undefined
                        ? title
                        : route.name
                  }
                  icon={drawerIcon}
                  focused={focused}
                  activeTintColor={currentPalette.basic.white}
                  inactiveTintColor={currentPalette.basic.black}
                  activeBackgroundColor={currentPalette.primary.main}
                  labelStyle={{
                    ...Typography.body2,
                    marginLeft: -16, /* Accounting for margin in container */
                    fontWeight: Platform.OS === 'android' ? 'normal' : undefined, /* Fix for mobile */
                  }}
                  style={{ margin: 0, borderRadius: 0 }}
                  onPress={() => NavigationService.navigate('App', { screen: route.name })}
                />
              );
            })
          }
          {additionalItems(learner)?.map(item => (
            <DrawerItem
              style={{ margin: 0 }}
              key={item.label}
              icon={() => (
                <Image
                  style={ApplicationStyles.icon}
                  source={item.icon}
                />
              )}
              label={() => (
                <Body2 style={{ textAlign: 'left', marginLeft: -16 }}>
                  {item.label}
                </Body2>
              )}
              inactiveTintColor={Colors.black}
              onPress={() => item.onPress(rootStore)}
            />
          ))}
        </DrawerContentScrollView>
      </>
    );
  };

  return (
    <Drawer.Navigator
      drawerType="front"
      gestureEnabled={false}
      drawerContent={CustomDrawerContent}
      initialRouteName={initialRoute || (learner && !learner.isGuest ? 'Home' : 'Learn')}
      screenOptions={{ headerShown: false }}
    >
      { learner && homeTabEnabled && (
      <Drawer.Screen
        name="Home"
        component={HomeScreen}
        options={{ drawerIcon: ({ focused }) => getRouteIcon('Home', focused) }}
      />
      ) }
      { learner && learnTabEnabled && (
        <Drawer.Screen
          name="Learn"
          component={LearnScreen}
          options={{ drawerIcon: ({ focused }) => getRouteIcon('Learn', focused) }}
        />
      )}
      { learner && learner.config.inPersonEnabled && (
        <Drawer.Screen
          name="In-Person"
          component={InPersonScreen}
          options={{ drawerIcon: ({ focused }) => getRouteIcon('In-Person', focused) }}
        />
      )}
      { learner && learner.config.discussionBoardsEnabled && (
        <Slide.Screen
          name="Discussion Board"
          component={AssigmentQuestions}
          options={{ label: 'Discussion Board', drawerIcon: ({ focused }) => getRouteIcon('Discussion Board', focused) }}
        />
      )}
      <Drawer.Screen name="CourseDetailsScreen" component={CourseDetailsScreen} />
      <Drawer.Screen name="DetailedCourseOutlineScreen" component={DetailedCourseOutlineScreen} />
      <Drawer.Screen name="TexasResourcesScreen" component={TexasResourcesScreen} />
      <Drawer.Screen name="SettingsScreen" component={SettingsScreen} />
      <Drawer.Screen name="PracticeLabDetailsScreen" component={PracticeLabDetailsScreen} />
      <Drawer.Screen name="PracticeLabAssetScreen" component={PracticeLabAssetScreen} />
    </Drawer.Navigator>
  );
}));
